<!--****Home Slider Start**-->
<div id="tg-homebanner" class="tg-homebanner tg-haslayout ">
    <figure data-vide-bg="poster: images/slider/img-01.jpg" data-vide-options="position: 50% 50%">
        <figcaption>
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="tg-bannercontent">
                            <h1>World’s Largest Marketplace</h1>
                            <h2>Search from 12,45,754 Awesome Verified Ads!</h2>
                            <div class="tg-formtheme tg-formbannersearch">
                            <form class="tg-formbannersearch" #f="ngForm">
                                <fieldset>
                                    <div class="form-group tg-inputwithicon">
                                        <i class="icon-bullhorn ">
                                        <fa-icon [icon]="faBullhorn " size="1x"></fa-icon>
                                        </i>
                                        <input type="text " name="customword " class="form-control " placeholder="What are you looking for ">
                                    </div>
                                    <div class="form-group66 tg-inputwithicon " style=" height: 60px;padding-top: 10px;border-left: 1px solid #dbdbdb; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
                                        <i class="icon-bullhorn ">
                                            <fa-icon [icon]="faLayerGroup"></fa-icon>                                            
                                        </i>
                                        <angular2-multiselect name="catSelect" [data]="allCategories" [(ngModel)]="selectedItems" [settings]="settings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                                            <c-badge>
                                                <ng-template let-item="item">
                                                    <label style="margin: 0px; font-size: 12pt; color: white!important;">{{item.catName}}</label>
                                                    <!-- <img [src]="item.image" style="width: 16px; margin-right: 5px;" /> -->
                                                </ng-template>
                                            </c-badge>
                                            <c-item>
                                                <ng-template let-item="item">
                                                    <label style="color: #333;min-width: 150px;">{{item.catName}}</label>
                                                    <!-- <img [src]="item.image" style="width: 30px; border: 1px solid grey;margin-right: 20px;" />
                                                    <label>Capital - {{item.catName}}</label> -->
                                                </ng-template>
                                            </c-item>
                                        </angular2-multiselect>
                                    </div>
                                    <div style="float: left;;width: 100%;">
                                        <button class="tg-btn " (click)="searchSend()" type="button" style="border-top-right-radius: 5px;border-bottom-right-radius: 5px;">Search Now</button>
                                    </div>

                                </fieldset>

                            </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </figcaption>
    </figure>
</div>
<!--******Home Slider End********-->
