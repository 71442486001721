import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { chattingService } from '../shared/liveUpdate/chatting.service';
import { usermessage } from '../shared/models/chat/usermessage';
import { chatmessages } from '../shared/models/chatmessage';
import { notifyMessage } from '../shared/models/notifyMessage';
import { ActionserviceService } from '../shared/services/compnies/actionservice.service';
import { NotificationService } from '../shared/services/notification.service';
import { chatUser } from './chatUser.model';
import { LocalStorageService } from 'angular-web-storage';
import * as jwt_decode from "jwt-decode";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-user-chat',
  templateUrl: './user-chat.component.html',
  styleUrls: ['./user-chat.component.css']
})
export class UserChatComponent implements OnInit {
  @ViewChildren('chatInput') chatInput: QueryList<ElementRef>;
  itemArray: notifyMessage;
  ChatMessages: notifyMessage[];
  highlight: string;
  selectedIndexs: any;
  index: number;
  loginuserID: any;

  constructor(private router: Router, private route: ActivatedRoute, private userNotifyService: chattingService,
    private actions: ActionserviceService, private notifyService: NotificationService, private ngxLoader: NgxUiLoaderService,
    private storage: LocalStorageService) { }

  ngOnInit(): void {
    this.LoadMyFriends();
    let token = this.storage.get('currentUser');
    let tokenInfo = this.getDecodedAccessToken(token); // decode token   
    this.loginuserID = tokenInfo.nameid
    this.userNotifyService.ChatNotificationReceived.subscribe((data: notifyMessage) => {
      //debugger;
      // alert("Notify") 
      //this.ChatMessages.splice(0, 0, this.itemArray);
      //this.ChatMessages.push(data);

        
      if (tokenInfo != null) {
        if (this.MyFriends.length > 0) {
          const result = this.MyFriends.filter((obj) => {
            return obj.userProfileID == data.senderID;
          });

          const resultSender = this.MyFriends.filter((obj) => {
            return obj.userProfileID == data.recevierID;
          });

         // debugger;
          // const receiveIsOpen = this.MyFriends.filter((obj) => {
          //   return obj.userProfileID == this.loginuserID;
          // });
          if (result != null && result.length > 0 && result[0].IsOpen == true && this.FromUserID == data.senderID){
              if(result[0].IsOpen == true){
              data.seenByReceiver = 1;
              alert("success");
              this.friendmessages.push(data);
            }
          }

          //Sender mesaage update   
          if (resultSender != null && resultSender.length > 0 && resultSender[0].IsOpen == true && tokenInfo.nameid == data.senderID) {
            this.friendmessages.push(data);
            // if(result[0].IsOpen == true){
            //   data.seenByReceiver = 1;
            //   alert("success");
            // }
            // this.friendmessages.forEach(element => {
            //   if (element.recevierID == data.recevierID && result[0].IsOpen == true) {
            //     element.seenByReceiver = 1;               
            //   }
            //   else {

            //   }
            // });
            //this.IsOpenSenderChat();
          }
        //  debugger;
          //Reciever mesaage update 
          if (result != null && result.length > 0 && result[0].IsOpen == true && this.FromUserID == data.senderID && tokenInfo.nameid == data.recevierID) {
            this.friendmessages.push(data);
            this.friendmessages.forEach(element => {
              if (element.senderID == data.senderID && result[0].IsOpen == true) {
                element.seenByReceiver = 1;   
               // this.IsOpenReceiverChat();             
              }
              else {

              }
            });
          }
          else if (tokenInfo.nameid == data.recevierID) { //only notify
            this.MyFriends.forEach(element => {
              if (element.userProfileID == data.senderID && result[0].IsOpen != true) {
                element.newMessageReceived = true;
              }
              else {

              }
            });
          }
          else { }
        }
        else {
        }
      }

    });
    //MessageSeenNotificaiton
    this.userNotifyService.MessageSeenNotificationReceived.subscribe((data: notifyMessage) => {
    // debugger;
      if (tokenInfo != null) {
        if(data.senderID == tokenInfo.nameid){
          const resultSender = this.MyFriends.filter((obj) => {
            return obj.userProfileID == data.recevierID;
          });
          if (resultSender != null && resultSender.length > 0 && resultSender[0].IsOpen == true && this.FromUserID == data.senderID && tokenInfo.nameid == data.recevierID) {            
            this.friendmessages.forEach(element => {
              element.seenByReceiver == 1;              
            });
            this.friendmessages.push(data);
            //this.IsOpenChat();
          }
        }          
      }
    });

  }
  MyFriends: chatUser[];
  SearchFrnd: string;
  searchfrndlist: any;
  friendname: string;
  friendimage: string;
  friendmessages: notifyMessage[];
  mymessages: any;
  message: string;
  FromUserID: any;
  online: any;
  selectedIndex;

  LoadMyFriends() {
    //debugger;
    this.ngxLoader.start();
    this.actions.GetMyFriends().subscribe((data: chatUser[]) => {
      this.MyFriends = data;
      //console.log(this.MyFriends);
      setTimeout(() => {
        this.ngxLoader.stop();

      }, 1000);
    });
  }

  searchnow() {
    if (this.SearchFrnd == "" || this.SearchFrnd == null || this.SearchFrnd == undefined) {
      this.LoadMyFriends();
    } else {
      this.actions.SearchFriends(this.SearchFrnd).subscribe((result) => {
        this.searchfrndlist = result;
        this.MyFriends = this.searchfrndlist;
      });
    }
  }

  onSubmit() {
    const obj = new usermessage();
    obj.msgtoID = this.FromUserID;
    obj.message = this.message;
    this.actions.SendMessages(obj).subscribe((result) => {
    //  debugger
      if (result > 0) {
        this.message = '';
        //this.chatfilter(result,this.index);
        this.MyFriends[this.index].IsOpen = true; 
      } else { this.notifyService.showError('Provide the missing data !', 'Missing Data'); }
    });
  }

  chatfilter(userProfileID, _index) {
    this.actions.From_FriendMessages(userProfileID).subscribe((result) => {
      this.friendmessages = result;
    });
    this.index = _index;
    this.MyFriends[_index].newMessageReceived = false;

    const result = this.MyFriends.filter((obj) => {
      return obj.userProfileID === userProfileID;
    });
    this.friendname = result[0].userName;
    this.friendimage = result[0].imageBase64;

    this.FromUserID = result[0].userProfileID;
    this.online = result[0].OnlineStatus;

    this.MyFriends.forEach(element => {
      element.IsOpen = false;
    });
    //debugger
    this.resetUserList(userProfileID, _index);
    
    this.chatInput.first.nativeElement.focus();
    //this.goToBottom();
  }
  public setRow(items) {
    ////debugger
    this.selectedIndex = items;
  }

  goToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  IsOpenSenderChat(){     
   // debugger
    const obj = new usermessage();
    obj.msgfromID = this.loginuserID;
    this.actions.SendMessagesUPDATE(obj).subscribe((result) => {
    });
  }

  IsOpenReceiverChat(){  
  //  debugger   
    const obj = new usermessage();
    obj.msgtoID = this.FromUserID;
    this.actions.SendMessagesUPDATE(obj).subscribe((result) => {
    });
  }

  public resetUserList(userID: string, _index: number) {
  //  debugger
    if (this.MyFriends.length > 0) {
      this.MyFriends[_index].index = _index;
      //let updateItem = this.MyFriends.findIndex(a => a.userProfileID == userID);
      this.MyFriends[_index].IsOpen = true;
    }
    const obj = new usermessage();
    obj.msgtoID = userID;
    this.actions.SendMessagesUPDATE(obj).subscribe((result) => {
    });
   // this.MyFriends[_index].seenByReceiver = 1;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }


}
