<app-header *ngIf="userMode === '0'"></app-header>
<app-header-inner *ngIf="userMode === '1'"></app-header-inner>
<router-outlet></router-outlet>
<div style="z-index: 10000000000;">
    <ngx-ui-loader fgsColor="#00cc67" pbColor="#00cc67"></ngx-ui-loader>
</div>

<!-- <app-mainloader></app-mainloader> -->
<!-- <div class="container">
    <div class="row">
        <div class="col-xs-12">
            
        </div>
    </div> -->
<!-- <div class="row">
    <div class="col-xs-4">
        <h3>Default Theme Options:</h3>
        <button type="button" class="btn btn-light" (click)="switchTheme('light-theme')">Light</button>
        <button type="button" class="btn btn-dark" (click)="switchTheme('dark-theme')">Dark</button>
    </div>
    <div class="col-xs-4" *ngIf="!signalRAdapter || (signalRAdapter && !signalRAdapter.userId)">
        <h6>Join SignalR chat (Open another tab in incognito mode and join as another user to exchange messages):</h6>
        <button type="button" class="btn" (click)="joinSignalRChatRoom()">Join SignalR Chat Room</button>
    </div>

    <ng-chat *ngIf="signalRAdapter && signalRAdapter.userId" [adapter]="signalRAdapter" [groupAdapter]="signalRAdapter" [userId]="signalRAdapter.userId" [historyEnabled]="false" [pollFriendsList]="true" [theme]="currentTheme" [fileUploadUrl]="fileUploadUrl"
        (onParticipantClicked)="onEventTriggered('ParticipantClicked triggered')" (onParticipantChatOpened)="onEventTriggered('ParticipantChatOpened triggered')" (onParticipantChatClosed)="onEventTriggered('ParticipantChatClosed triggered')" (onMessagesSeen)="onEventTriggered('MessageSeen triggered')">
    </ng-chat>
    <ng-chat *ngIf="!signalRAdapter || (signalRAdapter && !signalRAdapter.userId)" [adapter]="adapter" [groupAdapter]="adapter" [userId]="userId" [historyEnabled]="true" [historyPageSize]="4" [hideFriendsList]="false" [theme]="currentTheme" (onParticipantClicked)="onEventTriggered('ParticipantClicked triggered')"
        (onParticipantChatOpened)="onEventTriggered('ParticipantChatOpened triggered')" (onParticipantChatClosed)="onEventTriggered('ParticipantChatClosed triggered')" (onMessagesSeen)="onEventTriggered('MessageSeen triggered')"></ng-chat>
</div>
</div>-->

<app-footer *ngIf="userMode === '0'"></app-footer>






<!-- SignalR instance -->