import { Component, OnInit ,NgZone } from '@angular/core';
import { Messages } from '../shared/message.model';  
import { ChatService } from '../shared/chat.service'; 

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.css']
})
export class ChatBotComponent implements OnInit {


  ngOnInit(): void {
  }

  title = 'ClientApp';  
  txtMessage: string = '';  
  uniqueID: string = new Date().getTime().toString();  
  messages = new Array<Messages>();  
  message = new Messages();  
  constructor(  
    private chatService: ChatService,  
    private _ngZone: NgZone  
  ) {  
    this.subscribeToEvents();  
  }

  sendMessage(): void {  
    //debugger;
    if (this.txtMessage) {  
      this.message = new Messages();  
      this.message.clientuniqueid = this.uniqueID;  
      this.message.type = "sent";  
      this.message.message = this.txtMessage;  
      //this.message.date = new Date();  
      this.messages.push(this.message);  
      this.chatService.sendChatMessage(this.message);  
      this.txtMessage = '';  
    }  
  }  
  private subscribeToEvents(): void {  
  
    // this.chatService.messageReceived.subscribe((message: Message) => {  
    //   this._ngZone.run(() => {  
    //     if (message.clientuniqueid !== this.uniqueID) {  
    //       message.type = "received";  
    //       this.messages.push(message);  
    //     }  
    //   });  
    // });  
  }  
}
