<div class="row">
    <div class="col-xs-12">
        <h3 class=" text-center chat_header">Company Check</h3>
        <div class="messaging">
            <div class="inbox_msg">
                <div class="mesgs">
                    <div class="msg_history">
                        <div *ngFor="let msg of messages">
                            <div class="incoming_msg" *ngIf="msg.type == 'received'">
                                <div class="incoming_msg_img"> </div>
                                <div class="received_msg">
                                    <div class="received_withd_msg">
                                        <p>
                                            {{msg.message}}
                                        </p>
                                        <span class="time_date"> {{msg.date | date:'medium'}} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="outgoing_msg" *ngIf="msg.type == 'sent'">
                                <div class="sent_msg">
                                    <p>
                                        {{msg.message}}
                                    </p>
                                    <span class="time_date"> {{msg.date | date:'medium'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="type_msg">
                        <div class="input_msg_write">
                            <input type="text" class="write_msg" [value]="txtMessage" (input)="txtMessage=$event.target.value" (keydown.enter)="sendMessage()" placeholder="Type a message" />
                            <button class="btn btn-primary" type="button" (click)="sendMessage()"><i class="fa fa-paper-plane-o" aria-hidden="true"></i> Send </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>