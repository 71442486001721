<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Your Cart</h1> &nbsp;        
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()" style="cursor: pointer;text-decoration:none">Go to Home</a></li>
            <li class="tg-active">Shoping Cart</li>
        </ol>
    </div>
    <main id="tg-main" class="tg-main tg-haslayout">
        <div class="row">
            <fieldset>
                <div class="tg-postanad">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="tg-dashboardbox">
                            <div class="tg-dashboardboxtitle">                                
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 left" style="text-align: left;">
                                    <h2>Shoping Details</h2>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 right" style="text-align: right;">
                                    <a href="javascript:void(0)" (click)="backtoMain()" class="" data-abc="true">Continue Shopping</a>
                                </div>
                            </div>
                            <div class="tg-dashboardholder">
                                <div class="container-xl center">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                            <tr style="background: #00cc67;color: white;">
                                                <th scope="col" style="text-align: left; padding-left: 10px;">Product</th>
                                                <th scope="col" width="120" style="text-align: left; padding-left: 10px;">Quantity </th>
                                                <th scope="col" width="120" style="text-align: left; padding-left: 10px;">Unit Price</th>
                                                <th scope="col" width="120" style="text-align: left; padding-left: 10px;">Price</th>
                                                <th scope="col" class="text-center d-none d-md-block" style="text-align: right; padding-right: 10px;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let product of cartProducts | paginate: { itemsPerPage: 10, currentPage: page,totalItems:TotalLength }">
                                                <td>
                                                    <figure class="itemside align-items-center">
                                                        <div class="aside">
                                                            <img *ngIf="product.proImageBase != null" [src]="product.proImageBase" class="img-sm" />
                                                            <img *ngIf="product.proImageBase == null" src="../../assets/images/ads/no_image.jpg" class="img-sm" />
                                                        </div>
                                                        <figcaption class="info"> <a href="javascript:void(0)" class="title text-dark"
                                                                data-abc="true">{{product.proName}}</a>
                                                            <p class="text-muted small">Brand: {{product.proBrandName}}</p>
                                                        </figcaption>
                                                    </figure>
                                                </td>
                                                <td> 
                                                   <input type="number" class="form-control" (change)="changeQuantity(product,product.proQuantity)" [(ngModel)]="product.proQuantity" name="quantity">
                                                    <!-- <input type="number" class="form-control" value="{{product.proQuantity}}" > -->
                                                </td>
                                                <td>
                                                    <div class="price-wrap"> <var class="price">{{product.proPrice}}</var> <small
                                                            class="text-muted">&nbsp;{{product.proPriceUnitName}}</small> </div>
                                                </td>
                                                <td>
                                                    <div class="price-wrap"> <var class="price">{{product.proPrice * product.proQuantity}}</var> <small
                                                            class="text-muted">&nbsp;{{product.proPriceUnitName}}</small> </div>
                                                </td>
                                                <td class="text-center d-none d-md-block"> 
                                                    <!-- <a data-original-title="Save to Wishlist" href="javascript:void(0)" class="btn btn-light" data-toggle="tooltip"
                                                        data-abc="true" (click)="addFavourite(product)"> <i class="fa fa-heart"></i></a> -->
                                                    <a class="btn btn-light" data-abc="true" (click)="removeCartProduct(product)">
                                                        Remove <i class="fa fa-trash" aria-hidden="true"></i></a>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!cartProducts || cartProducts.length==0">
                                                <td colspan="6">No data found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <pagination-controls class="my-pagination text-center" (pageChange)="page = $event">
                                    </pagination-controls>
                                </div>
                                <hr />
                                <div class="container-xl center">
                                    <aside class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h4 class="d-flex justify-content-between align-items-center mb-3">
                                                    <span class="text-muted">Order Details:</span>
                                                    <span class="badge badge-primary badge-pill" style="float: right;background: #00cc67;">{{ cartProducts.length }}</span>
                                                </h4>
                                                <li class="list-group-item d-flex justify-content-between lh-condensed">
                                                    <h5 style="text-align: center;">Order Summary:</h5>
                                                    <table class="table table-striped table-hover">
                                                        <thead>
                                                            <th style="text-align: center;">Name</th>
                                                            <th style="text-align: center;">Quantity</th>
                                                            <th style="text-align: center;">Price</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let product of cartProducts">
                                                                <td>{{product.proName}}</td>
                                                                <td>{{product.proQuantity}}</td>
                                                                <td>{{product.proPrice * product.proQuantity}} {{product.proPriceUnitName}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!-- <dl class="dlist-align" *ngFor="let product of cartProducts">
                                                        <dt>{{product.proName}}</dt> &nbsp;
                                                        <dt>{{product.proQuantity}}</dt>
                                                        <dd class="text-right ml-3">{{product.proPrice * product.proQuantity}} {{product.proPriceUnitName}}</dd>
                                                    </dl>                        -->
                                                </li>
                                                <hr />
                                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 right ">
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="text-align: right;">
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="text-align: right;">
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: right;">
                                                            <dt>Total price:</dt>
                                                        </div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: right;">
                                                            <dd class="text-right ml-3" >{{ totalValue }}</dd>
                                                        </div> 
                                                    </div>                                                                                      
                                                </div> 
                                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 right ">
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="text-align: right;">
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="text-align: right;">
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: right;">
                                                            <dt>Estimated Total :</dt>
                                                        </div>
                                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: right;">
                                                            <dd class="text-right text-dark b ml-3"><strong>{{ grandTotal }}</strong></dd>
                                                        </div>
                                                    </div>                       
                                                </div> 
                                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 right ">                       
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="text-align: right;">
                                                        <dt>Taxed, Discount and Shipping will be calculated at checkout</dt>
                                                    </div>                                           
                                                </div>                                      
                                                <hr> 
                                                <a href="javascript:void(0)" routerLink="/checkouts/usershopping" class="btn btn-out btn-light btn-main" data-abc="true"> CheckOut</a>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </main>
</div>

