import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { error } from 'protractor';
import { adds } from '../models/Adds/adds.model';
import { categories } from '../models/category/category.model';


@Injectable()
export class searchService{
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     
constructor(private httpclient:HttpClient){}

getALLCategories(){
  //debugger;
 return this.httpclient.get<categories[]>(this.baseUrl+'services/Cat/getAllCategoriesNew',this.httpOptions)
  .pipe(map((response : categories[])=> {
    return response;
  }),
  catchError(error =>{
    return throwError('seomething went wrong');
  })
  )     
}  
}