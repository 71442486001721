export class userskill{
    public mode :string;
    public US_ID: number;
    public US_SkillID: number;
    public US_ParentID :number;
    public US_IsActive :boolean;
    public US_IsDelted :boolean;
    public US_AddedBy :number;
    public US_AddedDate :string;
    public US_EditedBy :string;
    public US_EditedDate :string;
}