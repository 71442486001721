import { Injectable } from '@angular/core';
import {createEffect, Actions,ofType, Effect} from '@ngrx/effects';
import * as searchActions from '../actions/search.action';
import { of, from, pipe, Observable } from 'rxjs';
import { map, switchMap, mergeMap,catchError } from "rxjs/operators";
import { dispatch } from 'rxjs/internal/observable/pairs';
import { Action } from 'rxjs/internal/scheduler/Action';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';


@Injectable()
export class searchEffects{
  constructor(private actions$:Actions,private httpclient:HttpClient, public localStor: LocalStorageService,){
  }
    // @Effect()    
    // setSearchParams = this.actions$.pipe(ofType(searchActions.SearchActionTypes.SETSEARCH),map(
    //     (action: searchActions.trySearch)=>{
    //         debugger;
    //         console.log('insideSearchEffectsParam');
    //         console.log(action.payload);
    //         return action.payload;            
    //     })
    //     , switchMap((searchData : {
    //         category: string , 
    //         subCategory: string,
    //         firstLevel: string,
    //         secondLevel: string,
    //         thirdLevel: string
    //     })=>
    //     {
    //         //debugger;
    //         //console.log('insideSearchEffectsParam');
    //         //console.log(searchData);
    //         return pipe(mergeMap(val => of(`${val}World!`)))            
    //     })               
    //     ); 


        // @Effect()
        // loadPostsSearchResults$ = this.actions$.pipe(
        //   ofType<searchActions.SearchActionTypes.SETSEARCH>(searchActions.trySearch),
        //   mergeMap((action: PostsSearchRequestedAction) => this.postsService.searchPosts(action.payload.searchText)),
        //   map((results: PostsSearchResult[]) => {
        //     return new PostsSearchLoadedAction({ results: results });
        //   })
        // );
  
  
        debugger;
        SETSEARCH: Observable<any> = createEffect(() => this.actions$.pipe(
          ofType(searchActions.SearchActionTypes.SETSEARCH)
          ,map((action: searchActions.trySearch)=>{
              debugger;
              console.log('istMapInSearch');
             //console.log(action.payload);
              return action.payload;
          })
          ,switchMap((searchData:{
             levelOfSearch : string, 
             category: string,
             subCategory : string ,  
             firstChild: string,
             secondChild : string,  
             thirdChild: string,
             searchSender:string
          })=>{
            debugger;
            console.log(searchData);
            this.localStor.set('searchPattern', JSON.stringify(searchData)); 
            return  '';
          })), { dispatch: false });            
                
              
  // @Effect()        
  //  searchSet = this.actions$.pipe(        
  //      ofType(searchActions.SearchActionTypes.SETSEARCH)
  //   ,map((action: searchActions.trySearch)=>{      
  //       //debugger;
  //       console.log('insideSearchE');
  //       console.log(action.payload);
  //       // return {
  //       //         type: searchActions.SearchActionTypes.SETSEARCH,
  //       //         payload : action.payload
  //       //     } 
  //       return action.payload;       
  //   })      
  //   ,switchMap(action => this.httpclient.post<any>(this.url,this.bodyHeader, {
  //           headers: { 'Content-Type':  'application/json' }
  //         }).pipe(map(response => {
  //           //debugger;
  //           //console.log('insideSearchE2');             
  //             return {
  //               type: searchActions.SearchActionTypes.SETSEARCH,
  //               payload : response.token
  //             }             
  //           })
  //         ))
  //        );
   
}