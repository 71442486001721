import { autoFacilities } from './autoFacilities.model';

export class postAutos{
    public color: number;
    public years : number ;
    public bodyCon : number ;
    public macCon: number;
    public trimID : number ;
    public bodyType : number ;
    public doors: number;
    public cylender : number ;
    public transmission : number ;
    public hp: number;
    public war : number ;
    public ft : number ;
    public kilometer: number;
    public motercycleEngine : number ;
    public st : number ;
    public man: number;
    public usage : number ;
    public age : number ;
    public warranty : number ;
    public autoFacilities: autoFacilities[];
}
