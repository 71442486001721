import { books } from './books.model';
import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { error } from 'protractor';


@Injectable()
export class BookService{
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     
topBooks:books[];
constructor(private httpclient:HttpClient){}

  getBooks(){
   // //debugger;
   return this.httpclient.get<books[]>(this.baseUrl+'books/getBooks',this.httpOptions)
    .pipe(map((response : books[])=> {
      return response;
    }),
    catchError(error =>{
      return throwError('seomething went wrong');
    })
    )     
  }
  getALLCategories(){
    //debugger;
   return this.httpclient.get<books[]>(this.baseUrl+'services/Cat/getAllCategories',this.httpOptions)
    .pipe(map((response : books[])=> {
      return response;
    }),
    catchError(error =>{
      return throwError('seomething went wrong');
    })
    )     
  }
}