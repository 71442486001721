import { TopCompanies } from './company.model';
import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { error } from 'protractor';


@Injectable()
export class companyService{
    private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'
        })
      };     
topCompanies:TopCompanies[];
constructor(private httpclient:HttpClient){}

  getRecipe(){
    //debugger;
   return this.httpclient.get<TopCompanies[]>(this.baseUrl+'companies/getCompanyInfo',this.httpOptions)
    .pipe(map((response : TopCompanies[])=> {
      return response;
    }),
    catchError(error =>{
      return throwError('seomething went wrong');
    })
    ) 
    // this.httpclient.get<any>(this.baseUrl+'api/services/Ads/topFifteenAds').pipe
    // (
    //     catchError(this.handleError<TopCompanies[]>('getHeroes', []))
    // )
    // // (map((data : TopCompanies[])=>{
    //     //debugger;  
    //     //console.log(data);        
    //   })
    //   catchError((error: Error) => {
    //     return null;
    //   })
   // }
   // )
   // return this.topCompanies;  
  }
}