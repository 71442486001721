<aside class="col-lg-4">
    <div class="card">
        <div class="card-body">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
                <span class="text-muted">Your cart</span>
                <span class="badge badge-primary badge-pill" style="float: right;">{{ products.length }}</span>
            </h4>
            <li class="list-group-item d-flex justify-content-between lh-condensed">
                <h5>Order Summary</h5>
                <dl class="dlist-align" *ngFor="let product of products">
                    <dt>{{product.proName}}</dt> &nbsp;
                    <dt>{{product.proQuantity}}</dt>
                    <dd class="text-right ml-3">{{product.proPrice * product.proQuantity}} {{product.proPriceUnitName}}</dd>
                </dl>                       
            </li>
            <hr />
            <dl class="dlist-align">
                <dt>Total price:</dt>
                <dd class="text-right ml-3">${{ Total }}</dd>
            </dl>
            <dl class="dlist-align">
                <dt>Delivery Fee:</dt>
                <dd class="text-right text-danger ml-3">+ $ 0.00 {{grandTotal}}</dd>
            </dl>
            <dl class="dlist-align">
                <dt>Total:</dt>
                <dd class="text-right text-dark b ml-3"><strong>${{ totalValue }}</strong></dd>
            </dl>
            <hr> <a href="javascript:void(0)" routerLink="/usershopping" class="btn btn-out btn-primary tn-square btn-main" data-abc="true"> Make Purchase
            </a> <a href="javascript:void(0)" (click)="backtoMain()" class="btn btn-out btn-success btn-square btn-main mt-2" data-abc="true">Continue
                Shopping</a>
        </div>
    </div>
</aside>