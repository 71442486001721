<div class="tg-wrapper tg-haslayout">
  <div class="tg-dashboardbanner">
      <h1>Your Cart</h1> &nbsp;        
      <ol class="tg-breadcrumb">
          <li><a (click)="backtoMain()" style="cursor: pointer;text-decoration:none">Go to Home</a></li>
          <li class="tg-active">Shoping Cart Details</li>
      </ol>
  </div>
  <main id="tg-main" class="tg-main tg-haslayout">
    <div class="row">       
      <div class="tg-postanad">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="tg-dashboardbox">
                        <div class="tg-dashboardboxtitle">                                
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 left" style="text-align: left;">
                                <h2>Order Details</h2>
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 right" style="text-align: right;">
                              <a (click)="backtoMain()" style="cursor: pointer;text-decoration:none" >Continue Shopping</a>
                          </div>
                        </div>
                        <div class="tg-dashboardholder" style="padding: 0px!Important;min-height: 350px;">
                          <div class="container-xl center">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 left" style="text-align: left;">
                            <div>
                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 15px;">
                                <h5>Ship to:</h5>
                              </div>
                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 tg-alert alert alert-success fade in" *ngIf="AddressList1 != null">
                                <p><strong>Home Address:</strong>Your Home address is already saved, you can use it.</p>
                                <div class="tg-anchors">
                                  <a class="tg-btndoaction" href="javascript:void(0)" (click)="HomeShippingAddress()">Apply</a>
                                  <a href="javascript:void(0)" class="close" data-dismiss="alert">&times;</a>
                                </div>
                              </div>
                              <div class=" col-xs-12 col-sm-12 col-md-12 col-lg-12tg-alert alert alert-success fade in" *ngIf="AddressList2 != null">
                                <p><strong>Office Address:</strong>Your Office address is already saved, you can use it.</p>
                                <div class="tg-anchors">
                                  <a class="tg-btndoaction" href="javascript:void(0)" (click)="OfficeShippingAddress()">Apply</a>
                                  <a href="javascript:void(0)" class="close" data-dismiss="alert">&times;</a>
                                </div>
                              </div>
                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 tg-alert alert alert-success fade in" *ngIf="AddressList1 == null && AddressList2 == null">
                                <p><strong>Add Address:</strong></p>
                                <div class="tg-anchors">
                                  <a class="tg-btndoaction" href="javascript:void(0)" (click)="AddNewAddress()">New Address</a>
                                  <a href="javascript:void(0)" class="close" data-dismiss="alert">&times;</a>
                                </div>
                              </div>
                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 0px;">
                                <div class="col-md order-md-1">
                                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 5px;">
                                    <h5>Shipping Address:</h5>
                                  </div>
                                                                    
                                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 0px;" *ngIf="AddressList1 != null">
                                    <div class="" style="width: 100%;">
                                      {{firstName}} {{lastName}} , {{address1}}  , {{this.selectedCountry}} , {{this.state}}
                                    </div>
                                    <div class="tg-alert alert alert-success fade in" style="padding: 10px!important;width: 100%;">
                                      <a class="tg-btndoaction" style="color:white!Important;" href="javascript:void(0)" (click)="showEditAddress()">Edit Address</a>
                                    </div>
                                  </div>
                                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 0px;" *ngIf="AddressList2 != null">
                                    <div style="width: 100%;">
                                      {{firstName}} {{lastName}} , {{address1}}  , {{this.selectedCountry}} , {{this.state}}
                                    </div>
                                    <div class="tg-alert alert alert-success fade in" style="padding: 10px!important;width: 100%;">
                                      <a class="tg-btndoaction" style="color:white!Important;" href="javascript:void(0)" (click)="showEditAddress()">Edit Address</a>
                                    </div>                                    
                                  </div>

                                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 0px;" *ngIf="EditAdress == true">
                                    <fieldset style="    border: 1px solid silver; padding: 10px; margin-top: 10px; margin-bottom: 10px;">
                                      <form class="needs-validation" #f="ngForm" (ngSubmit)="onSubmit(f)">
                                        <div class="row">
                                          <div class="col-md-6 mb-3">
                                            <label for="firstName">First name</label>
                                            <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName" required />
                                            <div class="invalid-feedback" *ngIf="firstNameAlert==true">
                                              Valid first name is required.
                                            </div>
                                          </div>
                                          <div class="col-md-6 mb-3">
                                            <label for="lastName">Last name</label>
                                            <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName" required />
                                            <div class="invalid-feedback" *ngIf="lastNameAlert==true">
                                              Valid last name is required.
                                            </div>
                                          </div>
                                        </div>
                                  
                                        <div class="mb-3">
                                          <label for="email">Email
                                            <span class="text-muted">(Optional)</span>
                                          </label>
                                          <input type="email" class="form-control" id="email" [(ngModel)]="email" name="email" />
                                          <div class="invalid-feedback" *ngIf="emailNameAlert==true">
                                            Please enter a valid email address for shipping updates.
                                          </div>
                                        </div>
                                  
                                        <div class="row">
                                          <div class="col-md-8 b-3">
                                            <label for="address">Address</label>
                                            <input type="text" class="form-control" id="address" placeholder="1234 Main St" [(ngModel)]="address1"
                                              name="address1" required />
                                            <div class="invalid-feedback" *ngIf="address1NameAlert==true">
                                              Please enter your shipping address.
                                            </div>
                                          </div>
                                          <div class="col-md-4">
                                            <label for="type">Address Type</label>
                                            <select class="custom-select d-block w-100 col-md-12" id="type" required [(ngModel)]="addresstype"
                                              name="type">
                                              <option value="0">Options</option>
                                              <option value="home">Home</option>
                                              <option value="office">Office</option>
                                            </select>
                                            <div class="invalid-feedback" *ngIf="typeNameAlert==true">
                                              Please select a valid address type.
                                            </div>
                                          </div>
                                        </div>
                                  
                                        <div class="mb-3">
                                          <label for="address2">Address 2
                                            <span class="text-muted"></span>
                                          </label>
                                          <input type="text" class="form-control" id="address2" placeholder="Apartment or suite" [(ngModel)]="address2"
                                            name="address2" />
                                          <div class="invalid-feedback" *ngIf="address2NameAlert==true">
                                            Please enter your shipping address.
                                          </div>
                                        </div>
                                  
                                        <div class="row">
                                          <div class="col-md-5 mb-3">
                                            <label for="country">Country</label>
                                            <ng-select [items]="CurrienciesList" [(ngModel)]="this.selectedCountry" bindLabel="title"
                                              bindValue="id" appendTo="body" name="selectedCountry" (change)="getCities()">
                                            </ng-select>
                                            <div class="invalid-feedback" *ngIf="countryNameAlert==true">
                                              Please select a valid country.
                                            </div>
                                          </div>
                                          <div class="col-md-4 mb-3">
                                            <label for="state">City</label>
                                            <select class="custom-select d-block w-100 col-md-12" id="state" [(ngModel)]="state" name="state" required>
                                              <option value="0">Select City</option>
                                              <option *ngFor="let subCat of citySelection" value={{subCat.id}}>{{subCat.name}}</option>
                                            </select>
                                            <div class="invalid-feedback" *ngIf="stateNameAlert==true">
                                              Please provide a valid state.
                                            </div>
                                          </div>
                                          <div class="col-md-3 mb-3">
                                            <label for="zip">Zip</label>
                                            <input type="text" class="form-control" id="zip" placeholder="Zip Code" [(ngModel)]="zip" name="zip"
                                              required />
                                            <div class="invalid-feedback" *ngIf="zipNameAlert==true">
                                              Zip code required.
                                            </div>
                                          </div>
                                        </div>
                                        <hr class="mb-4" />
                                        <button class="btn btn-primary btn-lg btn-block" type="submit">
                                          Save
                                        </button>
                                        <button class="btn btn-primary btn-lg btn-block" href="javascript:void(0)" (click)="hideShowAdressDetails()" >
                                          Cancel
                                        </button>
                                      </form>
                                    </fieldset>
                                  </div>
                                  
                                </div>
                              </div>
                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 tg-alert alert alert-success fade in" style=" margin-top: 15px; margin-bottom: 15px;">
                                <div class="">
                                  <p><strong>Shipping Method:</strong>Standard </p>
                                  <p>Delivery is Available only in UAE and Pakistan</p>                                  
                                </div>
                              </div>

                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 tg-alert alert alert-success fade in" style=" margin-top: 15px; margin-bottom: 15px;" *ngIf="cardPayment==true">
                                <div class="">
                                  <p><strong>Card Details:</strong></p>                                     
                                      <form class="needs-validation" #f="ngForm" (ngSubmit)="onUAECardSubmit(f)">
                                          <div class="row">
                                            <div class="col-md-6 mb-3">
                                                <label for="firstName">Card Number</label>
                                                <input type="number" class="form-control" placeholder="14 digit card number " maxlength="14" id="cardNumber" [(ngModel)]="cardNumber" name="cardNumber" />
                                                  <div class="invalid-feedback" *ngIf="cardNumberAlert==true">
                                                    Valid card number is required.
                                                  </div>
                                                  <div class="invalid-feedback" *ngIf="cardNumberDigitAlert==true">
                                                    Valid card number 12 Digit is required.
                                                  </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label for="ExpiryMonth">Expiry Month</label>
                                                <input type="number" class="form-control" maxlength="2" id="ExpiryMonth" [(ngModel)]="ExpiryMonth" name="ExpiryMonth" />
                                                  <div class="invalid-feedback" *ngIf="ExpiryMonthAlert==true">
                                                      Enter valid Expiry Month
                                                  </div>
                                            </div>
                                           </div>
                                           <div class="row">
                                              <div class="col-md-6 mb-3">
                                                        <label for="ExpiryMonth">Expiry Year</label>
                                                        <input type="number" class="form-control" maxlength="2" id="ExpiryYear" [(ngModel)]="ExpiryYear" name="ExpiryYear" />
                                                        <div class="invalid-feedback" *ngIf="ExpiryMonthAlert==true">
                                                          Enter valid Expiry Year
                                                        </div>
                                               </div>
                                               <div class="col-md-6 mb-3">
                                                        <label for="address">CVV</label>
                                                        <input type="number" class="form-control" maxlength="3" id="cardCvv" placeholder="123" [(ngModel)]="cardCvv"
                                                          name="cardCvv" />
                                                        <div class="invalid-feedback" *ngIf="cardCvvAlert == true">
                                                          Enter valid Three Digit code only
                                                        </div>
                                               </div> 
                                            </div>
                                            <hr class="col-md-6 mb-3" />
                                            <button class="btn btn-primary btn-lg btn-block" [disabled]="!addressAvailable"  type="submit">
                                               Continue to checkout
                                            </button>
                                            <button class="btn btn-primary btn-lg btn-block" [disabled]="!addressAvailable" (click)="onOrderSubmit()"  type="button">
                                              Confirm Order Without payment !
                                            </button>
                                    </form>
                                  
                                </div>
                              </div>
                              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 tg-alert fade in" style=" margin-top: 15px; margin-bottom: 15px;" *ngIf="cardPayment==false">                                
                                <button class="btn btn-primary btn-lg btn-block" [disabled]="!addressAvailable" (click)="onOrderSubmit()"  type="button">
                                  Confirm Order We will contact you !
                                </button>                                
                              </div>
                            </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 right" style="text-align: left;min-height: 350px; background-color: #f5f5f5;">
                              <aside class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding-top: 15px; padding-bottom: 15px;">
                                    <div class="container-xl center">
                                        <table class="table table-striped table-hover">
                                            <thead>
                                                <tr style="background: #00cc67;color: white;">
                                                    <th scope="col" style="border-color: transparent !important">Product</th>
                                                    <th scope="col" width="120" style="border-color: transparent !important">Quantity </th>
                                                    <th scope="col" width="120" style="border-color: transparent !important">Unit Price</th>
                                                    <th scope="col" width="120" style="border-color: transparent !important">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let product of cartProducts | paginate: { itemsPerPage: 10, currentPage: page,totalItems:TotalLength }">
                                                    <td style="border: 1px solid #d3d3d3!Important;">
                                                        <figure class="itemside align-items-center">
                                                            <div class="aside">
                                                                <img *ngIf="product.proImageBase != null" [src]="product.proImageBase" class="img-sm" />
                                                                <img *ngIf="product.proImageBase == null" src="../../assets/images/ads/no_image.jpg" class="img-sm" />
                                                            </div>
                                                            <figcaption class="info"> <a href="javascript:void(0)" class="title text-dark"
                                                                    data-abc="true">{{product.proName}}</a>
                                                                <p class="text-muted small">Brand: {{product.proBrandName}}</p>
                                                            </figcaption>
                                                        </figure>
                                                    </td>
                                                    <td> 
                                                      {{product.proQuantity}}
                                                    </td>
                                                    <td>
                                                        <div class="price-wrap"> <var class="price">{{product.proPrice}}</var> <small
                                                                class="text-muted">&nbsp;{{product.proPriceUnitName}}</small> </div>
                                                    </td>
                                                    <td>
                                                        <div class="price-wrap"> <var class="price">{{product.proPrice * product.proQuantity}}</var> <small
                                                                class="text-muted">&nbsp;{{product.proPriceUnitName}}</small> </div>
                                                    </td>
                                                  
                                                </tr>
                                                <tr *ngIf="!cartProducts || cartProducts.length==0">
                                                    <td colspan="6">No data found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <pagination-controls class="my-pagination text-center" (pageChange)="page = $event">
                                        </pagination-controls>
                                    </div>
                                    <hr style="border-top: 1px solid darkgrey" />
                                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top: 15px; margin-bottom: 15px;">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: left;">
                                            <span>SubTotal:</span>
                                      </div>  
                                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: right;">
                                        <span>{{ totalValue }}</span>
                                      </div>  
                                    </div>  
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: left;">
                                            <span>Delivery Fee:</span>
                                      </div>  
                                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: right;">
                                        <span class="text-danger">+  0.00</span>
                                      </div>  
                                    </div> 
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: left;">
                                            <span>Estimated Taxes:</span>
                                      </div>  
                                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: right;">
                                        <span class="text-danger">+  0.00</span>
                                      </div>  
                                    </div> 
                                    <hr/>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top: 15px;">
                                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: left;">
                                            <span style="font-weight: bold;">Total Price:</span>
                                      </div>  
                                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: right;">
                                        <span style="font-weight: bold;">{{ grandTotal }}</span>
                                      </div>  
                                    </div>                                      
                                  </div>
                                </aside>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
      </div>        
    </div>
  </main>
</div>

<!-- <h3 class="text-center">Checking Out Products</h3> -->

<!-- <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 board-inner" id="status-buttons">
  <ul class="nav nav-tabs" id="myTab">
    <div class="liner"></div>


    <li id="productsTab" style="margin: auto;">
      <a [routerLink]="['/usercart']" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" data-toggle="tab" title="Product Summary">
        <span class="round-tabs one">
          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
        </span>
      </a>
    </li>


    <li id="shippingTab" style="margin: auto;">
      <a [routerLink]="['/checkouts/usershopping']" routerLinkActive="active" data-toggle="tab" title="Shipping Details">
        <span class="round-tabs two">
          <i class="fa fa-truck" aria-hidden="true"></i>
        </span>
      </a>
    </li>


    <li id="billingTab" style="margin: auto;">
      <a [routerLink]="['/checkouts/usershopping']" routerLinkActive="active" data-toggle="tab" title="Shipping Details">
        <span class="round-tabs three">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </span>
      </a>
    </li>


    <li id="resultTab" style="margin: auto;">
      <a [routerLink]="['/checkouts/usershopping']" routerLinkActive="active" data-toggle="tab" title="Shipping Details">
        <span class="round-tabs four">
          <i class="fa fa-credit-card" aria-hidden="true"></i>
        </span>
      </a>
    </li>
  </ul>
  <div class="clearfix"></div>
</div> -->

<!-- <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 " style="    border: 2px solid rgba(0, 0, 0, .125);
border-radius: 10px; padding-top: 15px; padding-bottom: 15px;">
  <div class="tg-alert alert alert-success fade in" *ngIf="AddressList1 != null">
    <p><strong>Home Address:</strong>Your Home address is already saved, you can use it.</p>
    <div class="tg-anchors">
      <a class="tg-btndoaction" href="javascript:void(0)" (click)="HomeShippingAddress()">Apply</a>
      <a href="javascript:void(0)" class="close" data-dismiss="alert">&times;</a>
    </div>
  </div>
  <div class="tg-alert alert alert-success fade in" *ngIf="AddressList2 != null">
    <p><strong>Office Address:</strong>Your Office address is already saved, you can use it.</p>
    <div class="tg-anchors">
      <a class="tg-btndoaction" href="javascript:void(0)" (click)="OfficeShippingAddress()">Apply</a>
      <a href="javascript:void(0)" class="close" data-dismiss="alert">&times;</a>
    </div>
  </div>
  <div class="col-md order-md-1">
    <h4 class="mb-3">Shipping Address</h4>
    <form class="needs-validation" #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="firstName">First name</label>
          <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName" required />
          <div class="invalid-feedback" *ngIf="firstNameAlert==true">
            Valid first name is required.
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="lastName">Last name</label>
          <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName" required />
          <div class="invalid-feedback" *ngIf="lastNameAlert==true">
            Valid last name is required.
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="email">Email
          <span class="text-muted">(Optional)</span>
        </label>
        <input type="email" class="form-control" id="email" [(ngModel)]="email" name="email" />
        <div class="invalid-feedback" *ngIf="emailNameAlert==true">
          Please enter a valid email address for shipping updates.
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 b-3">
          <label for="address">Address</label>
          <input type="text" class="form-control" id="address" placeholder="1234 Main St" [(ngModel)]="address1"
            name="address1" required />
          <div class="invalid-feedback" *ngIf="address1NameAlert==true">
            Please enter your shipping address.
          </div>
        </div>
        <div class="col-md-4">
          <label for="type">Address Type</label>
          <select class="custom-select d-block w-100 col-md-12" id="type" required [(ngModel)]="addresstype"
            name="type">
            <option value="0">Options</option>
            <option value="home">Home</option>
            <option value="office">Office</option>
          </select>
          <div class="invalid-feedback" *ngIf="typeNameAlert==true">
            Please select a valid address type.
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="address2">Address 2
          <span class="text-muted">(Optional)</span>
        </label>
        <input type="text" class="form-control" id="address2" placeholder="Apartment or suite" [(ngModel)]="address2"
          name="address2" />
        <div class="invalid-feedback" *ngIf="address2NameAlert==true">
          Please enter your shipping address.
        </div>
      </div>

      <div class="row">
        <div class="col-md-5 mb-3">
          <label for="country">Country</label>
          <ng-select [items]="CurrienciesList" [(ngModel)]="this.selectedCountry" bindLabel="title"
            bindValue="id" appendTo="body" onchange="getCities()" name="selectedCountry">
          </ng-select>
          <div class="invalid-feedback" *ngIf="countryNameAlert==true">
            Please select a valid country.
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="state">City</label>
          <select class="custom-select d-block w-100 col-md-12" id="state" [(ngModel)]="state" name="state" required>
            <option value="0">Select City</option>
            <option *ngFor="let subCat of citySelection" value={{subCat.id}}>{{subCat.name}}</option>
          </select>
          <div class="invalid-feedback" *ngIf="stateNameAlert==true">
            Please provide a valid state.
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="zip">Zip</label>
          <input type="text" class="form-control" id="zip" placeholder="Zip Code" [(ngModel)]="zip" name="zip"
            required />
          <div class="invalid-feedback" *ngIf="zipNameAlert==true">
            Zip code required.
          </div>
        </div>
      </div>
      <hr class="mb-4" />
      <button class="btn btn-primary btn-lg btn-block" type="submit">
        Continue to checkout
      </button>
    </form>
  </div>
</div> -->

<!--   
  <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 ">
    <div class="mb-4">
      <h2>Confirm order and pay</h2>
      <span>please make the payment, after that you can enjoy all the features and benefits.</span>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card p-3">
          <h6 class="text-uppercase">Payment details</h6>
          <div class="inputbox mt-3"> <input type="text" name="name" class="form-control" required="required"> <span>Name
              on card</span>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required"> <i
                  class="fa fa-credit-card"></i> <span>Card Number</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex flex-row">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>Expiry</span>
                </div>
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>CVV</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 mb-4">
            <h6 class="text-uppercase">Billing Address</h6>
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>Street Address</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>City</span>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>State/Province</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="inputbox mt-3 mr-2"> <input type="text" name="name" class="form-control" required="required">
                  <span>Zip code</span>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="mt-4 mb-4 d-flex justify-content-between">
          <span>Previous step</span>
          <button class="btn btn-success px-3">Pay $840</button>
        </div>
      </div>
    </div>
  </div> -->