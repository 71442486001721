<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Company Orders </h1> &nbsp;
         <!-- <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" routerLink="//about">About YourSelf</a>&nbsp;
         <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" routerLink="/userprofile/friend">Friends</a>&nbsp;
         <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" routerLink="/userchat">Message's</a>&nbsp;
         <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" (click)="myuserview()">Preview</a> -->
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()" style="cursor: pointer;">Go to Home</a></li>
            <li class="tg-active">Company Orders</li>
        </ol>
    </div>
    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
					Section Start
			*************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <fieldset>
                    <div class="tg-postanad">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="tg-dashboardbox">
                                <div class="tg-dashboardboxtitle">
                                    <h2>Company Orders</h2>
                                </div>
                                <div class="tg-dashboardholder">   
                                    <div class="row" style="margin-top: 50px;" style="">  
                                        <aside class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style=" border: 2px solid rgba(0, 0, 0, .125);
                                        border-radius: 10px; padding-top: 15px; padding-bottom: 15px; margin-right: 10px;">
                                            <div class="container-xl center">
                                                <table class="table table-striped table-hover">
                                                    <thead>
                                                        <tr style="background: #00cc67;color: white;">
                                                            <th scope="col">Order Number</th>
                                                            <th scope="col" width="120">Status </th>
                                                            <th scope="col" width="120">Date</th>  
                                                            <th scope="col" width="120">Remarks</th> 
                                                            <th scope="col" width="120">Details</th>                         
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let items of OrderList | paginate: { itemsPerPage: 10, currentPage: page,totalItems:TotalLength }">                            
                                                            <!-- src="../../assets/images/angular.png" -->
                                                            <td>{{items.orderNumber}}</td>
                                                            <td>Received</td>
                                                            <td>{{items.orderAddedDate}}</td> 
                                                            <td>Under Processing</td>      
                                                            <td>
                                                                <!-- <a routerLink="/user/orders/{{items.orderId}}"> -->
                                                                    <svg width="1em" (click)="goToOrderDetails(items.orderId)" style="cursor:pointer" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path fill-rule="evenodd"
                                                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                </svg>
                                                                <!-- </a> -->


                                                            </td>                    
                                                        </tr>
                                                        <tr *ngIf="!OrderList || OrderList.length==0">
                                                            <td colspan="6">No data found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <pagination-controls class="my-pagination text-center" (pageChange)="page = $event">
                                                </pagination-controls>
                                            </div>
                                        </aside>       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </section>
    </main>
</div>


