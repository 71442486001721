export class ShippingAddress{
   public ID: number;
   public firstName:string;
   public lastName:string;
   public email:string;
   public address:string;
   public description:string;
   public addresstype:string;
   public userID:number;
   public countryID:string;
   public state:string;
   public zip:string;
}