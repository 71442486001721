import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import { environment } from '../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { switchMap, mergeMap } from "rxjs/operators";
import { error } from 'protractor';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { adPosting } from '../shared/models/adPosting.model';

@Injectable()
export class AdPostingService{
private result:number;
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     

constructor(private httpclient:HttpClient,public localStor: LocalStorageService){ 
  //debugger;   
}

submitThePost(postingDate:adPosting){  
    //debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/Ads/adPost',JSON.stringify(postingDate),this.httpOptions)
  }
}