import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AdPostingService } from './adPosting.service';
import { adPosting } from '../shared/models/adPosting.model';
import { classified } from '../shared/models/classified.model';
import { postAutos } from '../shared/models/postAutos.model';
import { autoFacilities } from '../shared/models/autoFacilities.model';
import { postProperty } from '../shared/models/postProperty.model';
import { propertyFacilities } from '../shared/models/propertyFacilities.model';
import { jobsWanted } from '../shared/models/jobsWanted.model';
import { jobsAvailable } from '../shared/models/jobsAvailable.model';
import { addPhotos } from '../shared/models/addPhotos.model';

@Component({
  selector: 'app-add-posting',
  templateUrl: './add-posting.component.html',
  styleUrls: ['./add-posting.component.css']
})
export class AddPostingComponent implements OnInit {

  constructor(private postingService:AdPostingService ) { 
    //;
  }

  ngOnInit(): void {
  }

  onAddPosting(form : NgForm)
  {
    ////debugger; 
    //first do the validation   
      const postingData = new adPosting();
      postingData.title = form.value.txtTitle;
      postingData.description = form.value.txtDescription;     
      postingData.countryID = 1;
      postingData.cityID= 2;
      postingData.currencyID = form.value.ddlCurrency;    
      //for classified
      //postingData.catID = "D7B411FB-1C21-4F0A-88F7-34D6B789F67D";
      //postingData.subCatID="3B3A5EC7-4CB1-4FFB-85C8-13E849427C90";                 
      //for autos
      //postingData.catID = "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F";
      //postingData.subCatID="FE1C13DE-9342-4EFB-824B-806DA0B8E3FE"; 
      //for property for rent
      //postingData.catID = "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA";
      //postingData.subCatID="A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982"; 
      //for property for sale
      //postingData.catID = "27BBB528-5666-4429-B153-7D6441C0EFA1";
      //postingData.subCatID="220EC902-8ED1-4128-BD35-F3C4318EF5AC"; 
      //for job wanted
      // postingData.catID = "A07D5BE2-BC59-4086-82F5-1E1DF97EC87A";
      // postingData.subCatID="CFC3247C-A7BD-40BD-9A26-58B346D081A5"; 
      //for job hiring
      postingData.catID = "967CEED7-54C0-488E-8AA8-69E6045E6C05";
      postingData.subCatID="4569F2C8-D877-43DD-B6DB-DE691E2CF9DD"; 
      
      postingData.lat = '25.400774';
      postingData.lon = '55.485495';
      postingData.link = '';      
      postingData.phone = form.value.txtNumber;
      postingData.price = form.value.txtPrice;
      postingData.ImageName=""; 
      postingData.addSecondLevel=""; 
      postingData.addThirdLevel=""; 
      postingData.addFourthLevel=""; 
      
      const classifiedData = new classified();      
      classifiedData.ageID = form.value.ddlAge;
      classifiedData.conditionID = form.value.ddlCondition;
      classifiedData.usageID = form.value.ddlUsage;      
      postingData.postClassfied = classifiedData;

      // let autoFaci: autoFacilities[];
      // let facility = new autoFacilities(); 
      // facility.facilityID = 1;     
      // autoFaci.push(facility);

      var autoFa: autoFacilities[] = [
        { "ID":0, "addID": "","facilityID":1 }
    ];

      //let autoFaci = new autoFacilities([]);

      const autosDate = new postAutos();      
      autosDate.age = 1;
      autosDate.autoFacilities = autoFa;
      autosDate.bodyCon = 1;
      autosDate.bodyType = 1;
      autosDate.color = 1;
      autosDate.cylender = 1;
      autosDate.doors = 1;
      autosDate.ft = 1;
      autosDate.hp = 1;
      autosDate.kilometer = 1200;
      autosDate.macCon = 1;
      autosDate.man = 1;
      autosDate.motercycleEngine = 1;
      autosDate.st = 1;
      autosDate.transmission = 1;
      autosDate.trimID = 1;
      autosDate.usage = 1;
      autosDate.war = 1;
      autosDate.warranty = 1;
      autosDate.years = 2010; 

      postingData.postAutos = autosDate;

      var proFa: propertyFacilities[] = [
        { "ID":0, "FacilityID": 1 }
    ];

      const property = new postProperty();      
      property.PM = 1;
      property.baths = 1;
      property.building ="abc";
      property.duration = 1;
      property.furnished = 1;
      property.proAffentNumber = "1";
      property.proBrokerID = "1";
      property.proBrokerName = "abc";
      property.proCommunityFee = 0;
      property.proDeveloper = "abc";      
      property.proDuration = 1;
      property.proFurnished = false;
      property.proID = 1;
      property.proLandlordName = "abc";
      property.proReferenceID = 1;
      property.proRegistrationNumber = "abc";
      property.proSender = false;
      property.propaymentMethod = 1; 
      property.propertyFacilities = proFa;
      property.readyDate = "03/03/2020";
      property.size = 1000;    

      postingData.postProperty = property;
//debugger;
      const jobWanted = new jobsWanted()
      jobWanted.jobAddID = "";
      jobWanted.jobCatID = "";
      jobWanted.jobCommitmentID =1;
      jobWanted.jobEduID = 1;
      jobWanted.jobExpID = 1;
      jobWanted.jobID = 1;
      jobWanted.jobSubCatID = "";
      
      postingData.postJobsWanted = jobWanted;


      const jobHiring = new jobsAvailable()
      jobHiring.jobEduID = 1;
      jobHiring.jobExpID =1;
      jobHiring.JobAddID = "";      
      jobHiring.jobCatID = "";   
      jobHiring.jobCommitmentID = 1;   
      jobHiring.jobID = 1;
      jobHiring.jobSubCatID ="";
      jobHiring.jobsDisplaySalary = false;
      jobHiring.jobsFromAge =1;
      jobHiring.jobsFromSalary = 1;
      jobHiring.jobsGenderID = 1;
      jobHiring.jobsNoOfPositions = 1;
      jobHiring.jobsRequiredAge = false;
      jobHiring.jobsRequiredCity =false;
      jobHiring.jobsTravelRequired = false;
      jobHiring.jobsRequiredDegree = false;
      jobHiring.jobsRequiredExp = false;
      jobHiring.jobsRequiredGender = false;
      jobHiring.jobsShiftID = 1;
      jobHiring.jobsToAge =1;
      jobHiring.jobsToSalary = 1;      
      jobHiring.jobsjDegreeTitle = "abc";   
              
      postingData.postJobs = jobHiring;


      var proPhotos: addPhotos[] = [
        { "addPhotoID":0,
          "addPhotoPath":"testing", 
         "addPhotoIsActive":false ,
          "addPHotoIsDeleted" : false, 
          "addPhotoAddedBy": 10,
          "addPhotoAddedDate" : "03/03/2020" , 
          "addPhotoEditedBy" : 0 ,  
          "addPhotoEditedDate": "",
          "addIsThumbnail": false          
        }
    ];     
    postingData.postPhotos = proPhotos;

      //debugger;
      //console.log(postingData);
    this.postingService.submitThePost(postingData);  
   ////debugger;       
   //this.router.navigate(['../home'],{relativeTo : this.route });
  }

  //vm.SelectedCat = "d7b411fb-1c21-4f0a-88f7-34d6b789f67d";

}
