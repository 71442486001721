<app-search></app-search>
<div class="tg-breadcrumbarea " style="background: #fcfcfc;">
    <div class="container ">
        <div class="row ">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <ol class="tg-breadcrumb ">
                    <li><a (click)="backtoMain()">Home</a></li>
                    <li class="tg-active">{{addDetails[0].cat}} <i class="fa fa-angle-right" aria-hidden="true"></i>  Details</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<main id="tg-main" class="tg-main tg-haslayout" style="background: #fcfcfc;">
    <div class="container">
        <div class="row">
            <div id="tg-twocolumns" class="tg-twocolumns">
                <div class="col-xs-12 col-sm-5 col-md-4 col-lg-4">
                    <aside id="tg-sidebar" class="tg-sidebar">
                        <div class="tg-pricebox">
                            <div id="tg-flagstrapfour" class="tg-flagstrap" data-input-name="country"></div>
                            <div class="tg-priceandlastupdate">
                                <span>{{addDetails[0].price}}<b>:</b>{{addDetails[0].currencyName}}</span>
                                <span>Last Updated: {{addDetails[0].datePosted}}</span>
                            </div>
                        </div>
                        <div class="tg-sellercontactdetail">
                            <div class="tg-sellertitle">
                                <h1>Seller Contact Detail</h1>
                            </div>
                            <div class="tg-sellercontact">
                                <div class="tg-memberinfobox">
                                    <figure>
                                        <a href="javascript:void(0);">
                                            <img *ngIf="addDetails[0].imageBase64 == null || addDetails[0].imageBase64 == ''" src="../../assets/images/ads/no_image.jpg"  alt="image description" style="width: 70px;height: 70px;">
                                            <img *ngIf="addDetails[0].imageBase64 != null" src="{{addDetails[0].imageBase64}}"  alt="image description" style="width: 70px;height: 70px;">
                                        </a>
                                    </figure>
                                    <div class="tg-memberinfo">
                                        <!-- [routerLink]="['/userprofile/detail/',addDetails[0].addedBy]" -->
                                        <h3><a (click)="ProfileView(addDetails[0].addedBy)">{{addDetails[0].userName}}</a></h3>
                                        <span>Member Since {{addDetails[0].memberSince}}</span>
                                        <a class="tg-btnseeallads" href="javascript:void(0);">See All Ads</a>
                                    </div>
                                </div>
                                <a class="tg-btnphone" href="javascript:void(0);">
                                    <i class="fa fa-phone"></i>
                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No."
                                        data-last="123456">
                                        <em (click)="showPhoneNumber()" *ngIf="showPhone == false">Show Phone No.</em>
                                        <em *ngIf="showPhone == true && addDetails[0].phone != null">{{addDetails[0].phone}}</em>
                                        <em *ngIf="showPhone == true && addDetails[0].phone == null">Hidden</em>
                                        <span (click)="showPhoneNumber()">Click To Show Number</span>
                                    </span>
                                </a>
                                <a class="tg-btnmakeanoffer" href="#" data-toggle="modal"
                                    data-target="#tg-modalmakeanoffer">
                                    <i class="fa fa-briefcase"></i>
                                    <span>
                                        <em>Make An Offer</em>
                                        <span>Place Your Comments to offer</span>
                                    </span>
                                </a>
                                <span class="tg-like tg-liked"><i class="fa fa-heart">Add To Favourite</i></span>
                            </div>
                            <div class="tg-sellerlocation">
                                <agm-map *ngIf="latitude && latitude != null || latitude && latitude != ''" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                                    <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                                </agm-map>
                                <div *ngIf="latitude && latitude == null || latitude && latitude == ''" id="tg-locationmap" class="tg-locationmap">
                                    <google-map height="100%" width="100%"> </google-map>
                                </div>
                            </div>
                        </div>
                        <div class="tg-reportthisadbox">
                            <div class="tg-reportthisadtitle">
                                <h2>Report This Ad</h2>
                            </div>
                            <div class="tg-formtheme tg-formreportthisad">
                                <h3>Select Reason:</h3>
                                <fieldset>
                                    <div class="tg-radio">
                                        <input id="tg-radioone" type="radio" name="repotadd"
                                            value="This is illegal/fraudulent" checked>
                                        <label for="tg-radioone">This is illegal/fraudulent</label>
                                    </div>
                                    <div class="tg-radio">
                                        <input id="tg-radiotwo" type="radio" name="repotadd" value="This ad is spam">
                                        <label for="tg-radiotwo">This ad is spam</label>
                                    </div>
                                    <div class="tg-radio">
                                        <input id="tg-radiothree" type="radio" name="repotadd"
                                            value="This ad is a duplicate">
                                        <label for="tg-radiothree">This ad is a duplicate</label>
                                    </div>
                                    <div class="tg-radio">
                                        <input id="tg-radiofour" type="radio" name="repotadd"
                                            value="This ad is in the wrong category">
                                        <label for="tg-radiofour">This ad is in the wrong category</label>
                                    </div>
                                    <div class="tg-radio">
                                        <input id="tg-radiofive" type="radio" name="repotadd"
                                            value="The ad goes against posting rules">
                                        <label for="tg-radiofive">The ad goes against <span
                                                class="tg-themecolor">posting rules</span></label>
                                    </div>
                                    <div class="form-group tg-inputwithicon">
                                        <i class="icon-bubble"></i>
                                        <textarea class="form-control"
                                            placeholder="Provide More Information"></textarea>
                                    </div>
                                    <div class="tg-btns">
                                        <button class="tg-btn" type="button">Send Report</button>
                                        <button class="tg-btn" type="button">Cancel</button>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </aside>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-8 col-lg-8">
                    <div id="tg-content" class="tg-content">
                        <div class="tg-ad tg-verifiedad tg-detail tg-addetail" style="background: #fcfcfc;">
                            <div class="tg-adcontent">
                                <ul class="tg-pagesequence">
                                    {{addDetails[0].cat}} <i class="fa fa-angle-double-right" aria-hidden="true"></i> {{addDetails[0].subCat}}
                                    <!-- <li><a href="javascript:void(0);">{{addDetails[0].cat}}</a> > </li>
                                    <li><a href="javascript:void(0);">{{addDetails[0].subCat}}</a></li> -->
                                    <!-- <li><span>HP</span></li> -->
                                </ul>
                                <div class="tg-adtitle">
                                    <h2>{{addDetails[0].title}}</h2>
                                </div>
                                <ul class="tg-admetadata">
                                    <li>Country
                                        <!-- <a href="javascript:void(0);">{{addDetails[0].userName}}</a> -->
                                    </li>
                                    <!-- <li>Ad Id: <a href="javascript:void(0);">248GCa57</a></li> -->
                                    <li><i class="fa fa-globe">
                                            <!-- <fa-icon [icon]="faGlobe " size="1x"></fa-icon> -->
                                        </i>
                                        <address>{{addDetails[0].country}}<b> / </b>{{addDetails[0].city}}</address>
                                    </li>
                                    <!-- [disabled]="(authState | async).athenticated === true" -->

                                    <i class="icon-thumbs-up" style="padding: 5px;">
                                        <button [disabled]="(authState | async).athenticated === false"
                                            (click)="likeDislikePost(1)" class="backTransparent">
                                            <ejs-tooltip id="tooltip" content="Like the post">
                                                <fa-icon [icon]="faThumbsUp" size="1x" [styles]="{'color': '#00cc67'}"
                                                    class="likeButton"></fa-icon>
                                            </ejs-tooltip>
                                        </button>
                                    </i>
                                    <span>{{addLikes}}</span>

                                    <i class="icon-thumbs-down" style="padding: 5px;">
                                        <button [disabled]="(authState | async).athenticated === false"
                                            (click)="likeDislikePost(2)" class="backTransparent">
                                            <ejs-tooltip id="tooltip1" content="Dislike the post">
                                                <fa-icon [icon]="faThumbsDown " size="1x"
                                                    [styles]="{'color': '#00cc67'}" class="likeButton"></fa-icon>
                                            </ejs-tooltip>
                                        </button>

                                    </i>

                                    <span>{{addDisLikes}}</span>
                                    <!-- <button class="btn btn-success"  (click)="counters()">{{counter}}</button> -->
                                    
                                    <i class="icon-eye" style="margin-left: 5px;    margin-right: 5px;">
                                            <fa-icon [icon]="faEye " size="1x"></fa-icon>
                                        </i><span>{{addDetails[0].viewCount}}</span>
                                </ul>
                                <div class="tg-share">
                                    <!-- <share-button [theme]="'modern-dark'" [button]="'facebook'" [url]="'https://twitter.com/'" [autoSetMeta]="false"></share-button> -->
                                    <!-- <button mat-fab shareButton="facebook" [style.backgroundColor]="share.prop.facebook.color">
                                        <fa-icon [icon]="share.prop.facebook.icon" size="lg"></fa-icon>
                                      </button> -->
                                    <strong>share:</strong>
                                    <ul class="tg-socialicons">
                                        <li class="tg-facebook" *ngFor="let link of Facebook">
                                            <a [href]="link?.link" target="_blank"><i>
                                                    <fa-icon [icon]="faFacebook " size="1x"></fa-icon>
                                                </i></a></li>
                                        <li class="tg-twitter"  *ngFor="let link of Twitter">
                                            <a [href]="link?.link"  target="_blank"><i>
                                                    <fa-icon [icon]="faTwitter " size="1x"></fa-icon>
                                                </i></a></li>
                                        <li class="tg-pinterestp" *ngFor="let link of Pinterest">
                                            <a  [href]="link?.link"  target="_blank"><i>
                                                    <fa-icon [icon]="faPinterest " size="1x"></fa-icon>
                                                </i></a></li>
                                        <li class="tg-whatsapp" *ngFor="let link of Whatsapp">
                                            <a  [href]="link?.link"  target="_blank"><i>
                                                    <fa-icon [icon]="faWhatsapp " size="1x"></fa-icon>
                                                </i></a></li>
                                        <!-- <li class="tg-rss"><a href="javascript:void(0);"><i class="fa fa-rss">
                                                </i></a></li> -->
                                    </ul>
                                    <div class="tg-adadded">
                                        <i style="margin-right: 5px;">
                                            <fa-icon [icon]="faMobile " size="1x"></fa-icon>
                                        </i>
                                        <span>Added via PC on {{addDetails[0].datePosted}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="homeBigImage">
                            <span class="tg-themetag tg-featuretag">featured</span>
                            <span class="tg-photocount">See 18 Photos</span>
                            <div id="tg-productgallery" class="tg-productgallery">
                                <p>Put your alt no-js content here.</p>
                            </div>
                        </div> -->
                            <div *ngIf="addDetails[0].cat == 'Classified'" class="row" id="divClass"
                                style="margin-bottom: 15px;">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-md-6" style="float: left;">
                                            <h5>Condition:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <i class="fa fa-check"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].condition !=null)?addDetails[0].condition:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-md-5" style="float: left;">
                                            <h5>Age:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <i class="fa fa-calendar"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].age !=null)?addDetails[0].age:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-md-6" style="float: left;">
                                            <h5>Usage:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <i class="fa fa-check"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].usage !=null)?addDetails[0].usage:'N/A'}}</h6>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="addDetails[0].cat == 'Autos'" class="row" id="divAutos"
                                style="margin-bottom: 15px;background: #fcfcfc;">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Condition:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <i class="fa fa-car"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear" >                                      
                                            <h6>{{(addDetails[0].condition !=null)?addDetails[0].condition:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear" >
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear" >
                                            <h5>Year:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <i class="fa fa-calendar"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear" >
                                            <h6>{{(addDetails[0].years !=null)?addDetails[0].years:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear" >
                                            <h5>Color:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <i class="fa fa-paint-brush"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">
                                            <h6>{{(addDetails[0].color !=null)?addDetails[0].color:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Machanical:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/MechanicalCondition.png"
                                                height="25" width="25" alt="Machinical">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">
                                            <h6>{{(addDetails[0].macCon !=null)?addDetails[0].macCon:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Type:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/BodyCondition.png" height="25"
                                                width="25" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">                                       
                                            <h6>{{(addDetails[0].bodyType !=null)?addDetails[0].bodyType:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Doors:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/Doors.png"
                                                height="25" width="25" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">                                     
                                            <h6>{{(addDetails[0].doors !=null)?addDetails[0].doors:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>cylender:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/Cylinders.png" height="25"
                                                width="25" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">
                                            <h6>{{(addDetails[0].cylender !=null)?addDetails[0].cylender:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Transmission:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/Automatic.png" height="25"
                                                width="25" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">
                                            <h6>{{(addDetails[0].transmission !=null)?addDetails[0].transmission:'N/A'}}
                                            </h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Horse/Power:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/Horse.png"
                                                height="25" width="25" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">
                                            <h6>{{(addDetails[0].hp !=null)?addDetails[0].hp:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Warrenty:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/Warranty.png" height="25"
                                                width="25" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">
                                            <h6>{{(addDetails[0].war !=null)?addDetails[0].war:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Fuel:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/FuelType.png" height="25"
                                                width="25" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">
                                            <h6>{{(addDetails[0].ft !=null)?addDetails[0].ft:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Kilometer:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/Km.png"
                                                height="25" width="25" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">                                        
                                            <h6>{{(addDetails[0].kilometer !=null)?addDetails[0].kilometer:'N/A'}}</h6>                                        
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Seller:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <i class="fa fa-user-circle"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">                                        
                                            <h6>{{(addDetails[0].st !=null)?addDetails[0].st:'N/A'}}</h6>                                        
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Manufacturer:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <i class="fa fa-user-circle-o"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear">                                      
                                            <h6>{{(addDetails[0].man !=null)?addDetails[0].man:'N/A'}}</h6>                                        
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="addDetails[0].cat == 'Property For Sale' || addDetails[0].cat == 'Property For Rent'"
                                class="row" id="divProperty" style="margin-bottom: 15px;">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Beds:</h5>
                                        </div>
                                        <div class="col-md-4" style="float: left;">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/bed.png"
                                                height="25" width="25" alt="Machinical">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].beds !=null)?addDetails[0].beds:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Baths:</h5>
                                        </div>
                                        <div class="col-md-4" style="float: left;">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/bath.png"
                                                height="25" width="25" alt="Machinical">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].baths !=null)?addDetails[0].baths:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Building:</h5>
                                        </div>
                                        <div class="col-md-4" style="float: left;">
                                            <i class="fa fa-building"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].building !=null)?addDetails[0].building:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Furnished:</h5>
                                        </div>
                                        <div class="col-md-4" style="float: left;">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/furnished.png" height="25"
                                                width="25" alt="Machinical">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].furnished !=null)?addDetails[0].furnished:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Size:</h5>
                                        </div>
                                        <div class="col-md-4" style="float: left;">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/size.png"
                                                height="25" width="25" alt="Machinical">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].size !=null)?addDetails[0].size:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Ready By:</h5>
                                        </div>
                                        <div class="col-md-3" style="float: left;">
                                            <i class="fa fa-calendar"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].readyDate !=null)?addDetails[0].readyDate:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Duration:</h5>
                                        </div>
                                        <div class="col-md-4" style="float: left;">
                                            <i class="fa fa-calendar"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].duration !=null)?addDetails[0].duration:'N/A'}}</h6>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="addDetails[0].cat == 'Jobs' || addDetails[0].cat == 'I want Job' || addDetails[0].cat == 'I am Hiring' " class="row" id="divAutos" 
                                style="margin-bottom: 15px;background: #fcfcfc;">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Experience:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/certificate.png" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].experience !=null)?addDetails[0].experience:'N/A'}}
                                            </h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Education:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/graduation-cap.png"
                                                alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].education !=null)?addDetails[0].education:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Salary:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/salary.png"
                                                alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].fromSalary !=null)?addDetails[0].fromSalary :'N/A'}} -
                                                {{(addDetails[0].fromSalary !=null)?addDetails[0].toSalary :'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Shift:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/shift.png"
                                                alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].shift !=null)?addDetails[0].shift:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Position:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/career-promotion.png"
                                                alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].positions !=null)?addDetails[0].positions:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Gender:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/gender.png"
                                                alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].gender !=null)?addDetails[0].gender:'N/A'}}</h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Degree :</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/education.png" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].degreeTitle !=null)?addDetails[0].degreeTitle:'N/A'}}
                                            </h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Commitmant:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/contract.png" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].commitment !=null)?addDetails[0].commitment:'N/A'}}
                                            </h6>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="float: left;">
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>TravelRequired:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons" src="../assets/images/AdsImages/Icons/world.png"
                                                alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].travelRequired
                                                !=null)?addDetails[0].travelRequired:'N/A'}}</h6>
                                    </div>
                                    <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 leftClear">
                                        <div class="col-xs-6 col-sm-6 col-md-8 leftClear">
                                            <h5>Age:</h5>
                                        </div>
                                        <div class="col-xs-4 col-sm-6 col-md-4 leftClear">
                                            <img class="details-icons"
                                                src="../assets/images/AdsImages/Icons/Warranty.png" height="25"
                                                width="25" alt="condition">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 leftClear"> 
                                            <h6>{{(addDetails[0].toAge !=null)?addDetails[0].fromAge :'N/A'}} -
                                                {{(addDetails[0].toAge !=null)?addDetails[0].toAge :'N/A'}}</h6>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="demo">
                                <ul id="vertical">

                                    <li ng-if="addDetails[0].cat == 'Property For Rent' || addDetails[0].cat == 'Property For Sale'" data-thumb="assets/images/AdsImages/{{addDetails[0].cat}}/{{item.add_ID}}/{{item.add_PhotoPath}}" ng-repeat="item in addImages">
                                        <img src="assets/images/AdsImages/Property/{{item.add_ID}}/{{item.add_PhotoPath}}">
                                    </li>
                                    <li ng-if="addDetails[0].cat == 'Classified' || addDetails[0].cat == 'Autos'" data-thumb="assets/images/AdsImages/{{addDetails[0].cat}}/{{item.add_ID}}/{{item.add_PhotoPath}}" ng-repeat="item in addImages">
                                        <img src="assets/images/AdsImages/{{addDetails[0].cat}}/{{item.add_ID}}/{{item.add_PhotoPath}}">
                                    </li>
                                    <li data-thumb="">
                                        <img src="" />
                                    </li>
                                    <li data-thumb="">
                                        <img src="" />
                                    </li>
                                </ul>
                            </div> -->
                                               
                            <!--************************************
                        Offers/Messages Start
                        *************************************-->

                            <div *ngIf="galleryImagesList.length > 0 && this.addDetails[0].adImagesData[0].imageBase64 != ''" class="gallery-wrapper">
                                <!-- <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery> -->
                                <ngx-gallery [options]="galleryOptions" [images]="galleryImagesList"
                                    class="ngx-gallery">
                                </ngx-gallery>
                            </div>
                            <img *ngIf="this.addDetails[0].adImagesData == null || this.addDetails[0].adImagesData[0].imageBase64 == ''" src="/assets/images/no_image.jpg" alt="image description" >
                            <div class="tg-share">
                                <strong>Description:</strong>
                                <div class="tg-dashboardholder tg-offersmessages">
                                    <div class="ql-snow">
                                        <p [innerHtml]="addDetails[0].description"></p>
                                        <!-- <p class="ql-editor" [innerHtml]="transformhtml(addDetails[0].description)"></p> -->
                                    </div>                                
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 tg-lgcolwidthfull"
                                style="padding-left: 0px!important;padding-right:0px!important">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle" style="background-color: #00cc67;">
                                        <h2 style="color: white;">Comments</h2>
                                    </div>
                                    <div class="tg-dashboardholder tg-offersmessages">
                                        <ul>
                                            <li style="list-style: none;">
                                                <div class="tg-chatarea">
                                                    <!-- <div class="tg-messages tg-verticalscrollbar tg-dashboardscrollbar"> -->
                                                    <div class=" tg-dashboardscrollbar" malihu-scrollbar
                                                        [scrollbarOptions]="scrollbarOptions">

                                                        <!-- <div *ngFor="let item of addsNewComments[0].adComments" class="tg-offerermessage"></div> -->
                                                        <div *ngFor="let item of addsNewComments"
                                                            class="tg-offerermessage">
                                                            <figure>
                                                                <!-- <img src="{{item.userImage}}" alt="image description"> -->
                                                                <img [src]="item.imageBase64" alt="image description">
                                                                <!-- <img *ngIf="item.imageBase64 == null || item.imageBase64 == 0 || item.imageBase64 == ''" src="../../assets/images/ads/dummyimage.png" alt="image description"> -->
                                                            </figure>
                                                            <div class="tg-description">
                                                                <h6>{{(item.addedByName!=null)?item.addedByName: "my"}}</h6>
                                                                <p>{{item.description}}</p>
                                                                <!-- <span ng-bind-html="{{item.description}}"></span> -->
                                                                <div class="clearfix"></div>
                                                                <time
                                                                    datetime="2017-08-08">{{item.timeCommented}}</time>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="tg-replaybox">
                                                        <textarea
                                                            [disabled]="(authState | async).athenticated === false"
                                                            class="form-control" name="reply"
                                                            [(ngModel)]="commentDescription"
                                                            ng-keyup="$event.keyCode == 13 && submitComment()"
                                                            (keyup.enter)="submitComment()"
                                                            placeholder="Type Here &amp; Press Enter"></textarea>
                                                        <!-- <button type="button" class="btn btn-success" (click)="submitComment()">Send</button> -->
                                                        <!-- <div class="tg-iconbox">
                                                            <i class="icon-thumbs-up">

                                                            </i>
                                                            <i class="icon-thumbs-down"></i>
                                                            <i class="icon-smile"></i>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!--************************************
                        Offers/Messages End
                *************************************-->
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="tg-youmayalsolike">
                        <div class="tg-sectionhead">
                            <div class="tg-title">
                                <h2>More Ads By {{addDetails[0].userName}}</h2>
                            </div>
                            <div class="tg-description">
                                <p>Total 432 Ads Posted</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="tg-ads">
                                <div *ngFor="let item of topAddsByUser; let i = index" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                                    <div class="tg-ad tg-verifiedad" *ngIf="i < 4">
                                        <figure (click)="onMoreProducts(item.cat,item.addID)">
                                            <span class="tg-themetag tg-featuretag">featured</span>
                                            <!-- <a href="javascript:void(0);"><img src="{{item.mainImagePath}}{{item.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                            <a href="javascript:void(0);">
                                                <img *ngIf="item.addImageBase != null && item.addImageBase != ''" [src]="item.addImageBase" alt="image description" class="featuredAdsImageClass">
                                                <img *ngIf="item.addImageBase == null || item.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                            </a>
                                            <span class="tg-photocount">See All Photos</span>
                                        </figure>
                                        <div class="tg-adcontent">
                                            <ul class="tg-productcagegories">
                                                <li><a href="javascript:void(0);">{{item.cat}}</a></li>
                                            </ul>
                                            <div class="tg-adtitle">
                                                <h3><a href="javascript:void(0);">{{item.title}}</a></h3>
                                            </div>
                                            <time datetime="2017-06-06">Last Updated: {{item.timeOfThePost}} </time>
                                            <div class="tg-adprice">
                                                <h4>{{item.currency}}:{{item.price}}</h4>
                                            </div>
                                            <!-- <address>44-46 abc Road, Manchester</address> -->
                                            <div class="tg-phonelike">
                                                <a class="tg-btnphone" [ngClass]="showPhones == item.addID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                    <i class="fa fa-phone"></i>
                                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                        <em *ngIf="showPhones == item.addID">{{item.mobileNumber}}</em>
                                                        <em (click)="showPhoneNumber2(item.addID)" [ngClass]="showPhones != item.addID ? 'view' : 'hidden'">Show Phone No.</em>
                                                    </span>
                                                </a>
                                                <span class="tg-like tg-liked"><i class="fa fa-heart"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><br/>
                            <!-- <div class="tg-viewallbox">
                                <a class="tg-btn" href="javascript:void(0)">View All</a>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="tg-youmayalsolike">
                        <div class="tg-sectionhead">
                            <div class="tg-title">
                                <h2>You may also like !</h2>
                            </div>
                            <div class="tg-description">
                                <p>Total 5000 Ads Posted</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="tg-ads">
                                <div *ngFor="let item of topAdds; let i = index" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                                    <div class="tg-ad tg-verifiedad" *ngIf="i < 4">
                                        <figure (click)="onMoreProducts(item.cat,item.addID)">
                                            <span class="tg-themetag tg-featuretag">featured</span>
                                            <!-- <a href="javascript:void(0);"><img src="{{item.mainImagePath}}{{item.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                                            <a href="javascript:void(0);">
                                                <img *ngIf="item.addImageBase != null && item.addImageBase != ''" [src]="item.addImageBase" alt="image description" class="featuredAdsImageClass">
                                                <img *ngIf="item.addImageBase == null || item.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                            </a>
                                            <span class="tg-photocount">See All Photos</span>
                                        </figure>
                                        <div class="tg-adcontent">
                                            <ul class="tg-productcagegories">
                                                <li><a href="javascript:void(0);">{{item.cat}}</a></li>
                                            </ul>
                                            <div class="tg-adtitle">
                                                <h3><a href="javascript:void(0);">{{item.title}}</a></h3>
                                            </div>
                                            <time datetime="2017-06-06">Last Updated: {{item.datePosted}} </time>
                                            <div class="tg-adprice">
                                                <h4>{{item.currency}}:{{item.price}}</h4>
                                            </div>
                                            <!-- <address>44-46 abc Road, Manchester</address> -->
                                            <div class="tg-phonelike">
                                                <a class="tg-btnphone" [ngClass]="showPhones == item.addID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                                    <i class="fa fa-phone"></i>
                                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                                        <em *ngIf="showPhones == item.addID">{{item.phone}}</em>
                                                        <em (click)="showPhoneNumber2(item.addID)" [ngClass]="showPhones != item.addID ? 'view' : 'hidden'">Show Phone No.</em>
                                                    </span>
                                                </a>
                                                <span class="tg-like tg-liked"><i class="fa fa-heart"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tg-viewallbox">
                                <a class="tg-btn" (click)="goSearch()">View All</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <script>
        var _clickelement = $('.tg-btnphone');
        _clickelement.on('click', 'span', function() {
            $(this).find('em').text($(this).data('last'));
        });
    </script> -->
</main>

<!-- <div class="tg-ads tg-adsvtwo tg-adslist">
                            <div class="row">
                                <div *ngFor="let item of addResults;let i = index" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="tg-ad tg-verifiedad" style="border: 1px solid #eee;">
                                        <figure>
                                            <span class="tg-themetag tg-featuretag">featured</span>
                                            <a href="javascript:void(0);"><img src="{{item.imagePath}}" alt="{{item.title}}" class="featuredAdsImageClass"></a>
                                            <!-- <a href="javascript:void(0);"><img src="../assets/images/AdsImages/Autos/4C741829-C8BC-4AE4-B274-532D22774D33/main.jpeg" alt="{{item.title}}" class="featuredAdsImageClass"></a>
                                            <span class="tg-photocount">See 10 Photos</span>
                                        </figure>
                                        <div class="tg-adcontent">
                                            <ul class="tg-productcagegories">
                                                <li><a href="javascript:void(0);">{{item.cat}}</a></li>
                                                <div style="float:right">
                                                    <li><i class="fa fa-eye" style="color: #4c505f;"></i> {{item.totalAddViews}}</li>
                                                </div>
                                            </ul>
                                            <div class="tg-adtitle">
                                                <h3><a href="javascript:void(0);">{{item.title}}</a></h3>
                                            </div>
                                            <time datetime="2017-06-06">Date Time: {{item.datePosted}} / {{item.timePosted}}</time>
                                            <div class="tg-adprice">
                                                <h4>{{item.currency}} : {{item.price}}</h4>
                                            </div>
                                            <address><i class="fas fa-location-arrow" style="margin-right: 5px;"></i>{{item.country}} - {{item.city}}</address>
                                            <div class="tg-phonelike">
                                                <a class="tg-btnphone" href="javascript:void(0);">
                                                    <i class="icon-phone-handset"></i>
                                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6"><em>Show Phone No.</em></span>
                                                </a>
                                                <span class="tg-like tg-liked"><i class="fa fa-heart"></i></span>
                                            </div>
                                        </div>
                                        <ul class="tg-productFooter">
                                            <div style="float:left;padding-top: 2%;">
                                                <li style="list-style: none;text-decoration: none;float: left;font-size: 15px; color:#00cc67" class="fas fa-thumbs-up" (click)="submitPostLikeDislike(1,i,addResults[i])"> </li>
                                                <li style="list-style: none;text-decoration: none;float: left;font-size: 15px;margin-top: -13%;padding-left: 5px;"> {{item.totalLikeCount}}</li>
                                                <li style="list-style: none;text-decoration: none;float: left;font-size: 15px;padding-left: 10px; color:#00cc67" class="fas fa-thumbs-down" (click)="submitPostLikeDislike(2,i,addResults[i])"> </li>
                                                <li style="list-style: none;text-decoration: none;float: left;font-size: 15px;margin-top: -13%;padding-left: 5px;"> {{item.totalDisLikeCount}}</li>
                                            </div>
                                            <div style="float:right">
                                                <li style="list-style: none;text-decoration: none;"> Posted by : {{item.userName}} <img src="{{item.userImagePath}}" alt="{{item.userName}}" class="uesrImageClass"></li>
                                            </div>
                                        </ul>
                                        <div *ngIf="item.adComments.length > 0" class="tg-dashboardholder tg-offersmessages" style="padding:0px">
                                            <ul>
                                                <li style="list-style: none;text-decoration: none; ">
                                                    <div class="tg-chatarea ">
                                                        <div class="tg-dashboardscrollbar " malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                                                            <div *ngFor="let comm of item.adComments" class="tg-offerermessage ">
                                                                <figure><img src="{{comm.userImage}} " alt="image description " style="width:35px;height:35px "></figure>
                                                                <div class="tg-description ">
                                                                    <div style="float:left;margin-top: 11px; ">
                                                                        <h6 class="ng-binding ">{{comm.addedByName}}</h6>
                                                                    </div>
                                                                    <div style="float:left ">
                                                                        <p class="ng-binding ">{{comm.description}}</p>
                                                                    </div>
                                                                    <!-- <span ng-bind-html="{{item.description}} "></span>
                                                                    <div class="clearfix "></div>
                                                                    <time datetime="2017-08-08 ">{{comm.timeCommented}}</time>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="form-group ">
                                            <div style="float: left;width: 79% ; padding-right: 5px; "><input type="text " name="adtitle " [(ngModel)]="commentDescription" class="form-control " placeholder="Knock Here * " ng-keyup="$event.keyCode==13 && vm.submitComment(item,vm.commentDescription) " (keyup.enter)="submitComment(i,addResults[i],commentDescription)"></div>
                                            <div style="float: left;width: 19% ">
                                                <!-- <button [disabled]="(authState | async).athenticated === false" class="tg-btn " type="button " name="reply" ng-keyup="$event.keyCode == 13 && submitComment()" (keyup.enter)="submitComment(i,addResults[i],commentDescription)" placeholder="Type Here &amp; Press Enter">Knock</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->