import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Actionbtn } from '../shared/models/Company/User-About/Action_btn';
import { ActionserviceService } from '../shared/services/compnies/actionservice.service';
import { NotificationService } from '../shared/services/notification.service';
import { openSearchService } from '../shared/services/search/openSearch.service';

@Component({
  selector: 'app-user-friend',
  templateUrl: './user-friend.component.html',
  styleUrls: ['./user-friend.component.css']
})
export class UserFriendComponent implements OnInit {

  constructor(private userInterface: openSearchService,private router: Router,private route: ActivatedRoute,
    private actions: ActionserviceService,private notifyService: NotificationService,private ngxLoader: NgxUiLoaderService) { }

    MyFriends:any;
    SuggestedFrnd:any;
    FrndRequests:any;
    SendFrndRequests:any;
    searchfrndlist:any;
    searchUserList:any;
    SearchFrnd:string;
    SearchFrndReq:string;
    SearchUser:string;


    ngOnInit(): void {
      this.LoadMyFriends();
      this.LoadSuggestFrnds();
      this.LoadFriendRequests();
      this.LoadSendFriendRequests();
    }

  LoadMyFriends() { 
   //this.ngxLoader.start();
    this.actions.GetMyFriends().subscribe(data => {
      this.MyFriends = data;
      // setTimeout(() => {
      //   this.ngxLoader.stop();
      //  }, 0); 
    });
  }
  LoadSuggestFrnds() {
    this.ngxLoader.start();
    this.actions.GetSuggestFrnds().subscribe(data => {
      this.SuggestedFrnd = data;   
      //console.log(this.SuggestedFrnd);
      setTimeout(() => {
        this.ngxLoader.stop();
       }, 1000); 
    });
  }
  LoadFriendRequests(){
    this.actions.GetFrndsRequest().subscribe(data => {
      this.FrndRequests = data;
      //console.log(this.FrndRequests);
    });
  }
  LoadSendFriendRequests(){
    this.actions.GetSENDFrndsRequest().subscribe(data => {
      this.SendFrndRequests = data;
      //console.log(this.SendFrndRequests);
    });
  }
  searchnow(){
    if(this.SearchFrnd == "" || this.SearchFrnd == null || this.SearchFrnd == undefined){
      this.LoadMyFriends();
    }else{
    this.actions.SearchFriends(this.SearchFrnd).subscribe((result) => {
      this.ngxLoader.start(); 
      this.searchfrndlist = result;
      this.MyFriends =this.searchfrndlist;
      setTimeout(() => {
        this.ngxLoader.stop();
       }, 0); 
      ////console.log(this.searchfrndlist);
    });
  }
  }
  Usersearchnow(){
    //debugger;
    if(this.SearchUser == "" || this.SearchUser == null || this.SearchUser == undefined){
      this.LoadSuggestFrnds();
    }else{
      this.actions.SearchALLUSERs(this.SearchUser).subscribe((result) => {
        this.ngxLoader.start();
        this.searchUserList = result;
        this.SuggestedFrnd = this.searchUserList;
        setTimeout(() => {
          this.ngxLoader.stop();
         }, 0); 
        //console.log(this.searchUserList);
      });
    }
  }
  // Button Actions //
  addfriend(frndID){
  //  //debugger;
    const data = new Actionbtn();
    data.mode = "AddFriend"
    data.userID = frndID;
    this.actions.AcceptFriendRequest(data).subscribe(data => {
    this.LoadMyFriends();
    this.LoadSendFriendRequests();
    this.LoadSuggestFrnds();
    //this.removeFriend(frndID);
    this.notifyService.showSuccess('Baravo !!', 'Send Request Successfully');
    });   
  }
  AcceptReq(frndID){
   // //debugger;
    const data = new Actionbtn();
    data.mode = "Accept"
    data.userID = frndID;
    this.actions.AcceptFriendRequest(data).subscribe(data => {
      this.LoadMyFriends();
      this.LoadFriendRequests();
      this.notifyService.showSuccess('Baravo !!', 'Accept Successfully');
    });     
  }
  RejectReq(frndID){
    var result = confirm("want to Reject ??")
    if (result) {
    const data = new Actionbtn();
    data.mode = "Reject"
    data.userID = frndID;
    this.actions.AcceptFriendRequest(data).subscribe(data => {
      this.LoadFriendRequests();
      this.notifyService.showSuccess('Baravo !!', 'Reject Successfully');
    });        
    }
  }
  UnFriend(frndID){
  //  //debugger;
    const data = new Actionbtn();
    data.mode = "Delete"
    data.userID = frndID;
    this.actions.AcceptFriendRequest(data).subscribe(data => {
      this.LoadMyFriends();
      this.LoadSuggestFrnds();
      this.notifyService.showSuccess('Baravo !!', 'Delete Friend Successfully');
    });        
  }
  CancelRequest(frndID){
  //  //debugger;
    const data = new Actionbtn();
    data.mode = "Cancel"
    data.userID = frndID;
    this.actions.AcceptFriendRequest(data).subscribe(data => {
      this.LoadSendFriendRequests();
      this.LoadSuggestFrnds();
      this.notifyService.showSuccess('Baravo !!', 'Cancel Request Successfully');
    });        
  }

  profileview(userProfileID){
    this.actions.GetUserViewDetails(userProfileID).subscribe(data => {
      //console.log(data);
      this.router.navigate(['/userprofile/detail',parseInt(userProfileID)], { relativeTo: this.route });
    });     
  }


  removeFriend(i) {
    this.SuggestedFrnd.splice(i, 1);
  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }
}
