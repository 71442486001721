export class usereducation{
    public mode :string;
    public EduID: number;
    public UserID: number;
    public EduDescription :string;
    public EduCompany :string;
    public EduStartDate :string;
    public EduEndDate :string;
    public EduDesignation :string;
    public EduIsCUrrent: boolean;

}