import { Action } from '@ngrx/store';
import { searchState } from 'src/app/shared/models/search/searchState.model';
import { searchResulteDTO } from 'src/app/shared/models/search/openSearch.model';
 
export enum SearchResultActionTypes {  
  GETSEARCH= 'GETSEARCH'
}

export class getSearch implements Action {
  readonly type = SearchResultActionTypes.GETSEARCH;
  constructor(public payload: searchResulteDTO[]) {
    //debugger;     
  }
}
 
export type SearchResultActions =
| getSearch;