import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { switchMap, mergeMap } from "rxjs/operators";
import { error } from 'protractor';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { environment } from 'src/environments/environment';
import { OrderRequest } from '../../models/Company/Orders/OrderRequest';

@Injectable()
export class OrderService{
private result:number;
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     

constructor(private httpclient:HttpClient,public localStor: LocalStorageService){ 
  //debugger;   
}

submitThePost(postingDate:OrderRequest){  
    debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/Orders/adOrder',JSON.stringify(postingDate),this.httpOptions)
  }

  getOrdersList():Observable<any[]>{
    return this.httpclient.get<any>(this.baseUrl+'services/Orders/all');
  }

  getOrdersDetails(orderID : string):Observable<any[]>{
    return this.httpclient.get<any>(this.baseUrl+'services/Orders/detailsByID?orderID='+orderID);
  }

  getCompanyOrdersList():Observable<any[]>{
    return this.httpclient.get<any>(this.baseUrl+'services/Company/Orders/all');
  }

  getCompanyOrdersDetails(orderID : string):Observable<any[]>{
    return this.httpclient.get<any>(this.baseUrl+'services/Company/Orders/detailsByID?orderID='+orderID);
  }

  getLocalOrders(): OrderRequest {
    const orders: OrderRequest =
      JSON.parse(localStorage.getItem("active_local_Order"));
    
    return orders;
  }

  setLocalOrders(orderDetail:OrderRequest ){
    localStorage.setItem("active_local_Order", JSON.stringify(orderDetail));     
  }

  removeLocalOrder()
  {
    localStorage.removeItem("active_local_Order");
  }

}