<section class="tg-sectionspace tg-haslayout">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="tg-sectionhead">
                    <div class="tg-title">
                        <h2>My Favorite Ads</h2>
                    </div>
                </div>
            </div>
            <div class="tg-ads">
                <div  *ngFor="let items of  adproductService.getFavoriteAds()" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                    <div class="tg-ad tg-verifiedad">
                        <figure>
                            <span class="tg-themetag tg-featuretag">featured</span>
                            <a style="cursor: pointer;">
                                <img *ngIf="items.addImageBase == null || items.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                <img *ngIf="items.addImageBase != null  && items.addImageBase != ''" [src]="items.addImageBase" alt="image description" class="featuredAdsImageClass"></a>
                            <!-- <img src="{{product.mainImagePath}}{{product.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                            <span class="tg-photocount">See All Photos</span>
                        </figure>
                        <div class="tg-adcontent">
                            <ul class="tg-productcagegories">
                                <li><a href="javascript:void(0);">{{items.subCat}}</a></li>
                            </ul>
                            <div class="tg-adtitle">
                                <h3><a href="javascript:void(0);">{{items.title}}</a></h3>
                            </div>
                            <time datetime="2017-06-06">Last Updated: {{items.datePosted}} </time>
                            <div class="tg-adprice">
                                <h4>{{items.currencyName}}:{{items.price}}</h4>
                            </div>
                            <!-- <address>44-46 abc Road, Manchester</address> -->
                            <div class="tg-phonelike">
                                <a class="tg-btnphone" [ngClass]="showPhones == items.add_ID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                    <i class="fa fa-phone"></i>
                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                        <em *ngIf="showPhones == items.add_ID">{{items.add_Phone}}</em>
                                        <em (click)="showPhoneNumber(items.add_ID)" [ngClass]="showPhones != items.add_ID ? 'view' : 'hidden'">Show Phone No.</em>
                                    </span>
                                </a>
                                <span title="Remove from Favorite" (click)="removeFavAds(items)" class="tg-like tg-liked" style="font-size: 20px;">
                                    <i class="fa fa-heart-o" aria-hidden="true"></i>                                       
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div> <br />
        <br />
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="tg-sectionhead">
                    <div class="tg-title">
                        <h2>My Favorite Products</h2>
                    </div>
                </div>
            </div>
            <div class="tg-ads">
                <div  *ngFor="let items of  adproductService.getFavoriteCartProducts()" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                    <div class="tg-ad tg-verifiedad">
                        <figure>
                            <span class="tg-themetag tg-featuretag">featured</span>
                            <a style="cursor: pointer;">
                                <img *ngIf="items.proImageBase == null || items.proImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                <img *ngIf="items.proImageBase != null  && items.proImageBase != ''" [src]="items.proImageBase" alt="image description" class="featuredAdsImageClass"></a>
                            <!-- <img src="{{product.mainImagePath}}{{product.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                            <span class="tg-photocount">See All Photos</span>
                        </figure>
                        <div class="tg-adcontent">
                            <ul class="tg-productcagegories">
                                <li><a href="javascript:void(0);">{{items.subCat}}</a></li>
                            </ul>
                            <div class="tg-adtitle">
                                <h3><a href="javascript:void(0);">{{items.proName}}</a></h3>
                            </div>
                            <time datetime="2017-06-06">Last Updated: {{items.proAddedDate}} </time>
                            <div class="tg-adprice">
                                <h4>{{items.proPriceUnitName}}:{{items.proPrice}}</h4>
                            </div>
                            <!-- <address>44-46 abc Road, Manchester</address> -->
                            <div class="tg-phonelike">
                                <a class="tg-btnphone" [ngClass]="showPhones == items.proID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                    <i class="fa fa-phone"></i>
                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                        <em *ngIf="showPhones == items.proID">{{items.proPhoneNumber}}</em>
                                        <em (click)="showPhoneNumber(items.proID)" [ngClass]="showPhones != items.proID ? 'view' : 'hidden'">Show Phone No.</em>
                                    </span>
                                </a>
                                <span title="Remove from Favorite" (click)="removeFavProducts(items)" class="tg-like tg-liked" style="font-size: 20px;">
                                    <i class="fa fa-heart-o" aria-hidden="true"></i>                                       
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>    <br /><br />
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="tg-sectionhead">
                    <div class="tg-title">
                        <h2>My Favorite Deals</h2>
                    </div>
                </div>
            </div>
            <div class="tg-ads">
                <div  *ngFor="let items of  adproductService.getFavoriteDeals()" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                    <div class="tg-ad tg-verifiedad">
                        <figure>
                            <span class="tg-themetag tg-featuretag">featured</span>
                            <a style="cursor: pointer;">
                                <img *ngIf="items.addImageBase == null || items.addImageBase == ''" src="/assets/images/no_image.jpg" alt="image description" class="featuredAdsImageClass">
                                <img *ngIf="items.addImageBase != null  && items.addImageBase != ''" [src]="items.addImageBase" alt="image description" class="featuredAdsImageClass"></a>
                            <!-- <img src="{{product.mainImagePath}}{{product.imageName}}" alt="image description" class="featuredAdsImageClass"></a> -->
                            <span class="tg-photocount">See All Photos</span>
                        </figure>
                        <div class="tg-adcontent">
                            <ul class="tg-productcagegories">
                                <li><a href="javascript:void(0);">Deal</a></li>
                            </ul>
                            <div class="tg-adtitle">
                                <h3><a href="javascript:void(0);">{{items.title}}</a></h3>
                            </div>
                            <time datetime="2017-06-06">Last Updated: {{items.dealEnd}} </time>
                            <div class="tg-adprice">
                                <h4>{{items.currency}}:{{items.price}}</h4>
                            </div>
                            <!-- <address>44-46 abc Road, Manchester</address> -->
                            <div class="tg-phonelike">
                                <a class="tg-btnphone" [ngClass]="showPhones == items.addID ? 'highLight' : 'clearfix'" href="javascript:void(0);">
                                    <i class="fa fa-phone"></i>
                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6">
                                        <em *ngIf="showPhones == items.addID">{{items.mobileNumber}}</em>
                                        <em (click)="showPhoneNumber(items.addID)" [ngClass]="showPhones != items.addID ? 'view' : 'hidden'">Show Phone No.</em>
                                    </span>
                                </a>
                                <span title="Remove from Favorite" (click)="removeFavDeals(items)" class="tg-like tg-liked" style="font-size: 20px;">
                                    <i class="fa fa-heart-o" aria-hidden="true"></i>                                       
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>     
    </div>
</section>