import { Injectable } from '@angular/core';
import {createEffect, Actions,ofType} from '@ngrx/effects';
import * as authActions from '../actions/auth.actions';
import { of, from, pipe, Observable } from 'rxjs';
import { map, switchMap, mergeMap,catchError } from "rxjs/operators";
import { dispatch } from 'rxjs/internal/observable/pairs';
import { Action } from 'rxjs/internal/scheduler/Action';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-web-storage';
import { AuthenticationService } from 'src/app/auth/auth.service';
import 'rxjs/add/observable/of';
import { Router } from '@angular/router';
import { chattingService } from 'src/app/shared/liveUpdate/chatting.service';
import * as jwt_decode from "jwt-decode";
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';

@Injectable()
export class authEffects{    
    private baseUrl = environment.baseUrl;  
    chatServiceAdapter: chattingService;
    //private url = this.baseUrl+'authenticate/token';
    private url = this.baseUrl+'token';    
    private bodyHeader =  JSON.stringify({ username:'mario', password :'secret' });                 
    private httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded'            
          })
        };              
        private bodyMain ='grant_type=client_credentials&client_id=GSB&client_secret=GSB@123';
       // this.httpclient.post<any>(url,bodyMain,httpOptions).subscribe(data => {
       //     //debugger;            
       // this.token = data.access_token;

    //@Effect(dispatch:false)
    // @Effect()    
    // authSignUp = this.actions$.pipe(ofType(authActions.AuthenticationActionTypes.TRYSIGNUP),map(
    //     (action: authActions.trySignUp)=>{
    //         return action.payload;
    //     })
    //     , switchMap((actionauthData : {username: string, password : string})=>
    //     {
    //         return pipe(mergeMap(val => of(`${val}World!`)))            
    //     })
    //     ,switchMap(()=>{
    //         return pipe(mergeMap(val => of(`${val}World!`)))
    //     })
    //     ,mergeMap((token:string)=>
    //     {
    //         return [
    //             {
    //                 type: authActions.AuthenticationActionTypes.SIGNUP
    //             },
    //             {
    //                 type : authActions.setToken,
    //                 payload : token
    //             }                
    //         ]
    //     })        
    //     );  
        
    // @Effect()
    // authAction = this.actions$.pipe(ofType(authActions.AuthenticationActionTypes.TRYSIGNUP),map(
    //     (action: authActions.trySignIn)=>{
    //         //console.log('testLoginEffect');
    //         //console.log(action.payload);
    //         return action.payload;
    //     })
    //     , switchMap((authData : {username: string, password : string})=>
    //     {
    //         //console.log('testLoginEffect');
    //         //console.log(authData);
    //         return pipe(mergeMap(
    //             val => of(`${val}World!`)                
    //             //put the http call here
    //             ))            
    //     })
    //     ,switchMap(()=>{
    //         return pipe(mergeMap(val => of(`${val}World!`)))
    //     })
    //     ,mergeMap((token:string)=>
    //     {
    //         //console.log('testLoginEffect3');
    //         //console.log(token);
    //         return [
    //             {
    //                 type: authActions.AuthenticationActionTypes.SIGNIN
    //             },
    //             {
    //                 type : authActions.setToken,
    //                 payload : token
    //             }                
    //         ]
    //     }));
    // @Effect()        
    // authSignIn = this.actions$.pipe(        
    //     ofType(authActions.AuthenticationActionTypes.TRYSIGNIN)
    // ,map((action: authActions.trySignIn)=>{
    //     //debugger;
    //    //console.log('testLoginEffectMain');
    //    //console.log(action.payload);
    //     return action.payload;
    // })      
    // ,switchMap(action => this.httpclient.post<any>(this.url,action, {
    //     //,switchMap(action => this.httpclient.post<any>(this.url,this.bodyHeader, {
    //         headers: { 'Content-Type':  'application/json' }
    //       }).pipe(map(response => {
    //           //debugger;
    //           //console.log(response);
    //           //setting the local storage    
    //           //console.log('testLoginEffectMainIn');
    //           //console.log(response);
    //           this.localStor.set('currentUser', JSON.stringify(response.token.replace(/^"(.*)"$/, '$1')));        
    //           //localStorage.setItem('currentUser', JSON.stringify(response.access_token.replace(/^"(.*)"$/, '$1')));
    //           return {
    //             type: authActions.AuthenticationActionTypes.SET_TOKEN,
    //             payload : response.token,
    //             errormessage: null
    //           }             
    //         }),
    //         catchError((error: any) => {                          
    //           //console.log(error.message);            
    //           return error;              
    //         })
    //       ))); 
          
    
    LogIn: Observable<any> = createEffect(() => this.actions$.pipe(
            ofType(authActions.AuthenticationActionTypes.TRYSIGNIN)
            ,map((action: authActions.trySignIn)=>{
                //debugger;
                //console.log('istMap');
               //console.log(action.payload);
                return action.payload;
            })
            ,switchMap(payload => {
                return this.authService.logIn(payload.username,payload.password)
                .pipe(map(response => {
                    this.localStor.set('currentUser', JSON.stringify(response.token.replace(/^"(.*)"$/, '$1')));                                 
                    return {
                        type: authActions.AuthenticationActionTypes.SIGNIN_SUCCESS,
                        payload : response.token,
                        errormessage: null
                      }                    
                  }),
                  catchError((error) => {                    
                    return Observable.of({
                      type: authActions.AuthenticationActionTypes.SIGNIN_FAILURE,
                      payload: { error },
                      errorMessage: { error }
                    });
                  })               
                )})));          
    
    LoginSuccess: Observable<any> = createEffect(() => this.actions$.pipe(
            ofType(authActions.AuthenticationActionTypes.SIGNIN_SUCCESS)            
        ,map((response: string)=>{  
            //console.log('LoginSuccessEffect');  
            let token = this.localStor.get('currentUser');
            let tokenInfo = this.getDecodedAccessToken(token);                                                      
            this.chatService.joinUser(tokenInfo.nameid);                    
            this.router.navigate(['../home']);
            return '';
        })), { dispatch: false });
    
        SignUpFailure: Observable<any> = createEffect(() => this.actions$.pipe(
          ofType(authActions.AuthenticationActionTypes.SIGNIN_FAILURE)
          ,map((response: string)=>{  
            //console.log('SignUpFailure');             
            this.localStor.clear();                   
            this.router.navigate(['../login']);
            return '';
        })), { dispatch: false });  
        
        public LogOut: Observable<any> = createEffect(() => this.actions$.pipe(
          ofType(authActions.AuthenticationActionTypes.LOGOUT),
          map((response: string)=>{            
            console.log('401 Error'); 
            this.localStor.clear();
            this.userInterface.setInterface("0");                   
            this.router.navigate(['../login']);
            return '';
        })       
        ), { dispatch: false }); 
    constructor(private actions$:Actions,private httpclient:HttpClient, public localStor: LocalStorageService, public authService: AuthenticationService,private router:Router, private chatService: chattingService,private userInterface: openSearchService){
     }
    getDecodedAccessToken(token: string): any {
        try {
          return jwt_decode(token);
        }
        catch (Error) {
          return null;
        }
      }  
}