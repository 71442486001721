export class userexperience{
    public mode :string;
    public ExpID: number;
    public UserID: number;
    public ExpDescription :string;
    public ExpCompany :string;
    public ExpStartDate :string;
    public ExpEndDate :string;
    public ExpDesignation :string;
    public ExpIsCUrrent: boolean;

}