import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DealRequest } from '../../models/deal/deal';
//import { Deal } from '../deal/Deal'
import { DealProductRequest } from '../../models/deal/dealProductRequest';

@Injectable()
export class adDealService{
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     
private result:number;
private resultLike:string;
constructor(private httpclient:HttpClient){}

    getDealList():Observable<any[]>{
      return this.httpclient.get<any>(this.baseUrl+'services/Deal/getDeallist');
    }

    updateDealImages(dealDetails:DealProductRequest){  
      //debugger;
      this.httpclient.post<number>(this.baseUrl+'services/Deal/UpdateDealImage',JSON.stringify(dealDetails),this.httpOptions).subscribe(data => {
        //debugger; 
        //console.log(data);           
    return this.result = data; 
      })               
    }
    
    GetDealbyID(dealID : number){  
      //debugger;
      return  this.httpclient.get<any>(this.baseUrl+'services/Deal/getDealbyID?dealID='+dealID,this.httpOptions)
      .pipe(map((response : any)=> {
        return response;
        }),
        catchError(error =>{
          return throwError('seomething went wrong');
        })
        );                        
    }
    GetMmoreDealbyUserID(dealID : number){  
      //debugger;
      return  this.httpclient.get<any>(this.baseUrl+'services/Deal/getDealbyuserID?dealID='+dealID,this.httpOptions)
      .pipe(map((response : any)=> {
        return response;
        }),
        catchError(error =>{
          return throwError('seomething went wrong');
        })
        );                        
    }

    submitDealDetails(DealDetails:DealRequest){  
      //debugger;
      return this.httpclient.post<number>(this.baseUrl+'services/Deal/AdDeal',JSON.stringify(DealDetails),this.httpOptions)
    }

    updateDealDetails(DealDetails:DealRequest){  
      //debugger;
      this.httpclient.post<number>(this.baseUrl+'services/Deal/UpdateDeal',JSON.stringify(DealDetails),this.httpOptions).subscribe(data => {
        //debugger; 
        //console.log(data);           
    return this.result = data; 
      })               
    }

}

