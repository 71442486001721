import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgOption } from '@ng-select/ng-select';
import { LocalStorageService } from 'angular-web-storage';
import { dataLibrary } from '../shared/models/category/dataLibraries/dataLibrary.model';
import { adProductService } from '../shared/services/compnies/ProductServices';
import { NotificationService } from '../shared/services/notification.service';
import { dataLibraryService } from '../shared/services/search/DataLibraries.service';
import { ShippingAddress } from '../user-product/ShippingAddress';
import { openSearchService } from '../shared/services/search/openSearch.service';
import { Product } from '../product/show-product/Product';
import Swal from 'sweetalert2';
import { OrderRequest } from '../shared/models/Company/Orders/OrderRequest';
import { Orders } from '../shared/models/Company/Orders/Orders';
import { OrderDetails } from '../shared/models/Company/Orders/OrderDetails';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OrderService } from '../shared/services/compnies/OrderServices';
import * as jwt_decode from "jwt-decode";
@Component({
  selector: 'app-shopping-details',
  templateUrl: './shopping-details.component.html',
  styleUrls: ['./shopping-details.component.css']
})
export class ShoppingDetailsComponent implements OnInit {
  firstName:string;
  lastName:string;
  email:string;
  address1:string;
  address2:string;
  country:string;
  state:string; 
  zip:string;
  addresstype:string;
  firstNameAlert :boolean;
  lastNameAlert :boolean;
  emailNameAlert:boolean;
  address1NameAlert:boolean;
  address2NameAlert:boolean;
  countryNameAlert :boolean;
  stateNameAlert :boolean;
  zipNameAlert :boolean;
  typeNameAlert:boolean;
  AddressList1:ShippingAddress;
  AddressList2:ShippingAddress;
  selectedCountry = '0';
  selectedCity = '0';
  cityName:any;
  TotalLength:any;
  citySelection : dataLibrary[]=[];
  AddressID:any;
  EditAdress =true;
  page:number=1;
  cartProducts: Product[];
  totalValue = 0;
  Total=0;
  name:number;
  grandTotal=0;
  proQuantity=1;
  discountTotal=0;
  cardCvvAlert: boolean = false;
  cardNumberAlert: boolean = false;
  cardNumberDigitAlert: boolean = false;
  ExpiryMonthAlert: boolean = false;
  cardNumber:number=null;
  ExpiryMonth:number=0;
  ExpiryYear:number=0;
  cardCvv:number=0;
  cardPayment:boolean=false;
  shippingCountry = '0';  
  confirmOrder:boolean;
  orderDetailsNew = [];
  logInUserName:string;
  addressAvailable = false;
  productCurrencyName = "";
  constructor(private adproductService: adProductService,private router: Router, private dlService : dataLibraryService,
    private notifyService: NotificationService,public localStor: LocalStorageService,private userInterface: openSearchService,
    private route:ActivatedRoute, private ngxLoader: NgxUiLoaderService , private orService: OrderService,
    private storage: LocalStorageService) {
    if(this.localStor.get('currentCountry'))
    {
      this.selectedCountry =this.localStor.get('currentCountry');
    }
    if(this.localStor.get('currentCity'))
    {
      this.selectedCity =this.localStor.get('currentCity');
    }
    this.getCities();
  debugger;
    if(this.selectedCountry == "1" || this.selectedCountry == "2")
    {
      this.cardPayment =true;
    }
    let token = this.storage.get('currentUser');
    let tokenInfo = this.getDecodedAccessToken(token); // decode token 

    if (tokenInfo != null) {
      this.logInUserName = tokenInfo.nameid;
    }
    
  }

     

     CurrienciesList: NgOption[]=[
     { id:"0", title: "Select Curriencies" },
     { id:"1", title: "United Arab Emirates"},
      { id:"2", title: "Pakistan"}
    
    //  { id:"3", title: "Saudi Arabia"},
    //  { id:"4", title: "KUWAIT"},
    //  { id:"5", title: "Oman"},
    //  { id:"7", title: "india"},
    //  { id:"8", title: "United Kingdom"},
    //  { id:"9", title: "United States"},
    //  { id:"10", title: "China"},
    //  { id:"11", title: "Japan"},
    //  { id:"12", title: "Canada"}
    ]
    
  ngOnInit(): void {
    this.showPop1();
    this.showPop2();
    this.cartProducts =  JSON.parse(localStorage.getItem("active_item"))
    this.TotalLength = this.cartProducts.length;
    this.calculateCartProduct();
    debugger;
    if(this.selectedCountry == "1" || this.selectedCountry == "2")
    {
      this.cardPayment =true;
    }
  }

  calculateCartProduct(): void {  
    debugger;
    this.totalValue =0;
    this.grandTotal=0;
    let productTotal =0;
    this.discountTotal =0;
    this.cartProducts = this.adproductService.getLocalCartProducts();
    this.cartProducts.forEach((product) => { 
      this.totalValue  += parseInt(product.proPrice) * product.proQuantity ;
      this.discountTotal +=  parseInt(product.proPrice) - product.proDiscount;
      
    });
    if(this.totalValue >0)
    {
      this.grandTotal =  this.totalValue - 0;
      
    }
    else 
    {
      
    }
    //this.productCurrencyName =this.cartProducts[0].proPriceUnitName
  }


  onSubmit(form: NgForm){
    //debugger
    var result = this.validateForm();
    if (result === true) {
    const obj = new ShippingAddress();
    obj.ID = this.AddressID;
    obj.firstName = this.firstName;
    obj.lastName = this.lastName;
    obj.address = this.address1;
    obj.description = this.address2;
    obj.email = this.email;
    obj.countryID = this.selectedCountry;
    obj.state = this.state;
    obj.zip = this.zip;
    obj.addresstype = this.addresstype;
    this.adproductService.ShippingAddress(obj).subscribe((data:ShippingAddress) => {
      this.AddressID = data;
      if(data != null && data != undefined){
        if(this.AddressID !='' && this.AddressID != undefined )        
        {
          localStorage.setItem('shippingAdd', this.AddressID);          
        }
        console.log(data)
        this.notifyService.showSuccess('You Address is Approved !', 'Successfully');
        this.showPop1();
        this.showPop2();
        this.EditAdress = false;
        this.addressAvailable = true;
        //this.router.navigate(['checkouts/userbilling']);

      }
    });   
  }else{
    this.notifyService.showError('Provide the missing data !', 'Somthing Wrong');
  }  
  }

  AddNewAddress()
  {
    this.showEditAddress();
  }

  HomeShippingAddress(){
    debugger;
    const val = new ShippingAddress();
    val.addresstype = "home";
    this.adproductService.GetShippingAddress(val).subscribe((address:ShippingAddress) =>{
      console.log(address.ID)
      if(address.ID !=null )        
      {
        localStorage.setItem('shippingAdd', address.ID.toString()); 
        this.addressAvailable = true;
      }
    this.firstName = address.firstName;
    this.lastName = address.lastName;
    this.address1 = address.address;
    this.address2 = address.description;
    this.email = address.email;
    this.country = address.countryID;
    this.state = address.state;
    this.zip = address.zip;
    this.addresstype = address.addresstype;
    });
  }
  
  OfficeShippingAddress(){
    const val = new ShippingAddress();
    val.addresstype = "office";
    this.adproductService.GetShippingAddress(val).subscribe((address:ShippingAddress) =>{
      console.log(address.ID)
      if(address.ID !=null )        
      {
        localStorage.setItem('shippingAdd',  address.ID.toString());   
        this.addressAvailable = true;      
      }
    this.firstName = address.firstName;
    this.lastName = address.lastName;
    this.address1 = address.address;
    this.address2 = address.description;
    this.email = address.email;
    this.country = address.countryID;
    this.state = address.state;
    this.zip = address.zip;
    this.addresstype = address.addresstype;
    });
  }

  showEditAddress(){
    this.EditAdress = true;    
  }

  changeQuantity(selectedProduct: Product, quantity: number): void {  
    //debugger
    console.log(selectedProduct)
  
  if(quantity > 0)
  {
  
  this.cartProducts = this.adproductService.getLocalCartProducts();
  
  let updateItem = this.cartProducts.find(f=>f.proID == selectedProduct.proID);
  
    let index = this.cartProducts.indexOf(updateItem);
  
    selectedProduct.proQuantity = quantity;
  
    this.cartProducts[index].proQuantity =  quantity;
  }
  else	
  {	
    this.cartProducts = this.adproductService.getLocalCartProducts();
    
    let updateItem = this.cartProducts.find(f=>f.proID == selectedProduct.proID);
  
    let index = this.cartProducts.indexOf(updateItem);
  
    this.cartProducts.splice(index, 1);
  }
  
    this.adproductService.setLocalCartProducts(this.cartProducts);
    this.calculateCartProduct();
  } 

  onUAECardSubmit(form : NgForm)
  {

  }

  showPop1(){
    const val = new ShippingAddress();
    val.addresstype = "home";
    this.adproductService.GetShippingAddress(val).subscribe((address:ShippingAddress) =>{      
      this.AddressList1 = address;  
      this.EditAdress =false;
      this.addressAvailable = true;
      this.HomeShippingAddress();    
    });
  }

  showPop2(){
    const val = new ShippingAddress();
    val.addresstype = "office";
    this.adproductService.GetShippingAddress(val).subscribe((address:ShippingAddress) =>{      
      this.AddressList2 = address; 
      this.EditAdress =false;   
      this.addressAvailable = true;  
      this.OfficeShippingAddress();
    });
  }

  hideShowAdressDetails()
  {
    if(this.EditAdress == true)
    {
      this.EditAdress = false;
    }
    else
    {
      this.EditAdress = true;
    }
  }

  getCities(){
    //debugger;
    this.dlService.getCitiesByCountry(this.selectedCountry)
        .subscribe((data:dataLibrary[]) => {
          this.citySelection = data;
          this.cityName = data;
          //this.TotalLength = data.length;
          //console.log(this.selectedCountry);
        });
  }
  
  validateForm() {
    //debugger;
    var result = true;
    if (typeof this.firstName == 'undefined' || !this.firstName || this.firstName == "0") {
      this.firstNameAlert = true;
      result = false;
    }
    else if (typeof this.lastName == 'undefined' || !this.lastName || this.lastName == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = true;
      result = false;
    }
    else if (typeof this.email == 'undefined' || !this.email || this.email == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = true;
      result = false;
    }
    else if (typeof this.address1 == 'undefined' || !this.address1 || this.address1 == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = true;
      result = false;
    }
    else if (typeof this.addresstype == 'undefined' || !this.addresstype || this.addresstype == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = true;
      result = false;
    }
    else if (typeof this.address2 == 'undefined' || !this.address2 || this.address2 == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = true;
      result = false;
    }
    else if (typeof this.selectedCountry == 'undefined' || !this.selectedCountry || this.selectedCountry == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = false;
      this.countryNameAlert = true;
      result = false;
    }
    else if (typeof this.state == 'undefined' || !this.state || this.state == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = false;
      this.countryNameAlert = false;
      this.stateNameAlert = true;
      result = false;
    }
    else if (typeof this.zip == 'undefined' || !this.zip || this.zip == "0") {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = false;
      this.countryNameAlert = false;
      this.stateNameAlert = false;
      this.zipNameAlert = true;
      result = false;
    }
    else {
      this.firstNameAlert = false;
      this.lastNameAlert = false;
      this.emailNameAlert = false;
      this.address1NameAlert = false;
      this.typeNameAlert = false;
      this.address2NameAlert = false;
      this.countryNameAlert = false;
      this.stateNameAlert = false;
      this.zipNameAlert = false;
    }
    return result;
  }

  backtoMain()
  {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'],{relativeTo : this.route });
  }

  onOrderSubmit() {
    debugger; 

    //var orderDetailsNew = [];
    const postOrderRequest = this.getOrderDetails();
    if(postOrderRequest!=null)
    {
     this.ngxLoader.start();  
     this.orService.submitThePost(postOrderRequest).subscribe(data => {
       if (data != 0) {
        this.confirmOrder = false;
       //  this.ResetForm();
         setTimeout(() => {
           this.ngxLoader.stop();
          }, 0);
         this.successNotification();
         localStorage.removeItem("active_item");             
         localStorage.removeItem('shippingAdd');
         this.orderDetailsNew = null;
         this.userInterface.setInterface("1");
        this.router.navigate(['../user/orderlist']);         
         // this.reloadComponent();             
       }
       else {
         this.errorNotification();
       }       
     });
    }          
 }

 successNotification() {
  Swal.fire({
    //position: 'top-center',
    icon: 'success',
    title: 'Your work has been saved',
    showConfirmButton: false,
    timer: 1000
  })
}
errorNotification() {
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Something went wrong!',
    footer: '<a href="">Provide the missing data ‼️‼️</a>'
  })
}

getOrderDetails()
  {
    debugger; 
    const postOrderRequest = new OrderRequest();
        
    const orderRequest = new Orders();
    const orderDetails = new OrderDetails();         

    orderRequest.CompanyID =0
    orderRequest.CustomerID = 0;
    orderRequest.OrderAddedBy = 0;
    orderRequest.OrderAddedDate ='';
    orderRequest.OrderCoupenAppliedId =0;
    orderRequest.OrderCurrency =0;
    orderRequest.OrderDiscountApplied =0;
    orderRequest.OrderEditDate = '';
    orderRequest.OrderEditedBy =0;
    orderRequest.OrderId =0;
    orderRequest.OrderNumber=this.getCurrentOrderNumber();
    orderRequest.OrderTotal=this.grandTotal;
    orderRequest.OrderStatus=0;
    orderRequest.PaymentDetailId =0;


    let shippingAddID = localStorage.getItem('shippingAdd');
    if(this.shippingCountry != "0")
    {
      orderRequest.ShippingAddressId=parseInt(this.shippingCountry);
    } 
    else
    {
      orderRequest.ShippingAddressId=0;
    }

    orderRequest.ShippingAddressId=0;//this.AddressID;
    orderRequest.ShippingMethodId =1;
    
    postOrderRequest.ProductOrder = orderRequest;     
    postOrderRequest.OrderPayment = null;

   this.cartProducts = this.adproductService.getLocalCartProducts();

    this.cartProducts.forEach((product) => { 
      debugger;
      var orderDetailsToNew = {};
      orderDetailsToNew["OrderId"]=0;
      orderDetailsToNew["ProductColledtedAmmount"]= parseInt(product.proPrice);
      orderDetailsToNew["ProductDiscountApplied"] =0;
      orderDetailsToNew["ProductId"] = product.proID;
      orderDetailsToNew["ProductQuantity"] = product.proQuantity;
      orderDetailsToNew["ProductTotalPrice"] = parseInt(product.proPrice);

      this.orderDetailsNew.push(orderDetailsToNew);      
  });
  postOrderRequest.ProductOrderDetails = this.orderDetailsNew ; 
  
  return postOrderRequest;
}
  
getCurrentOrderNumber()
{
  const now = new Date();
  const hoursAndMinutes = now.getDay() +''+ now.getHours() + '' + now.getMinutes() + ''+now.getMonth()+''+now.getFullYear();
  const OrderNumber = 'KC-'+ this.logInUserName + '-'+ hoursAndMinutes;
 
  return OrderNumber;

}
getDecodedAccessToken(token: string): any {
  try {
    return jwt_decode(token);
  }
  catch (Error) {
    return null;
  }
}

}
