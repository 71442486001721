import { Component, OnInit,NgZone } from '@angular/core';
import { searchService } from '../shared/services/searchService';
import { openSearchService } from '../shared/services/search/openSearch.service';
import { allSearchFilters } from '../shared/models/requestsModel/search/searchInput.model';
import { searchResulteDTO } from '../shared/models/search/openSearch.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DefaultGlobalConfig, ToastrService } from 'ngx-toastr';
import { likeDislikeDetails } from '../shared/models/Adds/likeDislikeDetails.model';
import { addsService } from '../shared/services/addServices';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AdComments } from '../shared/models/comments/comments.model';
import { Comments } from '../shared/models/comments/comment.model';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { categories } from '../shared/models/category/category.model';
import { CategoryDto } from '../shared/models/category/categoryDTO.model';
import { Options } from 'ng5-slider';
import { LocalStorageService } from 'angular-web-storage';
import { ActivatedRoute, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormatInputPathObject } from 'path';
import * as fromApp from '../store/app.state';
import * as searchActions from '../store/actions/search.action';
import * as fromSearch from './../store/reducers/search.reducers';
import { searchState } from '../shared/models/search/searchState.model';
import { ThrowStmt } from '@angular/compiler';
import { liveUpdateService } from '../shared/liveUpdate/listUpdate.service';
import * as jwt_decode from "jwt-decode";
import { ProComments } from '../shared/models/comments/Pro-comments.model';
import { ProductComments } from '../shared/models/comments/pro-comment.model';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {Meta , Title } from '@angular/platform-browser';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-add-listing',
  templateUrl: './add-listing.component.html',
  styleUrls: ['./add-listing.component.css']
})
export class AddListingComponent implements OnInit {
  addResults: searchResulteDTO[];
  
  isContentOpen: boolean = false;
  isFirstOpen : boolean  = true;
  isFirstClose : boolean  = true;
  customClass = 'customClass';
  
  commentDescription ='';
  addID : string ;
  CatName : string ;
  addLikes :number;
  addDisLikes :number;
  indexToSetValues :number;

  //drop down search 
  levelOfSearch : number = 0;
  levelToShow : number = 0;
  selectedCategory ='0';
  selectedSubCategory = '0';
  selectedSubCatFirstChild = '0';
  selectedSubCatSecondChild= '0';
  selectedSubCatthirdChild= '0';
  
  selectedSearchWord = '';
  subCategories: CategoryDto[];
  subCatFirstChildList: CategoryDto[];
  subCatSecondChildList: CategoryDto[];

  selectedCategoryList = '0';

  typeIdentity = '1';
  selectedDoors = '0';
  selectedColor = '0';
  selectedBodyType = '0';
  selectedTransmission = '0';
  selectedHP = '0';
  selectedCylender = '0';
  selectedFT = '0';
  selectedUsage = '0';
  selectedCondition = '0';
  selectedAge = '0';
  selectedWL = '0';

  selectedWE = '0';
  selectedGender = '0';
  selectedEdu = '0';
  selectedCommitment = '0';
  selectedTravel = '0';
  
  selectedFurnished = '0';
  selectedBuilding = '';
  selectedSize ='0';


  selectedCountry = '0';  
  selectedCity = '0';

  update=new BehaviorSubject<boolean>(false);
  updateLike=new BehaviorSubject<boolean>(false);
  updateDislike=new BehaviorSubject<boolean>(false);
  commentsObject :Comments;
  searchStateParams : Observable<{searchStateParams:searchState}>;
  searchModule : searchState ;
  authModule : boolean ;
  public scrollbarOptions = { axis: 'y', theme: 'light' };
  minValue: number = 1000;
  maxValue: number = 200000;
  options: Options = {
    floor: 0,
    ceil: 500000
  };
  minYearValue: number = 2000;
  maxYearValue: number = 2022;
  optionsYears: Options = {
    floor: 1985,
    ceil: 2023
  };
  minKMValue: number = 1000;
  maxKMValue: number = 200000;
  optionsKM: Options = {
    floor: 0,
    ceil: 500000
  };
  minRoomValue: number = 0;
  maxRoomValue: number = 3;
  optionsRoom: Options = {
    floor: 0,
    ceil: 10
  };
  minBathValue: number = 0;
  maxBathValue: number = 2;
  optionsBath: Options = {
    floor: 0,
    ceil: 10
  };
  minSalValue: number = 0;
  maxSalValue: number = 15000;
  optionsSal: Options = {
    floor: 0,
    ceil: 100000
  };
  config: any;
  subCategoryIDFromSearch:string;
  startpage: number = 1;
  endpage:number = 15;
  faEye = faEye;

  title = 'World’s Largest Marketplace - MyKnocks';
  searchData: any;  
  subscription: Subscription;
  message:string;
  newSearchPattern:allSearchFilters;
  newSearchPatternStore:searchState;
  constructor(private route : ActivatedRoute,private searchService:openSearchService,private ngxLoader: NgxUiLoaderService
    , private toasterService:ToastrService,public localStor: LocalStorageService,private addService: addsService,private mScrollbarService: MalihuScrollbarService, private router: Router,
    private store:Store<fromApp.AppState>,private liveUpdate: liveUpdateService,  private _ngZone: NgZone, private openSearch
    :openSearchService,private titleService: Title, private metaService: Meta ) { 
    //  private store:Store<{searchNew:{searchStateParams:searchState}}>) {                

    this.subscribeToEvents();    
      // this.config = {
      //   itemsPerPage: 5,
      //   currentPage: 1,
      //   totalItems: 50
      // };
    debugger;
      if(this.localStor.get('currentCountry'))
      {
        this.selectedCountry =this.localStor.get('currentCountry');
      }
      if(this.localStor.get('currentCity'))
      {
        this.selectedCity =this.localStor.get('currentCity');
      }

    }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);
    if(this.selectedCountry == '2')
    {
      this.metaService.addTags(    
        [
        {name: 'description', content: 'Home page of MyKnocks Pakistan'},
        {name: 'author', content: 'MyKnocks Pakistan'},
        {name: 'keywords', content: 'used cars in pakistan , used cars in lahore , used cars in gujranwala , buy used cars pakistan , buy used cars , used cars pakistan , second hand vehicle in pakistan, '+
        'second hand vehicle in lahore, second hand vehicle in gujranwala, second hand cars pakistan , second hand cars lahore, second hand cars gujranwala , '+
        'apartment for sale , flat for sale , apartments for sale in lahore , apartments for sale , home for sale , villa for sale , plot for sale , '+
        'home for rent , classified ads in lahore , classified ads in gujranwala , classified ads in pakistan, lahore cars for sale, pakistan cars for sale, '+
        'second hand cars in lahore , second hand cars in gujranwala , second hand cars in pakistan , used phone in lahore , used phone in pakistan, used phone in gujranwala,  '+
        'villa for rent lahore, villa for rent islamabad, villa for rent karachi, lahore property sale,buy car in lahore, buy apartment , buy car in lahore, '+
        'buy car in gujranwala, buy car in karachi, buy car in islamabad , jobs in lahore, jobs in pakistan ,property for rent in lahore , property for rent in gujranwala, '+
        'property for rent in karachi, property for rent in islamabad , olx pakistan , second hand furniture lahore, second hand furniture , buying house in lahore, '+
        'buying house in gujranwala , buying house in karachi , buying house in islamabad ,used furniture lahore , used furniture , used mobile in lahore ,  '+
        'used mobile in gujranwala , used mobile in karachi , used mobile in islamabad , second hand mobile , second hand mobile market ,  '+
        'real estate jobs , software engineer jobs , house for sale , house for rent , iphone for sale , samsung for sale , iphone15 for sale ,sell my car ,  '+
        'plots for sale , plots for sale in lahore , files for sale , plot file for sale , toyota for sale, honda for sale , property for sale , property for rent ,  '+
        'shop for sale , shop for sale in lahore , shop for sale in gujranwala , shop for sale in karachi , shop for sale in islamabad , shop for rent, '+
        'shop for rent in lahore , shop for rent in gujranwala , shop for rent in islamabad , shop for rent in karachi , classified website in pakistan , classified website , '+
        'free posting site in pakistan, free ads posting , used trucks for sale, heavy bike for sale , cars for rent ,second hand ipad for sale ,  mobile sale , '+
        'sell your car pakistan , sell your car lahore , sell your car gujranwala , free advertising website in pakistan, free ads in pakistan , used phone for sale'}
       ]);
    }
    else if (this.selectedCountry == '1')
    {
      this.metaService.addTags(    
        [
        {name: 'description', content: 'Home page of MyKnocks UAE'},
        {name: 'author', content: 'MyKnocks UAE'},
        {name: 'keywords', content: 'buy used cars dubai, used cars uae dubai ,used cars dubai , used cars uae , second hand vehicle in dubai , second hand cars uae dubai, '+
        ' apartment for sale in dubai , apartment for sale in uae, flat for sale in dubai , apartments for sale in dubai uae, villa for sell in dubai, '+
        ' classified ads in uae, classified ads in dubai, dubai cars for sale ,second hand cars in dubai , used phone in dubai,villa for rent dubai,  '+
        ' dubai property sale,buy car in dubai ,buy apartment in dubai, buy car in uae , jobs in dubai , property for rent in dubai , olx dubai ,  '+
        ' second hand furniture dubai, buying house in dubai ,used furniture dubai , used mobile in uae , second hand mobile in uae , second hand mobile market in dubai,  '+
        ' real estate jobs in dubai , software engineer jobs in dubai , house for sale , house for rent , shop for sale , shop for rent, classified website in uae,  '+
        ' classified website in dubai , free posting site in dubai, free ads posting in uae , free ads posting in dubai , used trucks for sale, heavy bike for sale,  '+
        ' second hand ipad for sale in dubai , mobile sale uae , sell your car dubai , free advertising website in dubai , free advertising website in uae , used phone for sale, '+
        ' free ads in uae '}
       ]);
    }
    else
    {
      this.metaService.addTags(    
        [
        {name: 'description', content: 'Home page of MyKnocks UAE'},
        {name: 'author', content: 'MyKnocks UAE'},
        {name: 'keywords', content: 'buy used cars dubai, used cars uae dubai ,used cars dubai , used cars uae , second hand vehicle in dubai , second hand cars uae dubai, '+
        ' apartment for sale in dubai , apartment for sale in uae, flat for sale in dubai , apartments for sale in dubai uae, villa for sell in dubai, '+
        ' classified ads in uae, classified ads in dubai, dubai cars for sale ,second hand cars in dubai , used phone in dubai,villa for rent dubai,  '+
        ' dubai property sale,buy car in dubai ,buy apartment in dubai, buy car in uae , jobs in dubai , property for rent in dubai , olx dubai ,  '+
        ' second hand furniture dubai, buying house in dubai ,used furniture dubai , used mobile in uae , second hand mobile in uae , second hand mobile market in dubai,  '+
        ' real estate jobs in dubai , software engineer jobs in dubai , house for sale , house for rent , shop for sale , shop for rent, classified website in uae,  '+
        ' classified website in dubai , free posting site in dubai, free ads posting in uae , free ads posting in dubai , used trucks for sale, heavy bike for sale,  '+
        ' second hand ipad for sale in dubai , mobile sale uae , sell your car dubai , free advertising website in dubai , free advertising website in uae , used phone for sale, '+
        ' free ads in uae '}
       ]);
    }   

    const namepro = this.route.snapshot.params['category'];
   // const subCatName = this.route.snapshot.params['subCategory'];
   // //console.log(namepro , subCatName);
   //this.openSearch.currentSearchFiltersNew$..getValue();
debugger;
   if(this.localStor.get('searchPattern'))
   {
    var searchOptions = JSON.parse(this.localStor.get('searchPattern'));     
    this.newSearchPattern = searchOptions;
    //this.newSearchPattern.searchSender ="100";

   }
    debugger;
   if(this.newSearchPattern.CatID !="" && this.router.url.includes('search') && this.newSearchPattern.searchSender !="4")
   {  
     // debugger;
      //console.log("Inside search setting :"+this.newSearchPattern.CatID);
      this.selectedCategory = this.newSearchPattern.CatID.toLowerCase();
      this.levelOfSearch = parseInt(this.newSearchPattern.levelOfSearch);
      this.typeIdentity = this.newSearchPattern.typeIdentity;

        if(this.newSearchPattern.CatID == "3c064386-a300-4f90-8e18-f6da9f83aa1c")
        {
          this.levelOfSearch ==3;
          if(this.levelOfSearch ==3)
          {
            this.getSubCategoriesLevelTwo(this.selectedCategory);
            this.selectedSubCategory =this.newSearchPattern.subCatID;         
            this.levelToShow=1;
            setTimeout(() => {
              this.getSubCategoriesLevelThree(this.selectedSubCategory);
              this.selectedSubCatFirstChild =this.newSearchPattern.firstChild;         
              this.levelToShow=2;
           }, 500);            
          }          
        }
        else
        {
          if(this.levelOfSearch ==1)
          {
            this.getSubCategoriesLevelTwo(this.selectedCategory);            
            this.levelToShow=1;
          }
          else if(this.levelOfSearch ==2)
          {
            this.getSubCategoriesLevelTwo(this.selectedCategory);
            this.selectedSubCategory =this.newSearchPattern.subCatID;         
            this.levelToShow=1;
          }
          else
          {
            this.levelOfSearch =1;
          }
        }
        
        if(this.newSearchPattern.searchSender =="3" || this.newSearchPattern.searchSender =="1")
        {
          this.GetSearchResult(2);
        }
    }
    else if(this.newSearchPattern.CatID !="" && this.router.url.includes('search') && this.newSearchPattern.searchSender =="4")
    {
      debugger;
      this.loadSearchFromInnerBar(this.newSearchPattern);
    }   

    this.openSearch.currentSearchFilters.subscribe(data => {                
    });   

    //this.liveUpdate.receiveServerEvents(); 
    this.liveUpdate.commentReceived.subscribe((comment: Comments)=>
    {
      this.commentsObject = comment;
      //debugger;
      ////console.log(this.commentsObject.description);
      //alert(this.commentsObject.description);
     let Number = this.addResults.findIndex(a=> a.add_ID == this.commentsObject.postID);          
     //let NumberTotal =this.addResults[Number].adComments.length;
     this.addResults[Number].adComments.splice(0,0,this.commentsObject);
     //.push(this.commentsObject);
     //[this.indexToSetValues].adComments = data;                  
    }); 
    
    debugger;
    this.store.select('search').subscribe(data =>       
      this.newSearchPatternStore = data.selectedSearch
      );
      
      if(this.newSearchPattern.CatID !="")
      {
        debugger;
      }

    this.store.select('searchResult').subscribe(data =>       
      this.addResults = data.selectedResult
      );
    //debugger;
    if(this.addResults == null)
    {
      this.GetSearchResult(2);
    }
          
    this.update.subscribe(update=>update === true ? this.getCommentsByAdd() : '');
    this.updateLike.subscribe(update=>update === true ? this.getAddLikeDislikeCount(1,'','direct') : '');
    this.updateDislike.subscribe(update=>update === true ? this.getAddLikeDislikeCount(2,'','direct') : '');
    this.liveUpdate.likeReceived.subscribe((likeDetails: likeDislikeDetails)=>
    {
      //debugger;
      if(likeDetails.actionType == 1)
      {
        this.getAddLikeDislikeCount(1,likeDetails.postLikePostID,"update");                 
      }
      else if (likeDetails.actionType == 2)
      {
        this.getAddLikeDislikeCount(2,likeDetails.postLikePostID,"update");
      }      
    });
       
   //let good = this.store.select('search');
   //this.searchStateParams =  fromSearch.initialState.selectedSearch; 
   //will give the particular store data only
  //  this.store.select('search').subscribe(data => 
  //   this.searchModule = data.selectedSearch
  //   )
    this.store.select('auth').subscribe(data => 
      this.authModule = data.athenticated
      )
   // let cat = this.searchModule.category;
   // let autho = this.authModule;
           
  //below will give all the store data and you have to chose
    //  this.store.select(state => state).subscribe(data => {
  //   //console.log('data', data);
  // });  
  //debugger;
//this.selectedSubCategory = this.subCategoryIDFromSearch;
  ////console.log(this.searchStateParams)
  }
  pageChanged(action:number){
    if(action == 1)
    {
      this.startpage = this.startpage +15;
      this.endpage = this.endpage+15;

    }
    else if (action ==2)
    {

      if(this.startpage > 15)
      {
        this.startpage = this.startpage -15;
        this.endpage = this.endpage-15;
      }      
    }
    this.GetSearchResult(2);
    this.config.currentPage = event;
  }
  private subscribeToEvents(): void {  
  //this.liveUpdate.subsc
   // this.liveUpdate.SendComments

    // this.chatService.messageReceived.subscribe((message: Message) => {  
    //   this._ngZone.run(() => {  
    //     if (message.clientuniqueid !== this.uniqueID) {  
    //       message.type = "received";  
    //       this.messages.push(message);  
    //     }  
    //   });  
    // });  
  }  
  GetSearchResult(action:number) {
    debugger;
    if(this.localStor.get('currentCountry'))
    {
      this.selectedCountry =this.localStor.get('currentCountry');
    }
    if(this.localStor.get('currentCity'))
    {
      this.selectedCity =this.localStor.get('currentCity');
    }  

     const requestData = new allSearchFilters();
     requestData.BodyCondition="0";
     requestData.BodyType=this.selectedBodyType;
     requestData.Building=this.selectedBuilding;     
     requestData.City=this.selectedCity;
     requestData.Color=this.selectedColor;
     requestData.Commitment=this.selectedCommitment;
     requestData.Country=this.selectedCountry;
     requestData.Cylender=this.selectedCylender;
     requestData.Degree=this.selectedEdu;
     requestData.DisplaySalary="0";
     requestData.Doors=this.selectedDoors;
     requestData.Edu=this.selectedEdu;     
    if(this.selectedCategory =="27BBB528-5666-4429-B153-7D6441C0EFA1" ||this.selectedCategory =="0372DC00-F9CB-4CB5-8B95-901EA8A02EFA" || this.selectedCategory =="0372dc00-f9cb-4cb5-8b95-901ea8a02efa")
    {
      // property for sale || peroperty for rent || peroperty for rent
      this.maxValue = 10000000;  
    }

     requestData.EndPrice=this.maxValue.toString();
     requestData.EndYear=this.maxYearValue.toString();
     requestData.Exp=this.selectedWE;
     requestData.FT=this.selectedFT;
     requestData.FromAge=this.selectedAge;

     requestData.FromSalary=this.minSalValue.toString();
     requestData.Furnished=this.selectedFurnished;
     requestData.Gender=this.selectedGender;
     requestData.HP=this.selectedHP;
     requestData.MacCondition=this.selectedCondition;
     requestData.Positions="0";
     requestData.Shift="0";
     requestData.Size=this.selectedSize;
     requestData.StartBath=this.minBathValue.toString();
     requestData.StartBed=this.minRoomValue.toString();
     if(this.minKMValue ==10000)
     {
      this.minKMValue =0;
     }
     if(this.maxKMValue ==10000)
     {
      this.maxKMValue =0;
     }
     requestData.EndKilo=this.maxKMValue.toString();
     requestData.StartKilo=this.minKMValue.toString();
     requestData.StartPrice=this.minValue.toString();
     requestData.StartYear=this.minYearValue.toString();
     requestData.Supplier="0";
     requestData.ToAge=this.selectedAge;
     if(this.maxBathValue ==2)
     {
      this.maxBathValue =0;
     }
     requestData.ToBath=this.maxBathValue.toString();
     if(this.maxRoomValue ==3)
     {
      this.maxRoomValue =0;
     }
     requestData.ToBed=this.maxRoomValue.toString();
     if(this.maxSalValue ==15000)
     {
      this.maxSalValue =0;
     }

     requestData.ToSalary=this.maxSalValue.toString();
     requestData.Transmission=this.selectedTransmission;
     requestData.TravelRequired=this.selectedTravel;
     requestData.Usage=this.selectedUsage;
     requestData.Warrenty="0";    
     
    //requestData.firstChild="750cc92f-da9f-4c78-95ef-4eb19fb21ca2";      // Jaguar
    //requestData.secondChild="0a874538-e6aa-4d2c-bcdb-65b5fa9c6122";     // XJ-Series
    //debugger;
    requestData.CatID = this.selectedCategory;
    requestData.subCatID = this.selectedSubCategory;
    //requestData.subCatID = this.subCategoryIDFromSearch;
    requestData.firstChild = this.selectedSubCatFirstChild;
    requestData.secondChild = this.selectedSubCatSecondChild; 
    requestData.thirdChild = this.selectedSubCatthirdChild;

    //requestData.CatName = this.route.snapshot.params['subCategory'];

    if(requestData.CatID =="0")
    {
     requestData.CatID = "5bf243b1-2be4-4dda-93a0-cf9e06209e0f";   
     requestData.levelOfSearch = "1";  
    }

    //debugger
      if(requestData.CatID == 'c4e1f606-8105-4666-a8bc-c6657730a0df'){
        requestData.CatName = "Products"
     // alert('Deals success')
      }
      else if(requestData.CatID == 'C4E1F606-8105-4666-A8BC-C6657730A0DF')
      {
        requestData.CatName = "Products"
     // alert('Products success')
      }
      else if(requestData.CatID == 'c4e1f606-8105-4666-A8BC-C6657730A0DF')
      {
        requestData.CatName = "Suppliers"
    //  alert('Suppliers success')
      }
      else{
        requestData.CatName = ''
      //  alert('failure')
      }    

   // if(this.levelOfSearch != 1){
     requestData.levelOfSearch=this.levelOfSearch.toString();
   // }
     requestData.searchWord=this.selectedSearchWord;
     requestData.typeIdentity = this.typeIdentity; 
     if(action==1)
     {
      this.startpage=1;
      this.endpage=15;               
     }     
      requestData.start=this.startpage.toString();
      requestData.end=this.endpage.toString(); 
      //requestData.typeIdentity = this.typeIdentity;              
                        
    this.ngxLoader.start();
   //console.log(requestData);
     this.searchService.getSearchResult(requestData)
     .subscribe((data:searchResulteDTO[]) => {
      // console.log(data);
       //debugger;
       this.addResults = data;      
       //this.store.dispatch(new searchActions.getSearch(this.addResults));    
       setTimeout(() => {
         this.ngxLoader.stop();        
      }, 0);    
     });
          
   }
   
   setSearch()
   {
     //const searchCriteria = new searchState(this.levelOfSearch,this.selectedCategory, this.selectedSubCategory, requestData.firstChild, requestData.secondChild, requestData.thirdChild );
     const searchCriteria = new searchState();
     searchCriteria.levelOfSearch = this.levelOfSearch.toString();
     searchCriteria.category = this.selectedCategory;
     searchCriteria.subCategory = this.selectedSubCategory;
     searchCriteria.firstChild = '79504CCA-41F7-4936-ACF3-6757E9AA7E29'; //car
     searchCriteria.secondChild = '79504CCA-41F7-4936-ACF3-6757E9AA7E29';
     searchCriteria.thirdChild = '79504CCA-41F7-4936-ACF3-6757E9AA7E29';

     this.store.dispatch(new searchActions.trySearch(searchCriteria));
   }

 
   submitPostLikeDislike(action : number,index:number,result : searchResulteDTO)
   {     
     //debugger;
     const likeDislike = new likeDislikeDetails();
    likeDislike.actionType = action;    
    likeDislike.postLikePostID = result.add_ID;     
    this.addID = result.add_ID;
    this.addLikes =  parseInt(result.totalLikeCount);
    this.addDisLikes = parseInt (result.totalDisLikeCount);
    this.indexToSetValues= index;
    const x= this.addService.submitLikeDislike(likeDislike);     
    if(action ==1)
    {
     // //debugger;     
      x.subscribe(()=>this.updateLike.next(true));
    }
    else if (action ==2)
    {
     // //debugger;
      x.subscribe(()=>this.updateDislike.next(true));
    } 
    this.liveUpdate.sendLikeDisliek(likeDislike);    
   }   

   getAddLikeDislikeCount(action: number,postID: string ,callingPlace: string) {   
    //debugger;
    const likeDislike = new likeDislikeDetails();
    likeDislike.actionType = action;    
    if(callingPlace =="direct")
    {
      likeDislike.postLikePostID = this.addID; 
    }
    else if (callingPlace =="update")
    {
      likeDislike.postLikePostID = postID; 
    }

    this.addService.getAddLikeDislikeCount(likeDislike)
        .subscribe((data:number) => {
                
         // //debugger;
          if(action ==1)
          {
            if(callingPlace =="direct")
            {
              if(this.addLikes<data)
              {
                this.toasterService.success('Your like submitted successfully', 'Like', {
                      closeButton: true,
                      progressBar:true,
                      tapToDismiss:true
                    });
              }
              else
              {
                this.toasterService.success('Your like removed successfully', 'Like', {
                  closeButton: true,
                  progressBar:true,
                  tapToDismiss:true
                });

              }
            }
            setTimeout(() => { 
            if(callingPlace =="update")
            {
              let Number = this.addResults.findIndex(a=> a.add_ID == postID); 
              this.addResults[Number].totalLikeCount = data.toString();            
            }
            else
            {
              this.addResults[this.indexToSetValues].totalLikeCount = data.toString();            
            }     
          }, 1000);                
          }
          else if (action == 2)
          {
            if(callingPlace =="direct")
            {
            if(this.addDisLikes<data)
              {
                this.toasterService.success('Your dislike submitted successfully', 'Like', {
                  closeButton: true,
                  progressBar:true,
                  tapToDismiss:true
                });
              }
              else
              {
                this.toasterService.success('Your dislike removed successfully', 'Like', {
                  closeButton: true,
                  progressBar:true,
                  tapToDismiss:true
                });
              }
            }
            setTimeout(() => { 
            if(callingPlace =="update")
            {
              let Number = this.addResults.findIndex(a=> a.add_ID == postID); 
              this.addResults[Number].totalDisLikeCount = data.toString();            
            }
            else
            {
              this.addResults[this.indexToSetValues].totalDisLikeCount = data.toString();                        
            } 
          }, 1000);            
          }                            
        });        
  } 
  submitComment(index: number,result : searchResulteDTO, commentDes : string)
  {
      //debugger;          

      let token = this.localStor.get('currentUser');
      let tokenInfo = this.getDecodedAccessToken(token); // decode token

      this.indexToSetValues = index;
      this.addID = result.add_ID;
      this.CatName = result.cat;

      if(result.cat == "Deals"){
        const Details = new ProComments();
        Details.postID = parseInt(result.add_ID);
        Details.CatName = result.cat;
        Details.commentDesc = commentDes;
        const x= this.addService.submitDealComment(Details);  
        x.subscribe(()=>this.update.next(true));  

        Details.addedBy = parseInt(tokenInfo.nameid);
        this.liveUpdate.sendDealChatMessage(Details);
      }
      else if(result.cat == "Products"){
        const ProDetails = new ProComments();
        ProDetails.postID = parseInt(result.add_ID);
        ProDetails.CatName = result.cat;
        ProDetails.commentDesc = commentDes;
        const x= this.addService.submitProductComment(ProDetails);  
        x.subscribe(()=>this.update.next(true));  

        ProDetails.addedBy = parseInt(tokenInfo.nameid);
        this.liveUpdate.sendProductChatMessage(ProDetails);
      }
      else{
        const commentDetails = new AdComments();
      commentDetails.postID = result.add_ID;
      commentDetails.CatName = result.cat;
      commentDetails.commentDesc = commentDes;

      const x= this.addService.submitAddComment(commentDetails);   
      x.subscribe(()=>this.update.next(true)); 

      commentDetails.addedBy = parseInt(tokenInfo.nameid);
      this.liveUpdate.sendChatMessage(commentDetails);
      }         
      
      
      this.toasterService.success('Comment submitted successfully', 'Comment', {
        closeButton: true,
        progressBar:true,
        tapToDismiss:true
      });
      this.commentDescription = "";
      //debugger;
      //this.liveUpdate.sendChatMessage(commentDetails);
     
  } 
  getCommentsByAdd() {   
    //debugger;
    var postID = parseInt(this.addID);
    if(this.CatName == "Deals"){
      this.addService.getDealComments(postID)
        .subscribe((data:Comments[]) => { 
          this.addResults[this.indexToSetValues].adComments = data;                  
        }); 
    }
    else if(this.CatName == "Products"){
      this.addService.getProductComments(postID)
        .subscribe((data:Comments[]) => { 
          this.addResults[this.indexToSetValues].adComments = data;                  
        }); 
    }
    else{
    this.addService.getAddComments(this.addID)
        .subscribe((data:Comments[]) => { 
          this.addResults[this.indexToSetValues].adComments = data;                  
        });        
      }
  }
  categoryChange(selected: string)
  {
    alert(this.selectedCategory);
  }  
  
  getSubCategoriesLevelTwo(catToSearch:string) {  
    debugger; 
    catToSearch = this.selectedCategory;
    this.searchService.getSubCategories(catToSearch)
    .subscribe((data:CategoryDto[]) => { 
      this.subCategories = data;  
    });
  }

  getSubCategoriesLevelThree(catToSearch:string) {  
    debugger; 
    this.searchService.getSubCategories(catToSearch)
    .subscribe((data:CategoryDto[]) => { 
      this.subCatFirstChildList = data;  
    });
  }

  getSubCategoriesLevelFour(catToSearch:string) {  
    debugger; 
    this.searchService.getSubCategories(catToSearch)
    .subscribe((data:CategoryDto[]) => { 
      this.subCatSecondChildList = data;  
    });
  }


getCategoryResultFromApi(catToSearch:string){
  this.searchService.getSubCategories(catToSearch)
        .subscribe((data:CategoryDto[]) => { 
          //debugger;
          if(this.levelOfSearch == 0)
          {
            this.subCategories = data;  
            this.levelToShow= 1;
          }          
          else if(this.levelOfSearch == 1)
          {
            this.subCatFirstChildList = data; 
            this.levelOfSearch =  2;
            if(this.subCatFirstChildList.length >0)
            {              
              this.levelToShow= 2;
            } 
            else
            {             
              this.levelToShow= 1;
            }            
          }
          else if(this.levelOfSearch == 2)
          {
            this.subCatSecondChildList = data;  
            this.levelOfSearch =  3; 
            if(this.subCatSecondChildList.length >0)
            {
              this.levelToShow =  3; 
            }
            else
            {
              this.levelToShow =  2;
            }
                       
          } 
          else if(this.levelOfSearch == 3)
          {
            this.levelOfSearch =  4; 
            if(this.subCatSecondChildList.length >0)
            {
              this.levelToShow =  4;
            }    
            else
            {
              this.levelToShow =  3;
            }                      
          }                        
        });               
  }


  getSubCategories(selectedLevel: number) {  
    //debugger; 
    let catToSearch : string ="";
    if(selectedLevel == 0)
    {
      catToSearch = this.selectedCategory;
      this.selectedSubCategory = '0';
      this.selectedSubCatFirstChild = '0';
      this.selectedSubCatSecondChild = '0';      
    }
    else if(selectedLevel == 1)
    {
      catToSearch = this.selectedSubCategory;
    }
    else if(selectedLevel == 2)
    {
      catToSearch = this.selectedSubCatFirstChild;
    }
    else if(selectedLevel == 3)
    {
      catToSearch = this.selectedSubCatSecondChild;
    }
    

    this.searchService.getSubCategories(catToSearch)
        .subscribe((data:CategoryDto[]) => { 
          //debugger;
          if(selectedLevel == 0)
          {
            this.subCategories = data;  
            this.levelOfSearch =  1;
            this.levelToShow= 1;
          }          
          else if(selectedLevel == 1)
          {
            this.subCatFirstChildList = data; 
            this.levelOfSearch =  2;
            if(this.subCatFirstChildList.length >0)
            {              
              this.levelToShow= 2;
            } 
            else
            {             
              this.levelToShow= 1;
            }
            
          }
          else if(selectedLevel == 2)
          {
            this.subCatSecondChildList = data;  
            this.levelOfSearch =  3; 
            if(this.subCatSecondChildList.length >0)
            {
              this.levelToShow =  3; 
            }
            else
            {
              this.levelToShow =  2;
            }
                       
          } 
          else if(selectedLevel == 3)
          {
            this.levelOfSearch =  4; 
            if(this.subCatSecondChildList.length >0)
            {
              this.levelToShow =  4;
            }    
            else
            {
              this.levelToShow =  3;
            }                      
          }            
          ////console.log(data);             
        });  
        
        // if(this.subCategoryIDFromSearch)
        // {
        //   this.selectedSubCategory = this.subCategoryIDFromSearch;
        // }
  }

  ongetDetails(itemDetails :searchResulteDTO)
  {
    //alert(itemDetails.cat + itemDetails.add_ID);
    //this.router.navigate(['/details',itemDetails.cat,itemDetails.add_ID]);
    if(itemDetails.cat == "Products"){
      let url = this.router.createUrlTree(['/product/details/',itemDetails.add_ID])
      window.open(url.toString(), '_self')
    }else if(itemDetails.cat == "Deals"){
      let url = this.router.createUrlTree(['/deal/details/',itemDetails.add_ID])
      window.open(url.toString(), '_self')
    }
    else{
      let url = this.router.createUrlTree(['/details',itemDetails.cat,itemDetails.add_ID])
      window.open(url.toString(), '_self')
    }
  }
  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  visibleIndex = -1;
  showSubItem(ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }

  visibleFilters = 1;
  showFilters() {
    if (this.visibleFilters === 1) {
      this.visibleFilters = -1;
    } else {
      this.visibleFilters = 1;
    }
  }


  searchSetFromListPage(action:number)
  {
    debugger;
    let categoryName :string="";
    let subCategoryName:string="";
    let subcateFirstChildName:string="";
    let subcateSecondChildName:string="";
    let subcateThirdChildName:string="";

    const requestData = new allSearchFilters();    

    requestData.CatID = this.selectedCategory;
    requestData.subCatID = this.selectedSubCategory;
    requestData.firstChild = this.selectedSubCatFirstChild;    
    requestData.secondChild = this.selectedSubCatSecondChild; 
    requestData.thirdChild = this.selectedSubCatthirdChild;

    if(this.selectedCategory !="" && this.selectedCategory !="0")
    {
      categoryName= this.getCategoryName();
    }
    
    if (this.selectedSubCategory!="" && this.selectedSubCategory!="0" ){
      subCategoryName= this.getSubCategoryName(this.selectedSubCategory,this.subCategories);
      requestData.levelOfSearch ="2";
    } 

    
    if (this.selectedSubCatFirstChild!="" && this.selectedSubCatFirstChild!="0"){
      subcateFirstChildName= this.getSubCategoryName(this.selectedSubCatFirstChild,this.subCatFirstChildList);
      //subcateFirstChildName = subCategoryName;
      requestData.levelOfSearch ="3";
    } 

    if (this.selectedSubCatSecondChild!="" && this.selectedSubCatSecondChild!="0"){
      subcateSecondChildName= this.getSubCategoryName(this.selectedSubCatSecondChild,this.subCatSecondChildList);
      requestData.levelOfSearch ="4";
    }

    if (this.selectedSubCatthirdChild!="" && this.selectedSubCatthirdChild!="0"){
     // subCategoryName= this.getSubCategoryName(this.selectedSubCategory,this.subCatSecondChildList);
     requestData.levelOfSearch ="5";
    }
    
    if(this.router.url.includes('search'))
     {
      requestData.searchSender = "4";
      
      debugger;
      const searchSt = new searchState;
      searchSt.category = requestData.CatID;
      searchSt.firstChild =requestData.firstChild;
      searchSt.levelOfSearch =requestData.levelOfSearch;
      searchSt.secondChild =requestData.secondChild;
      searchSt.thirdChild =requestData.thirdChild;
      searchSt.subCategory =requestData.subCatID;      

      //this.store.dispatch(new searchActions.trySearch(searchSt));

      this.localStor.set('searchPattern', JSON.stringify(requestData)); 

      //this.operSearch.setDataNew(requestData);
      if(this.selectedCategory.toLowerCase() == "3c064386-a300-4f90-8e18-f6da9f83aa1c")
      {
        categoryName = subCategoryName;
        subCategoryName = subcateFirstChildName;
        subcateFirstChildName ="";
      }

      this.routeNavigation(categoryName,subCategoryName,subcateFirstChildName,subcateSecondChildName,subcateThirdChildName);
     }
     else
     {           
     }
  }
  
  getCategoryName()
  {
   let categoryName:string;
    if(this.selectedCategory !="" && this.selectedCategory.toLowerCase() == "5bf243b1-2be4-4dda-93a0-cf9e06209e0f")
    {
      categoryName ="Autos";
    }
    else if (this.selectedCategory !="" && this.selectedCategory.toLowerCase() == "d7b411fb-1c21-4f0a-88f7-34d6b789f67d")
    {
      categoryName ="Classified";
    }
    else if (this.selectedCategory !="" && this.selectedCategory.toLowerCase() == "3c064386-a300-4f90-8e18-f6da9f83aa1c")
    {
      categoryName ="Jobs";
    }
    else if (this.selectedCategory !="" && this.selectedCategory.toLowerCase() == "0372dc00-f9cb-4cb5-8b95-901ea8a02efa")
    {
      categoryName ="Property-For-Rent";
    }
    else if (this.selectedCategory !="" && this.selectedCategory.toLowerCase() == "27bbb528-5666-4429-b153-7d6441c0efa1")
    {
      categoryName ="Property-For-Sale";
    }
    else if (this.selectedCategory !="" && this.selectedCategory.toLowerCase() == "c4e1f606-8105-4666-a8bc-c6657730a0df")
    {
      categoryName ="Products";
    }
    else if (this.selectedCategory !="" && this.selectedCategory.toLowerCase() == "d7b411fb-1c21-4f0a-88f7-34d6b789f67d")
    {
      categoryName ="Deals";
    }
    else if (this.selectedCategory !="" && this.selectedCategory.toLowerCase() == "d7b411fb-1c21-4f0a-88f7-34d6b789f67d")
    {
      categoryName ="Suppliers";
    }
    else
    {

    }
    return categoryName;
  }

  getSubCategoryName(selectedCategory:string, selectedCatList:CategoryDto[])
  {
    let subCategoryName: string;
    if(selectedCatList.length>0)
    {
      subCategoryName = selectedCatList.find(a=>a.catID == selectedCategory).catName.replace(/ /g,'-');
    }

    return subCategoryName;
  }

  routeNavigation(catName:string, subCatName:string,subCatFirst:string,subCatSecond:string,subCatThird:string)
  {
    let currentRoute:string;
    let nextRoute:string;
    let eventCounter:number=0;

    if(subCatThird!="")
    {
      this.router.navigate(['/search',catName,subCatName,subCatFirst,subCatSecond,subCatThird], { relativeTo: this.route });
    } 
    else if (subCatSecond !="")
    {
      this.router.navigate(['/search',catName,subCatName,subCatFirst,subCatSecond], { relativeTo: this.route });
    }
    else if(subCatFirst !="")
    {
      this.router.navigate(['/search',catName,subCatName,subCatFirst], { relativeTo: this.route });
    }
    else
    {
      this.router.navigate(['/search',catName,subCatName], { relativeTo: this.route });
    }
debugger;
    this.router.events
    .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    .subscribe((events: RoutesRecognized[]) => {
      debugger;
      let firstURL:string[] ;
      let secondURL:string;
      console.log('previous url', events[0].urlAfterRedirects);
      console.log('current url', events[1].urlAfterRedirects);
      var newstrFirst = events[0].urlAfterRedirects.split('/');
      var newstrSecond = events[1].urlAfterRedirects.split('/');

      if(newstrFirst.length == newstrSecond.length)
      {
        console.log("ist" + newstrFirst.length +"-"+ newstrSecond.length);
        if(eventCounter == 0 )
        {
          console.log("second");
          if(newstrFirst[newstrFirst.length-1] != newstrSecond[newstrSecond.length-1])
          {
            console.log("3rd");
            debugger;
            this.GetSearchResult(2);
          }
        }                
      }
      eventCounter ++;       
    });

  }

  loadSearchFromInnerBar(searchOptions:allSearchFilters)
  {
    if(this.newSearchPattern.CatID !="")
    {
      this.selectedCategory = this.newSearchPattern.CatID.toLowerCase();
      this.levelOfSearch = parseInt(this.newSearchPattern.levelOfSearch);
      //this.typeIdentity = this.newSearchPattern.typeIdentity;

      if(this.levelOfSearch==2)
      {
        
        this.getSubCategoriesLevelTwo(this.selectedCategory);
        this.selectedSubCategory =this.newSearchPattern.subCatID;         
        this.levelToShow=1;
      }
      else if(this.levelOfSearch==3)
      {
        this.getSubCategoriesLevelTwo(this.selectedCategory);
            this.selectedSubCategory =this.newSearchPattern.subCatID;         
        setTimeout(() => {
          this.getSubCategoriesLevelThree(this.selectedSubCategory);
          this.selectedSubCatFirstChild =this.newSearchPattern.firstChild;         
          this.levelToShow=2;
       }, 500);      

      }
      else if(this.levelOfSearch==4)
      {

        this.getSubCategoriesLevelTwo(this.selectedCategory);
            this.selectedSubCategory =this.newSearchPattern.subCatID;         
        setTimeout(() => {
          this.getSubCategoriesLevelThree(this.selectedSubCategory);
          this.selectedSubCatFirstChild =this.newSearchPattern.firstChild;         
          
          setTimeout(() => {
            this.getSubCategoriesLevelFour(this.selectedSubCatFirstChild);
            this.selectedSubCatSecondChild =this.newSearchPattern.secondChild;         
            this.levelToShow=3; 
         }, 200); 
       }, 500); 
              
      }
      this.GetSearchResult(2);
    }
  }
}
