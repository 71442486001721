import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { Product, UpdateProduct } from '../show-product/Product';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CategoryDto } from 'src/app/shared/models/category/categoryDTO.model';
import { dataLibraries } from 'src/app/shared/models/category/dataLibraries/dataLibraries.model';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { Options } from 'ng5-slider';
//import Swal from 'sweetalert2/dist/sweetalert2.js';
//import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { NgOption } from '@ng-select/ng-select';
import { galleryImagesDTO } from 'src/app/shared/models/Adds/galleryImage.model';
import { productDetails } from 'src/app/shared/models/Company/productDetail';
import { productRequest } from 'src/app/shared/models/Company/productRequest';
import { productImagesRequest } from 'src/app/shared/models/Company/productImagesRequest';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AdcompanyService } from 'src/app/shared/services/compnies/CompanyServices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Quill from 'quill';
import 'quill-mention';
import 'quill-emoji';

@Component({
  selector: 'app-add-edit-product',
  templateUrl: './add-edit-product.component.html',
  styleUrls: ['./add-edit-product.component.css']
})
export class AddEditProductComponent implements OnInit {

  Productobj: any;
  empObj: UpdateProduct = new UpdateProduct();
  galleryImagesList: Array<galleryImagesDTO> = [];

  @Input() pro: any;
  updateData: FormGroup;
  proID: string; proName: string; proDescription: string; proPrice: number; proDiscount: number;
  DiscountCheck: boolean;
  proDetails: Product;
  productDetails: any;
  isDisable:boolean=false;

  faArrowRight = faArrowRight;
  selectedCategory = '0';
  selectedSubCategory = '0';
  selectedSubCatFirstChild = '0';
  selectedSubCatSecondChild = '0';
  selectedSearchWord = '';
  subCategories: CategoryDto[];
  subCatFirstChildList: CategoryDto[];
  subCatSecondChildList: CategoryDto[];
  categoryTitle = "";
  subCategoryTitle = "";
  subToFirstTitle = "";
  subToSecondTitle = "";
  finalSelection = "";

  CurrienciesList: NgOption[] = [
    { id: 0, title: "Select Curriencies" },
    { id: 1, title: "PKR" },
    { id: 2, title: "INR" },
    { id: 3, title: "DOLLER" },
    { id: 4, title: "OR" },
    { id: 5, title: "AED" },
    { id: 6, title: "SR" },
    { id: 7, title: "Other" }
  ]
  selectedCurrencyID: number;
  // selectedCurrency: dataLibraries;
  productMainID: number = 0;
  allselected: number = 0;
  levelOfSearch: number = 0;
  levelToShow: number = 0;

  imageDeleteFrom: FormGroup;
  videoDeleteFrom: FormGroup;
  imageurls = [];
  videoUrls = [];
  imageurlsNew = [];
  base64String: string;
  name: string;
  imagePath: string;
  maxkmValue: number = 0;
  optionsKM: Options = {
    floor: 1,
    ceil: 500
  };
  proImagesData: any;
  proPriceUnit: any;
  proMinQty: any;
  proMaxSupply: any;
  descriptionText="hello how are you ";

  fillDropDowns() {
    // this.CurrienciesList = [
    //   { id: "0", title: "Select Curriencies" },
    //   { id: "1", title: "PKR" },
    //   { id: "2", title: "INR" },
    //   { id: "3", title: "DOLLER" },
    //   { id: "4", title: "OR" },
    //   { id: "5", title: "AED" },
    //   { id: "6", title: "SR" },
    //   { id: "7", title: "Other" }
    // ]
  }


 


  constructor(private adcompanyService: AdcompanyService,private ngxLoader: NgxUiLoaderService,private userInterface: openSearchService, private adproductService: adProductService, private formBuilder: FormBuilder,
    private mScrollbarService: MalihuScrollbarService, private notifyService: NotificationService, private route: ActivatedRoute,private router: Router,) { }

  ngOnInit(): void {
    this.fillDropDowns();
    //this.LoadCompanyData();
    this.LoadProductData();
  }

  public quill: Quill;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        [
          { header: 1 },
          { header: 2 },
          { header: 3 },
          { header: 4 },
          { header: 5 },
        ], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction  
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
        [{ 'font': [] }],
        [{ 'align': [] }],  
        ['clean'], // remove formatting button  
        //['link'],
        ['link', 'image', 'video'],
        [{ 'color': [] }],
      ]
    }
  };

  public editorCreated(event: Quill,description): void {
    debugger;
    this.quill = event;
    //this.quill.setText(description);
    this.quill.setHtml(description);
    //this.quill.setValue(description);
    //.setHtml(description);
    let toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('table', () => {
      //this.addNewTable();
    });
  }


  // companyList: any;

  // LoadCompanyData() {
  //   //debugger;
  //   this.adcompanyService.getCompanyDetails().subscribe(data => {
  //     this.companyList = data;      
  //   });
  // }

  LoadProductData() {
    //debugger
    //console.log(this.route.snapshot.params.proID);
    //this.ngxLoader.start();
    this.adproductService.GetProductByByIDNew(this.route.snapshot.params.proID).subscribe((result) => {
      console.log(result);
      this.proDetails = result;
      this.productDetails = result;
      this.proName = result[0].proName;
      this.proPrice = result[0].proPrice;
      this.proDiscount = result[0].proDiscount;
      this.DiscountCheck = result[0].discountCheck;
      this.proDescription = result[0].proDescription;
      this.proPriceUnit = result[0].proPriceUnit;
      this.proMinQty = result[0].proMinQty;
      this.proMaxSupply = result[0].proMaxSupply;
      
      // this.updateData = this.formBuilder.group({
      //   proID: new FormControl(result[0].proID),
      //   proName: new FormControl(result[0].proName),
      //   proDescription: this.descriptionText, // new FormControl(result[0].proDescription),
      //   proPrice: new FormControl(result[0].proPrice),
      //   proDiscount: new FormControl(result[0].proDiscount),
      //   DiscountCheck: new FormControl(result[0].discountCheck),
      //   proPriceUnit: new FormControl(result[0].proPriceUnit),
      //   proMinQty: new FormControl(result[0].proMinQty),
      //   proMaxSupply: new FormControl(result[0].proMaxSupply),
      //   proMainImage: new FormControl(result[0].proMainImage)
      // });
      if(this.proDetails[0].discountCheck == true){
        this.isDisable = true;
      }
      this.productMainID = this.proDetails[0].proID;
      this.categoryTitle = result[0].cat;
      this.subCategoryTitle = result[0].subCat;

      debugger;
      if (this.proDetails[0].proImagesData != null) {
        //debugger;
        for (let ingrediant of this.proDetails[0].proImagesData) {
          //this.imageurls.push({ base64String: ingrediant.imageBase64 });
          this.imageurls.push(ingrediant.imageBase64);
          //this.imageurlsNew.push({ base64String: ingrediant.imageBase64 });
        }
      }
      //debugger;
      if (this.productDetails[0].videO_PATH != null && this.productDetails[0].videO_PATH != "0"  && this.productDetails[0].videO_PATH != "") {
        //debugger;
        this.videoUrls.push(this.productDetails[0].videoBase64);
      }

      //console.log("videoURl");
      //console.log(this.videoUrls);
      // setTimeout(() => {
      //   this.ngxLoader.stop();
  
      // }, 1000);

    });
  }

  updateDetails() {
    debugger;
    //this.updateData.value.proID;
    // const postingData = new Product();
    // postingData.proID = this.productMainID;// this.updateData.value.proID;
    // postingData.proName = this.proName;// this.updateData.value.proName;
    // postingData.proPrice = this.proPrice ;// this.updateData.value.proPrice;
    // postingData.proDiscount =  this.proDiscount;// this.updateData.value.proDiscount;
    // postingData.DiscountCheck = this.DiscountCheck;// this.updateData.value.DiscountCheck;
    // postingData.proDescription =  this.proDetails[0].proDescription;// this.updateData.value.proDescription;
    // postingData.proPriceUnit =  this.proPriceUnit;// this.updateData.value.proPriceUnit;
    // postingData.proMinQty =  this.proMinQty ;//this.updateData.value.proMinQty;
    // postingData.proMaxSupply = this.proMaxSupply;// this.updateData.value.proMaxSupply;
    //postingData.proAddedBy = 0;

    this.empObj.proID = this.productMainID;// this.updateData.value.proID;
    this.empObj.proName = this.proName;// this.updateData.value.proName;
    this.empObj.proPrice =  this.proPrice;// this.updateData.value.proPrice;
    this.empObj.proDiscount =  this.proDiscount;// this.updateData.value.proDiscount;
    this.empObj.DiscountCheck = this.DiscountCheck;// this.updateData.value.DiscountCheck;
    this.empObj.proDescription =  this.proDetails[0].proDescription;// this.updateData.value.proDescription;
    this.empObj.proPriceUnit =  this.proPriceUnit;// this.updateData.value.proPriceUnit;
    this.empObj.proMinQty =  this.proMinQty ;//this.updateData.value.proMinQty;
    this.empObj.proMaxSupply = this.proMaxSupply;// this.updateData.value.proMaxSupply;
    //this.empObj.proAddedBy = "0";


    var data = this.adproductService.updateProduct(this.empObj).subscribe(res => {
      //console.log(res);
    }, err => {
      //console.log(err);
    })

    if (data != null) {
      //this.reloadComponent();
     // this.LoadProductData();
      this.successNotification();
    }
    else {
      this.errorNotification();
    }
  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  onImagesUpdate() {
    const productDetails = new productImagesRequest();
    productDetails.ProID = this.productMainID;
    //console.log(this.proDetails[0]);
    var photos = [];

    var counter = 1;
    if (this.imageurls.length > 0) {
      this.imageurls.forEach(element => {
        var photo = {}; // here's your object
        photo["ProID"] = counter,
          photo["proPhotoPath"] = element,
          photo["proPhotoIsActive"] = true,
          photo["proPHotoIsDeleted"] = false,
          photo["proPhotoAddedBy"] = 0,
          photo["proPhotoAddedDate"] = "07/02/2022",
          photo["proPhotoEditedBy"] = 0,
          photo["proPhotoEditedDate"] = "",
          photo["proIsThumbnail"] = false
        photos.push(photo);
        counter++;
      });
    }
    productDetails.postPhotos = photos;
    //debugger;
    //console.log(productDetails);
    this.adproductService.updateProductImages(productDetails).subscribe(data => {
      if (data != 0) {
        this.notifyService.showSuccess('Bravo!', 'Video Updated successfully !!');
      }
      else {
        this.notifyService.showError('Error!', 'Video NOT Updated !!');
      }
    });
   // //console.log(photos);
  }

  onVideoUpdate() {
    const productDetails = new productRequest();
    productDetails.ProID = this.productDetails[0].proID;
    var Video = [];
    //debugger
    var counter = 1;
    if (this.videoUrls.length > 0) {
      this.videoUrls.forEach(element => {
        productDetails.VIDEO_PATH = element;
      })
    }
    //debugger;
    //console.log(productDetails);
    this.adproductService.updateproductVideo(productDetails).subscribe(data => {
      if (data != 0) {
        this.notifyService.showSuccess('Bravo!', 'Video Updated successfully !!');
      }
      else {
        this.notifyService.showError('Error!', 'Video NOT Updated !!');
      }
    });
    //this.successNotification();
    
  }
  backtoMain()
  {
    this.userInterface.setInterface("1");
    this.router.navigate(['./user/productlist']);
  }
  getSubCategories(selectedLevel: number, event: any) {
    //debugger;
    let catToSearch: string = "";
    if (selectedLevel == 0) {
      catToSearch = this.selectedCategory;
      this.categoryTitle = event.target.options[event.target.options.selectedIndex].text;
      this.selectedSubCategory = '0';
      this.selectedSubCatFirstChild = '0';
      this.selectedSubCatSecondChild = '0';
    }
    else if (selectedLevel == 1) {
      catToSearch = this.selectedSubCategory;
      this.subCategoryTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToFirstTitle = "";
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 2) {
      catToSearch = this.selectedSubCatFirstChild;
      this.subToFirstTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 3) {
      catToSearch = this.selectedSubCatSecondChild;
      this.subToSecondTitle = event.target.options[event.target.options.selectedIndex].text;
    }

    this.userInterface.getSubCategories(catToSearch)
      .subscribe((data: CategoryDto[]) => {
        //debugger;
        if (selectedLevel == 0) {
          this.subCategories = data;
          this.levelOfSearch = 1;
          this.levelToShow = 1;
        }
        else if (selectedLevel == 1) {
          this.subCatFirstChildList = data;
          this.levelOfSearch = 2;
          if (this.subCatFirstChildList.length > 0) {
            this.levelToShow = 2;
          }
          else {
            this.levelToShow = 1;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 2) {
          this.subCatSecondChildList = data;
          this.levelOfSearch = 3;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 3;
          }
          else {
            this.levelToShow = 2;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 3) {
          this.levelOfSearch = 4;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 4;
          }
          else {
            this.levelToShow = 3;
            this.allselected = 1;
          }
        }
        ////console.log(data);
      });

    // if(this.subCategoryIDFromSearch)
    // {
    //   this.selectedSubCategory = this.subCategoryIDFromSearch;
    // }
  }

  successNotification() {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1000
    })
  }

  errorNotification() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Provide the missing data ‼️‼️</a>'
    })
  }

  removeImageEdit(i, imagepath) {
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.ImagePath = imagepath;
  }

  removeImage(i) {
    this.imageurls.splice(i, 1);
    this.imageurlsNew.splice(i, 1);
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      var totalFiles = this.imageurls.length + filesAmount;
      //debugger;
      if (totalFiles > 5) {
        alert("file limit execede!");
      }
      else {
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            //this.imageurls.push({ base64String: event.target.result, });
            this.imageurls.push(event.target.result);
            //this.imageurlsNew.push({base64String: event.target.result});
            //this.imageurlsNew.push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }

  removeVideoEdit(videopath) {
    this.videoDeleteFrom.value.id = 0;
    this.videoDeleteFrom.value.Videopath = videopath;
  }
  removeVideo() {
    this.proDetails[0].videoBase64.splice(0);
    this.proDetails[0].videoBase64.splice(1);
  }

  onSelectVideo(event) {
    //debugger;
    let files = event.target.files;
    if (!this.validateFile(files[0].name)) {
     // this.errorNotification();
      this.notifyService.showError('Please Select Video .mp4 !!', 'Somthing Wrong');
      return false;
    }
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;     
    
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.videoUrls[0] = event.target.result;
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'mp4') {
        return true;
    }
    else {
        return false;
    }
  }

  cleaeForm(){
    this.proName = null;
    this.proDescription = null;
    this.proPrice = null;
    this.proPriceUnit = null;
    this.proMinQty = null;
    this.proMaxSupply = null;
    this.selectedCategory = '0';
    this.selectedSubCategory = '0';
    this.selectedSubCatFirstChild = '0'; 
    this.selectedSubCatSecondChild = '0';
    this.categoryTitle = ''
    this.subCategoryTitle = '';
    this.subToFirstTitle = '';
    this.subToSecondTitle= '';
  }

}


// this.updateData.controls['proID'].setValue(this.proID);
// this.updateData.controls['proName'].setValue(this.proName);
// this.updateData.controls['proDescription'].setValue(this.proDescription);