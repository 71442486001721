import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as fromApp from '../store/app.state';
import * as authActions from '../store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store<fromApp.AppState>,private router:Router, private route:ActivatedRoute) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //debugger;
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {               
                // auto logout if 401 response returned from api
                this.store.dispatch(new authActions.LogOut());                             
            }
            const error = err.error.title || err.status;
            return throwError(error);
        }))
    }
}