import { addPhotos } from "../addPhotos.model";

export class companyRequest{
    public mode: string;
    public Com_ID: number;
    public Com_UserID : number ;  
    public Com_Name: string;    
    public Com_Industry: string;
    public Com_Size : number ;  
    public Com_Email : string ;  
    public Com_Number: string;
    public Com_Licence : string ;  
    public Com_Website : string ;  
    public Com_Address : string ;  
    public Com_CountryID : number ; 
    public Com_Description: string;
    public ImageName : string ;  
    public isActive : boolean;  
    public isDeleted : boolean;  
    public postPhotos : addPhotos[];
}