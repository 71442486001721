import { Component, OnInit } from '@angular/core';
import { BookService } from './book.service';
import { books } from './books.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscribable, Subscription } from 'rxjs';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.css']
})
export class BooksComponent implements OnInit {
  topBooks : books[];

  constructor(private http: HttpClient,private bookService: BookService) { }

  ngOnInit(): void {
   // //debugger;
    this.getBooksList();
    this.getALLCategories();
  }

  getBooksList() {
    this.bookService.getBooks()
    .subscribe((data:books[]) => {
      //console.log(data);
      this.topBooks = data;
      //console.log(this.topBooks);
      // this.topBooks.forEach(element => {
      //   //console.log(element);
      // });
    });
  }
  getALLCategories() {
    this.bookService.getALLCategories()
    .subscribe((data:books[]) => {
      //console.log(data);
      this.topBooks = data;
      //console.log(this.topBooks);      
    });
  }  
}
