import { Component, OnInit } from '@angular/core';
import { addDetailsService } from 'src/app/shared/services/addDetails.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-show-post',
  templateUrl: './show-post.component.html',
  styleUrls: ['./show-post.component.css']
})
export class ShowPostComponent implements OnInit {

  constructor(private adService: addDetailsService,private formBuilder: FormBuilder,private ngxLoader: NgxUiLoaderService,
    private router: Router,
    //private router: ActivatedRoute
    ) { }

  PostList: any=[];
    TotalLength:any;
    page:number=1;
    ngOnInit(): void {
      this.LoadPostData();
    }
    
  LoadPostData() {
    //debugger;
    this.ngxLoader.start(); 
    this.adService.getadsDetails().subscribe((data:any[]) => {
      this.PostList = data;
      setTimeout(() => {
        this.ngxLoader.stop();
       }, 0); 
      this.TotalLength = data.length;
      ////console.log(this.PostList);
    });
    //this.categoryTitle = result[0].cat;
    //this.subCategoryTitle = result[0].subCat;
  }
  
 


}
