import { Component, OnInit } from '@angular/core';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { LocalStorageService } from 'angular-web-storage';
import { AdcompanyService } from 'src/app/shared/services/compnies/CompanyServices';
import { AuthenticationService } from 'src/app/auth/auth.service';
import { Observable } from 'rxjs';
import * as fromAuth from '../../store/reducers/auth.reducers';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.state';
import jwt_decode from 'jwt-decode';
import { postnotify } from 'src/app/shared/models/postnotify.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { notificationService } from 'src/app/shared/liveUpdate/Notification.service';
import { ActionserviceService } from 'src/app/shared/services/compnies/actionservice.service';
import { Actionbtn } from 'src/app/shared/models/Company/User-About/Action_btn';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import { Product } from 'src/app/product/show-product/Product';
import { adds } from 'src/app/shared/models/Adds/adds.model';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html',
  styleUrls: ['./header-inner.component.css']
})
export class HeaderInnerComponent implements OnInit {
  [x: string]: any;
  public scrollbarOptions = { axis: 'y', theme: 'light' };
  status: boolean = false;
  myAdds: boolean = false;
  myPro: boolean = false;
  myDeal: boolean = false;
  myCom: boolean = false;
  mySet: boolean = false;
  myOrder: boolean = false;
  authState: Observable<fromAuth.State>;
  userInnerSideBarMode: string = "0"; // means normal
  ImageName: any;
  imageBase64: any;
  FrndRequests: any;
  SendFrndRequests: any;
  friendmessages: any[];
  messageslength: number;
  frndrequestlenght: any;
  selectedCountry = '0';
  selectedCity = '0';
  title = 'World’s Largest Marketplace - MyKnocks';
  
  clickEvent() {
    ////debugger;
    if (this.status == true) {
      this.status = false;
      this.userInterface.setInnerSideBar("1");
      //this.userInterface.currentInnerSideBarInterface = "1";  

    }
    else {
      this.status = true;
      this.userInterface.setInnerSideBar("0");
    }
    //this.status = !this.status;       
  }
  selectMyCom() {
    //debugger;
    if (this.myCom == false) {
      this.myCom = true;
    }
    else {
      this.myCom = false;
    }
  }
  selectMyAdds() {
    //debugger;
    if (this.myAdds == false) {
      this.myAdds = true;
    }
    else {
      this.myAdds = false;
    }
  }
  selectMyPro() {
    //debugger;
    if (this.myPro == false) {
      this.myPro = true;
    }
    else {
      this.myPro = false;
    }
  }
  selectMyDeal() {
    //debugger;
    if (this.myDeal == false) {
      this.myDeal = true;
    }
    else {
      this.myDeal = false;
    }
  }
  selectMySettings() {
    //debugger;
    if (this.mySet == false) {
      this.mySet = true;
    }
    else {
      this.mySet = false;
    }
  }

  selectOrders() {
    //debugger;
    if (this.myOrder == false) {
      this.myOrder = true;
    }
    else {
      this.myOrder = false;
    }
  }
  
  constructor(private authSer: AuthenticationService, private adcompanyService: AdcompanyService, private actions: ActionserviceService,
    private router: Router, private store: Store<fromApp.AppState>, private userInterface: openSearchService,
    private mScrollbarService: MalihuScrollbarService,private liveNotification: notificationService,public adproductService: adProductService
    , private route: ActivatedRoute, public localStor: LocalStorageService,private notifyService: NotificationService,
    private titleService: Title, private metaService: Meta) {
    if (this.localStor.get('currentUser')) {
    }
    else {
      this.userInterface.setInterface("0");
      this.router.navigate(['../home'], { relativeTo: this.route });
    }
  }
  userName: string;
  token: string;
  email: string;
  userId: string;
  UserImage: any;
  companyList: any;
companyLoaded=0;

  LoadCompanyData() {
    //debugger;
    this.adcompanyService.getCompanyDetails().subscribe(data => {
      this.companyList = data; 
      debugger;
      if(this.companyList)
      {
        this.companyLoaded=1;
        //console.log("plus"+this.companyLoaded);
      }     
      //console.log("minus"+this.companyLoaded);      
    });
  }
  onLogOut() {
    this.authSer.LogedOut();
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }
  ngOnInit(): void {

    //debugger;
    this.liveNotification.currentnotification.subscribe((adds: postnotify) => {
      //debugger;
      if(adds.userName !== null && adds.userName !== undefined){
      this.showNotifyAdd(adds);
    }
    });
       
    this.authState = this.store.select('auth');

    this.store.select('auth').subscribe(data =>
      {
        //debugger;
        this.token = data.token;
        const tokenInfo = this.getDecodedAccessToken(this.token); // decode token
        if (tokenInfo != null) {
        this.email = tokenInfo.email; // get token expiration dateTime
        this.userName = tokenInfo.given_name;
        this.userId = tokenInfo.nameid;
        this.ImageName = tokenInfo.unique_name;
        
        this.LoadCompanyData();
        this.LoadFriendRequests();
        //this.LoadSendFriendRequests();
        this.LoadMessageNotify();    
        this.LoadUserData();

        }
      });
    //debugger;

      this.userInterface.currentInnerSideBarInterface.subscribe(data => {
        //debugger;
        //console.log(data);
        if (data.toString() == "1") {
          this.status = false;
          //this.userInnerSideBarMode  = data.toString();
        }
        else if (data.toString() == "0") {
          this.status = true;
          //this.userInnerSideBarMode  = '0';
        }
      });

      if (this.localStor.get('currentUser')) {
      }
      else {
        this.userInterface.setInterface("0");
        this.router.navigate(['../home'], { relativeTo: this.route });
      }


      this.titleService.setTitle(this.title);
      if(this.selectedCountry == '2')
       {
         this.metaService.addTags(    
           [
           {name: 'description', content: 'Home page of MyKnocks Pakistan'},
           {name: 'author', content: 'MyKnocks Pakistan'},
           {name: 'keywords', content: 'used cars in pakistan , used cars in lahore , used cars in gujranwala , buy used cars pakistan , buy used cars , used cars pakistan , second hand vehicle in pakistan, '+
           'second hand vehicle in lahore, second hand vehicle in gujranwala, second hand cars pakistan , second hand cars lahore, second hand cars gujranwala , '+
           'apartment for sale , flat for sale , apartments for sale in lahore , apartments for sale , home for sale , villa for sale , plot for sale , '+
           'home for rent , classified ads in lahore , classified ads in gujranwala , classified ads in pakistan, lahore cars for sale, pakistan cars for sale, '+
           'second hand cars in lahore , second hand cars in gujranwala , second hand cars in pakistan , used phone in lahore , used phone in pakistan, used phone in gujranwala,  '+
           'villa for rent lahore, villa for rent islamabad, villa for rent karachi, lahore property sale,buy car in lahore, buy apartment , buy car in lahore, '+
           'buy car in gujranwala, buy car in karachi, buy car in islamabad , jobs in lahore, jobs in pakistan ,property for rent in lahore , property for rent in gujranwala, '+
           'property for rent in karachi, property for rent in islamabad , olx pakistan , second hand furniture lahore, second hand furniture , buying house in lahore, '+
           'buying house in gujranwala , buying house in karachi , buying house in islamabad ,used furniture lahore , used furniture , used mobile in lahore ,  '+
           'used mobile in gujranwala , used mobile in karachi , used mobile in islamabad , second hand mobile , second hand mobile market ,  '+
           'real estate jobs , software engineer jobs , house for sale , house for rent , iphone for sale , samsung for sale , iphone15 for sale ,sell my car ,  '+
           'plots for sale , plots for sale in lahore , files for sale , plot file for sale , toyota for sale, honda for sale , property for sale , property for rent ,  '+
           'shop for sale , shop for sale in lahore , shop for sale in gujranwala , shop for sale in karachi , shop for sale in islamabad , shop for rent, '+
           'shop for rent in lahore , shop for rent in gujranwala , shop for rent in islamabad , shop for rent in karachi , classified website in pakistan , classified website , '+
           'free posting site in pakistan, free ads posting , used trucks for sale, heavy bike for sale , cars for rent ,second hand ipad for sale ,  mobile sale , '+
           'sell your car pakistan , sell your car lahore , sell your car gujranwala , free advertising website in pakistan, free ads in pakistan , used phone for sale'}
          ]);
       }
       else if (this.selectedCountry == '1')
       {
         this.metaService.addTags(    
           [
           {name: 'description', content: 'Home page of MyKnocks UAE'},
           {name: 'author', content: 'MyKnocks UAE'},
           {name: 'keywords', content: 'buy used cars dubai, used cars uae dubai ,used cars dubai , used cars uae , second hand vehicle in dubai , second hand cars uae dubai, '+
           ' apartment for sale in dubai , apartment for sale in uae, flat for sale in dubai , apartments for sale in dubai uae, villa for sell in dubai, '+
           ' classified ads in uae, classified ads in dubai, dubai cars for sale ,second hand cars in dubai , used phone in dubai,villa for rent dubai,  '+
           ' dubai property sale,buy car in dubai ,buy apartment in dubai, buy car in uae , jobs in dubai , property for rent in dubai , olx dubai ,  '+
           ' second hand furniture dubai, buying house in dubai ,used furniture dubai , used mobile in uae , second hand mobile in uae , second hand mobile market in dubai,  '+
           ' real estate jobs in dubai , software engineer jobs in dubai , house for sale , house for rent , shop for sale , shop for rent, classified website in uae,  '+
           ' classified website in dubai , free posting site in dubai, free ads posting in uae , free ads posting in dubai , used trucks for sale, heavy bike for sale,  '+
           ' second hand ipad for sale in dubai , mobile sale uae , sell your car dubai , free advertising website in dubai , free advertising website in uae , used phone for sale, '+
           ' free ads in uae '}
          ]);
       }
       else
       {
         this.metaService.addTags(    
           [
           {name: 'description', content: 'Home page of MyKnocks UAE'},
           {name: 'author', content: 'MyKnocks UAE'},
           {name: 'keywords', content: 'buy used cars dubai, used cars uae dubai ,used cars dubai , used cars uae , second hand vehicle in dubai , second hand cars uae dubai, '+
           ' apartment for sale in dubai , apartment for sale in uae, flat for sale in dubai , apartments for sale in dubai uae, villa for sell in dubai, '+
           ' classified ads in uae, classified ads in dubai, dubai cars for sale ,second hand cars in dubai , used phone in dubai,villa for rent dubai,  '+
           ' dubai property sale,buy car in dubai ,buy apartment in dubai, buy car in uae , jobs in dubai , property for rent in dubai , olx dubai ,  '+
           ' second hand furniture dubai, buying house in dubai ,used furniture dubai , used mobile in uae , second hand mobile in uae , second hand mobile market in dubai,  '+
           ' real estate jobs in dubai , software engineer jobs in dubai , house for sale , house for rent , shop for sale , shop for rent, classified website in uae,  '+
           ' classified website in dubai , free posting site in dubai, free ads posting in uae , free ads posting in dubai , used trucks for sale, heavy bike for sale,  '+
           ' second hand ipad for sale in dubai , mobile sale uae , sell your car dubai , free advertising website in dubai , free advertising website in uae , used phone for sale, '+
           ' free ads in uae '}
          ]);
       }

    }  
    LoadFriendRequests(){
      this.actions.GetFrndsRequest().subscribe(data => {
        this.FrndRequests = data;
        ////console.log(this.FrndRequests)
      });
    }
    LoadSendFriendRequests(){
      this.actions.GetSENDFrndsRequest().subscribe(data => {
        this.SendFrndRequests = data;
        if(data.length == 0){
          this.SendFrndRequests = 1;
        }else{
          this.SendFrndRequests =data.length;
        }
      });
    }

    AcceptReq(frndID){
      // //debugger;
       const data = new Actionbtn();
       data.mode = "Accept"
       data.userID = frndID;
       this.actions.AcceptFriendRequest(data).subscribe(data => {
         this.LoadFriendRequests();
         this.notifyService.showSuccess('Baravo !!', 'Accept Successfully');
       });     
     }
     RejectReq(frndID){
       var result = confirm("want to Reject ??")
       if (result) {
       const data = new Actionbtn();
       data.mode = "Reject"
       data.userID = frndID;
       this.actions.AcceptFriendRequest(data).subscribe(data => {
         this.LoadFriendRequests();
         this.notifyService.showSuccess('Baravo !!', 'Reject Successfully');
       });        
       }
     }

    LoadMessageNotify(){
      var userProfileID = 0;
      this.actions.From_FriendMessages(userProfileID).subscribe((result) => {
        this.friendmessages = result;
        this.messageslength = result.length;
        // this.todayWithPipe = this.pipe.transform(Date.now(), 'dd/MM/yyyy');
      });
    }

    showNotifyAdd(adds: postnotify) {
      //debugger;
      this.notifyService.showSuccessWithHtml(
        '<div style="width:100%; float:left;">' +
          '<div> ' +
            '<a href="/' + adds.URL + '" target="_self"><img src="' + adds.userImage + '"  class="img-fluid circular_image" style="width: 15px;height: 15px;border-radius: 50%;"  alt="..."></a>' +
            //'<span> Ali Ahamd </span>'+
          '</div>' +
          '<div style="width:75%">' +
            '<div style="width:100%; float:left;">' +
            '<div style="width:50%"><span>' + adds.userName + '</span></div>' +
            '<div style="width:50%"> <span>' + adds.datetime + '</span></div>' +
          '</div>' +
          '<div style="width:100%; float:left;"><span>' + adds.addTitle + '</span></div>' +
        ' </div>', '')
  
    }
  goUserNewPost() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../user/adsposting'], { relativeTo: this.route });
  }

  refreshComponent() {
    this.router.navigate(['../userPosting'], { relativeTo: this.route });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  LoadUserData() {
    //debugger;
    this.adcompanyService.getProfileDetails().subscribe(data => {
      this.UserImage = data;
      this.imageBase64 = data.imageBase64;
      ////console.log(this.imageBase64);
      //console.log(this.UserImage);
    });
  }
  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

  myuserview(){
    //debugger;
    var userProfileID = this.userId;
    this.router.navigate(['/userprofile/detail',userProfileID], { relativeTo: this.route });
  }

  removeCartProduct(product: Product) {
    this.adproductService.removeLocalCartProduct(product);
  }
  goUserCart() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../usercart'], { relativeTo: this.route });
  }

  goUserCartFavorite() {
    this.userInterface.setInterface("1");
    this.router.navigate(['../user/favorite/products'], { relativeTo: this.route });
  }

  removeLocalAds(items:adds){
    this.adproductService.removeLocalAds(items);
  }
}

