<!-- <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"> -->
<main id="tg-main" class="tg-main tg-haslayout">
    <section class="tg-dbsectionspace tg-haslayout">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div id="content" class="content content-full-width">
                        <!-- begin profile -->
                        <div class="profile">
                            <div class="profile-header">
                                <!-- BEGIN profile-header-cover -->
                                <div class="profile-header-cover"></div>
                                <!-- END profile-header-cover -->
                                <!-- BEGIN profile-header-content -->
                                <div class="profile-header-content">
                                    <!-- BEGIN profile-header-img -->
                                    <div class="profile-header-img" *ngFor='let url of imageurls; let i = index'>
                                        <img [src]="url" alt="userimage">
                                        <img *ngIf="url == null" src="../../assets/images/ads/dummyimage.png" alt="dummyimage">
                                    </div>
                                    <!-- END profile-header-img -->
                                    <!-- BEGIN profile-header-info -->
                                    <div class="profile-header-info">
                                        <h4 class="m-t-10 m-b-5">{{profileList.userName}}</h4>
                                        <p class="m-b-10">{{profileList.userMiddleName}}</p>
                                        <a routerLink="/userprofile/about" class="btn btn-sm btn-info mb-2">Edit Profile</a>
                                    </div>
                                    <!-- END profile-header-info -->
                                </div>
                                <!-- END profile-header-content -->
                            </div>
                        </div>
                        <!-- end profile -->
                    </div>
                </div>
            </div>
        </div>
        <!-- // Counter // -->
        <div class="container" style="width: 90%;">
            <div class="row">
                <div class="tg-dashboardbox col-md-12">
                    <div class="col-6 col-lg-3">
                        <div class="count-data text-center">
                            <h6 class="count h2" data-to="500" data-speed="500">500</h6>
                            <p class="m-0px font-w-600">Happy Clients</p>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="count-data text-center">
                            <h6 class="count h2" data-to="150" data-speed="150">150</h6>
                            <p class="m-0px font-w-600">Project Completed</p>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="count-data text-center">
                            <h6 class="count h2" data-to="850" data-speed="850">850</h6>
                            <p class="m-0px font-w-600">Photo Capture</p>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="count-data text-center">
                            <h6 class="count h2" data-to="190" data-speed="190">190</h6>
                            <p class="m-0px font-w-600">Telephonic Talk</p>
                        </div>
                    </div>
                </div>
            </div>
        </div><br />
        <div class="container">
            <div class="row col-md-12">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                    <div class="tg-dashboardboxtitle">
                        <h2>Friend Information</h2>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7"><br />
                        <div class="card">
                            <div class="card-title">
                                <i class="fa fa-graduation-cap" aria-hidden="true"></i> &nbsp; Education
                            </div>
                            <div class="card-body">
                                <blockquote class="blockquote mb-0">
                                    <div class="" *ngFor="let item of education">
                                        <p>{{item.eduDescription}}</p>
                                        <footer class="blockquote-footer">{{item.eduCompany}}<cite
                                                title="Source Title">{{item.eduStartDate}}</cite></footer>
                                    </div>
                                    <hr />
                                    <div style="background-color: rgb(247, 247, 247);">
                                        <i class="fa fa-user-circle-o" aria-hidden="true"></i> &nbsp; Class Mates
                                    </div>
                                    <div *ngFor="let item of class;let i = index">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="col-md-2" *ngFor="let classmate of item.classmatelist">
                                                    <img *ngIf="classmate.friendImageBase64 != null"
                                                        [src]="classmate.friendImageBase64" class="circular_image" />
                                                    <img *ngIf="classmate.friendImageBase64 == null"
                                                        src="../../assets/images/ads/dummyimage.png"
                                                        class="circular_image" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-title">
                                <i class="fa fa-area-chart" aria-hidden="true"></i> &nbsp; Experience
                            </div>
                            <div class="card-body">
                                <blockquote class="blockquote mb-0">
                                    <div class="" *ngFor="let item of experience">
                                        <p>{{item.expDescription}}</p>
                                        <footer class="blockquote-footer">{{item.expCompany}}<cite
                                                title="Source Title">{{item.expStartDate}}</cite></footer>
                                    </div>
                                    <hr />
                                    <div style="background-color: rgb(247, 247, 247);">
                                        <i class="fa fa-user-circle-o" aria-hidden="true"></i> &nbsp; Collegues
                                    </div>
                                    <div *ngFor="let item of team;let i = index">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="col-md-2" *ngFor="let collegue of item.colleguelist">
                                                    <img *ngIf="collegue.collegueImageBase64 != null"
                                                        [src]="collegue.collegueImageBase64" class="circular_image" />
                                                    <img *ngIf="collegue.collegueImageBase64 == null"
                                                        src="../../assets/images/ads/dummyimage.png"
                                                        class="circular_image" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-title">
                                <i class="fa fa-bars" aria-hidden="true"></i> &nbsp; Skills
                            </div>
                            <div class="scrollskills modal-body d-flex justify-content-center"
                                data-spy="scroll" data-offset="0">
                                <div class="card-body">
                                    <blockquote class="blockquote mb-0">
                                        <div *ngFor="let item of skills; let i = index">
                                            <div class="row">
                                                <p id="review">{{item.endorcementcounter}}</p>
                                                <p> &nbsp; {{item.skillName}}</p>
                                                <footer class="blockquote-footer">
                                                    <a href="javascript:void(0);"
                                                        (click)="AddEndorcement(item.skillID)"><b>+</b>
                                                    </a>
                                                    &nbsp;&nbsp;<cite title="Source Title"
                                                        *ngFor="let items of item.endorcementuserlist;">
                                                        <img src="../../assets/images/ads/dummyimage.png"
                                                            *ngIf="items.endorcementImageBase64 == null || items.endorcementImageBase64 == ''"
                                                            class="circular_image" />
                                                        <img *ngIf="items.endorcementImageBase64 != null"
                                                            [src]="items.endorcementImageBase64"
                                                            class="circular_image" />
                                                    </cite>
                                                </footer>
                                            </div>
                                        </div>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5"><br />
                        <div class="card">
                            <div class="card-title">
                                <i class="fa fa-file-text" aria-hidden="true"></i> &nbsp; Summary
                            </div>
                            <div class="card-body">
                                <blockquote class="blockquote mb-0">
                                    <p [innerHtml]="profileList.currentCoverLetter"></p>
                                    <footer class="blockquote-footer">Someone famous in <cite
                                            title="Source Title">Source
                                            Title</cite></footer>
                                </blockquote>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-title">
                                <i class="fa fa-certificate" aria-hidden="true"></i> &nbsp; Certifications
                            </div>
                            <div class="card-body">
                                <blockquote class="blockquote mb-0">
                                    <div class="" *ngFor="let item of certification">
                                        <p>{{item.certDescription}}</p>
                                        <footer class="blockquote-footer">{{item.certInstitute}}<cite
                                                title="Source Title">{{item.certDate}}</cite></footer>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-title">
                                <i class="fa fa-trophy" aria-hidden="true"></i> &nbsp; Awards & Honours
                            </div>
                            <div class="card-body">
                                <blockquote class="blockquote mb-0">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    <footer class="blockquote-footer">Someone famous in <cite
                                            title="Source Title">Source
                                            Title</cite></footer>
                                </blockquote>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-title">
                                <i class="fa fa-file-archive-o" aria-hidden="true"></i> &nbsp; Projects
                            </div>
                            <div class="card-body">
                                <blockquote class="blockquote mb-0">
                                    <div class=""  *ngFor="let item of project">
                                        <p>{{item.projectTitle}}</p>
                                        <footer class="blockquote-footer">{{item.projectCompany}}</footer>
                                    </div>
                                    <hr />
                                    <div style="background-color: rgb(247, 247, 247);">
                                        <i class="fa fa-user-circle-o" aria-hidden="true"></i> &nbsp; Collegues
                                    </div>
                                    <div *ngFor="let item of project;let i = index">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="col-md-4" *ngFor="let collegue of item.projectdetail">
                                                    <img *ngIf="collegue.collegueImageBase64 != null"
                                                        [src]="collegue.collegueImageBase64" class="circular_image" />
                                                    <img *ngIf="collegue.collegueImageBase64 == null"
                                                        src="../../assets/images/ads/dummyimage.png"
                                                        class="circular_image" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                    <div class="tg-dashboardboxtitle">
                        <h2>Suggested Friends</h2>
                    </div><br />
                    <div class="row">
                        <div class="col-md-4" *ngFor="let items of SuggestedFrnd; let i = index">
                            <div class="suggest">
                                <div class="suggest-inner">
                                    <img *ngIf="items.imageBase64 != null" [src]="items.imageBase64" />
                                    <img src="../../assets/images/ads/dummyimage.png"
                                        *ngIf="items.imageBase64 == null" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>