import { EventEmitter, Injectable } from '@angular/core'; 
import { environment } from '../../environments/environment'; 
import {HubConnection,HubConnectionBuilder,HubConnectionState,LogLevel} from '@microsoft/signalr';
import { Messages } from './message.model'; 
import { BehaviorSubject, Subject, Observable, of } from 'rxjs'; 
import { ChatAdapter, IChatGroupAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from 'ng-chat';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
  
@Injectable()  
export class ChatService extends ChatAdapter {  
  public userId: string;
  foodchanged$ = new Subject();
  messageReceived$ = new Subject<Messages>();
  newCpuValue$ = new Subject<number>();
  connectionEstablished$ = new BehaviorSubject<boolean>(false);

  private hubConnection: HubConnection;
  private username: string;
  //username = 'ali';
  constructor(private http: HttpClient, private httpClient: HttpClientModule) {  
    super();
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection(); 
  }  
  
  sendChatMessage(message: Messages) {
    //debugger;
    this.hubConnection.invoke('NewMessage', message);
  } 
  
  // private createConnection() {  
  //   this._hubConnection = new HubConnectionBuilder()      
  //     .withUrl('http://localhost:44373/messageHub')        
  //     .build();  
  // }  
  
  private createConnection() {
    this.hubConnection = new HubConnectionBuilder()
      //.withUrl('https://localhost:44373/messageHub')
      .withUrl('https://api.myknocks.com/messageHub')
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
  }

  private startConnection() {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      return;
    }
    this.hubConnection.start().then(
      () => {
        //console.log('Hub connection started!');
        this.connectionEstablished$.next(true);
      },
      error => console.error(error)
    );
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('Send', (data: any) => {
      //debugger;
      //console.log(data);
      alert(data.message);
      this.foodchanged$.next(data);      
    });
    
    this.hubConnection.on("messageReceived", (participant, message) => {
      // Handle the received message to ng-chat
      //console.log(message);
      this.onMessageReceived(participant, message);
    });

    this.hubConnection.on("friendsListChanged", (participantsResponse: Array<ParticipantResponse>) => {
      // Handle the received response to ng-chat
      this.onFriendsListChanged(participantsResponse.filter(x => x.participant.id != this.userId));
    });
  }

  joinRoom(): void {
    if (this.hubConnection && this.hubConnection.state == HubConnectionState.Connected) {
      this.hubConnection.send("join", this.username);
    }
  }

  listFriends(): Observable<ParticipantResponse[]> {
    //debugger;
    // List connected users to show in the friends list
    // Sending the userId from the request body as this is just a demo 
    return this.http
      .post('listFriends', { currentUserId: this.userId })
      .pipe(
        map((res: any) => res),
        catchError((error: any) => Observable.throw(error.error || 'Server error'))
      );
  }

  getMessageHistory(destinataryId: any): Observable<Message[]> {
    // This could be an API call to your web application that would go to the database
    // and retrieve a N amount of history messages between the users.
    return of([]);
  }

  sendMessage(message: Message): void {
    if (this.hubConnection && this.hubConnection.state == HubConnectionState.Connected)
      this.hubConnection.send("sendMessage", message);
  }


    // this.hubConnection.on('Send', (data: any) => {
    //   //console.log('data', data);
    //   this.messageReceived$.next(data);
    // });     
}    