<!-- Table -->
<div class="container-xl center" style="width: 90%">
    <!-- <select [(ngModel)]="adproductService.pageSize" (change)="onPageSizeChange()" id="pageSize">
        <option value="3">3</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="50">50</option>
      </select>
      <br/>
      <div class="form-group">
        <label for="brand_select">By Brands:</label>
        <select
          class="form-control"
          name=""
          id="brand_select"
          [(ngModel)]="selectedBrand"
        >
          <option *ngFor="let brand of brands" [ngValue]="brand">{{
            brand
          }}</option>
        </select>
      </div><br /> -->
    <table class="table table-striped table-hover">
        <thead>
            <tr style="background: #00cc67;color: white;">
                <th></th>
                <th>Name</th>
                <th>Category</th>
                <th>Price</th>
                <th>Date</th>
                <th>Actions 🎬</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let items of ProductList | paginate: { itemsPerPage: 15, currentPage: page,totalItems:TotalLength }">
                <td><img class="img-fluid circular_image" *ngIf="items.proImageBase != null" [src]="items.proImageBase" /><img class="img-fluid circular_image" src="../../../assets/images/no_image.jpg" alt="product image" *ngIf="items.proImageBase == null" /></td>
                <td>{{items.proName}}</td>
                <td>Product ⇛ {{items.subCat}}</td>
                <td>{{items.proPrice}} ⇛ {{items.proPriceUnitName}}</td>
                <td>{{items.proAddedDate}}</td>
                <td>
                    <!-- routerLink="/update/{{items.proID}}"       (click)="editClick(items)" -->
                    <a routerLink="/user/edit/{{items.proID}}">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                    </a>
                      <!-- &nbsp; | &nbsp; 
                    <a (click)="addToCart(items)">Add to cart <i class="fa fa-shopping-cart" aria-hidden="true"></i></a>                    
                 -->
                </td>
            </tr>
            <tr *ngIf="!ProductList || ProductList.length==0">
                <td colspan="6">No data found</td>
            </tr>
        </tbody>
    </table>
    <pagination-controls class="my-pagination text-center" (pageChange)="page = $event">
    </pagination-controls>
    <!-- <div class="pagination">
        <a href="#" title="first page"><svg fill="currentColor"><path d="M17.59 18L19 16.59 14.42 12 19 7.41 17.59 6l-6 6zM11 18l1.41-1.41L7.83 12l4.58-4.59L11 6l-6 6z"/></svg> First</a>
        <a href="#" title="previous page"><svg fill="currentColor"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg></a>
        <a href="#">1</a>
        <a href="#" class="page-active">2</a>
        <a href="#">3</a>
        <span class="page-dots">...</span>
        <a href="#">6</a>
        <a href="#" title="next page"><svg fill="currentColor"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg></a>
        <a href="#" title="last page">Last <svg fill="currentColor"><path d="M6.41 6L5 7.41 9.58 12 5 16.59 6.41 18l6-6zM13 6l-1.41 1.41L16.17 12l-4.58 4.59L13 18l6-6z"/></svg></a>
    </div> -->
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <app-add-edit-product [pro]="pro" *ngIf="ActivateAddEditProComp">
                </app-add-edit-product> -->
                <!-- <div class="modal-body">
                    <form [formGroup]="UpdateData">
                        <div class="form-group">
                            <label for="exampleInputEmail1">ID :</label>
                            <input type="text" class="form-control" formControlName="proID" placeholder="ID">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Name :</label>
                            <input type="text" class="form-control" formControlName="proName" placeholder="Name">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword2">Description :</label>
                            <input type="text" class="form-control" formControlName="proDescription"
                                placeholder="Description">
                        </div>
                    </form>
                </div> -->
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" (click)="updateProduct()">Update</button>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal-body">
    <form [formGroup]="UpdateData">
        <div class="form-group">
            <label for="exampleInputEmail1">ID :</label>
            <input type="text" class="form-control" formControlName="proID" placeholder="ID">
        </div>
        <div class="form-group">
            <label for="exampleInputPassword1">Name :</label>
            <input type="text" class="form-control" formControlName="proName" placeholder="Name">
        </div>
        <div class="form-group">
            <label for="exampleInputPassword2">Brand :</label>
            <input type="text" class="form-control" formControlName="proBrandName" placeholder="Brand Name">
        </div>
    </form>
    </div> -->