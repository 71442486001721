export class userprofile{
    public ID: number;
    public userName :string;
    public UserMiddleName :string;
    public UserLastName :string;
    public UserFatherName :string;
    public PrimaryEmail :string;
    public MobileNumber :string;
    public oldPassword :string;
    public UserPassword :string;
    public ConfirmPassword :string;
    public ActivationKey :string;
    public ImageName :string;
    public imageBase64 :string;
    public ReligionID :number;
    public GenderID :number;
    public Status :number;
    public Nationality :number;
    public CurrentCompany :string;
    public CurrentCarrierLevel :string;
    public CarrierLevel: number;
    public CurrentCVPath :string;
    public CurrentCoverLetter :string;
    public CVPathBase64 :string;
    public DOB :string; // Date Of Birth
    public Address: string;
    public MakePrivate: boolean;
    public DOBPrivate: boolean;
    public MobilePrivate: boolean;

}