import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/store/app.state';
import * as fromApp from './store/app.state';
import * as fromAuth from './store/reducers/auth.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tokenName } from '@angular/compiler';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';

import { ChatAdapter } from 'ng-chat';
import { DemoAdapter } from './shared/demoAdaptor.service';
import { ChatService } from './shared/chat.service';
import { SignalRGroupAdapter } from './shared/signalRGroupAdapter.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { openSearchService } from './shared/services/search/openSearch.service';
import { Router,NavigationEnd, NavigationStart  } from '@angular/router';
//import {JwtHelperService} from '@auth0/angular-jwt';
import * as jwt_decode from "jwt-decode";
import { DataLayerService } from './core/services/DataLayerService';
import { App } from '@capacitor/app';
import { PlatformLocation } from'@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {  
  title = 'myTestApp';
  authState : Observable<fromAuth.State>;
  //name = 'NGX-UI-LOADER';
  currentTheme = 'light-theme';

  fileUploadUrl: string = 'UploadFile';
  userId: string = "offline-demo";
  username: string;
  triggeredEvents = [];
  userMode : string = "0"; // means normal

  userInnerSideBarMode : string = "1"; // means normal

  adapter: ChatAdapter = new DemoAdapter();
  signalRAdapter: SignalRGroupAdapter;

  switchTheme(theme: string): void {
    this.currentTheme = theme;
  }

  onEventTriggered(event: string): void {
    this.triggeredEvents.push(event);
  }

  joinSignalRChatRoom(): void {
    ////debugger;
    const userName = prompt('Please enter a user name:');

    this.signalRAdapter = new SignalRGroupAdapter(userName, this.http);
  }

  constructor(private store: Store<fromApp.AppState>, public localStor: LocalStorageService, public sessionStor: SessionStorageService,private http: HttpClient,
    private ngxLoader: NgxUiLoaderService, private userInterface: openSearchService, private router: Router,
    //private jwtHelper: JwtHelperService
    private _dataLayerService: DataLayerService,private _location: PlatformLocation
    ){
    ////debugger;
    if(this.getToken() == true)
    {
      ////debugger;
      fromAuth.initialState.athenticated = true;
      fromAuth.initialState.token = this.localStor.get('currentUser').replace(/^"(.*)"$/, '$1');           
    }
    else{
     //this.store.dispatch(new autha)
    }  
    
    debugger;
    if(this._location.pathname.includes("user") || this._location.pathname.includes("company") || this._location.pathname.includes("product") )
    {
      this.userMode = "1";
    }
    else
    {
      this.userMode = "0";
    }
    this._location.onPopState (() => {      
     debugger;  
      if(this._location.pathname.includes("user") || this._location.pathname.includes("company") || this._location.pathname.includes("product") )
      {
        this.userMode = "1";
      }
      else
      {
        this.userMode = "0";
      }
    
     });

    // let currentRoute : string = window.location.href;
    // if(currentRoute.includes("user") || currentRoute.includes("company") || currentRoute.includes("product") )
    // {
    //   this.userMode = "1";
    // }
    // else
    // {
    //   this.userMode = "0";
    // }

  //   this.router.events.subscribe(event=> { // subscribe to router events
  //     if (event instanceof NavigationEnd) //if our event is of our interest
  //     {
  //         this._dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
  //     }
  // });

  debugger;
  this.router.events.subscribe(event=> { // subscribe to router events
    if (event instanceof NavigationStart) //if our event is of our interest
    {
      if (event.navigationTrigger === 'popstate') {
        // Perform actions
      }
       // this._dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
    }
});

debugger;
  App.addListener('backButton', ({ canGoBack }) => {
    debugger;
    if(canGoBack){
      let currentRoute : string = window.location.href;
      if(currentRoute.includes("user") || currentRoute.includes("company") || currentRoute.includes("product") )
      {
        this.userMode = "1";
      }
      else
      {
        this.userMode = "0";
      }
  
      this.router.events.subscribe(event=> { // subscribe to router events
        if (event instanceof NavigationEnd) //if our event is of our interest
        {
            this._dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
        }
    });
      window.history.back();
    } else {
      App.exitApp();
    }
  });

  }
  
  getToken(): boolean {
    //this.localStor.set(this.KEY, { a: 1, now: +new Date }, expired, 's');
    //this.localStor.remove(this.KEY);
    if(this.localStor.get('currentUser'))
    {
      return true;      
    }
    return  false;
  }    
  ngOnInit(){ 
    // this.ngxLoader.start();
    // this.http.get(`https://api.npmjs.org/downloads/range/last-year/ngx-ui-loader`).subscribe((res: any) => {
    //   //console.log(res);
    //   this.ngxLoader.stop();
    // });   
    // //debugger;
    // let currentRoute : string = window.location.href;
    // if(currentRoute.includes("user"))
    // {
    //   this.userMode = "1";
    // }
    // else
    // {
    //   this.userMode = "0";
    // }
    // //console.log(this.router.url);
    this.userInterface.currentInterface.subscribe(data => {
      ////debugger;
      //console.log(data);
      if(data.toString()=="1")
      {
        this.userMode  = data.toString();
      }
      else if(data.toString()=="0")
      {
        this.userMode  = '0';
      }
    });

    let token = this.localStor.get('currentUser');
    let tokenInfo = this.getDecodedAccessToken(token);

    if(this.getToken() == true)
    {
      //if (this.jwtHelper.isTokenExpired(token)) {
        // token expired 
      //} else {
        // token valid
      //}
    }
    

    //for sideBarSettings
    // this.userInterface.currentInnerSideBarInterface.subscribe(data => {
    //   //debugger;
    //   //console.log(data);
    //   if(data.toString()=="1")
    //   {
    //     this.userInnerSideBarMode  = data.toString();
    //   }
    //   else if(data.toString()=="0")
    //   {
    //     this.userInnerSideBarMode  = '0';
    //   }
    // });
  } 

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
 
}
