import { addPhotos } from "../addPhotos.model";
import { addVideo } from "../addVideos.model";

export class productRequest{
    public ProID : number;
    public ProName : string ; 
    public ProBrandName : string ;  
    public ProDescription : string ; 
    public ProAddedBy : number ; 
    public CountryID : number ; 
    public CityID : number ; 
    public ProAddedDate : string ; 
    public ProEditedBy : number ; 
    public ProEditedDateTime: string ; 
    public ProUserID: number ; 
    public ProComID: number ; 
    public ProCatID: string ; 
    public ProSubCatID: string ; 
    public ProEndCatID: string ; 
    public ProEndSubCatID: string;
    public ProPrice: number ; 
    public DiscountCheck:boolean;
    public DiscountPrice: number ; 
    public ProPriceUnit: number ; 
    public ProMinQty: number ; 
    public ProMaxSupply: number ; 
    public ProMainImage: string ; 
    public VIDEO_PATH: string ;
    public ProIsActive: boolean;  
    public ProIsDeleted: boolean;  

    public postPhotos : addPhotos[];
    public addVideo: addVideo[];
}