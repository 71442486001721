import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RregisterDTO } from '../auth/signin/Register';

@Injectable({
  providedIn: 'root'
})
export class RegisterserviceService {
  private baseUrl = environment.baseUrl;  
  private httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'         
      })
    };     
private result:number;
private resultLike:string;
constructor(private httpclient:HttpClient){}

submitRegistration(reg:RregisterDTO){  
  //debugger;
  return this.httpclient.post<number>(this.baseUrl+'service/registeration/all',JSON.stringify(reg),this.httpOptions); 
}

submitEmail(email:string){  
  //debugger;
  return this.httpclient.post<number>(this.baseUrl+'service/forgetPassword/email?email='+email,this.httpOptions);
}

verifyWhtsApp(whatsAppNumber){  
  debugger;
  return this.httpclient.post<number>(this.baseUrl+'services/User/ValidateWhatsAppUser?userNumber='+whatsAppNumber,this.httpOptions);
}

onWhtsAppVerify(verficationCode){  
  //debugger;
  return this.httpclient.post<number>(this.baseUrl+'services/User/VerifyWhatsAppUser?requestCode='+verficationCode,this.httpOptions);
}

}
