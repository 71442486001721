import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OrderService } from '../shared/services/compnies/OrderServices';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { openSearchService } from '../shared/services/search/openSearch.service';

@Component({
  selector: 'app-user-orders-details',
  templateUrl: './user-orders-details.component.html',
  styleUrls: ['./user-orders-details.component.css']
})
export class UserOrdersDetailsComponent implements OnInit {

  constructor(private ngxLoader: NgxUiLoaderService,private orderService: OrderService,
    private router: Router, private route: ActivatedRoute,private userInterface: openSearchService) { }

  ngOnInit(): void {
    const orderID = this.route.snapshot.params['orderID'];
    this.LoadUserOrders(orderID);
  }
  OrderList: any=[];
  TotalLength:any;
  page:number=1;
  LoadUserOrders(orderID:string) {
    //debugger;

   // let requestData:string;
   // requestData = this.route.snapshot.params.orderID;

    this.ngxLoader.start(); 
    this.orderService.getOrdersDetails(orderID).subscribe((data:any[]) => {
      this.OrderList = data;
      console.log(this.OrderList[0])
      setTimeout(() => {
        this.ngxLoader.stop();
       }, 0); 
      this.TotalLength = data.length;
    });
  }

  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home']);
  }
  goToUserOrders()
  {
    //this.userInterface.setInterface("1");
    this.router.navigate(['/user/orderlist']);
  }
}
