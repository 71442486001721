import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';
import * as fromAuth from '../store/reducers/auth.reducers';
import * as fromSearch from '../store/reducers/search.reducers';
import * as fromSearchResults from '../store/reducers/searchResult.reducer';
 
export interface AppState {
  auth: fromAuth.State;
  search: fromSearch.State,
  searchResult:fromSearchResults.State
}
 
export const reducers : ActionReducerMap<AppState> ={
  auth: fromAuth.authReducer ,
  search : fromSearch.searchReducer ,
  searchResult :fromSearchResults.searchResultReducer
};

// export const reducers = {
//   authentication: authentication.reducer
// };
 
// export const selectAuthenticationState = createFeatureSelector<AppState>('authentication');