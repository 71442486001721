<main id="tg-main" class="tg-main tg-haslayout" style="min-height:600px">
    <section class="tg-dbsectionspace tg-haslayout">
        <div class="container" style="border: 2px solid #f4f7f6">
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card chat-app">
                        <div id="plist" class="people-list">
                            <div class="input-group">
                                <span class="input-group-addon" (click)="searchnow()"><i
                                        class="fa fa-search"></i></span>
                                <input type="text" [(ngModel)]="this.SearchFrnd" class="form-control" name="search"
                                    placeholder="Search...">
                            </div>
                            <ul class="list-unstyled chat-list mt-2 mb-0" style="overflow: auto; height: 44rem;">
                                <li class="clearfix" *ngFor="let items of MyFriends; let i = index;"
                                    (click)="setRow(items)" [ngClass]="{'active': items == selectedIndex }">
                                    <!-- <div [ngClass]="items.newMessageReceived  == true ? 'highLight' : 'clearfix'"> -->
                                    <img *ngIf="items.imageBase64 != null && items.imageBase64 != ''" [src]="items.imageBase64" alt="avatar">
                                    <img *ngIf="items.imageBase64 == null || items.imageBase64 == ''" src="../../assets/images/ads/dummyimage.png" alt="avatar">
                                    <div class="about" (click)="chatfilter(items.userProfileID,i)">
                                        <div class="name">
                                            <!-- {{(items.userName != null) ? items.userName: "Friend"}} -->
                                            {{ (items.userName.length>15)? (items.userName | slice:0:15)+'...':(items.userName) }}
                                            <span *ngIf="items.newMessageReceived  == true"
                                                [ngClass]="items.newMessageReceived  == true ? 'highLight' : 'clearfix'"><i
                                                 style="color: #00cc67;"   class="fa fa-bell" aria-hidden="true"></i></span>
                                            <!-- <span *ngIf="items.imageBase64 == null" class="notify"></span> -->
                                        </div>
                                        <div class="status"> <i class="fa fa-circle offline"></i> left 7 mins ago
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </li>
                            </ul>
                        </div>
                        <div class="chat">
                            <div class="chat-header clearfix">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                                            <img *ngIf="friendimage != null" [src]="friendimage" alt="avatar"
                                                style="border: 2px solid #00cc67;padding:2px;width: 45px;height: 45px;">
                                            <img *ngIf="friendimage == null"
                                                src="../../assets/images/ads/dummyimage.png" alt="avatar"
                                                style="border: 2px solid #00cc67;padding:2px;">
                                        </a>
                                        <div class="chat-about">
                                            <h5 class="m-b-0">{{(friendname != null)? friendname:"Friend"}}</h5>
                                            <small>Last seen: 1 hours ago</small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 hidden-sm text-right">
                                        <!-- <div class="input-group">
                                            <span class="input-group-addon" (click)="searchnow()"><i
                                                    class="fa fa-search"></i></span>
                                            <input type="text" [(ngModel)]="this.SearchFrnd" class="form-control"
                                                name="search" placeholder="Search...">
                                        </div> -->
                                        <a href="javascript:void(0);" class="btn btn-default"><i
                                                class="fa fa-camera"></i></a>
                                        <a href="javascript:void(0);" class="btn btn-default"><i
                                                class="fa fa-image"></i></a>
                                        <a href="javascript:void(0);" class="btn btn-default"><i
                                                class="fa fa-cogs"></i></a>
                                        <a href="javascript:void(0);" class="btn btn-default"><i
                                                class="fa fa-question"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-history" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                                <ul class="m-b-0">
                                    <li class="clearfix" *ngFor="let item of friendmessages">
                                        <div *ngIf="item.recevierID == FromUserID">
                                            <div class="message-data text-right">
                                                <span class="message-data-time">{{item.datetime}}</span>
                                                <img class="tg-userdp" [src]="item.userimagebase64" alt="avatar">
                                            </div>
                                            <div class="message other-message float-right">{{item.message}}
                                                <i *ngIf="item.seenByReceiver == 0" class="fa fa-check" aria-hidden="true" style="color: rgb(172, 172, 172);"></i>
                                                <i *ngIf="item.seenByReceiver == 1"  class="fa fa-check" aria-hidden="true" style="color: #00cc67;"></i>
                                            </div>
                                        </div>
                                        <div *ngIf="item.recevierID != FromUserID">
                                            <div class="message-data">
                                                <span class="message-data-time">{{item.datetime}}</span>
                                            </div>
                                            <div class="message my-message">{{item.message}}</div>
                                        </div>
                                    </li>
                                    <!-- <li class="clearfix" *ngFor="let item of friendmessages">
                                        <div class="message-data">
                                            <span class="message-data-time">{{item.msgdatetime}}</span>
                                        </div>
                                        <div class="message my-message">{{item.message}}</div>
                                    </li> -->
                                </ul> 
                            </div>
                            <div class="chat-message clearfix">
                                <form (ngSubmit)="onSubmit()">
                                    <div class="input-group" *ngIf="this.FromUserID != null">
                                        <span class="input-group-addon" (click)="onSubmit()" type="submit"
                                            aria-hidden="true" data-icon="&#xe000;"><i class="fa fa-send" type="submit"
                                                aria-hidden="true" data-icon="&#xe000;"></i></span>
                                        <input type="text" class="form-control" name="message" #chatInput
                                            [(ngModel)]="this.message" placeholder="Enter text here...">
                                        <!-- <span class="input-group-addon" (click)="goToBottom()"><i class="fa fa-angle-double-down" aria-hidden="true"></i></span> -->
                                        <button type="submit"></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>