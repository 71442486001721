import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { chatUser } from 'src/app/user-chat/chatUser.model';
import { environment } from 'src/environments/environment';
import { usermessage } from '../../models/chat/usermessage';
import { Comments } from '../../models/comments/comment.model';
import { ProComments } from '../../models/comments/Pro-comments.model';
import { ProlikeDislikeDetails } from '../../models/Company/likeDislikeDetails.model';
import { Actionbtn } from '../../models/Company/User-About/Action_btn';
import { userprofile } from '../../models/Company/userprofile';

@Injectable({
  providedIn: 'root'
})
export class ActionserviceService {
  private baseUrl = environment.baseUrl;  
  private httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'         
      })
    };     
private result:number;
private resultLike:string;
constructor(private httpclient:HttpClient){}

    submitLikeDislike(likeDislike:ProlikeDislikeDetails){  
      // //debugger;
      return  this.httpclient.post<string>(this.baseUrl+'services/ProLikeDislike/likeDislikePost',JSON.stringify(likeDislike),this.httpOptions)
      .pipe();                                   
    }

    getAddLikeDislikeCount(likeDislike: ProlikeDislikeDetails): Observable<any>{    

      return this.httpclient.post<number>(this.baseUrl+'services/ProLikeDislike/getlikeDislikeCountByPost',JSON.stringify(likeDislike),this.httpOptions)
       .pipe(map((response : number)=> {
       //  //debugger;
         return response;
       })
       )     
     }

    getAddComments(proID : number):Observable<any[]>{
      //debugger;
      return this.httpclient.get<any>(this.baseUrl+'services/ProComments/byID?proID='+proID,this.httpOptions);
    }
    
    submitAddComment(commentDetails:ProComments):Observable<number>{     
    ////debugger;   
    return this.httpclient.post<number>(this.baseUrl+'services/ProComments/addPostComment',JSON.stringify(commentDetails),this.httpOptions)
    .pipe(
      map((response : number)=> {
      return response;
    })
    );

    

    }
    ////////////// USER FRIENDS ///////////////

    GetMyFriends(){
      return this.httpclient.get<chatUser[]>(this.baseUrl+'services/user/friend/getbyuser');
    }

    GetSuggestFrnds(){
      return this.httpclient.get<any>(this.baseUrl+'services/user/top15users');
    }
    GetFrndsRequest(){
      return this.httpclient.get<any>(this.baseUrl+'services/user/friendrequest/getbyuser');
    }
    GetSENDFrndsRequest(){
      return this.httpclient.get<any>(this.baseUrl+'services/user/sendfriendrequest/getbyuser');
    }
    SearchFriends(username : string){  
      //debugger;
      return  this.httpclient.get<any>(this.baseUrl+'services/user/friend/searchusername?username='+username,this.httpOptions)
      .pipe(map((response : any)=> {
        return response;
        }),
        catchError(error =>{
          return throwError('seomething went wrong');
        })
        );                        
    }
    From_FriendMessages(userID : number):Observable<any[]>{
    //  //debugger;
      return this.httpclient.get<any>(this.baseUrl+'services/user/friendmessages/getfromuser?userID='+userID,this.httpOptions);
    }
    To_FriendMessages(userID : number):Observable<any[]>{
     // //debugger;
      return this.httpclient.get<any>(this.baseUrl+'services/user/friendmessages/gettouser?userID='+userID,this.httpOptions);
    }
    SearchALLUSERs(username : string){  
      //debugger;
      return  this.httpclient.get<any>(this.baseUrl+'services/user/allfriend/searchusername?username='+username,this.httpOptions)
      .pipe(map((response : any)=> {
        return response;
        }),
        catchError(error =>{
          return throwError('seomething went wrong');
        })
        );                        
    }
      AcceptFriendRequest(data:Actionbtn){  
        //debugger;
        return this.httpclient.post<number>(this.baseUrl+'services/user/action/friendrequest',JSON.stringify(data),this.httpOptions)            
      }

      GetUserViewDetails(userID : number):Observable<any[]>{
        //debugger;
        return this.httpclient.get<any>(this.baseUrl+'services/User/LoginUser?userID='+userID,this.httpOptions);
      }

      SendMessages(data:usermessage){  
        ////debugger;
        return this.httpclient.post<any>(this.baseUrl+'services/user/message/postmessage',JSON.stringify(data),this.httpOptions)            
      }
      SendMessagesUPDATE(data:usermessage){  
        ////debugger;
        return this.httpclient.post<number>(this.baseUrl+'services/user/friendmessages/seenorunseen',JSON.stringify(data),this.httpOptions)
      //   .subscribe((result) => {
      //  return result;
      //  });         
      }
}

