import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { teamcollegues } from '../shared/models/Company/User-About/getTeamCollegues';
import { skillObj } from '../shared/models/Company/User-About/skillObj';
import { usercertification } from '../shared/models/Company/User-About/usercertification';
import { userclass } from '../shared/models/Company/User-About/userclass';
import { usereducation } from '../shared/models/Company/User-About/usereducation';
import { userexperience } from '../shared/models/Company/User-About/userexperience';
import { userproject } from '../shared/models/Company/User-About/userproject';
import { userskill } from '../shared/models/Company/User-About/userskill';
import { userteam } from '../shared/models/Company/User-About/userteam';
import { alluserlist } from '../shared/models/Company/User-About/userwebskill';
import { ActionserviceService } from '../shared/services/compnies/actionservice.service';
import { AdcompanyService } from '../shared/services/compnies/CompanyServices';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-user-profile-about',
  templateUrl: './user-profile-about.component.html',
  styleUrls: ['./user-profile-about.component.css']
})
export class UserProfileAboutComponent implements OnInit {
  @ViewChild('name') inputName;
  ID: number;
  form: FormGroup;
  IsChecked: any[];
  collegues: any;
  teampopup: any;
  TMID: any;
  PROJID=[];
  NewProjectID:any;
  CMID: any;  
  constructor(private fb: FormBuilder,private actions: ActionserviceService,private ngxLoader: NgxUiLoaderService, private adcompanyService: AdcompanyService, private router: Router, private notifyService: NotificationService) { }
  panelOpenState = false;
  panelOpenState1 =  true;
  panelOpenState2 = false;
  // USER EDUCATION
  education: any = [];
  eduID: number;
  eduDescription: string;
  eduCompany: string;
  eduStartDate: string;
  eduEndDate: string;
  eduIsCUrrent: false;
  // USER EXPERIENCE
  experience: any = [];
  expID: number;
  expDescription: string;
  expCompany: string;
  expStartDate: string;
  expEndDate: string;
  expIsCUrrent: false;
  // USER CERTIFICATION 
  certification: any = [];
  certID: number;
  certDescription: string;
  certInstitute: string;
  certDate: string;
  // USER PROJECTS 
  project: any = [];
  projectID: number;
  projectTitle: string;
  projectDescription: string;
  projectCompany: string=null;
  // CLASS & TEAM MEMBERS 
  MyFriends:any;
  //team: teamcollegues;
  team: any=[];
  class:any;
  classmate:string=null;
  classmatename:string=null;
  teamFriend:string=null;
  teamCompany:string=null;
  // USER SKILLS
  checkedItems: userskill[];
  checked = [];
  unchecked = [];
  uncheckedItems: userskill[];
  objSkills: userskill;
  selectedSkills: any = [];
  webskill: any;
  websiteskills: false;
  mobileskill: any = [];
  writingskill: any = [];
  designskill: any = [];
  dataentryskill: any = [];
  engineeringskill: any = [];
  productskill: any = [];
  saleskill: any = [];
  bussinessskill: any = [];
  translateskill: any = [];
  localskill: any = [];
  ///////////////////
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
 // FrnddropdownList: any;
  selectedAccord = 0;

  ngOnInit(): void {
    this.LoadEducationList();
    this.LoadExperienceList();
    this.LoadCertificationList();
    this.LoadProjectList();
    this.LoadSkillList();
    this.LoadMyFriends();
    this.LoadMyTeams();
    this.LoadMyClasses();

    this.form = this.fb.group({
      name: this.fb.array([])
    });

    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Friends',
      selectAllText: 'Select All Friends',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class'
    };
    // this.dropdownSettings = {
    //   singleSelection: false,
    //   idField: 'item_id',
    //   textField: 'item_text',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 3,
    //   allowSearchFilter: true
    // };
  }
 
  onItemSelect(item: any) {
    //console.log(item);
    //console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    //console.log(item);
    //console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }
  onDeSelectAll(items: any) {
    //console.log(items);
  }
  //  USER EDUCATION
  LoadEducationList() {
    ////debugger;
    this.adcompanyService.GetEducationList().subscribe(data => {
      this.education = data;
     // //console.log(this.education);
    });
  }
  addeducation(): void{
    const edu = new usereducation();
    edu.mode = "Add";
    edu.EduDescription = this.eduDescription;
    edu.EduCompany = this.eduCompany;
    edu.EduStartDate = this.eduStartDate;
    edu.EduEndDate = this.eduEndDate;
    edu.EduIsCUrrent = this.eduIsCUrrent;
   // //debugger;
    if (edu.EduDescription != null && edu.EduCompany != null && edu.EduStartDate != null && edu.EduEndDate != null) {
      if (edu.EduStartDate < edu.EduEndDate) {
       // this.ngxLoader.start();
        this.adcompanyService.UpdateEducation(edu).subscribe(data => {
          data;
          this.LoadEducationList();
          this.notifyService.showSuccess('BRAVO !!', 'Education Add Successfully');
        this.handleClear();
        // setTimeout(() => {
        //   this.ngxLoader.stop();
        // }, 0);
        });
      } else {
        this.notifyService.showError('ERROR !!', 'EndEdate is less then StartDate');
      }
    } else {
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 0;
  }
  editeducation() {
    const edu = new usereducation();
    edu.mode = "edit";
    edu.EduID = this.eduID;
    edu.EduDescription = this.eduDescription;
    edu.EduCompany = this.eduCompany;
    edu.EduStartDate = this.eduStartDate;
    edu.EduEndDate = this.eduEndDate;
    edu.EduIsCUrrent = this.eduIsCUrrent;
    //debugger;
    if (edu.EduDescription != "" && edu.EduCompany != "" && edu.EduStartDate != null && edu.EduEndDate != null) {
      if (edu.EduStartDate < edu.EduEndDate) {
       // this.ngxLoader.start();
       this.adcompanyService.UpdateEducation(edu).subscribe(data => {
        data;
        this.LoadEducationList();
        this.notifyService.showSuccess('BRAVO !!', 'Education Update Successfully');
      this.handleClear();
      // setTimeout(() => {
      //   this.ngxLoader.stop();
      // }, 0);
      });
      } else {
        this.notifyService.showError('ERROR !!', 'EndEdate is less then StartDate');
      }
    } else {
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 0;
  }
  educationById(eduID) {
    this.eduID = eduID;
    // this.adcompanyService.GetSingleEducation(eduID).subscribe((result) => {
    //   //console.log(result);
    //   this.eduDescription = result[0].eduDescription;
    //   this.eduCompany = result[0].eduCompany;
    //   this.eduStartDate = result[0].eduStartDate;
    //   this.eduEndDate = result[0].eduEndDate;
    //   this.eduIsCUrrent = result[0].eduIsCUrrent;
    // });
    const result = this.education.filter((obj) => {
      return obj.eduID === eduID;
    });
      this.eduDescription = result[0].eduDescription;
      this.eduCompany = result[0].eduCompany;
      this.eduStartDate = result[0].eduStartDate;
      this.eduEndDate = result[0].eduEndDate;
      this.eduIsCUrrent = result[0].eduIsCUrrent;
  }
  DELETEeducationById(eduID) {
    var result = confirm("want to Delete ??")
    if (result) {
      this.adcompanyService.DELETESingleEducation(eduID).subscribe((result) => {
       // //console.log(result);
       this.LoadEducationList();
       this.notifyService.showSuccess('DELETE !!', 'Delete Successfully');
       this.selectedAccord == 0;
      });
    }   
  }

  // USER EXPERIENCE
  LoadExperienceList() {
   // //debugger;
    this.adcompanyService.GetExperienceList().subscribe(data => {
      this.experience = data;
     // //console.log(this.experience);
    });
  }
  addexperience() {
    const exp = new userexperience();
    exp.mode = "Add";
    exp.ExpDescription = this.expDescription;
    exp.ExpCompany = this.expCompany;
    exp.ExpStartDate = this.expStartDate;
    exp.ExpEndDate = this.expEndDate;
    exp.ExpIsCUrrent = this.expIsCUrrent;
    //debugger;
    if (exp.ExpDescription != null && exp.ExpCompany != null && exp.ExpStartDate != null && exp.ExpEndDate != null) {
      if (exp.ExpStartDate < exp.ExpEndDate) {
        this.adcompanyService.UpdateExperience(exp).subscribe(data => {
         // this.ngxLoader.start();       
          this.LoadExperienceList();
          this.handleClear();
         // setTimeout(() => {
          //  this.ngxLoader.stop();
          //}, 0);
          this.notifyService.showSuccess('BRAVO !!', 'Experience Add Successfully');  
        });       
      } else {
        this.notifyService.showError('ERROR !!', 'EndEdate is less then StartDate');
      }
    }
    else {
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 1;
  }
  editexperience() {
    const exp = new userexperience();
    exp.mode = "edit";
    exp.ExpID = this.expID;
    exp.ExpDescription = this.expDescription;
    exp.ExpCompany = this.expCompany;
    exp.ExpStartDate = this.expStartDate;
    exp.ExpEndDate = this.expEndDate;
    exp.ExpIsCUrrent = this.expIsCUrrent;
    //debugger;
    if (exp.ExpDescription != "" && exp.ExpCompany != "" && exp.ExpStartDate != null && exp.ExpEndDate != null) {
      if (exp.ExpStartDate < exp.ExpEndDate) {
        this.adcompanyService.UpdateExperience(exp).subscribe(data => {
          // this.ngxLoader.start();       
           this.LoadExperienceList();
           this.handleClear();
          // setTimeout(() => {
           //  this.ngxLoader.stop();
           //}, 0);
           this.notifyService.showSuccess('BRAVO !!', 'Experience Update Successfully');  
         });  
      } else {
        this.notifyService.showError('ERROR !!', 'EndEdate is less then StartDate');
      }
    } else {
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 1;
  }
  experienceById(expID) {
    this.expID = expID;
    // this.adcompanyService.GetSingleExperience(expID).subscribe((result) => {
    //   //console.log(result);
    //   this.expDescription = result[0].expDescription;
    //   this.expCompany = result[0].expCompany;
    //   this.expStartDate = result[0].expStartDate;
    //   this.expEndDate = result[0].expEndDate;
    //   this.expIsCUrrent = result[0].expIsCUrrent;
    // });
    const result = this.experience.filter((obj) => {
      return obj.expID === expID;
    });
      this.expDescription = result[0].expDescription;
      this.expCompany = result[0].expCompany;
      this.expStartDate = result[0].expStartDate;
      this.expEndDate = result[0].expEndDate;
      this.expIsCUrrent = result[0].expIsCUrrent;
  }
  DELETEexperienceById(expID) {
    ''
    var result = confirm("want to Delete ??")
    if (result) {
      //debugger;
      this.adcompanyService.DELETESingleExperience(expID).subscribe((result) => {
        ////console.log(result);
      this.LoadExperienceList();
      this.notifyService.showSuccess('DELETE !!', 'Delete Successfully');
      this.selectedAccord == 1;
      });
    }
  }

  // USER CERTIFICATION
  LoadCertificationList() {
    ////debugger;
    this.adcompanyService.GetCertificationList().subscribe(data => {
      this.certification = data;
      ////console.log(this.certification);
    });
  }
  addcertification() {
    const data = new usercertification();
    data.mode = "Add";
    data.CertDescription = this.certDescription;
    data.CertInstitute = this.certInstitute;
    data.CertDate = this.certDate;
    //debugger;
    if (data.CertDescription != null && data.CertInstitute != null && data.CertDate != null) {
      this.adcompanyService.UpdateCertification(data).subscribe(data => {
      this.LoadCertificationList();
      this.handleClear();
      this.notifyService.showSuccess('BRAVO !!', 'Certification Add Successfully');
      });     
    } else {
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 2;
  }
  editcertification() {
    const data = new usercertification();
    data.mode = "edit";
    data.CertID = this.certID;
    data.CertDescription = this.certDescription;
    data.CertInstitute = this.certInstitute;
    data.CertDate = this.certDate;
    //debugger;
    if (data.CertDescription != "" && data.CertInstitute != "" && data.CertDate != null) {
      this.adcompanyService.UpdateCertification(data).subscribe(data => {
        this.LoadCertificationList();
        this.handleClear();
        this.notifyService.showSuccess('BRAVO !!', 'Certification Update Successfully');
        });     
    } else {
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 2;
  }
  certificationById(certID) {
    this.certID = certID;
    this.adcompanyService.GetSingleCertification(certID).subscribe((result) => {
      //console.log(result);
      this.certID = result[0].certID;
      this.certDescription = result[0].certDescription;
      this.certInstitute = result[0].certInstitute;
      this.certDate = result[0].certDate;
    });
  }
  DELETCertificationById(certID) {
    ''
    var result = confirm("want to Delete ??")
    if (result) {
      //debugger;
      this.adcompanyService.DELETESingleCertification(certID).subscribe((result) => {
        ////console.log(result);
    this.LoadCertificationList();
    this.notifyService.showSuccess('DELETE !!', 'Delete Successfully');
    this.selectedAccord == 2;
      });
    }
  }

  // USER PROJECT
  LoadProjectList() {
   // //debugger;
    this.adcompanyService.GetProjectList().subscribe(data => {
      this.project = data;
     // //console.log(this.project);
    });
  }  
  frndlist=[];
  addProject() {
    //debugger;
    const data = new userproject();
    data.mode = "Add";
    data.ProjectTitle = this.projectTitle;
    data.ProjectDescription = ""; //this.projectDescription;
    data.ProjectCompany = this.projectCompany;
    //debugger;
    var Objs = [];
    var counter = 1;     
    if (this.selectedItems.length > 0) {
      for (let frnd of this.selectedItems ) {
         var Obj = {}; // here's your object
        Obj["ID"] = counter,
          Obj["FriendID"] = frnd.id
        Objs.push(Obj);
        counter++;
      }
    }
    data.projectdetail = Objs;
    //debugger;
    if (data.ProjectTitle != null && data.ProjectDescription != null && data.ProjectCompany != null) {
      this.adcompanyService.UpdateProject(data).subscribe(data => {
        this.LoadProjectList();
      this.handleClear();
      this.notifyService.showSuccess('BRAVO !!', 'Project Add Successfully');
      });     
    } else {
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 4;
  }
  editProject() {
    const data = new userproject();
    data.mode = "edit";
    data.ProjectID = this.NewProjectID;
    data.ProjectTitle = this.projectTitle;
    data.ProjectDescription = "";
    data.ProjectCompany = this.projectCompany.toString();
    //debugger;
    var Objs = [];
    //this.PROJID;
    var counter = 1;     
    if (this.selectedItems.length > 0) {
      for (let frnd of this.selectedItems) {
         var Obj = {}; // here's your object
        Obj["ID"] = counter,
          Obj["Id"] = frnd.Newid
          Obj["FriendID"] = frnd.id
        Objs.push(Obj);
        counter++;
      }
    }
    data.projectdetail = Objs;
    //debugger;
    if (data.ProjectTitle != "" && data.ProjectCompany != null) {
      this.adcompanyService.UpdateProject(data).subscribe(data => {
        this.LoadProjectList();
      this.handleClear();
      this.notifyService.showSuccess('BRAVO !!', 'Project Update Successfully');
      });     
    } else {
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 4;
  }
  ProjectById(projectID) {
    //debugger;
    this.projectID = projectID;
    // this.adcompanyService.GetSingleProject(projectID).subscribe((result) => {
    //   //console.log(result);
    //   this.certID = result[0].certID;
    //   this.projectTitle = result[0].projectTitle;
    //   this.projectDescription = result[0].projectDescription;
    //   this.projectCompany = result[0].projectCompany;
    // });
    const result = this.project.filter((obj) => {
      return obj.projectID === projectID;
    });
    for(let i=0; i < result[0].projectdetail.length; i++) {
      this.selectedItems.push({Newid: result[0].projectdetail[i].id, id: result[0].projectdetail[i].friendID, itemName: result[0].projectdetail[i].collegueName });
    }

    this.projectTitle = result[0].projectTitle;
    //this.projectDescription = result[0].projectDescription;
    this.projectCompany = result[0].projectCompanyId;  
    this.NewProjectID = projectID; 
  }
  DELETProjectById(projectID) {
    ''
    var result = confirm("want to Delete ??")
    if (result) {
      //debugger;
      this.adcompanyService.DELETESingleProject(projectID).subscribe((result) => {
        ////console.log(result);
        this.LoadProjectList();
        this.notifyService.showSuccess('DELETE !!', 'Delete Successfully');
        this.selectedAccord == 4;
      });
    }
  }
  GetProjectfriend(Id,projectCompany,projectID,friendID){
    ////debugger;
    const result1 = this.project.filter((obj) => {
      return obj.projectID === projectID;
    });
    const result2 = this.MyFriends.filter((obj) => {
      return obj.userProfileID === friendID;
    });
    const result3 = this.experience.filter((obj) => {
      return obj.expID === parseInt(projectCompany);
    });

    this.projectTitle = result1[0].projectTitle;
    for(let i=0; i < result2.length; i++) {
      this.selectedItems.push({ id: result2[i].userProfileID, itemName: result2[i].userName });
    }
    this.projectCompany = result3[0].expID
    this.PROJID.push(Id);
    this.NewProjectID = projectID;
  }

  // TEAM MEMBERS  
  LoadMyFriends() {    
    //debugger;
    let tmp = [];
    var data = this.actions.GetMyFriends().subscribe(data => {
      this.MyFriends = data;
      //debugger;      
    for(let i=0; i < data.length; i++) {
      tmp.push({ id: data[i].userProfileID, itemName: data[i].userName });
    }
    this.dropdownList = tmp;
    });
  }
  LoadMyTeams() {
    ////debugger;
    this.adcompanyService.GetTeamList().subscribe(data => {
      this.team = data; 
    });
  }
  addTeam(){
    const data = new userteam();
    data.mode = "Add";
    data.TMClassID = this.teamCompany.toString();
    data.TMMemberID = this.teamFriend;
    //debugger;
    if(data.TMClassID != null && data.TMMemberID != null){
      this.adcompanyService.UpdateTeam(data).subscribe(data => {
        this.LoadMyTeams();
        this.handleClear();
        this.notifyService.showSuccess('BRAVO !!', 'Member Added Successfully');
      });      
    }else{
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 5;
  }  
  GetTeamMember(expID,collegueID,tmID){   
    const result1 = this.experience.filter((obj) => {
      return obj.expID === expID;
    });
    const result2 = this.MyFriends.filter((obj) => {
      return obj.userProfileID === collegueID;
    });

    this.teamCompany = result1[0].expID;
    this.teamFriend = result2[0].userProfileID;
    this.TMID = tmID;
  }
  editTeam(){
    //debugger;
    const data = new userteam();
    data.mode = "Edit";
    data.TMID = this.TMID;
    data.TMClassID = this.teamCompany.toString();
    data.TMMemberID = this.teamFriend;
    //debugger;
    if(data.TMClassID != null && data.TMMemberID != null){
      this.adcompanyService.UpdateTeam(data).subscribe(data => {
        this.LoadMyTeams();
        this.handleClear();
        this.notifyService.showSuccess('BRAVO !!', 'Member Update Successfully');
      });      
    }else{
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 5;
  }
  removeTeamMember(expID,collegueID){
    //debugger;
    const data = new userteam();
    data.mode = "Delete";
    data.TMClassID = expID.toString();
    data.TMMemberID = collegueID;
    var result = confirm("want to Delete ??")
    if (result) {
    this.adcompanyService.UpdateTeam(data).subscribe(data => {
      this.LoadMyTeams();
      this.notifyService.showSuccess('BRAVO !!', 'Member Delete Successfully');
    });
  }
  }
  getteammember(i){
    this.teampopup = this.team[i].colleguelist;
    //console.log(i + "Row")
  }

 // CLASS
 classlenth:boolean=false;
  LoadMyClasses() {
    //debugger;
    this.adcompanyService.GetClassList().subscribe(data => {
      this.class = data;  
      // if(data[0].classmatelist.length > 4){
      //  this.classlenth = true;
      // }  
    });
  }
  addClass(){
    const data = new userclass();
    data.mode = "Add";
    data.CMClassID = this.classmate.toString();
    data.CMClassMateID = this.classmatename;
    //debugger;
    if(data.CMClassID != null && data.CMClassMateID != null){
      this.adcompanyService.UpdateClass(data).subscribe(data => {
      this.LoadMyClasses();
      this.handleClear();
      this.notifyService.showSuccess('BRAVO !!', 'Member Added Successfully');
      });
    }else{
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 3;
  }
  removeClassmate(eduID,classmateID){    
    const data = new userclass();
    data.mode = "Delete";
    data.CMClassID = eduID.toString();
    data.CMClassMateID = classmateID;
    var result = confirm("want to Delete ??")
    if (result) {
    this.adcompanyService.UpdateClass(data).subscribe(data => {
      this.LoadMyClasses();
      this.notifyService.showSuccess('BRAVO !!', 'Classmate Deleted Successfully'); 
      });
    this.LoadMyClasses();
    } 
  }
  GetClassMates(eduID,classmateID,cmID){   
    const result1 = this.education.filter((obj) => {
      return obj.eduID === eduID;
    });
    const result2 = this.MyFriends.filter((obj) => {
      return obj.userProfileID === classmateID;
    });

    this.classmate = result1[0].eduID;
    this.classmatename = result2[0].userProfileID;
    this.CMID = cmID;
  }
  editClass(){
    const data = new userclass();
    data.mode = "Edit";
    data.CMID = this.CMID;
    data.CMClassID = this.classmate.toString();
    data.CMClassMateID = this.classmatename;
    //debugger;
    if(data.CMClassID != null && data.CMClassMateID != null){
      this.adcompanyService.UpdateClass(data).subscribe(data => {
        this.LoadMyClasses();
        this.handleClear();
        this.notifyService.showSuccess('BRAVO !!', 'Member Update Successfully');
        });
    }else{
      this.notifyService.showError('ERROR !!', 'Please provide complete data');
    }
    this.selectedAccord == 3;
  }


  // USER SKILLS
  LoadSkillList() {
    //debugger;
    var parentId = 0;
    this.adcompanyService.GetSkillsList(parentId).subscribe(data => {
      this.webskill = data.filter(x => x.skill_ParentID == "4");
      this.mobileskill = data.filter(x => x.skill_ParentID == "5");
      this.writingskill = data.filter(x => x.skill_ParentID == "6");
      this.designskill = data.filter(x => x.skill_ParentID == "7");
      this.dataentryskill = data.filter(x => x.skill_ParentID == "8");
      this.engineeringskill = data.filter(x => x.skill_ParentID == "9");
      this.productskill = data.filter(x => x.skill_ParentID == "10");
      this.saleskill = data.filter(x => x.skill_ParentID == "11");
      this.bussinessskill = data.filter(x => x.skill_ParentID == "12");
      this.translateskill = data.filter(x => x.skill_ParentID == "13");
      this.localskill = data.filter(x => x.skill_ParentID == "14");
      ////console.log(data);
    });
  }
  onChange(skillId: number, parentID: string, isChecked: boolean) {
    //debugger;
    if (isChecked) {
      const objSkills = new userskill();
      objSkills.US_SkillID = skillId;
      objSkills.US_ParentID = parseInt(parentID);
      this.checked.push(objSkills);
      this.checkedItems = this.checked;
    } else {
      const objSkills = new userskill();
      objSkills.US_SkillID = skillId;
      objSkills.US_ParentID = parseInt(parentID);
      this.unchecked.push(objSkills);
      this.uncheckedItems = this.unchecked;
    }
  }

  submit() {
    //debugger;
    var obj = new skillObj();
    obj.check = this.checkedItems;
    obj.uncheck = this.uncheckedItems;
    this.adcompanyService.UpdateWebSkills(obj);
    this.checkedItems = null;
    this.uncheckedItems = null;
    this.notifyService.showSuccess('Skills !!', 'Update Successfully');
  }








  handleClear(){
    // clearing the value
  this.eduDescription = null,
  this.eduCompany = null,
  this.eduStartDate = null,
  this.eduEndDate = null,
  this.expDescription = null,
  this.expCompany =null,
  this.expStartDate = null,
  this.expEndDate = null,
  this.certDescription = null,
  this.certInstitute= null,
  this.certDate=null,
  this.classmate=null,
  this.classmatename= null,
  this.projectTitle=null,
  this.projectCompany=null,
  this.teamCompany=null,
  this.teamFriend=null
}
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  // mobilesubmit(){
  //   //debugger;
  //   const skill = new alluserlist();
  //   this.selectedItemsList = this.form.value;
  //   var Itemss = [];
  //   for (let ingrediant of this.selectedItemsList.name) {
  //     var store = {};
  //     store["US_SkillID"] = ingrediant,
  //     Itemss.push(store);
  //   }  
  //   //debugger; 
  //     //console.log(Itemss);
  //     skill.US_ParentID = 5;
  //     skill.theuserskill = Itemss;
  //    // this.adcompanyService.UpdateWebSkills(skill); 
  // }
}
