<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Eidt Deal</h1>
        <ol class="tg-breadcrumb">
            <li><a routerLink="/userHome">Go to Dashboard</a></li>
            <li class="tg-active">Eidt Deal</li>
        </ol>
    </div>

    <ul>

    </ul>

    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
					Section Start
			*************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <!-- <form class="tg-formtheme tg-formdashboard" [formGroup]="postDetailForm" (ngSubmit)="onSubmit()"> -->
                <form class="tg-formtheme tg-formdashboard" >
                    <fieldset>
                        <div class="tg-postanad">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Eidt Deal</h2>
                                    </div>
                                    <div class="tg-dashboardholder">
                                        <!-- <div class="form-group text-center">
                                            <a href="#" class="tg-btn" data-toggle="modal"
                                                data-target=".tg-categorymodal">Select Category Here</a>
                                        </div> -->
                                        <div class="form-group">
                                            <ol class="tg-categorysequence">
                                                <span>{{this.categoryTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subCategoryTitle != ''">
                                                </fa-icon>
                                                <span>{{' '+this.subCategoryTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subToFirstTitle != ''">
                                                </fa-icon>
                                                <span>{{' '+this.subToFirstTitle +' '}}</span>
                                                <fa-icon [icon]="faArrowRight" *ngIf="this.subToSecondTitle != ''">
                                                </fa-icon>
                                                <span *ngIf="this.subToSecondTitle != ''">{{' '+
                                                    this.subToSecondTitle}}</span>

                                            </ol>
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="addCategoryAlert == true" class="alert alert-danger">
                                                <div *ngIf="addCategoryAlert == true">
                                                    Please select Category !
                                                </div>
                                            </div>
                                            <div *ngIf="addSubCatAlert == true" class="alert alert-danger">
                                                <div *ngIf="addSubCatAlert == true">
                                                    Please select Sub-Category !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Title</label>
                                            <input type="text" required class="form-control" placeholder="Title"
                                                id="Name" name="Name" [(ngModel)]="this.Name">
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="ProNameAlert == true" class="alert alert-danger">
                                                <div *ngIf="ProNameAlert == true">
                                                    Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div style="width:30%; float:left">
                                                <div>
                                                    <label for="input_id_1">Price</label>
                                                    <input type="text" numbersOnly required class="form-control"
                                                        placeholder=" Price " id="Price" name="Price"
                                                        [(ngModel)]="this.Price">
                                                </div>
                                                <div class="form-group">
                                                    <div *ngIf="ProPriceAlert == true" class="alert alert-danger">
                                                        <div *ngIf="ProPriceAlert == true">
                                                            Price is required !
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width:30%; float:left;margin-left: 5px">
                                                <div>
                                                    <label for="input_id_1">Actual Price</label>
                                                    <input type="text" numbersOnly required class="form-control"
                                                        placeholder="Actual Price " id="ActualPrice" name="ActualPrice"
                                                        [(ngModel)]="this.ActualPrice">
                                                </div>
                                                <div class="form-group">
                                                    <div *ngIf="ActualPriceAlert == true" class="alert alert-danger">
                                                        <div *ngIf="ActualPriceAlert == true">
                                                            Actual Price is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style="width:35%;float:left; margin-left: 5px">
                                                <label for="input_id_1">Currency</label>
                                                <ng-select [items]="CurrienciesList" bindLabel="title" bindValue="id"
                                                    placeholder="Currency" appendTo="body" id="selectedCurrency"
                                                    name="selectedCurrency" [(ngModel)]="selectedCurrencyID">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Description</label>
                                            <!-- <editor [init]="{
                                                    height: 300,
                                                    menubar: true,
                                                    plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help'
                                                }" id="descriptionArea2" name="descriptionArea2"
                                                [(ngModel)]="descriptionArea"></editor> -->
                                                <quill-editor [(ngModel)]="descriptionArea"
                                                placeholder="Enter Description..."
                                                [modules]="quillConfig" 
                                                [format]="'html'"
                                                name="body"
                                                id="descriptionArea2" name="descriptionArea2"
                                                (onEditorCreated)="editorCreated($event,descriptionArea)"
                                                    trackChanges='all'>
                                            </quill-editor> 
                                            <!-- <div class="tg-dashboardholder tg-offersmessages">
                                                <div class="ql-snow">
                                                    <p [innerHtml]="descriptionArea"></p>                                                   
                                                </div>                                
                                            </div> -->
                                        </div>
                                        <label class="tg-fileuploadlabel" for="tg-photogallery2">
                                            <span>Get Product from Company</span>
                                            <span>Or</span>
                                            <!-- <span class="tg-btn">Include Product</span> -->
                                            <div class="form-group text-center">
                                                <a href="#" class="tg-btn" data-toggle="modal"
                                                    data-target="#exampleModal">Include Product</a>
                                            </div>
                                            <span>Maximum Add 5 Products</span>
                                            <!-- <input type="file" class="tg-fileinput" (change)="onSelectFile($event)" name="file" multiple accept="image/*" /> -->
                                             <a id="tg-photogallery2" 
                                                (change)="onSelectFile($event)" name="button"></a>
                                        </label>
                                        <div class="row">
                                            <div *ngFor="let items of selectedItemsList; let i=index" [attr.data-index]="i" class="col-md-4 col-sm-6">
                                                <div class="card mb-30">
                                                    <a class="card-img-tiles" data-abc="true" target="_self" routerLink="/product/details/{{items.proID}}">
                                                        <div class="inner">
                                                            <div class="main-img">
                                                                <!-- <img src="https://i.imgur.com/O0GMYuw.jpg" alt="Category"> -->
                                                                <img class="img-fluid circular_image" [src]="items.proImageBase" />
                                                                <!-- <img class="img-fluid circular_image" src="../../assets/images/ads/no_image.jpg" *ngIf="items.proImageBase == null" /> -->
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div class="card-body text-center">
                                                        <p class="card-title-1">{{items.proName}}</p>
                                                        <p class="text-muted">Price {{items.proPrice}} | {{items.proPriceUnitName}}</p>                                  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                                <div class="tg-dashboardbox tg-contactdetail">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Deal Detail</h2>
                                    </div>
                                    <div class="tg-dashboardholder" id="divCommon">
                                        <div class="form-group">
                                            <label for="input_id_1">Start Date:</label>
                                            <input type="date" name="StartDate" id="StartDate" 
                                            [(ngModel)]="this.StartDate" [ngModel]="this.StartDate | date:'yyyy-MM-dd'" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="StartDateAlert == true" class="alert alert-danger">
                                                <div *ngIf="StartDateAlert == true">
                                                    Start Date is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <!-- <ng5-slider [(value)]="minkmValue" [(highValue)]="maxkmValue" [(ngModel)]="this.MinQty" [options]="optionsKM"></ng5-slider>
                                             -->
                                            <label for="input_id_1">End Date:</label>
                                            <input type="date" name="EndDate" id="EndDate" 
                                            [(ngModel)]="this.EndDate" [ngModel]="this.EndDate | date:'yyyy-MM-dd'" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <div *ngIf="EndDateAlert == true" class="alert alert-danger">
                                                <div *ngIf="EndDateAlert == true">
                                                    End Date is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="input_id_1">Terms & Conditions</label>
                                            <quill-editor [(ngModel)]="TermsArea"
                                            placeholder="Enter Terms and Conditions..."
                                            [modules]="quillConfig" 
                                            [format]="'html'"
                                            name="body"
                                            id="TermsArea" name="TermsArea"
                                            (onEditorCreated)="editor2Created($event,TermsArea)"
                                                trackChanges='all'>
                                        </quill-editor> 
                                        <!-- <div class="tg-dashboardholder tg-offersmessages">
                                            <div class="ql-snow">
                                                <p [innerHtml]="TermsArea"></p>                                                
                                            </div>                                
                                        </div> -->
                                            <!-- <editor [init]="{
                                                    height: 300,
                                                    menubar: true,
                                                    plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help'
                                                }" id="TermsArea" name="TermsArea" [(ngModel)]="TermsArea"></editor> -->

                                        </div>
                                        <label class="tg-fileuploadlabel" for="tg-photogallery">
                                            <span>Drop files anywhere to upload</span>
                                            <span>Or</span>
                                            <span class="tg-btn">Select Files</span>
                                            <span>Maximum upload file size: 500 KB</span>
                                            <!-- <input type="file" class="tg-fileinput" (change)="onSelectFile($event)" name="file" multiple accept="image/*" /> -->
                                            <input id="tg-photogallery" class="tg-fileinput"
                                                (change)="onSelectFile($event)" type="file" name="file" multiple
                                                accept="image/jpeg,image/jpg">
                                        </label>
                                        <div class="tg-horizontalthemescrollbar tg-profilephotogallery">
                                            <div class="col-md-4 productAddfromImages"
                                                *ngFor='let url of imageurls; let i = index'>
                                                <img class="img-fluid" [src]="url">
                                                <a (click)="removeImage(i)"
                                                    class="btn btn-xs btn-danger mt-2">Remove</a>
                                            </div>
                                        </div>
                                        <button class="tg-btn mt-2" (click)="onImagesUpdate()">Update Images</button>
                                        <br />
                                        <button *ngIf="companyList || companyList.length ==0 " class="tg-btn mt-3"
                                            (click)="updatedetails()">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
        <!--************************************
					Section End
			*************************************-->
    </main>
     <!--***********PRODUCT ADD MODEL********-->
     <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
     <div class="modal-dialog modal-dialog-scrollable" role="document">
         <div class="modal-content">
             <div class="modal-header">
                 <h4 class="modal-title" id="exampleModalLabel">Select Product</h4>
                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                 </button>
             </div>
             <div class="modal-body d-flex justify-content-center">
                 <div class="row">
                     <div *ngFor="let items of DealList " class="col-md-4 col-sm-6">
                         <div class="card mb-30 hover10">
                             <a class="card-img-tiles "  data-abc="true">
                                 <div class="inner">
                                     <div class="main-img">
                                         <!-- <img src="https://i.imgur.com/O0GMYuw.jpg" alt="Category"> -->
                                         <figure><img class="img-fluid circular_image"  *ngIf="items.proImageBase != null" [src]="items.proImageBase" /><img class="img-fluid circular_image" src="../../assets/images/ads/no_image.jpg" *ngIf="items.proImageBase == null" />
                                    </figure> </div>
                                 </div>
                             </a>
                             <div class="card-body text-center">
                                 <p class="card-title-1">{{items.proName}}</p>
                                 <p class="text-muted">Price {{items.proPrice}} | {{items.proPriceUnitName}}</p>
                                 <div class="form-check check">
                                     <input name="{{items.proName}}" type="checkbox"
                                     [(ngModel)]="items.proIsActive" class="form-check-input" id="{{items.proName}}">
                                     <label class="form-check-label tg-checkbox" for="exampleCheck1"></label>
                                 </div>
                             </div>
                         </div>
                     </div>                        
                 </div>
             </div>
             <div class="modal-footer">
                 <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                 <button type="button" class="btn btn-success" data-dismiss="modal" (click)="onClickADD()">Select</button>
             </div>
         </div>
     </div>
 </div>

    <div class="modal fade tg-thememodal tg-categorymodal " tabindex="-1 " role="dialog ">
        <div class="modal-dialog tg-thememodaldialog " role="document ">
            <div class="modal-content tg-thememodalcontent " style="min-height: 200px; ">
                <div class="tg-title ">
                    <strong style="font-size: 16px; ">Select Category</strong>
                </div>
                <div>
                    <select id="mainCategory " [(ngModel)]="selectedCategory" aria-placeholder="Select Category"
                        (change)="getSubCategories(0,$event) " style="width: 100%; " name="ddCategory">
                        <option value="0">All Types</option>
                        <option value="c4e1f606-8105-4666-a8bc-c6657730a0df ">Deals</option>
                    </select>
                </div>
                <div *ngIf="this.levelToShow> 0" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Sub Category</strong>
                    </div>
                    <div class=" ">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCategory"
                            (change)="getSubCategories(1,$event)" name="ddSubCategory">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCat of subCategories" value={{subCat.catID}}>{{subCat.catName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="this.levelToShow > 1" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Child Category</strong>
                    </div>
                    <div class="">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCatFirstChild"
                            (change)="getSubCategories(2,$event)" name="ddSubCategoryChildOne">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCatFirst of subCatFirstChildList" value={{subCatFirst.catID}}>
                                {{subCatFirst.catName}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="this.levelToShow > 2" style="width: 100%;margin-top: 10px;margin-bottom: 10px;">
                    <div class="tg-title">
                        <strong style="font-size: 16px;">Select Child Category</strong>
                    </div>
                    <div class="">
                        <select style="width: 100%;" [(ngModel)]="selectedSubCatSecondChild"
                            (change)="getSubCategories(3,$event)" name="ddSubCategoryChildTwo">
                            <option value="0">All Types</option>
                            <option *ngFor="let subCatSecond of subCatSecondChildList" value={{subCatSecond.catID}}>
                                {{subCatSecond.catName}}</option>
                        </select>
                    </div>
                </div>

                <div style="margin-top: 50px;">
                    <button data-dismiss="modal" aria-label="Close" type="button" class="tg-btn"
                        style="background-color:#00cc67!important">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>