<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Email</h1>
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()">Go to Home</a></li>
            <li class="tg-active">Email Details</li>
        </ol>
    </div>

    <ul>

    </ul>
    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
                      Section Start
              *************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <form class="tg-formtheme tg-formdashboard" #f="ngForm" (ngSubmit)="onSubmit(f)">
                    <fieldset>
                        <div class="tg-postanad container ">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="tg-dashboardbox">
                                    <div class="tg-dashboardboxtitle">
                                        <h2>Send Email</h2>
                                    </div>
                                    <div class="tg-dashboardholder">
                                        <div class="form-group">
                                            <input type="text" required class="form-control" [(ngModel)]="Subject"
                                                placeholder="Enter Subject" id="Subject" name="Subject">
                                        </div>
                                        <div class="form-group">
                                            <input type="text" required class="form-control" [(ngModel)]="ToEmail"
                                                placeholder="Enter ToEmail" id="selectedToEmail" name="selectedToEmail">
                                        </div>
                                        <div class="form-group">
                                            <!-- <input type="text" required class="form-control" [(ngModel)]="BodyHTML"
                                                placeholder="Enter HTML Body" id="selectedBodyHTML" name="selectedBodyHTML"> -->
                                                <div class="form-group">
                                                    <editor [init]="{
                                                                height: 500,
                                                                menubar: true,
                                                                plugins: [
                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount'
                                                                ],
                                                                toolbar:
                                                                'undo redo | formatselect | bold italic backcolor | \
                                                                alignleft aligncenter alignright alignjustify | \
                                                                bullist numlist outdent indent | removeformat | help'
                                                            }" id="selectedDescription" name="selectedDescription" [(ngModel)]="BodyHTML"></editor>
                                                </div>
                                        </div>
                                        <button class="tg-btn" type="submit">Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
    </main>
</div>