import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { validateParams } from '../shared/models/Company/uservalidation';
import { AdcompanyService } from '../shared/services/compnies/CompanyServices';
import { NotificationService } from '../shared/services/notification.service';
import { openSearchService } from '../shared/services/search/openSearch.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  styleUrls: ['./user-validation.component.css']
})
export class UserValidationComponent implements OnInit {

  constructor(private userInterface: openSearchService,private route: Router,private router: ActivatedRoute,
    private adcompanyService: AdcompanyService,private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.LoadValidation();
  }
  validation:any;
  LoadValidation(){
    //debugger;
    ////console.log(this.router.snapshot.params.userID);
   // //console.log(this.router.snapshot.params.validationKey);
    const params = new validateParams();
    params.userID = this.router.snapshot.params.userID;
    params.validationKey = this.router.snapshot.params.validationKey;
    this.adcompanyService.getValidationUser(params).subscribe((result) => {
      //console.log(result);
      this.successNotification();
    });
    //this.backtoMain();
  }

  backtoMain() {
    this.userInterface.setInterface("0");
    this.route.navigate(['/home'], { relativeTo: this.router });
  }
  successNotification() {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'User Verified successfully, Please Login to use the services !',
      showConfirmButton: false,
      timer: 1000
    })
  }

}
