import { EventEmitter, Injectable } from '@angular/core'; 
import { environment } from '../../../environments/environment'; 
import {HubConnection,HubConnectionBuilder,HubConnectionState,LogLevel} from '@microsoft/signalr';
import { Messages } from '../message.model'; 
import { BehaviorSubject, Subject, Observable, of } from 'rxjs'; 
import { ChatAdapter, IChatGroupAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from 'ng-chat';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AdComments } from '../models/comments/comments.model';
import { Comments } from '../models/comments/comment.model';
import { likeDislikeDetails } from '../models/Adds/likeDislikeDetails.model';
import { ProlikeDislikeDetails } from '../models/Company/likeDislikeDetails.model';
import { ProComments } from '../models/comments/Pro-comments.model';
import { ProductComments } from '../models/comments/pro-comment.model';
import { notifyMessage } from '../models/notifyMessage';
  
@Injectable()  
//export class liveUpdateService extends ChatAdapter {  
export class liveUpdateService {  
  public userId: string;
  foodchanged$ = new Subject();
  messageReceived$ = new Subject<Messages>();
  commentReceived = new EventEmitter<Comments>();
  //ChatMessageReceived = new EventEmitter<notifyMessage>();
  likeReceived = new EventEmitter<likeDislikeDetails>();
  likeReceiveds = new EventEmitter<ProlikeDislikeDetails>();
  disLikeReceived = new EventEmitter<likeDislikeDetails>();
  disLikeReceiveds = new EventEmitter<ProlikeDislikeDetails>();
  newCpuValue$ = new Subject<number>();
  connectionEstablished$ = new BehaviorSubject<boolean>(false);

  private hubConnection: HubConnection;
  //private hubConnection1: signalR.HubConnection;
  private username: string;
  //username = 'ali';
  constructor(private http: HttpClient) {  
   // super();
    this.createConnection();
    //this.registerOnServerEvents();
    this.receiveServerEvents();
    //this.receiveChatMessage();
    this.startConnection(); 
  }  
  
  sendChatMessage(message: AdComments) {
    this.hubConnection.invoke('NewComment', message);
    //this.hubConnection.invoke('NewComment', message);
  } 
  sendDealChatMessage(message: ProComments) {
    this.hubConnection.invoke('NewComment', message);
    //this.hubConnection.invoke('NewComment', message);
  } 
  sendProductChatMessage(message: ProComments) {
    this.hubConnection.invoke('NewComment', message);
    //this.hubConnection.invoke('NewComment', message);
  } 

  sendLikeDisliek(likeDislie: likeDislikeDetails) {
    this.hubConnection.invoke('NewLikeDislike', likeDislie);    
  } 
  sendLikeDislieks(likeDislie: ProlikeDislikeDetails) {
    this.hubConnection.invoke('NewLikeDislike', likeDislie);    
  } 
  
  private createConnection() {
    this.hubConnection = new HubConnectionBuilder()
      //.withUrl('https://localhost:44373/liveUpdateHub')
     .withUrl('https://api.myknocks.com/liveUpdateHub')
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
  }

  private startConnection() {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      return;
    }
    this.hubConnection.start().then(
      () => {
        //console.log('Hub connection started!');
        this.connectionEstablished$.next(true);
      },
      error => console.error(error)
    );
  }
  public receiveServerEvents(): void {
    this.hubConnection.on('SendComments', (data: any) => {
      ////debugger;
      //console.log(data);
     // alert(data.commentDesc);     
      this.commentReceived.emit(data);  
    });

    this.hubConnection.on('SendLikes', (data: any) => {
      //debugger;
      ////console.log(data);
     // alert(data.commentDesc);     
      this.likeReceived.emit(data);  
    });    
}

// public receiveChatMessage(): void {
//   this.hubConnection.on('notifyMessage', (data: any) => {
//     this.ChatMessageReceived.emit(data);  
//   });
// }

  private registerOnServerEvents(): void {
    this.hubConnection.on('SendComments1', (data: any) => {
    //   //debugger;
    //   //console.log(data);
    //   alert(data.message);
    //   this.commentReceived = data;     
    });   
    
    // this.hubConnection.on("messageReceived", (participant, message) => {
    //   // Handle the received message to ng-chat
    //   //console.log(message);
    //   this.onMessageReceived(participant, message);
    // });

    // this.hubConnection.on("friendsListChanged", (participantsResponse: Array<ParticipantResponse>) => {
    //   // Handle the received response to ng-chat
    //   this.onFriendsListChanged(participantsResponse.filter(x => x.participant.id != this.userId));
    // });
  }
}    