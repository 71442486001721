import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OrderService } from '../shared/services/compnies/OrderServices';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { openSearchService } from '../shared/services/search/openSearch.service';


@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.css']
})
export class UserOrdersComponent implements OnInit {

  constructor(private userInterface: openSearchService,private ngxLoader: NgxUiLoaderService,private orderService: OrderService,private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.LoadUserOrders();
  }
  OrderList: any=[];
  TotalLength:any;
  page:number=1;
  LoadUserOrders() {
    //debugger;
    this.ngxLoader.start(); 
    this.orderService.getOrdersList().subscribe((data:any[]) => {
      this.OrderList = data;
      console.log(this.OrderList[0])
      setTimeout(() => {
        this.ngxLoader.stop();
       }, 0); 
      this.TotalLength = data.length;
    });
  }

  goToOrderDetails(orderId) {  
    //debugger;  
    this.router.navigate(['../user/orders/'+orderId]);
  }
  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home']);
  }

}
