import { User } from '../../core/models/user.model';
import { AuthenticationActionTypes, AuthenticationActions } from '../actions/auth.actions';
 
export interface State {  
  token: string;
  UserImage:String;
  athenticated: boolean;  
  errorMessage: string | null;
}
  
//set the initial state with localStorage
export const initialState: State = {
  token: null,
  UserImage:null,
  athenticated: false,  
  errorMessage : null
};
 
export function authReducer(state = initialState, action: AuthenticationActions): State {
  //debugger;
  switch (action.type) {
    case AuthenticationActionTypes.SIGNIN: {
      return {
        ...state,
        athenticated: true       
      };
    }    
    // case AuthenticationActionTypes.LOGOUT: {
    //   return{
    //     ...state,
    //     token: null,
    //     athenticated:false
    //   }
    // }

    case AuthenticationActionTypes.LOGOUT: {
      //return initialState;
      return{
            ...state,
            token: null,
            athenticated:false
          }
    }
    case AuthenticationActionTypes.SET_TOKEN: {
      ////debugger;
      return{
        ...state,
        token: action.payload,
        athenticated:true
      }
    }
    case AuthenticationActionTypes.SIGNIN_FAILURE: {
      ////debugger;
      return{
        ...state,
        athenticated:false,
        errorMessage: 'Incorrect email and/or password.'        
      }
    }
    case AuthenticationActionTypes.SIGNIN_SUCCESS: {
      ////debugger;
      return{
        ...state,
        athenticated:true,
        token: action.payload,
        errorMessage: ''        
      }
    }
    default: {
      return state;
    }
  }
}