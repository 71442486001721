import { propertyFacilities } from './propertyFacilities.model';

export class postProperty{        
    public proID: number;
    public proAddID : string ;  
    public beds : number ;
    public baths: number;
    public building : string ;  
    public furnished : number ;
    public size: number;
    public readyDate : string ;  
    public duration : number ;
    public PM: number;
    public proReferenceID : number ;  
    public proFurnished : boolean ;
    public proDuration: number;
    public propaymentMethod : number ;  
    public proDeveloper : string ;
    public proCommunityFee : number ;
    public proSender: boolean;
    public proLandlordName : string ;  
    public proRegistrationNumber : string ;
    public proBrokerID : string ;  
    public proBrokerName : string ;
    public proAffentNumber : string ;             
    public propertyFacilities : propertyFacilities[];


}