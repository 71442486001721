import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import {ActivatedRoute} from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { adDealService } from 'src/app/shared/services/deal/deal-services.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-show-deal',
  templateUrl: './show-deal.component.html',
  styleUrls: ['./show-deal.component.css']
})
export class ShowDealComponent implements OnInit {

  constructor(
    private dealService: adDealService,private ngxLoader: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.LoadDealList();
  }

  DealList: any=[];
  TotalLength:any;
  page:number=1;

  LoadDealList() {
    ////debugger;
    this.ngxLoader.start();
    this.dealService.getDealList().subscribe((data:any[]) => {
      this.DealList = data;
      this.TotalLength = data.length;
      setTimeout(() => {
        this.ngxLoader.stop();
  
      }, 1000);
      //console.log(this.DealList);
    });
  }
}
