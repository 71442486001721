<div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 ">
        <form (ngSubmit)="onAddPosting(f)" #f="ngForm">
            <div class="form-gorup">
                <label for="txtTitle">Title:</label>
                <input type="text" id="txtTitle" name="txtTitle" ngModel class="form-control">
            </div>
            <div class="form-gorup">
                <label for="txtNumber">Phone#:</label>
                <input type="number" id="txtNumber" name="txtNumber" ngModel class="form-control">
            </div>
            <div class="form-gorup">
                <label for="txtPrice">Price:</label>
                <input type="number" id="txtPrice" name="txtNumber" ngModel class="form-control">
            </div>
            <div class="form-gorup">
                <label for="ddlCurrency">Currency:</label>
                <input type="number" id="ddlCurrency" name="ddlCurrency" ngModel class="form-control">
            </div>
            <div class="form-gorup">
                <label for="ddlAge">Age:</label>
                <input type="number" id="ddlAge" name="ddlAge" ngModel class="form-control">
            </div>
            <div class="form-gorup">
                <label for="ddlUsage">Usage:</label>
                <input type="number" id="ddlUsage" name="ddlUsage" ngModel class="form-control">
            </div>
            <div class="form-gorup">
                <label for="ddlCondition">Condition:</label>
                <input type="number" id="ddlCondition" name="ddlCondition" ngModel class="form-control">
            </div>
            <div class="form-gorup">
                <label for="ddlCountry">Country:</label>
                <input type="number" id="ddlCountry" name="ddlCountry" ngModel class="form-control">
            </div>
            <div class="form-gorup">
                <label for="txtLocation">Location:</label>
                <input type="text" id="txtLocation" name="txtLocation" ngModel class="form-control">
            </div>
            <div class="form-gorup">
                <label for="txtDescription">Description:</label>
                <input type="text" id="txtDescription" name="txtDescription" ngModel class="form-control">
            </div>
            <hr>
            <button class="btn btn-primary" type="submit" id="btnSubmit"> Sign In</button>
        </form>
    </div>
</div>