export class ProComments{
    public commentID: number;
    public commentDesc : string ;  
    public addedBy: number;
    public addedDate : string ;  
    public editedBy : number ;  
    public editedDate : string ;  
    public postID: number ;  
    public PC_ImageName: string ;  
    
    public CatName: string ;
}