import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core'; 
import {HubConnection,HubConnectionBuilder,HubConnectionState,LogLevel} from '@microsoft/signalr';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs'; 
import { ChatAdapter, IChatGroupAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant } from 'ng-chat';
import { map, catchError } from 'rxjs/operators';
import { addDetailsDTO } from '../models/Adds/addDetails.model';
import { postnotify } from '../models/postnotify.model';
import { NotificationService } from '../services/notification.service';
  
@Injectable()  

export class notificationService {  

  private data = new BehaviorSubject<postnotify>({} as any);
  currentnotification = this.data.asObservable();

    private hubConnection: HubConnection;
    NotificationReceived = new EventEmitter<postnotify>();
    connectionEstablished$ = new BehaviorSubject<boolean>(false);
    toastr: any;

    constructor(private notifyService: NotificationService,private http: HttpClient) {  
        this.createConnection();
       // this.registerOnServerEvents();
        this.receiveServerEvents();
        this.startConnection(); 
       }  

      //  sendNotification(notify: postnotify) {
      //   this.hubConnection.invoke('SendNotification', notify);    
      // }

    private createConnection() {
        this.hubConnection = new HubConnectionBuilder()
          //.withUrl('https://localhost:44373/notificationHub')
          .withUrl('https://api.myknocks.com/notificationHub')
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build();
      }

      private startConnection() {
        if (this.hubConnection.state === HubConnectionState.Connected) {
          return;
        }
        this.hubConnection.start().then(
          () => {
            //console.log('Hub connection started!');
            this.connectionEstablished$.next(true);
          },
          error => console.error(error)
        );
      }
      
    public receiveServerEvents(): void {    
        this.hubConnection.on('SendNotification', (data: any) => {
          //debugger;
          // alert(data +"Hy Hello 1");
          //console.log(data.title);   
          this.NotificationReceived.emit(data);  
        });    
    }

    // shownotify(){
    //   //debugger;
    //   this.notifyService.showSuccess('Bravo!', 'Notification successfully !!');
    // }
}