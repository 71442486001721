<!-- <link rel="stylesheet" href="/assets/css/dashboard.css"> -->
<style>
    .mega-dropdown {
        position: static !important;
    }

    .mega-dropdown-menu {
        padding: 20px 0px;
        width: 100%;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .mega-dropdown-menu>li>ul {
        padding: 0;
        margin: 0;
    }

    .mega-dropdown-menu>li>ul>li {
        list-style: none;
    }

    .mega-dropdown-menu>li>ul>li>a {
        display: block;
        color: #222;
        padding: 3px 5px;
    }

    .mega-dropdown-menu>li ul>li>a:hover,
    .mega-dropdown-menu>li ul>li>a:focus {
        text-decoration: none;
    }

    .mega-dropdown-menu .dropdown-header {
        font-size: 18px;
        color: #00cc67;
        padding: 5px 60px 5px 5px;
        line-height: 30px;
    }

    .mega-dropdown-menu>a {
        text-decoration: none;
    }

    /* top header*/
    .rounded-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }

    .tg-dashboardheader {
        z-index: 999;
        background: #fff;
        padding: 20px 60px;
        position: relative;
        -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.20);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.20);
    }

    .tg-navigation>ul>li:first-child {
        padding-left: 0;
    }

    .tg-navigation>ul>li:last-child {
        padding-right: 0;
    }

    .tg-themedropdown {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .tg-notification {
        float: right;
        margin: 0 0 0 10px;
    }

    .tg-btndropdown {
        outline: 0;
        color: #666;
        background: none;
        position: relative;
    }

    .tg-btndropdown i {
        width: 46px;
        height: 46px;
        display: block;
        font-size: 18px;
        overflow: hidden;
        line-height: 44px;
        border-radius: 50%;        
    }

    .tg-dashboardheader .tg-btn {
        float: left;
        padding: 0 30px;
    }

    .tg-badge {
        top: 0;
        right: 0;
        color: #fff;
        min-width: 10px;
        padding: 1px 6px;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        background: #f91942;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
    }

    .tg-dropdownmenu {
        border: 0;
        margin: 0;
        right: 0;
        opacity: 0;
        top: 150px;
        left: auto;
        padding: 0;
        float: none;
        width: 330px;
        display: block;
        color: #363b4d;
        font-size: 13px;
        line-height: 20px;
        border-radius: 0;
        background: #fff;
        list-style: none;
        visibility: hidden;
        position: absolute;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.10);
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.10);
    }

    .tg-dropdownmenu:before {
        width: 0;
        height: 0;
        content: '';
        right: 15px;
        bottom: 100%;
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
    }
    .fa-times:hover{
        color: red;
    }
    .tg-dropdownmenu li {
        width: 100%;
        float: left;
        position: relative;
        line-height: inherit;
        list-style-type: none;
        padding: 20px 20px 20px 38px;
    }

    .tg-dropdownmenu li:before {
        top: 29px;
        left: 18px;
        width: 4px;
        height: 4px;
        content: '';
        border-radius: 50%;
        position: absolute;
        background: #f91942;
    }

    a:link {
        text-decoration: none;
    }

    .tg-dropdownmenu li+li {
        border-top: 1px solid #eaeaea;
    }

    .tg-dropdownmenu li:hover {
        background: #f7f7f7;
    }

    .tg-dropdownmenu li p {
        margin: 0;
        line-height: inherit;
    }

    .tg-themedropdown.open .tg-dropdownmenu {
        top: 70px;
        opacity: 1;
        visibility: visible;
    }

    .logoset {
        width: 100px;
        height: 60px;
        margin: auto;
    }
</style>
<header id="tg-header" class="tg-header tg-haslayout">
    <div class="tg-topbar">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">                  
                    <ul class="tg-navcurrency" style="margin-bottom: 0px!important;">
                        <li> <a href="javascript:void(0);" data-toggle="modal" data-target="#modalselectcurrency">
                                <i class="fa fa-globe" aria-hidden="true"
                                    style="color: white;text-decoration: none;"></i> select country</a>
                        </li>
                    </ul>
                  

                    <div  class="collapse navbar-collapse tg-navigation" style=" padding-top: 4px;">
                        <ul>
                            <!-- <li class="menu-item-has-children"><span class="tg-dropdowarrow"><i
                                        class="fa fa-angle-down"></i></span>
                                <a style="color: white;text-decoration: none;" [routerLink]="['home']">Home </a>
                            </li> -->
                            <li class="menu-item-has-children current-menu-item"><span class="tg-dropdowarrow"><i
                                        class="fa fa-angle-down"></i></span>
                                <a style="color: white;text-decoration: none;" [routerLink]="['aboutUs']">About us </a>
                            </li>
                            <li class="menu-item-has-children"><span class="tg-dropdowarrow"><i
                                        class="fa fa-angle-down"></i></span>
                                <a style="color: white;text-decoration: none;" [routerLink]="['contactUs']">Contact us
                                </a>
                                <ul class="sub-menu">
                                    <!-- <li><a href="dashboard.html">Dashboard</a></li>
                                        <li><a href="dashboard-myads.html">Dashboard My Ads</a></li>
                                        <li><a href="dashboard-myfavourites.html">Dashboard Favorites</a></li>
                                        <li><a href="dashboard-offermessages.html">Dashboard Offer Message</a></li>
                                        <li><a href="dashboard-payments.html">Dashboard Payment</a></li>
                                        <li><a href="dashboard-postanad.html">Dashboard Post Ad</a></li>
                                        <li><a href="dashboard-privacy-setting.html">Dashboard privacy Setting</a></li>
                                        <li><a href="dashboard-profile-setting.html">Dashboard Profile Setting</a></li> -->
                                </ul>
                            </li>
                            <li class="menu-item-has-children"><span class="tg-dropdowarrow"><i
                                        class="fa fa-angle-down"></i></span>
                                <a style="color: white;text-decoration: none;" href="javascript:void(0);">Helps<span
                                        class="caret"></span></a>
                                <ul class="sub-menu" style="z-index: 99999;">
                                    <!-- <li><a [routerLink]="['aboutUs']">About us</a></li> -->
                                    <li><a style="text-decoration: none;" [routerLink]="['privacyAgrement']">Privacy
                                            Agreement</a></li>
                                    <li><a style="text-decoration: none;" [routerLink]="['contactUs']">Terms &
                                            Conditions</a></li>
                                    <!-- <li class="menu-item-has-children"><span class="tg-dropdowarrow"><i
                                                    class="fa fa-angle-down"></i></span>
                                            <a href="javascript:void(0);">News</a>
                                            <ul class="sub-menu">
                                                <li><a href="newsgrid.html">News grid</a></li>
                                                <li><a href="newslist.html">News list</a></li>
                                                <li><a href="newsdetail.html">News detail</a></li>
                                            </ul>
                                        </li> -->
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul class="tg-navcurrency" style="float: right;"
                            *ngIf="(authState | async).athenticated === false">
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                    [routerLink]="['login']">Register</a></li>
                            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a
                                    [routerLink]="['login']">SignIn</a></li>
                        </ul>
                    </div>

                    <div id="userLoggedIn" appDropDown class="dropdown tg-themedropdown tg-userdropdown" style="min-width: 150px;cursor: pointer"
                        *ngIf="(authState | async).athenticated === true">                                           
                            <a class="dropdown-toggle tg-btndropdown" id="tg-adminnav">
                                <!-- <span class="tg-userdp"><img src="assets/images/author/img-01.jpg" alt="image description"></span> -->
                                <span class="tg-userdp"><img *ngIf="imageBase64 != null" [src]="imageBase64"
                                        alt="userImage" />
                                    <img src="../../../assets/images/ads/dummyimage.png"
                                        *ngIf="imageBase64 == null" /></span>
                                <span class="tg-name" style="cursor: pointer;color:#f7f7f7;text-decoration:none">Hi! {{this.userName}}
                                    <!-- <span class="caret"></span> -->
                                </span>                               
                            </a>                       
                        <ul class="dropdown-menu tg-themedropdownmenu" aria-labelledby="tg-adminnav">
                            <li>
                                <a style="cursor:pointer " (click)="onLogOut() ">
                                    <i class="icon-exit"></i>
                                    <span>SignOut</span>
                                </a>
                            </li>
                            <li><a (click)="goUserHome()" style="cursor: pointer;">
                                    <i class="icon-layers"></i>
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li>
                                <a (click)="goUserProfile()" style="cursor: pointer;">
                                    <i class="icon-cog"></i>
                                    <span>Profile Settings</span>
                                </a>
                            </li>
                            <!-- <li class="menu-item-has-children">
                                    <a href="javascript:void(0);">
                                        <i class="icon-layers"></i>
                                        <span>My Ads</span>
                                    </a>
                                    <ul>
                                        <li><a href="dashboard-myads.html">All Ads</a></li>
                                        <li><a href="dashboard-myads.html">Featured Ads</a></li>
                                        <li><a href="dashboard-myads.html">Active Ads</a></li>
                                        <li><a href="dashboard-myads.html">Inactive Ads</a></li>
                                        <li><a href="dashboard-myads.html">Sold Ads</a></li>
                                        <li><a href="dashboard-myads.html">Expired Ads</a></li>
                                        <li><a href="dashboard-myads.html">Deleted Ads</a></li>
                                    </ul>
                                </li> -->
                            <li>
                                <a href="javascript:void(0);" style="cursor: pointer;">
                                    <i class="icon-cart"></i>
                                    <span>Company</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" style="cursor: pointer;">
                                    <i class="icon-star"></i>
                                    <span>Help</span>
                                </a>
                            </li>

                        </ul>                    
                    </div>
                    <div *ngIf="this.token != null">
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown3" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-envelope" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">
                                    {{(messageslength != null) ? messageslength : '0'}}
                                </span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown3">
                                <div
                                    style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                                    Message's
                                    <span (click)="goUserMessages()"
                                        style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                                </div>
                                <li *ngFor="let item of this.friendmessages">
                                    <div class="row" (click)="goUserMessages()">
                                        <div class="col-md-3">
                                            <img *ngIf="item.userimagebase64 != null && item.userimagebase64 != ''" [src]="item.userimagebase64"
                                                class="img-fluid rounded-img" />
                                                <img *ngIf="item.userimagebase64 == null || item.userimagebase64 == ''" 
                                                src="/assets/images/no_image.jpg" class="img-fluid rounded-img">
                                        </div>
                                        <div class="col-md-9" style="margin-left:inherit;">
                                            <span style="font-size: 1.7rem;">{{item.sender}} &nbsp;
                                                <i class="fa fa-comments-o" aria-hidden="true"></i></span>
                                            <p style="cursor: pointer;"><span>{{ (item.message.length>25)? (item.message
                                                    | slice:0:25)+'...':(item.message) }}</span> &nbsp; &nbsp;
                                                <!-- {{item.datetime | date:'mediumTime'}} &nbsp; <a style="float: right;" -->
                                                    {{item.datetime}} &nbsp; <a style="float: right;text-decoration:none"
                                                    (click)="goUserMessages()">Reply</a></p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown2" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-bell" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">9</span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown2">
                                <li>
                                    <p>Consectetur adipisicing sedi eiusmod ampore incididunt ut labore et dolore.</p>
                                </li>
                            </ul>
                        </div>
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown1" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-users" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">
                                    {{(frndrequestlenght != null) ? frndrequestlenght : '0'}}
                                </span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown1">
                                <div
                                    style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                                    Friend Request's
                                    <span (click)="goUserFrineds()"
                                        style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                                </div>
                                <li *ngFor="let item of this.FrndRequests">
                                    <div class="row">
                                        <div class="col-md-3" (click)="goUserFrineds()">
                                            <img *ngIf="item.imageBase64 != null && item.imageBase64 != ''" [src]="item.imageBase64"
                                                class="img-fluid rounded-img" />
                                                <img *ngIf="item.imageBase64 == null || item.imageBase64 == ''" 
                                                src="/assets/images/no_image.jpg" class="img-fluid rounded-img">
                                        </div>
                                        <div class="col-md-9" style="margin-left: inherit;">
                                            <span style="font-size: 1.7rem;">{{item.userName}} &nbsp;
                                                <i class="fa fa-handshake-o" aria-hidden="true"></i></span>
                                            <p style="cursor: pointer;"><a
                                                    (click)="AcceptReq(item.userProfileID)">Accept</a> | <a
                                                    (click)="RejectReq(item.userProfileID)">Reject</a> &nbsp; <span
                                                    style="float: right;">{{item.datetime | date:'mediumTime'}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown1" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-shopping-cart" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">
                                    {{ adproductService.getLocalCartProducts().length }}
                                    <!-- {{(frndrequestlenght != null) ? frndrequestlenght : '0'}} -->
                                </span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown1" >
                                <div
                                    style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                                    Add to Cart
                                    <span (click)="goUserCart()"
                                        style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                                </div>
                                <li *ngFor="let item of adproductService.getLocalCartProducts()">
                                    <div class="row">
                                        <div class="col-md-3" (click)="goUserCart()">
                                            <img *ngIf="item.proImageBase != null && item.proImageBase != ''" [src]="item.proImageBase"
                                                class="img-fluid rounded-img" />
                                                <img *ngIf="item.proImageBase == null || item.proImageBase == ''" 
                                                src="/assets/images/no_image.jpg" class="img-fluid rounded-img">
                                        </div>
                                        <div class="col-md-9" style="margin-left: inherit;">
                                            <span style="font-size: 1.7rem;">{{item.proName}} &nbsp;
                                                <i (click)="removeCartProduct(item)" class="fa fa-times" aria-hidden="true"></i></span>
                                            <p style="cursor: pointer;"><a>{{item.proPriceUnitName}}</a> | <a>
                                                {{item.proPrice}}</a> &nbsp;
                                                 <!-- <span style="float: right;">{{item.proPriceUnitName}}</span> -->
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="dropdown tg-themedropdown tg-notification">
                            <button class="tg-btndropdown" id="tg-notificationdropdown1" type="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-heart" aria-hidden="true" style="color:white"></i>
                                <span class="tg-badge">
                                    {{adproductService.getFavoriteAds().length + adproductService.getFavoriteCartProducts().length+adproductService.getFavoriteDeals().length}}
                                </span>
                            </button>
                            <ul class="dropdown-menu tg-dropdownmenu" aria-labelledby="tg-notificationdropdown1">
                                <div
                                    style="font-weight: bold;background-color: rgb(241, 241, 241);padding: 5px 0px 5px 20px">
                                    Add to Favorite
                                    <span (click)="goUserCartFavorite()"
                                        style="float: right;cursor: pointer;padding: 0px 20px 5px 0px;">ViewAll</span>
                                </div>
                                <li *ngFor="let item of adproductService.getFavoriteAds(); let i = index">
                                    <div class="row">
                                        <div class="col-md-3" (click)="goUserCartFavorite()">
                                            <img *ngIf="item.addImageBase != null && item.addImageBase != ''" [src]="item.addImageBase"
                                                class="img-fluid rounded-img" />
                                                <img *ngIf="item.addImageBase == null || item.addImageBase == ''" 
                                                src="/assets/images/no_image.jpg" class="img-fluid rounded-img">
                                        </div>
                                        <div class="col-md-9" style="margin-left: inherit;">
                                            <span style="font-size: 1.7rem;">{{item.title}} &nbsp;
                                                <i (click)="removeLocalAds(item)" class="fa fa-times" aria-hidden="true"></i></span>
                                            <p style="cursor: pointer;"><a>{{item.currencyName}}</a> | <a>
                                                {{item.price}}</a> &nbsp;
                                                 <!-- <span style="float: right;">{{item.proPriceUnitName}}</span> -->
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tg-navigationarea" style="background-color: white; box-shadow: 0 0px 15px rgba(0,0,0,0.2);">
        <div class="container" style="margin-top: 10px;">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <strong class="tg-logo" (click)="goMainHome()"><a href="javascript:void(0);"><img class="logoset"
                        src="../../../assets/images/wihoutborder.png" alt="Logo"></a></strong>

                    <a style="background-color: #00cc67; cursor: pointer; text-decoration:none" class="tg-btn" (click)="goUserNewPost()">
                        <i class="icon-bookmark"></i>
                        <span>post an ad</span>
                    </a>
                    <nav id="tg-nav" class="tg-nav">
                        <div class="navbar-header">
                            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                                data-target="#tg-navigation" aria-expanded="false">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                        </div>

                        <div id="tg-navigation" class="collapse navbar-collapse js-navbar-collapse">
                            <ul class="nav navbar-nav" style="list-style: none;list-style-type: none;">
                                <li class="menu-item-has-children dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="javascript:void(0);"
                                        class="dropdown-toggle" data-toggle="dropdown">Classified <span
                                            class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Accessories</li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','D0242819-FB2B-47C5-B76A-F8B6AE1A0ABE','Classified','Baby-Items')"
                                                        href="javascript:void(0);">Clothing</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','2CC94F9F-117E-4096-8B44-5E163E8B2E78','Classified','Baby-Items')" href="javascript:void(0);">Baby
                                                        Items</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','D1FAF65C-41D6-46DF-A27E-D610447F3E1B','Classified','Jewellery')"
                                                        href="javascript:void(0);">Jewellery</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','1D36E7EB-89AC-4AB6-9A4C-94C24CDDDAB7','Classified','Toys')"
                                                        href="javascript:void(0);">Toys</a></li>
                                                <!-- <li class="divider"></li>
                                                    <li class="dropdown-header">Fonts</li> -->
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','D1FAF65C-41D6-46DF-A27E-D610447F3E1B','Classified','Scarves')"
                                                        href="javascript:void(0);">Scarves</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','D1FAF65C-41D6-46DF-A27E-D610447F3E1B','Classified','Wallets')"
                                                        href="javascript:void(0);">Wallets</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','E4E3813A-E469-4FA7-AA8D-C45F1733691D','Classified','Watches')"
                                                        href="javascript:void(0);">Watches</a></li>
                                                        <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','E07C2000-B995-440B-86B0-077F01B6089E','Classified','Cameras')"
                                                            href="javascript:void(0);">Cameras</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Home & Garden</li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','CBE61317-40BB-483B-BF40-F2A781BE701D','Classified','Furniture')"
                                                        href="javascript:void(0);">Furniture</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','3CB0BCFC-1399-4E18-BA43-A8AFBA13230F','Classified','Home-and-Garden')" href="javascript:void(0);">Home
                                                        & Garden</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','D0B7A17A-30BB-40CE-BFD5-33C04BA1D6DB','Classified','Home-Appliances')" href="javascript:void(0);">Home
                                                        Appliances</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','CBE61317-40BB-483B-BF40-F2A781BE701D','Classified','Sofas')"
                                                        href="javascript:void(0);">Sofas</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','CBE61317-40BB-483B-BF40-F2A781BE701D','Classified','Cupbords')"
                                                        href="javascript:void(0);">Cupbords</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','CBE61317-40BB-483B-BF40-F2A781BE701D','Classified','Beds')"
                                                        href="javascript:void(0);">Beds</a></li>
                                                 <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','D0242819-FB2B-47C5-B76A-F8B6AE1A0ABE','Classified','Clothings')"
                                                            href="javascript:void(0);">Clothings</a></li>
                                                        
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Electronics</li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','DB70A015-7A8C-4ADB-87FD-5DE91852CD6C','Classified','Mobiles')"
                                                        href="javascript:void(0);">Mobiles</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','97508C80-4BA0-4147-8643-6924A7','Classified','Music')"
                                                        href="javascript:void(0);">Music</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','3C71D311-2022-494E-8851-6CFFF6','Classified','Networking')"
                                                        href="javascript:void(0);">Networking</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','DB70A015-7A8C-4ADB-87FD-5DE91852CD6C','Classified','PDAs')"
                                                        href="javascript:void(0);">PDAs</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','DB70A015-7A8C-4ADB-87FD-5DE91852CD6C','Classified','LCD/LED')"
                                                        href="javascript:void(0);">LCD/LED</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','DB70A015-7A8C-4ADB-87FD-5DE91852CD6C','Classified','TV')"
                                                        href="javascript:void(0);">TV</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','DB70A015-7A8C-4ADB-87FD-5DE91852CD6C','Classified','Fans')"
                                                        href="javascript:void(0);">Fans</a></li>
                                                
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Others</li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','2CC94F9F-117E-4096-8B44-5E163E8B2E78','Classified','Baby-Items')" href="javascript:void(0);">Baby
                                                        Items</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','3B3A5EC7-4CB1-4FFB-85C8-13E849427C90','Classified','Books')"
                                                        href="javascript:void(0);">Books</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','1155E7CE-0844-4ED1-BB81-9DD982F8912E','Classified','Business')"
                                                        href="javascript:void(0);">Business</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','1155E7CE-0844-4ED1-BB81-9DD982','Classified','Collecatbles')"
                                                        href="javascript:void(0);">Collecables</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','5F29FF53-457B-4BA3-9CD0-A0A02BFBB5CD','Classified','Computers')"
                                                        href="javascript:void(0);">Computers</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','135FF770-A3D5-4C6A-97B1-9A8C6A45E132','Classified','Industries')"
                                                        href="javascript:void(0);">Industries</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('d7b411fb-1c21-4f0a-88f7-34d6b789f67d','2CC94F9F-117E-4096-8B44-5E163E8B2E78','Classified','Clocks')"
                                                        href="javascript:void(0);">Clocks</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="javascript:void(0);"
                                        class="dropdown-toggle" data-toggle="dropdown">Autos <span
                                            class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-12" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Autos</li>
                                                <li><a (click)="ClassifiedsearchSendNew('5bf243b1-2be4-4dda-93a0-cf9e06209e0f','9C89C78F-B925-4129-A95B-D4D750954AA3','Auto','Accessories-Parts')" href="javascript:void(0);">Auto
                                                        Accessories & Parts</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('5bf243b1-2be4-4dda-93a0-cf9e06209e0f','042F8B36-2F6E-4A05-8DF0-2BC49710D67C','Auto','Boats')" href="javascript:void(0);">Boats</a>
                                                </li>
                                                <li><a (click)="ClassifiedsearchSendNew('5bf243b1-2be4-4dda-93a0-cf9e06209e0f','79504CCA-41F7-4936-ACF3-6757E9AA7E29','Auto','Cars')" href="javascript:void(0);">Cars</a>
                                                </li>
                                                <li><a (click)="ClassifiedsearchSendNew('5bf243b1-2be4-4dda-93a0-cf9e06209e0f','FE1C13DE-9342-4EFB-824B-806DA0B8E3FE','Auto','Heavy-Vehycles')" href="javascript:void(0);">Heavy
                                                        Vehycles</a></li>                                              
                                                <li><a (click)="ClassifiedsearchSendNew('5bf243b1-2be4-4dda-93a0-cf9e06209e0f','6240A3B2-15B4-49AC-BF96-B30311BAEC60','Auto','Heavy-Vehycles')"
                                                        href="javascript:void(0);">Motorcycles</a></li>                                                
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="javascript:void(0);"
                                        class="dropdown-toggle" data-toggle="dropdown">Property <span
                                            class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Property for Rent</li>
                                                <li><a (click)="ClassifiedsearchSendNew('0372dc00-f9cb-4cb5-8b95-901ea8a02efa','309D0411-4A49-449F-9981-25DADB863CC0','Property-For-Rent','Commercial-For-Rent')"
                                                        href="javascript:void(0);">Commercial For Rent</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('0372dc00-f9cb-4cb5-8b95-901ea8a02efa','E0B72997-A58A-44DE-B6D6-AB325036A276','Property-For-Rent','Rental-Wanted')"
                                                        href="javascript:void(0);">Rental Wanted</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('0372dc00-f9cb-4cb5-8b95-901ea8a02efa','DE474B6E-1983-4C04-9EE6-1E67DA0436E9','Property-For-Rent','Residential-For-Rents')"
                                                        href="javascript:void(0);">Residential For Rent</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('0372dc00-f9cb-4cb5-8b95-901ea8a02efa','E49D23FB-F5E0-43A7-9489-FC3D52F1D9EC','Property-For-Rent','Rooms-For-Rent')"
                                                        href="javascript:void(0);">Rooms For Rent</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('0372dc00-f9cb-4cb5-8b95-901ea8a02efa','ADEEE04D-F451-4641-B93B-33AE22EA48AC','Property-For-Rent','Rooms-Wanted')"
                                                        href="javascript:void(0);">Rooms Wanted</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('0372dc00-f9cb-4cb5-8b95-901ea8a02efa','E0254826-3A26-48C9-891A-9526664C7518','Property-For-Rent','Short-Term-Daily')"
                                                        href="javascript:void(0);">Short Term (Daily)</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('0372dc00-f9cb-4cb5-8b95-901ea8a02efa','6264349E-6876-4B91-8A7C-8352FA3AC8E8','Property-For-Rent','Short-Term-Monthly')"
                                                        href="javascript:void(0);">Short Term(Monthly)</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Property for Sale</li>
                                                <li><a (click)="ClassifiedsearchSendNew('27bbb528-5666-4429-b153-7d6441c0efa1','AD708A18-5BEA-40BC-966B-C22383A8ADAA','Property-For-Sale','Commercial-For-Sale')"
                                                        href="javascript:void(0);">Commercial For Sale</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('27bbb528-5666-4429-b153-7d6441c0efa1','58F1AC77-DF13-469B-8457-38DD29AF3266','Property-For-Sale','Land-For-Sale')"
                                                        href="javascript:void(0);">Land For Sale</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('27bbb528-5666-4429-b153-7d6441c0efa1','69F94326-9BB7-4802-A965-30E88D024147','Property-For-Sale','Multiple-For-Sale')"
                                                        href="javascript:void(0);">Multiple For Sale</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('27bbb528-5666-4429-b153-7d6441c0efa1','85A400C4-0F9B-4D2D-AD3D-5982DBDE8974','Property-For-Sale','Residential-For-Sale')"
                                                        href="javascript:void(0);">Residential For Sale</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="javascript:void(0);"
                                        class="dropdown-toggle" data-toggle="dropdown">Jobs <span
                                            class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Jobs</li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','C0AE6A6B-4152-4DAC-B1C7-0F513DB98BCE','Jobs','I-am-Hiring','Accounting')"
                                                        href="javascript:void(0);">Accounting</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','4569F2C8-D877-43DD-B6DB-DE691E2CF9DD','Jobs','I-am-Hiring','Administration')"
                                                        href="javascript:void(0);">Administration</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','07174018-3111-4483-9D25-A95874FB65CE','Jobs','I-am-Hiring','Advertising')"
                                                        href="javascript:void(0);">Advertising</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','4DF8942E-FBB0-4BA6-A247-0C882F','Jobs','I-am-Hiring','Airline')"
                                                        href="javascript:void(0);">Airline</a></li>                                                
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','21E9A174-F466-4B28-BAFF-C52DB976112E','Jobs','I-am-Hiring','Architecture')"
                                                        href="javascript:void(0);">Architecture</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','3861727D-3E0C-42DC-A676-C73CAFA0C5E4','Jobs','I-am-Hiring','Art')"
                                                    href="javascript:void(0);">Art</a>
                                                </li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','259AA77C-E9DE-4BD4-822C-E65785A0C846','Jobs','I-am-Hiring','Automotive')"
                                                        href="javascript:void(0);">Automotive</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','16E87698-8400-4501-B0A6-640229BDDF06','Jobs','I-am-Hiring','Aviation')"
                                                        href="javascript:void(0);">Aviation</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Jobs</li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','387CB550-0936-40D8-8D43-E4D33F2EDD8F','Jobs','I-am-Hiring','Banking')"
                                                        href="javascript:void(0);">Banking</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','76B15BF0-C899-4184-8A58-F1D86624EB8C','Jobs','I-am-Hiring','Beauty')"
                                                        href="javascript:void(0);">Beauty</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','82EFBF06-F687-4256-8909-4C6B7F19EEC2','Jobs','I-am-Hiring','Constuction')"
                                                        href="javascript:void(0);">Constuction</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','Consulting','Jobs','I-am-Hiring','Consulting')"
                                                        href="javascript:void(0);">Consulting</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','18E03388-97CE-4ECD-A6C2-C0B636103276','Jobs','I-am-Hiring','Customer-Service')"
                                                        href="javascript:void(0);">Customer
                                                        Service</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','B66145DB-9770-4A42-BE03-09E1EF65FBED','Jobs','I-am-Hiring','Education')"
                                                        href="javascript:void(0);">Education</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','6B22E686-0AF1-42F2-AEAD-A52CA2534033','Jobs','I-am-Hiring','Engineering')"
                                                        href="javascript:void(0);">Engineering</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','B66145DB-9770-4A42-BE03-09E1EF65FBED','Jobs','I-am-Hiring','Education')"
                                                        href="javascript:void(0);">Education</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Jobs</li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','F29A0A84-A05D-4426-B26B-B2205418A594','Jobs','I-am-Hiring','Fashion')"
                                                        href="javascript:void(0);">Fashion</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','D54C706D-0F11-4952-BEC6-AF8001EBB20A','Jobs','I-am-Hiring','Finance')"
                                                        href="javascript:void(0);">Finance</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','92146124-4F1A-4F78-9867-8DC50AB793DC','Jobs','I-am-Hiring','Recruitment')"
                                                         href="javascript:void(0);">HR
                                                        Recruitment</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','AADC1FF6-F25B-4257-B3B6-1FD7C008700E','Jobs','I-am-Hiring','IT')"
                                                         href="javascript:void(0);">IT</a>
                                                </li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','B0E1E77E-511D-4C8D-AD60-2E7D469916E1','Jobs','I-am-Hiring','Legal-Services')" 
                                                        href="javascript:void(0);">Legal
                                                        Services</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','E500B7BB-A36B-4A5C-8DBC-6DE602011466','Jobs','I-am-Hiring','Manufacturing')"
                                                        href="javascript:void(0);">Manufacturing</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','6FBDEAEF-9C1E-4923-B641-5FDEEE7E6693','Jobs','I-am-Hiring','Marketing')"
                                                        href="javascript:void(0);">Marketing</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','D7414725-290E-4D71-8895-502355C73ED2','Jobs','I-am-Hiring','Oil-Ga-Energy')"
                                                         href="javascript:void(0);">Oil Gas
                                                        Energy</a></li>
                                            </ul>
                                        </li>
                                        <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Jobs</li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','BC7DD832-3085-49BB-8160-9886118D99E9','Jobs','I-am-Hiring','Online-Media')"
                                                         href="javascript:void(0);">Online
                                                        Media</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','AF9ED829-41A3-4C8A-B651-4DF57EBC3F2F','Jobs','I-am-Hiring','Pharmacuetical')"
                                                        href="javascript:void(0);">Pharmacuetical</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','9AE07E84-2FAB-4105-B4EE-7D02228C7810','Jobs','I-am-Hiring','Resturents')"
                                                        href="javascript:void(0);">Resturents</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','0172F379-02DC-49A6-B77F-18C45F3E8E3E','Jobs','I-am-Hiring','Sales')"
                                                        href="javascript:void(0);">Sales</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','A6C2FEEF-A9C7-41AF-AA70-B86DBA282101','Jobs','I-am-Hiring','Trasportation')"
                                                        href="javascript:void(0);">Trasportation</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','AC0EC80C-B711-4D36-8430-60D19C1CAFFD','Jobs','I-am-Hiring','Warehousing')"
                                                        href="javascript:void(0);">Warehousing</a></li>
                                                <li><a (click)="JobsSearchSend('3c064386-a300-4f90-8e18-f6da9f83aa1c','967CEED7-54C0-488E-8AA8-69E6045E6C05','4C88C286-9CDB-45CC-B0E9-2C23E0CB1533','Jobs','I-am-Hiring','Wholesale')"
                                                        href="javascript:void(0);">Wholesale</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow">
                                    <i class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black" href="#" class="dropdown-toggle"
                                        data-toggle="dropdown">Products <span class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                         <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Products</li>
                                                <li class="divider"></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','F3F3582D-2BF4-4D92-90D3-0F93C0426F1F','Products','Home-Kitchen')"
                                                        href="javascript:void(0);">Home & Kitchen</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','BB8D9DFA-63E0-47AD-AF6D-1575E4A57217','Products','Textile-Leather')"
                                                        href="javascript:void(0);">Textile | Leather</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','D8613F11-C947-4E1A-A9FB-2E1CB17C6039','Products','Sports-Health')"
                                                        href="javascript:void(0);">Sports | Health</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','F4006F3F-BFAC-46AA-8D92-69F3578835F4','Products','Automotives')"
                                                        href="javascript:void(0);">Automotives</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','CEFC0FA3-EF19-41AC-9892-7D6A6925E9AA','Products','Women-Fashion')"
                                                        href="javascript:void(0);">Women Fashion</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','6B3A9F5D-7B0D-4778-AECB-8640C98BC0C0','Products','Toys')"
                                                        href="javascript:void(0);">Toys</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','15627EF7-96EC-4C8D-A52B-86DAF5EC77A6','Products','Cameras')"
                                                        href="javascript:void(0);">Cameras</a></li>
                                            </ul>
                                        </li>
                                       <li class="col-sm-3" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Products</li>
                                                <li class="divider"></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','32483988-78B6-4E3F-BF27-8A2B726B0B14','Products','Computer')"
                                                        href="javascript:void(0);">Computer</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','F2C5E5A5-3A6E-4948-8C4D-9AD32C17F5DE','Products','Mobile-Tablets')"
                                                        href="javascript:void(0);">Mobile | Tablets</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','D20CF420-26AF-4AA1-BBF2-A95858EE3BF4','Products','Machinary-Tools')"
                                                        href="javascript:void(0);">Machinary | Tools</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','D1C6D949-447A-40C8-BF1A-BF3A6DA7E4D7','Products','Appliances')"
                                                        href="javascript:void(0);">Appliances</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','13629BDB-9E2D-4C9F-8135-C8E09A33F7CD','Products','Financial-Services')"
                                                        href="javascript:void(0);">Financial Services</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','BADC9697-01E1-483E-91D6-DF61BEBCB3A3','Products','Mens-Fashion')"
                                                        href="javascript:void(0);">Mens Fashion</a></li>
                                                <li><a (click)="ClassifiedsearchSendNew('C4E1F606-8105-4666-A8BC-C6657730A0DF','34B647D5-76BC-4B05-A4DF-E216216B063A','Products','TV-LCD-LED')"
                                                        href="javascript:void(0);">TVs|LCDs|LEDs</a></li>
                                            </ul>
                                        </li>
                                        <!-- <li class="col-sm-6" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Products</li>
                                                <li class="divider"></li>
                                                <li><a (click)="ProductsearchSend()" href="javascript:void(0);">Check all the products added by
                                                        supplier</a></li>
                                            </ul>
                                        </li> -->
                                    </ul>
                                </li>
                                <li class="dropdown mega-dropdown"><span class="tg-dropdowarrow"><i
                                            class="fa fa-angle-down"></i></span>
                                    <a style="text-decoration: none;color: black;" href="#" class="dropdown-toggle"
                                        data-toggle="dropdown">Deals <span class="caret"></span></a>
                                    <ul class="dropdown-menu mega-dropdown-menu"
                                        style="border-top:5px solid #00cc67;border-bottom:5px solid #00cc67;">
                                        <li class="col-sm-12" style=" list-style-type: none;">
                                            <ul>
                                                <li class="dropdown-header">Deals</li>
                                                <li><a (click)="DealsearchSend()" href="javascript:void(0);">Bose Headphone</a></li>
                                                <li><a (click)="DealsearchSend()" href="javascript:void(0);">Mont Blank Perfume</a></li>
                                                <li><a (click)="DealsearchSend()" href="javascript:void(0);">Stroller</a></li>
                                                <li><a (click)="DealsearchSend()" href="javascript:void(0);">Gravity Sunglasses</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>
            </div>
        </div>
    </div>
</header>