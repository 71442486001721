<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container">
<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-md-4">
            <div class="payment-card">
                <i class="fa fa-cc-visa payment-icon-big text-success"></i>
                <h2>
                    **** **** **** 1060
                </h2>
                <div class="row">
                    <div class="col-sm-6">
                        <small>
                            <strong>Expiry date:</strong> 10/16
                        </small>
                    </div>
                    <div class="col-sm-6 text-right">
                        <small>
                            <strong>Name:</strong> David
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="payment-card">
                <i class="fa fa-cc-mastercard payment-icon-big text-warning"></i>
                <h2>
                    **** **** **** 7002
                </h2>
                <div class="row">
                    <div class="col-sm-6">
                        <small>
                            <strong>Expiry date:</strong> 10/16
                        </small>
                    </div>
                    <div class="col-sm-6 text-right">
                        <small>
                            <strong>Name:</strong> Anna Smith
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="payment-card">
                <i class="fa fa-cc-discover payment-icon-big text-danger"></i>
                <h2>
                    **** **** **** 3466
                </h2>
                <div class="row">
                    <div class="col-sm-6">
                        <small>
                            <strong>Expiry date:</strong> 10/16
                        </small>
                    </div>
                    <div class="col-sm-6 text-right">
                        <small>
                            <strong>Name:</strong> Morgan
                        </small>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row">

        <div class="col-lg-12">

            <div class="ibox">
                <div class="ibox-title">
                    Payment method
                </div>
                <div class="ibox-content">

                    <main id="tg-main" class="tg-haslayout">
                        <section class="container">
                            <mat-accordion>
                                <mat-expansion-panel [expanded]="selectedAccord == 0" (opened)="panelOpenState1 = true"
                                (closed)="panelOpenState1 = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <i class="fa fa-credit-card-alt" aria-hidden="true"></i> &nbsp; PayPal
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="form-group">
                                        <div class="row">
                                            
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                            <mat-accordion>
                                <mat-expansion-panel [expanded]="selectedAccord == 0" (opened)="panelOpenState1 = true"
                                (closed)="panelOpenState1 = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <i class="fa fa-credit-card-alt" aria-hidden="true"></i> &nbsp; Credit Card 
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="form-group">
                                        <div class="row">
                                            
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </section>
                    
                    </main>
                    
                </div>
            </div>
        </div>
    </div>
</div>
</div>