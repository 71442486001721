import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { error } from 'protractor';
import { dataLibrary } from '../../models/category/dataLibraries/dataLibrary.model';

@Injectable()
export class dataLibraryService{
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     

constructor(private httpclient:HttpClient){}

  getCitiesByCountry(country : string){
   // //debugger;
   return this.httpclient.get<dataLibrary[]>(this.baseUrl+'services/dataLibraries/cities?country='+country,this.httpOptions)
    .pipe(map((response : dataLibrary[])=> {
      return response;
    }),
    catchError(error =>{
      return throwError('seomething went wrong');
    })
    )     
  }  
}