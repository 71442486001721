import { Action } from '@ngrx/store';
 
export enum AuthenticationActionTypes {
  TRYSIGNUP= 'TRYSIGNUP',
  TRYSIGNIN= 'TRYSIGNIN',
  SIGNUP= 'SIGNUP',
  SIGNIN = 'SIGNIN',
  SIGNIN_SUCCESS = 'LoginSuccess',
  SIGNIN_FAILURE = 'LoginFailure',
  LOGOUT = 'LOGOUT',
  SET_TOKEN = 'SETTOKEN',
}
 
export class trySignIn implements Action {
  readonly type = AuthenticationActionTypes.TRYSIGNIN;
  constructor(public payload: {username: string , password: string}) {
    //console.log('testLogin');
    //debugger;
  }
}
export class trySignUp implements Action {
  readonly type = AuthenticationActionTypes.TRYSIGNUP;
  constructor(public payload: {username: string , password: string}) {}
}

export class Login implements Action {
  readonly type = AuthenticationActionTypes.SIGNIN;
  //constructor(public payload: any) {}
}
 
export class LoginSuccess implements Action {
  readonly type = AuthenticationActionTypes.SIGNIN_SUCCESS;
  constructor(public payload: any) {
  }
}
 
export class LoginFailure implements Action {
  readonly type = AuthenticationActionTypes.SIGNIN_FAILURE;
  constructor(public payload: any) {}
}
 
export class LogOut implements Action {
  readonly type = AuthenticationActionTypes.LOGOUT;
  //localStorage.removeItem('currentUser');
}

// export class LogOut implements Action {
//   readonly type = AuthenticationActionTypes.LOGOUT;
// }

export class setToken implements Action {
  readonly type = AuthenticationActionTypes.SET_TOKEN;
  constructor(public payload: string) {}  
}
 
export type AuthenticationActions =
  | Login
  | LoginSuccess
  | LoginFailure
  | setToken
  | LogOut
  |trySignUp
  |trySignIn;