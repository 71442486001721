<main id="tg-main" class="tg-main tg-haslayout">
    <section class="container">
        <mat-accordion>
            <mat-expansion-panel [expanded]="selectedAccord == 0" (opened)="panelOpenState1 = true"
            (closed)="panelOpenState1 = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa fa-graduation-cap" aria-hidden="true"></i> &nbsp; Educations
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-3">
                            <label for="input_id_1">Education</label>
                            <input type="text" class="form-control" placeholder="Education"
                                [(ngModel)]="this.eduDescription" id="this" name="eduDescription">
                        </div>
                        <div class="col-md-3">
                            <label for="input_id_1">Institute Name</label>
                            <input type="text" class="form-control" placeholder="Institute Name"
                                [(ngModel)]="this.eduCompany" id="InstituteName" name="eduCompany">
                        </div>
                        <div class="col-md-3">
                            <label for="input_id_1">Start Date</label>
                            <input type="date" class="form-control" placeholder="StartDate"
                                [(ngModel)]="this.eduStartDate" [ngModel]="this.eduStartDate | date:'yyyy-MM-dd'"
                                name="txtDate" id="StartDate" name="eduStartDate">
                        </div>
                        <div class="col-md-3">
                            <label for="input_id_1">End Date</label>
                            <input type="date" class="form-control" placeholder="EndDate" [(ngModel)]="this.eduEndDate"
                                [ngModel]="this.eduEndDate | date:'yyyy-MM-dd'" name="eduEndDate" id="EndDate"
                                name="EndDate">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="tg-checkbox col-md-9">
                        <input id="tg-enablemessages1" type="checkbox" name="birth" [(ngModel)]="this.eduIsCUrrent"
                            value="0">
                        <label for="tg-enablemessages1">Is Current</label>
                    </div>
                    <div class="col-md-3">
                        <button class="btn btn-success" (click)="addeducation()">Add Education</button> &nbsp;
                        <button class="btn btn-info" (click)="editeducation()">Update</button>
                    </div>
                </div>
                <br />
                <table class="table table-bordered table-hover table-striped">
                    <thead class="thead-light">
                        <tr>
                            <!-- <th>UserID</th> -->
                            <th>Education</th>
                            <th>Institute</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let items of education">
                            <!-- <td>{{education.userID}}</td> -->
                            <td>{{items.eduDescription}}</td>
                            <td>{{items.eduCompany}}</td>
                            <td>{{items.eduStartDate}}</td>
                            <td>{{items.eduEndDate}}</td>
                            <td> <a (click)="educationById(items.eduID)">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                    </svg>
                                </a> ||
                                <a (click)="DELETEeducationById(items.eduID)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-trash3" viewBox="0 0 16 16">
                                        <path
                                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <mat-expansion-panel  [expanded]="selectedAccord == 1" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa fa-history" aria-hidden="true"></i> &nbsp; Experience
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="form-group">
                    <div class="col-md-3">
                        <label for="input_id_1">Experience</label>
                        <input type="text" class="form-control" placeholder="Experience"
                            [(ngModel)]="this.expDescription" id="this" name="this">
                    </div>
                    <div class="col-md-3">
                        <label for="input_id_1">Company</label>
                        <input type="text" class="form-control" placeholder="Company Name" [(ngModel)]="this.expCompany"
                            id="InstituteName" name="InstituteName">
                    </div>
                    <div class="col-md-3">
                        <label for="input_id_1">Start Date</label>
                        <input type="date" class="form-control" placeholder="StartDate" [(ngModel)]="this.expStartDate"
                            [ngModel]="this.expStartDate | date:'yyyy-MM-dd'" name="txtDate" id="StartDate"
                            name="StartDate">
                    </div>
                    <div class="col-md-3">
                        <label for="input_id_1">End Date</label>
                        <input type="date" class="form-control" placeholder="EndDate" [(ngModel)]="this.expEndDate"
                            [ngModel]="this.expEndDate | date:'yyyy-MM-dd'" name="txtDate" id="EndDate" name="EndDate">
                    </div>
                </div>
                <div class="row">
                    <div class="tg-checkbox col-md-9">
                        <input id="tg-enablemessages2" type="checkbox" name="birth1" [(ngModel)]="this.expIsCUrrent"
                            value="0">
                        <label for="tg-enablemessages2">Is Current</label>
                    </div>
                    <div class="col-md-3">
                        <button class="btn btn-success" (click)="addexperience()">Add Experience</button> &nbsp;
                        <button class="btn btn-info" (click)="editexperience()">Update</button>
                    </div>
                </div> <br />
                <table class="table table-bordered table-hover table-striped">
                    <thead class="">
                        <tr>
                            <!-- <th>UserID</th> -->
                            <th scope="col">Experience</th>
                            <th scope="col">Company</th>
                            <th scope="col">From</th>
                            <th scope="col">To</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let items of experience">
                            <!-- <td>{{education.userID}}</td> -->
                            <td>{{items.expDescription}}</td>
                            <td>{{items.expCompany}}</td>
                            <td>{{items.expStartDate}}</td>
                            <td>{{items.expEndDate}}</td>
                            <td> <a (click)="experienceById(items.expID)">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                    </svg>
                                </a> ||
                                <a (click)="DELETEexperienceById(items.expID)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-trash3" viewBox="0 0 16 16">
                                        <path
                                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="selectedAccord == 2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa fa-certificate" aria-hidden="true"></i>&nbsp; Certifications
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-4">
                            <label for="input_id_1">Certification</label>
                            <input type="text" class="form-control" placeholder="Certification"
                                [(ngModel)]="this.certDescription" id="this" name="this">
                        </div>
                        <div class="col-md-4">
                            <label for="input_id_1">Institute Name</label>
                            <input type="text" class="form-control" placeholder="Institute Name"
                                [(ngModel)]="this.certInstitute" id="InstituteName" name="InstituteName">
                        </div>
                        <div class="col-md-4">
                            <label for="input_id_1">Date</label>
                            <input type="date" class="form-control" placeholder="certDate" [(ngModel)]="this.certDate"
                                [ngModel]="this.certDate | date:'yyyy-MM-dd'" name="txtDate" id="certDate"
                                name="certDate">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9"></div>
                    <div class="col-md-3">
                        <button class="btn btn-success" (click)="addcertification()">Add Certification</button> &nbsp;
                        <button class="btn btn-info" (click)="editcertification()">Update</button>
                    </div>
                </div><br />
                <table class="table table-bordered table-hover table-striped">
                    <thead class="thead-light">
                        <tr>
                            <!-- <th>UserID</th> -->
                            <th>Certification</th>
                            <th>Institute</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let items of certification">
                            <!-- <td>{{education.userID}}</td> -->
                            <td>{{items.certDescription}}</td>
                            <td>{{items.certInstitute}}</td>
                            <td>{{items.certDate}}</td>
                            <td> <a (click)="certificationById(items.certID)">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                    </svg>
                                </a> ||
                                <a (click)="DELETCertificationById(items.certID)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-trash3" viewBox="0 0 16 16">
                                        <path
                                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="selectedAccord == 3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa fa-user-plus" aria-hidden="true"></i> &nbsp; Class Mates
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="Company">Class</label>
                            <!-- <input type="text" class="form-control" placeholder="Company Name" [(ngModel)]="this.projectCompany" id="Company" name="Company"> -->
                            <select class="form-select col-md-12" [(ngModel)]="this.classmate">
                                <option [ngValue]="null" [disabled]="true">Select Class</option>
                                <option *ngFor="let items of education; let i = index" value="{{items.eduID}}">
                                    {{items.eduDescription}}</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="Company">Friend Name</label>
                            <!-- <input type="text" class="form-control" placeholder="Company Name" [(ngModel)]="this.projectCompany" id="Company" name="Company"> -->
                            <select class="form-select col-md-12" [(ngModel)]="this.classmatename">
                                <option [ngValue]="null" [disabled]="true" >Select Friend</option>
                                <option *ngFor="let items of MyFriends; let i = index" value="{{items.userProfileID}}">
                                    {{items.userName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9"></div>
                    <div class="col-md-3">
                        <button class="btn btn-success" (click)="addClass()">Add ClassMate</button> &nbsp;
                        <button class="btn btn-info" (click)="editClass()">Update</button>
                    </div>
                </div><br />
                <table class="table table-bordered table-hover table-striped">
                    <thead class="thead-light">
                        <tr>
                            <th>Class</th>
                            <th>Institue</th>
                            <th>Class Mates</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let items of class; let i = index">
                            <td>{{items.eduDescription}}</td>
                            <td>{{items.eduCompany}}</td>
                            <td>
                                <div class="row">
                                    <div class="col-md-2 col-sm-3"
                                        *ngFor="let classmate of items.classmatelist; let c = index">
                                        <div class="card mb-30 hover10" style="cursor: pointer;" *ngIf="c < 5">
                                            <a class="card-img-tiles " data-abc="true">
                                                <div class="inner">
                                                    <div class="main-img"
                                                        (click)="GetClassMates(items.eduID,classmate.classmateID,classmate.cmID)">
                                                        <figure><img *ngIf="classmate.friendImageBase64 != null"
                                                                [src]="classmate.friendImageBase64"
                                                                class="img-fluid circular_image" />
                                                            <img *ngIf="classmate.friendImageBase64 == null"
                                                                class="img-fluid circular_image"
                                                                src="../../assets/images/ads/dummyimage.png" />
                                                        </figure>
                                                        <!-- <h5 *ngIf="classmate.friendName != null">
                                                            {{classmate.friendName}}</h5> -->
                                                            <h5>{{(classmate.friendName != null)? classmate.friendName : "classmate" }}</h5>
                                                        <!-- <h5 *ngIf="classmate.friendName == null">Classmate</h5> -->
                                                    </div>
                                                    <a (click)="removeClassmate(items.eduID,classmate.classmateID)"
                                                        class="btn btn-xs btn-danger">Remove</a>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <!-- <div style="margin-top:50px;">
                                        <button *ngIf="items.classmatelist.length > 4" class="btn btn-primary"
                                            data-toggle="modal" data-target="#classmateModal">More</button>
                                    </div> -->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="selectedAccord == 4" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa fa-tasks" aria-hidden="true"></i> &nbsp; Projects
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="form-group" *ngIf="this.experience != null">
                    <div class="row">
                        <div class="col-md-4">
                            <label for="input_id_1">Project Name</label>
                            <input type="text" class="form-control" placeholder="Name" [(ngModel)]="this.projectTitle"
                                id="this" name="this">
                        </div>
                        <!-- <div class="col-md-4">
                            <label for="input_id_1">Friend Name</label>
                            <input type="text" class="form-control" placeholder="Friend Name"
                                [(ngModel)]="this.projectDescription" id="InstituteName" name="InstituteName">
                        </div> -->
                        <div class="col-md-4">
                            <label for="input_id_1">Select Friends</label>
                            <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                                class="scrollskills" [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
                                (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                            </angular2-multiselect>
                        </div>
                        <div class="col-md-4">
                            <label for="Company">Company</label>
                            <!-- <input type="text" class="form-control" placeholder="Company Name" [(ngModel)]="this.projectCompany" id="Company" name="Company"> -->
                            <select class="form-select col-md-12" id="Company" placeholder="Select Company"
                                [(ngModel)]="this.projectCompany" name="Select Company">
                                <option [ngValue]="null" [disabled]="true">Select Company</option>
                                <option *ngFor="let items of experience; let i = index" value="{{items.expID}}">
                                    {{items.expCompany}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9"></div>
                    <div class="col-md-3">
                        <button class="btn btn-success" (click)="addProject()">Add Project</button> &nbsp;
                        <button class="btn btn-info" (click)="editProject()">Update</button>
                    </div>
                </div><br />
                <table class="table table-bordered table-hover table-striped">
                    <thead class="thead-light">
                        <tr>
                            <th>Project Name</th>
                            <th>Company</th>
                            <th>Collegues</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let items of project">
                            <td>{{items.projectTitle}}</td>
                            <td>{{items.projectCompany}}</td>
                            <td>
                                <div class="row">
                                    <div class="col-md-2 col-sm-3"
                                        *ngFor="let data of items.projectdetail;">
                                        <div class="card mb-30 hover10" style="cursor: pointer;" >
                                            <a class="card-img-tiles " data-abc="true">
                                                <div class="inner">
                                                    <!-- <div class="main-img" (click)="GetProjectfriend(data.id,items.projectCompany,items.projectID,data.friendID)"> -->
                                                        <div class="main-img" >
                                                            <figure><img *ngIf="data.collegueImageBase64 != null"
                                                                [src]="data.collegueImageBase64"
                                                                class="img-fluid circular_image" />
                                                            <img *ngIf="data.collegueImageBase64 == null"
                                                                class="img-fluid circular_image"
                                                                src="../../assets/images/ads/dummyimage.png" />
                                                        </figure>
                                                        <h5 *ngIf="data.collegueName != null">
                                                            {{data.collegueName}}</h5>
                                                        <h5 *ngIf="data.collegueName == null">NO FRIENDS</h5>
                                                    </div>
                                                </div>
                                            </a>
                                            <!-- <a (click)="removeTeamMember(items.expID,data.collegueID)"
                                                class="btn btn-xs btn-danger">Remove</a> -->
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td> 
                                <a (click)="ProjectById(items.projectID)">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                    </svg>
                                </a> ||
                                <a (click)="DELETProjectById(items.projectID)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-trash3" viewBox="0 0 16 16">
                                        <path
                                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="selectedAccord == 5" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa fa-users" aria-hidden="true"></i> &nbsp; Team Members
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="Company">Company</label>
                            <!-- <input type="text" class="form-control" placeholder="Company Name" [(ngModel)]="this.projectCompany" id="Company" name="Company"> -->
                            <select class="form-select col-md-12" id="Company" placeholder="Select Company"
                                [(ngModel)]="this.teamCompany">
                                <option [ngValue]="null" [disabled]="true">Select Company</option>
                                <option *ngFor="let items of experience; let i = index" value="{{items.expID}}">
                                    {{items.expCompany}}</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="friend">Friend Name</label>
                            <select class="form-select col-md-12" id="friend" placeholder="Select Frirnd"
                                [(ngModel)]="this.teamFriend">
                                <option [ngValue]="null" [disabled]="true" selected>Select Friend</option>
                                <option *ngFor="let items of MyFriends" value="{{items.userProfileID}}">
                                    {{items.userName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9"></div>
                    <div class="col-md-3">
                        <button class="btn btn-success" (click)="addTeam()">Add TeamMember</button> &nbsp;
                        <button class="btn btn-info" (click)="editTeam()">Update</button>
                    </div>
                </div><br />
                <table class="table table-bordered table-hover table-striped">
                    <thead class="thead-light">
                        <tr>
                            <th>Company</th>
                            <th>Experience</th>
                            <th>Team Members</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let items of team; let i = index">
                            <td>{{items.expCompany}}</td>
                            <td>{{items.expDescription}}</td>
                            <td>
                                <!-- <div *ngFor="let collegue of items.colleguelist"> -->
                                <!-- <img class="img-fluid" width="30px" [src]="collegue.collegueImageBase64"> -->
                                <div class="row">
                                    <div class="col-md-2 col-sm-3"
                                        *ngFor="let collegue of items.colleguelist; let c = index">
                                        <div class="card mb-30 hover10" style="cursor: pointer;" *ngIf="c < 5">
                                            <a class="card-img-tiles " data-abc="true">
                                                <div class="inner">
                                                    <div class="main-img"
                                                        (click)="GetTeamMember(items.expID,collegue.collegueID,collegue.tmID)">
                                                        <figure><img *ngIf="collegue.collegueImageBase64 != null"
                                                                [src]="collegue.collegueImageBase64"
                                                                class="img-fluid circular_image" />
                                                            <img *ngIf="collegue.collegueImageBase64 == null"
                                                                class="img-fluid circular_image"
                                                                src="../../assets/images/ads/dummyimage.png" />
                                                        </figure>
                                                        <!-- <h5 *ngIf="collegue.collegueName != null">
                                                            {{collegue.collegueName}}</h5>
                                                        <h5 *ngIf="collegue.collegueName == null">collegue</h5> -->
                                                        <h5>{{(collegue.collegueName != null)? collegue.collegueName : "collegue" }}</h5>
                                                    </div>
                                                </div>
                                            </a>
                                            <a (click)="removeTeamMember(items.expID,collegue.collegueID)"
                                                class="btn btn-xs btn-danger">Remove</a>
                                        </div>
                                    </div>
                                    <!-- <div style="margin-top:50px;">
                                        <button *ngIf="items.colleguelist.length > 4" class="btn btn-info"
                                        (click)="getteammember(i)" data-toggle="modal" data-target="#exampleModal">More</button>
                                    </div> -->
                                </div>
                                <!-- </div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="selectedAccord == 6" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Skills
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Websites, IT & Software
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of webskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Mobile Phones & Computing
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of mobileskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Writing & Content
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of writingskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Design, Media & Architecture
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of designskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Data Entry & Admin
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of dataentryskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Engineering & Science
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of engineeringskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Product Sourcing & Manufacturing
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of productskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Sales & Marketing
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of saleskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Business, Accounting, Human Resources &
                            Legal
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of bussinessskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Translation & Languages
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of translateskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i class="fa fa-cogs" aria-hidden="true"></i> &nbsp; Local Jobs & Trades
                        </mat-panel-title>
                        <mat-panel-description>
                            <!-- Currently I am {{panelOpenState ? 'open' : 'closed'}} -->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="row">
                            <div class="form-check col-md-3" *ngFor="let items of localskill; let i = index"
                                [attr.data-index]="i">
                                <input name="{{items.skill_Name}}" type="checkbox" style="width:30px;height:15px;"
                                    [checked]="items.isChecked"
                                    (change)="onChange(items.skill_ID,items.skill_ParentID, $event.target.checked)"
                                    class="form-check-input" id="{{items.skill_Name}}">
                                <label class="form-check-input" for="exampleCheck1">{{items.skill_Name}}</label>
                            </div>
                        </div>
                        <button class="btn btn-success" type="submit">update</button>
                    </form>
                </mat-expansion-panel>
            </mat-expansion-panel>
        </mat-accordion>
    </section>

</main>



<!-- /// CLASS MATES MODEL  -->
<!-- Modal -->
<div class="modal fade" id="classmateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Class Mates</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngFor="let items of class">
                    <div class="col-md-2 col-sm-3" *ngFor="let classmate of items.classmatelist; let i = index">
                        <div class="card mb-30 hover10" style="cursor: pointer;">
                            <a class="card-img-tiles " data-abc="true">
                                <div class="inner">
                                    <div class="main-img"
                                        (click)="GetClassMates(items.eduID,classmate.classmateID,classmate.cmID)">
                                        <figure><img *ngIf="classmate.friendImageBase64 != null"
                                                [src]="classmate.friendImageBase64" class="img-fluid circular_image" />
                                            <img *ngIf="classmate.friendImageBase64 == null"
                                                class="img-fluid circular_image"
                                                src="../../assets/images/ads/dummyimage.png" />
                                        </figure>
                                        <h5 *ngIf="classmate.friendName != null">
                                            {{classmate.friendName}}</h5>
                                        <h5 *ngIf="classmate.friendName == null">Classmate</h5>
                                    </div>
                                    <a (click)="removeClassmate(items.eduID,classmate.classmateID)"
                                        class="btn btn-xs btn-danger">Remove</a>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- /// TEAM MEMBERS MODEL  -->
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Team Members</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div *ngFor="let items of team; let i = index">
                        <div class="col-md-2 col-sm-3" *ngFor="let collegue of teampopup; let i = index">
                            <div class="card mb-30 hover10" style="cursor: pointer;">
                                <a class="card-img-tiles " data-abc="true">
                                    <div class="inner">
                                        <div class="main-img"
                                            (click)="GetTeamMember(items.expCompany,collegue.collegueID,collegue.tmID)">
                                            <figure><img *ngIf="collegue.collegueImageBase64 != null"
                                                    [src]="collegue.collegueImageBase64"
                                                    class="img-fluid circular_image" />
                                                <img *ngIf="collegue.collegueImageBase64 == null"
                                                    class="img-fluid circular_image"
                                                    src="../../assets/images/ads/dummyimage.png" />
                                            </figure>
                                            <h5 *ngIf="collegue.collegueName != null">{{collegue.collegueName}}</h5>
                                            <h5 *ngIf="collegue.collegueName == null">collegue</h5>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <a (click)="removeTeamMember(items.expID,collegue.collegueID)"
                                class="btn btn-xs btn-danger">Remove</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>