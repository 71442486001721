<div class="tg-wrapper tg-haslayout">
    <div class="tg-dashboardbanner">
        <h1>Company Orders </h1> &nbsp;
         <!-- <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" routerLink="//about">About YourSelf</a>&nbsp;
         <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" routerLink="/userprofile/friend">Friends</a>&nbsp;
         <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" routerLink="/userchat">Message's</a>&nbsp;
         <a style="border: 1px solid gray; padding: 5px; border: radius 10px;" (click)="myuserview()">Preview</a> -->
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()" style="cursor: pointer;">Go to Home</a></li>
            <li><a (click)="goToUserOrders()" style="cursor: pointer;">Company Orders</a></li>
            <li class="tg-active">Company Orders</li>
        </ol>
    </div>
    <main id="tg-main" class="tg-main tg-haslayout">
        <!--************************************
					Section Start
			*************************************-->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <fieldset>
                    <div class="tg-postanad">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="tg-dashboardbox">
                                <div class="tg-dashboardboxtitle">
                                    <h2>Company Orders Details</h2>
                                </div>
                                <div class="tg-dashboardholder">   
                                    <div class="row" style="margin-top: 50px;" style=""> 
                                    <aside class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style=" border: 2px solid rgba(0, 0, 0, .125);
                                    border-radius: 10px; padding-top: 15px; padding-bottom: 15px; margin-right: 10px;">
                                        <div class="container-xl center">
                                            <table class="table table-striped table-hover">
                                                <thead>
                                                    <tr style="background: #00cc67;color: white;">
                                                        <th scope="col">Order Number</th>
                                                        <th scope="col" width="120">Status </th>
                                                        <th scope="col" width="120">Date</th>                              
                                                        <th scope="col" width="120">Total </th>
                                                        <th scope="col" width="120">Company</th>  
                                                        <th scope="col" width="120">Remarks</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>                            
                                                        <!-- src="../../assets/images/angular.png" -->
                                                        <td>{{OrderList[0].orderNumber}}</td>
                                                        <td>Received</td>
                                                        <td>{{OrderList[0].orderAddedDate}}</td>                             
                                                        <td>{{OrderList[0].totalAmount}}</td>   
                                                        <td>{{OrderList[0].comName}}</td> 
                                                        <td>Under Processing</td>   
                                                    </tr>                       
                                                </tbody>
                                            </table>               
                                        </div>
                                    </aside>  
                                                                        
                                    <aside class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style=" border: 2px solid rgba(0, 0, 0, .125);
                                    border-radius: 10px; padding-top: 15px; padding-bottom: 15px; margin-right: 10px; margin-top: 25px;">
                                        <div class="container-xl center">
                                            <h3 class="text-center">Company Order Products Details</h3> 
                                            <table class="table table-striped table-hover">
                                                <thead>
                                                    <tr style="background: #00cc67;color: white;">
                                                        <th scope="col">Product Name</th>
                                                        <th scope="col" width="120">Quantity </th>
                                                        <th scope="col" width="120">Price</th>  
                                                        <th scope="col" width="120">Ammount</th>                          
                                                        <th scope="col" width="120">Discount Applied</th>                                                    
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let items of OrderList | paginate: { itemsPerPage: 10, currentPage: page,totalItems:TotalLength }">                            
                                                        <!-- src="../../assets/images/angular.png" -->
                                                        <td>{{items.productName}}</td>
                                                        <td>{{items.productQuantity}}</td> 
                                                        <td>{{items.productPrice}}</td> 
                                                        <td>{{items.productAmountReceived}}</td> 
                                                        <td>{{items.productDiscountApplied}}%</td>                                                                               
                                                    </tr>
                                                    <tr *ngIf="!OrderList || OrderList.length==0">
                                                        <td colspan="5">No data found</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls class="my-pagination text-center" (pageChange)="page = $event">
                                            </pagination-controls>
                                        </div>
                                    </aside> 

                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </section>
    </main>
</div>


