<app-search></app-search>
<main id="tg-main" class="tg-main tg-haslayout">
    <!--************************************
            About Us Start
        *************************************-->
    <div class="container">
        <div class="row">
            <div id="tg-twocolumns" class="tg-twocolumns">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="text-align: left;">
                    <div class="tg-pagehead">
                        <h3>Results for "All Items"</h3>
                        <p>10300 Results till today Date</p>

                        <!-- <accordion [isAnimated]="true">
                            <accordion-group heading="Static Header">
                                This content is straight in the template.
                            </accordion-group>
                            <accordion-group heading="Another group">
                                <p>Some content</p>
                            </accordion-group>
                            <accordion-group heading="Another group">
                                <p>Some content</p>
                            </accordion-group>
                            <accordion-group heading="Another group">
                                <p>Some content</p>
                            </accordion-group>
                            <accordion-group [isOpen]="isContentOpen">
                                <button class="btn btn-light float-right">
                                  <i *ngIf="isContentOpen" class="fas fa-caret-up">Open</i>
                                  <i *ngIf="!isContentOpen" class="fas fa-caret-down">Close</i>
                                </button>
                            </accordion-group>
                        </accordion> -->

                    </div>
                </div>
                <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3" style="float: left;">
                    <aside id="tg-sidebar" class="tg-sidebar">
                        <div class="tg-formtheme tg-formnerrowsearch">
                            <div class="tg-sidebartitle">
                                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                                    <h2>Narrow Your Search:</h2>
                                </div>
                                <div style="float: right;" class="col-xs-3 col-sm-3 col-md-3 col-lg-3 showHideFilters" (click)="showFilters()">
                                    <p style="color: white;">Show/Hide</p>
                                </div>
                            </div>
                            <fieldset *ngIf="visibleFilters === 1">
                                <div id="tg-narrowsearchcollapse" class="tg-themecollapse tg-narrowsearchcollapse">
                                    <accordion>
                                        <accordion-group [isOpen]="isFirstOpen" #group [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-layer-group' style="color: #4c505f;"></i> Category
                                                <i class='fa fa-undo' style="float: right; color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <i class="icon-magnifier"></i>
                                                <select id="mainCategory" [(ngModel)]="selectedCategory"
                                                    (change)="getSubCategories(0)" style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="c4e1f606-8105-4666-a8bc-c6657730a0df">Products</option>
                                                    <option value="5bf243b1-2be4-4dda-93a0-cf9e06209e0f">Autos</option>
                                                    <option value="d7b411fb-1c21-4f0a-88f7-34d6b789f67d">Classified</option>                                     
                                                    <option value="3c064386-a300-4f90-8e18-f6da9f83aa1c">Jobs</option>                                                   
                                                    <option value="0372dc00-f9cb-4cb5-8b95-901ea8a02efa">Property For Rent</option>
                                                    <option value="27bbb528-5666-4429-b153-7d6441c0efa1">Property For Sale</option>
                                                    <option value="c4e1f606-8105-4666-a8bc-c6657730a0df">Deals</option>
                                                    <option value="c4e1f606-8105-4666-a8bc-c6657730a0df">Suppliers
                                                    </option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group *ngIf="this.levelToShow > 0" [isOpen]="isFirstOpen"
                                            [panelClass]="customClass" style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-layer-group' style="color: #4c505f;"></i> Sub Category
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select style="width: 100%;" [(ngModel)]="selectedSubCategory"
                                                    (change)="getSubCategories(1)">
                                                    <option value="0">All Types</option>
                                                    <option *ngFor="let subCat of subCategories" value={{subCat.catID}}>
                                                        {{subCat.catName}}</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group *ngIf="this.levelToShow > 1" [isOpen]="isFirstOpen"
                                            [panelClass]="customClass" style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-layer-group' style="color: #4c505f;"></i> Sub Category
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <i class="icon-magnifier"></i>
                                                <select style="width: 100%;" [(ngModel)]="selectedSubCatFirstChild"
                                                    (change)="getSubCategories(2)">
                                                    <option value="0">All Types</option>
                                                    <option *ngFor="let subCatFirst of subCatFirstChildList"
                                                        value={{subCatFirst.catID}}>{{subCatFirst.catName}}</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group *ngIf="this.levelToShow > 2" [isOpen]="isFirstOpen"
                                            [panelClass]="customClass" style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-layer-group' style="color: #4c505f;"></i> Sub Category
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <i class="icon-magnifier"></i>
                                                <select style="width: 100%;" [(ngModel)]="selectedSubCatSecondChild"
                                                    (change)="getSubCategories(3)">
                                                    <option value="0">All Types</option>
                                                    <option *ngFor="let subCatSecond of subCatSecondChildList"
                                                        value={{subCatSecond.catID}}>{{subCatSecond.catName}}</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class="fas fa-search" style="color: #4c505f;"></i>
                                                Search By Keywords
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <input type="text" name="searchWord" [(ngModel)]="selectedSearchWord"
                                                    class="form-control" placeholder="Search Title/Keyword">
                                            </div>
                                        </accordion-group>
                                    </accordion>
                                    <div style="width: 100%;text-align: center;">
                                        <button (click)="searchSetFromListPage(1)" type="button"
                                            class="tg-btn">Search</button>
                                    </div>
                                    <accordion>
                                        <accordion-group [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class="fas fa-donate" style="color: #4c505f;"></i> Price Range
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <ng5-slider [(value)]="minValue" [(highValue)]="maxValue"
                                                    [options]="options"></ng5-slider>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-calendar-times' style="color: #4c505f;"></i> Year Range
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <ng5-slider [(value)]="minYearValue" [(highValue)]="maxYearValue"
                                                    [options]="optionsYears"></ng5-slider>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fas fa-tachometer-alt' style="color: #4c505f;"></i> Kilo Meter
                                                Range
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <ng5-slider [(value)]="minKMValue" [(highValue)]="maxKMValue"
                                                    [options]="optionsKM"></ng5-slider>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <img class="details-icons" src="../assets/images/icons/Doors.png"
                                                    height="18" width="18" alt="Doors">
                                                Doors
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedDoors"
                                                    style="width: 100%;">
                                                    <option value="0">All</option>
                                                    <option value="1">2 Doors</option>
                                                    <option value="2">3 Doors</option>
                                                    <option value="3">4 Doors</option>
                                                    <option value="4">5+ Doors</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <img class="details-icons" src="../assets/images/icons/Color.png"
                                                    height="18" width="18" alt="Doors">
                                                Color
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedColor"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Blue</option>
                                                    <option value="2">Black</option>
                                                    <option value="3">Brown</option>
                                                    <option value="4">Burgendy</option>
                                                    <option value="5">Gold</option>
                                                    <option value="6">Green</option>
                                                    <option value="7">Grey</option>
                                                    <option value="8">Purple</option>
                                                    <option value="9">Pink</option>
                                                    <option value="10">White</option>
                                                    <option value="11">Silver</option>
                                                    <option value="12">Other</option>
                                                    <option value="13">Red</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fas fa-car' style="color: #4c505f;"></i>
                                                Body Type
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedBodyType"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Coupe</option>
                                                    <option value="2">CrossOver</option>
                                                    <option value="3">Hard top convertable</option>
                                                    <option value="4">Hetchback</option>
                                                    <option value="5">Sedan</option>
                                                    <option value="6">PickUp Truck</option>
                                                    <option value="7">Soft top convertable</option>
                                                    <option value="8">Sports </option>
                                                    <option value="9">SUV</option>
                                                    <option value="10">Van</option>
                                                    <option value="11">Utility Truck</option>
                                                    <option value="12">Wagon</option>
                                                    <option value="13">Other</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <img class="details-icons" src="../assets/images/icons/Automatic.png"
                                                    height="17" width="17" alt="Doors">
                                                Transmission
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedTransmission"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Automatic</option>
                                                    <option value="2">Manual</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <img class="details-icons" src="../assets/images/icons/Horse.png"
                                                    height="17" width="17" alt="Doors">
                                                HorsePower
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedHP" style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Less Then 150</option>
                                                    <option value="2">150 - 200</option>
                                                    <option value="3">200 - 300 </option>
                                                    <option value="4">300 - 400</option>
                                                    <option value="5">400 - 500</option>
                                                    <option value="6">500 - 600</option>
                                                    <option value="7">600 - 700</option>
                                                    <option value="8">700 - 800</option>
                                                    <option value="9">800 - 900</option>
                                                    <option value="10">900 +</option>
                                                    <option value="11">unknown</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <img class="details-icons" src="../assets/images/icons/Cylinders.png"
                                                    height="17" width="17" alt="Doors">
                                                Cylender
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedCylender"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">2</option>
                                                    <option value="2">3</option>
                                                    <option value="3">4</option>
                                                    <option value="4">5</option>
                                                    <option value="5">6</option>
                                                    <option value="6">8</option>
                                                    <option value="7">10</option>
                                                    <option value="8">12</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <img class="details-icons" src="../assets/images/icons/FuelType.png"
                                                    height="17" width="17" alt="Doors">
                                                Fuel Type
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedFT" style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Gasoline</option>
                                                    <option value="2">Diesel</option>
                                                    <option value="3">Hybrid</option>
                                                    <option value="4">Electric</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf=" this.selectedCategory =='d7b411fb-1c21-4f0a-88f7-34d6b789f67d'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-layer-group' style="color: #4c505f;"></i>
                                                Usage
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedUsage"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Brand New </option>
                                                    <option value="2">Origional Packaging</option>
                                                    <option value="3">Not Used</option>
                                                    <option value="4">Used Some Time</option>
                                                    <option value="5">Used Average Time</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf=" this.selectedCategory =='d7b411fb-1c21-4f0a-88f7-34d6b789f67d'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-angle-down' style="color: #4c505f;"></i>
                                                Condition
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedCondition"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Brand New </option>
                                                    <option value="2">Perfect Inside &amp; Out</option>
                                                    <option value="3">A Bit wear &amp; Tear</option>
                                                    <option value="4">Normal wear &amp; tear</option>
                                                    <option value="5">Above average wear &amp; tear</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf=" this.selectedCategory =='d7b411fb-1c21-4f0a-88f7-34d6b789f67d'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-angle-down' style="color: #4c505f;"></i>
                                                Age
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedAge"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">New </option>
                                                    <option value="3">0-1 month</option>
                                                    <option value="4">1-6 months</option>
                                                    <option value="5">6-12 months</option>
                                                    <option value="6">1-2 years</option>
                                                    <option value="7">2-5 years</option>
                                                    <option value="8">5-10 years</option>
                                                    <option value="9">10+ month</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='3c064386-a300-4f90-8e18-f6da9f83aa1c' || this.selectedSubCategory == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A' || this.selectedSubCategory =='967CEED7-54C0-488E-8AA8-69E6045E6C05'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-briefcase' style="color: #4c505f;"></i>
                                                Work Experience
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedWE" style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">New </option>
                                                    <option value="1">0 - 1 Year</option>
                                                    <option value="2">1 - 2 Years</option>
                                                    <option value="3">2 - 3 Years</option>
                                                    <option value="4">3 - 5 Years</option>
                                                    <option value="5">5 - 10 Years</option>
                                                    <option value="6">10+ Years</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='3c064386-a300-4f90-8e18-f6da9f83aa1c' || this.selectedSubCategory == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A' || this.selectedSubCategory =='967CEED7-54C0-488E-8AA8-69E6045E6C05'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-user-circle' style="color: #4c505f;"></i>
                                                Gender
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedGender"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Male</option>
                                                    <option value="2">Female</option>
                                                    <option value="3">No Prefrence</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='3c064386-a300-4f90-8e18-f6da9f83aa1c' || this.selectedSubCategory == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A' || this.selectedSubCategory =='967CEED7-54C0-488E-8AA8-69E6045E6C05'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-certificate' style="color: #4c505f;"></i>
                                                Education
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedEdu"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">High School</option>
                                                    <option value="2">Bachelors </option>
                                                    <option value="3">Master </option>
                                                    <option value="4">Phd</option>
                                                    <option value="5">Other</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='3c064386-a300-4f90-8e18-f6da9f83aa1c' || this.selectedSubCategory == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A' || this.selectedSubCategory =='967CEED7-54C0-488E-8AA8-69E6045E6C05'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class='fa fa-handshake' style="color: #4c505f;"></i>
                                                Committment
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedCommitment"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Full Time</option>
                                                    <option value="2">Part Time</option>
                                                    <option value="3">Contract</option>
                                                    <option value="4">Temporary</option>
                                                    <option value="5">Other</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='3c064386-a300-4f90-8e18-f6da9f83aa1c' || this.selectedSubCategory == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A' || this.selectedSubCategory =='967CEED7-54C0-488E-8AA8-69E6045E6C05'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class="fa fa-car" style="color: #4c505f;"></i>
                                                Travel Required
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="travel" [(ngModel)]="selectedTravel" style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Required</option>
                                                    <option value="2">Not Required</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='3c064386-a300-4f90-8e18-f6da9f83aa1c' || this.selectedSubCategory == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A' || this.selectedSubCategory =='967CEED7-54C0-488E-8AA8-69E6045E6C05'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class="far fa-money-bill-alt" style="color: #4c505f;"></i>
                                                Salary
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <ng5-slider [(value)]="minSalValue" [(highValue)]="maxSalValue"
                                                    [options]="optionsSal"></ng5-slider>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='0372dc00-f9cb-4cb5-8b95-901ea8a02efa' || this.selectedCategory =='27bbb528-5666-4429-b153-7d6441c0efa1'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class="fas fa-bed" style="color: #4c505f;"></i>
                                                Rooms
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <ng5-slider [(value)]="minRoomValue" [(highValue)]="maxRoomValue"
                                                    [options]="optionsRoom"></ng5-slider>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='0372dc00-f9cb-4cb5-8b95-901ea8a02efa' || this.selectedCategory =='27bbb528-5666-4429-b153-7d6441c0efa1'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class="fas fa-bath" style="color: #4c505f;"></i>
                                                Bath
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <ng5-slider [(value)]="minBathValue" [(highValue)]="maxBathValue"
                                                    [options]="optionsBath"></ng5-slider>
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='0372dc00-f9cb-4cb5-8b95-901ea8a02efa' || this.selectedCategory =='27bbb528-5666-4429-b153-7d6441c0efa1'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class="fas fa-building" style="color: #4c505f;"></i>
                                                Building
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <input type="text" name="search" [(ngModel)]="selectedBuilding"
                                                    class="form-control" placeholder="Search Building">
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='0372dc00-f9cb-4cb5-8b95-901ea8a02efa' || this.selectedCategory =='27bbb528-5666-4429-b153-7d6441c0efa1'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class="fas fa-area-chart" style="color: #4c505f;"></i>
                                                Size - Square Feet
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <input type="text" name="search" [(ngModel)]="selectedSize"
                                                    class="form-control" placeholder="Search Size">
                                            </div>
                                        </accordion-group>
                                        <accordion-group
                                            *ngIf="this.selectedCategory =='0372dc00-f9cb-4cb5-8b95-901ea8a02efa' || this.selectedCategory =='27bbb528-5666-4429-b153-7d6441c0efa1'"
                                            [isOpen]="isFirstOpen" [panelClass]="customClass"
                                            style="border:none; margin-top: 0px!important;">
                                            <span accordion-heading
                                                style="font-size: 10pt!important;font-weight: 500!important;">
                                                <i class="fas fa-couch" style="color: #4c505f;"></i>
                                                Furnished
                                                <i class='fa fa-undo' style="float: right;color: #999;"></i>
                                            </span>
                                            <div class="form-group tg-inputwithicon">
                                                <select id="mainCategory" [(ngModel)]="selectedFurnished"
                                                    style="width: 100%;">
                                                    <option value="0" selected="selected">All Types</option>
                                                    <option value="1">Furnished</option>
                                                    <option value="2">Un Furnished</option>
                                                </select>
                                            </div>
                                        </accordion-group>
                                    </accordion>
                                    <div style="width: 100%;text-align: center;">
                                        <button (click)="searchSetFromListPage(1)" type="button" class="tg-btn">Apply
                                            filter</button>
                                    </div>
                                </div>


                            </fieldset>
                        </div>
                    </aside>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-8 col-lg-9">
                    <div id="tg-content" class="tg-content">
                        <div class="tg-contenthead">
                            <div class="tg-sortandview">
                                <div class="tg-sortby">
                                    <strong>Results from the search:</strong>
                                </div>
                                <!-- <button (click)="setSearch()" -->
                                <button (click)="setSearch()" type="button" class="btn btn-success">set search</button>
                            </div>
                        </div>
                        <!--   <div class="tg-select">
                                        <select>
                            <option value="1">Most Recent</option>
                            <option value="2">Ascending</option>
                            <option value="3">Descending</option>
                          </select>
                                    </div>
                                </div>
                                <div class="tg-views">
                                    <strong>View As Grid</strong>
                                    <ul>
                                        <li class="tg-active"><a href="javascript:void(0);"><i class="fa fa-th-large"></i></a></li>
                                        <li><a href="javascript:void(0);"><i class="fa fa-reorder"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tg-applyedfilters">
                                 <ul>
                                    <li class="alert alert-dismissable fade in">
                                        <a href="javascript:void" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        <span>Manchester</span>
                                    </li>
                                    <li class="alert alert-dismissable fade in">
                                        <a href="javascript:void" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        <span>$200 - $500</span>
                                    </li>
                                    <li class="alert alert-dismissable fade in">
                                        <a href="javascript:void" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        <span>Brand New</span>
                                    </li>
                                    <li class="alert alert-dismissable fade in">
                                        <a href="javascript:void" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        <span>Featured Ads</span>
                                    </li>
                                    <li class="alert alert-dismissable fade in">
                                        <a href="javascript:void" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        <span>Area Unit: Sq. ft.</span>
                                    </li>
                                    <li class="alert alert-dismissable fade in">
                                        <a href="javascript:void" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        <span>Sq. ft. 2500 - Sq. ft.5000</span>
                                    </li>
                                    <li class="alert alert-dismissable fade in">
                                        <a href="javascript:void" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        <span>Rooms: 04</span>
                                    </li>
                                    <li class="alert alert-dismissable fade in">
                                        <a href="javascript:void" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        <span>2014 - 2016</span>
                                    </li>
                                    <li class="alert alert-dismissable fade in">
                                        <a href="javascript:void" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                        <span>1500 - 3000</span>
                                    </li>
                                    <li><a class="tg-btncleall" href="javascript:void">Clear All</a></li>
                                </ul>
                            </div>
                        </div> -->
                        <!-- <div class="tg-ads tg-adsvtwo tg-adslist">
                            <div class="row">
                                <div *ngFor="let item of addResults;let i = index" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="tg-ad tg-verifiedad" style="border: 1px solid #eee;">
                                        <figure>
                                            <span class="tg-themetag tg-featuretag">featured</span>
                                            <a href="javascript:void(0);"><img src="{{item.imagePath}}" alt="{{item.title}}" class="featuredAdsImageClass"></a>
                                            <!-- <a href="javascript:void(0);"><img src="../assets/images/AdsImages/Autos/4C741829-C8BC-4AE4-B274-532D22774D33/main.jpeg" alt="{{item.title}}" class="featuredAdsImageClass"></a>
                                            <span class="tg-photocount">See 10 Photos</span>
                                        </figure>
                                        <div class="tg-adcontent">
                                            <ul class="tg-productcagegories">
                                                <li><a href="javascript:void(0);">{{item.cat}}</a></li>
                                                <div style="float:right">
                                                    <li><i class="fa fa-eye" style="color: #4c505f;"></i> {{item.totalAddViews}}</li>
                                                </div>
                                            </ul>
                                            <div class="tg-adtitle">
                                                <h3><a href="javascript:void(0);">{{item.title}}</a></h3>
                                            </div>
                                            <time datetime="2017-06-06">Date Time: {{item.datePosted}} / {{item.timePosted}}</time>
                                            <div class="tg-adprice">
                                                <h4>{{item.currency}} : {{item.price}}</h4>
                                            </div>
                                            <address><i class="fas fa-location-arrow" style="margin-right: 5px;"></i>{{item.country}} - {{item.city}}</address>
                                            <div class="tg-phonelike">
                                                <a class="tg-btnphone" href="javascript:void(0);">
                                                    <i class="icon-phone-handset"></i>
                                                    <span data-toggle="tooltip" data-placement="top" title="Show Phone No." data-last="0800 - 1234 - 562 - 6"><em>Show Phone No.</em></span>
                                                </a>
                                                <span class="tg-like tg-liked"><i class="fa fa-heart"></i></span>
                                            </div>
                                        </div>
                                        <ul class="tg-productFooter">
                                            <div style="float:left;padding-top: 2%;">
                                                <li style="list-style: none;text-decoration: none;float: left;font-size: 15px; color:#00cc67" class="fas fa-thumbs-up" (click)="submitPostLikeDislike(1,i,addResults[i])"> </li>
                                                <li style="list-style: none;text-decoration: none;float: left;font-size: 15px;margin-top: -13%;padding-left: 5px;"> {{item.totalLikeCount}}</li>
                                                <li style="list-style: none;text-decoration: none;float: left;font-size: 15px;padding-left: 10px; color:#00cc67" class="fas fa-thumbs-down" (click)="submitPostLikeDislike(2,i,addResults[i])"> </li>
                                                <li style="list-style: none;text-decoration: none;float: left;font-size: 15px;margin-top: -13%;padding-left: 5px;"> {{item.totalDisLikeCount}}</li>
                                            </div>
                                            <div style="float:right">
                                                <li style="list-style: none;text-decoration: none;"> Posted by : {{item.userName}} <img src="{{item.userImagePath}}" alt="{{item.userName}}" class="uesrImageClass"></li>
                                            </div>
                                        </ul>
                                        <div *ngIf="item.adComments.length > 0" class="tg-dashboardholder tg-offersmessages" style="padding:0px">
                                            <ul>
                                                <li style="list-style: none;text-decoration: none; ">
                                                    <div class="tg-chatarea ">
                                                        <div class="tg-dashboardscrollbar " malihu-scrollbar [scrollbarOptions]="scrollbarOptions">
                                                            <div *ngFor="let comm of item.adComments" class="tg-offerermessage ">
                                                                <figure><img src="{{comm.userImage}} " alt="image description " style="width:35px;height:35px "></figure>
                                                                <div class="tg-description ">
                                                                    <div style="float:left;margin-top: 11px; ">
                                                                        <h6 class="ng-binding ">{{comm.addedByName}}</h6>
                                                                    </div>
                                                                    <div style="float:left ">
                                                                        <p class="ng-binding ">{{comm.description}}</p>
                                                                    </div>
                                                                    <!-- <span ng-bind-html="{{item.description}} "></span>
                                                                    <div class="clearfix "></div>
                                                                    <time datetime="2017-08-08 ">{{comm.timeCommented}}</time>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="form-group ">
                                            <div style="float: left;width: 79% ; padding-right: 5px; "><input type="text " name="adtitle " [(ngModel)]="commentDescription" class="form-control " placeholder="Knock Here * " ng-keyup="$event.keyCode==13 && vm.submitComment(item,vm.commentDescription) " (keyup.enter)="submitComment(i,addResults[i],commentDescription)"></div>
                                            <div style="float: left;width: 19% ">
                                                <!-- <button [disabled]="(authState | async).athenticated === false" class="tg-btn " type="button " name="reply" ng-keyup="$event.keyCode == 13 && submitComment()" (keyup.enter)="submitComment(i,addResults[i],commentDescription)" placeholder="Type Here &amp; Press Enter">Knock</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="tg-ads tg-adsvtwo tg-adslist">
                            <div class="row">
                                <div class="panel-body" style="padding-top: 10px;">
                                    <!-- Nav tabs -->
                                    <!-- <ul class="nav nav-tabs">
                                        <li class="active"><a href="#home" data-toggle="tab" aria-expanded="true">Time Line</a>
                                        </li>
                                    </ul> -->

                                    <!-- Tab panes -->
                                    <div class="tab-content">
                                        <div class="tab-pane fade active in" id="home">
                                            <div *ngIf="addResults && addResults.length > 0"
                                                style=" margin-top: -3%;position: absolute;float: right;margin-left: 75%;z-index: 100000;    width: 30%;">
                                                <nav class="tg-pagination ">
                                                    <ul>
                                                        <li class="tg-prevpage"><a (click)="pageChanged(2)"><i
                                                                    class="fa fa-angle-left "></i></a></li>
                                                        <li class="tg-nextpage"><a (click)="pageChanged(1)"><i
                                                                    class="fa fa-angle-right "></i></a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                            <ul class="timelineO">
                                                <li *ngFor="let item of addResults;let i = index"
                                                    class="col-xs-12 col-sm-12 col-md-12 col-lg-12 listMainSettings">
                                                    <div id="lineMainLabel" class="time-label"
                                                        style="position: absolute;">
                                                        <span>
                                                            <time class="timeLableTime"
                                                                datetime="2017-06-06">{{item.datePosted}} </time>
                                                        </span>
                                                    </div>
                                                    <div id="lineSubUserImage" class="time-label listUserImage">
                                                        <img *ngIf="item.userImagebase64 != null" [src]="item.userImagebase64" alt="{{item.userName}}" class="imageRadiasHalf">
                                                        <img *ngIf="item.userImagebase64 == null" src="../../assets/images/ads/dummyimage.png" alt="{{item.userName}}" class="imageRadiasHalf">
                                                    </div>
                                                    <div class="time-label likeButton"
                                                        (click)="submitPostLikeDislike(1,i,addResults[i])">
                                                        <i class="fas fa-thumbs-up" style="color: white;"></i>
                                                        <p class="timeLineDescriptionText likeDislikeCountText">
                                                            {{item.totalLikeCount}}</p>
                                                    </div>
                                                    <div class="time-label dislikeButton"
                                                        (click)="submitPostLikeDislike(2,i,addResults[i])">
                                                        <i class="fas fa-thumbs-down" style="color: white;"></i>
                                                        <p class="timeLineDescriptionText likeDislikeCountText">
                                                            {{item.totalDisLikeCount}}</p>
                                                    </div>
                                                    <div class="time-label viewsButton">
                                                        <li style="list-style: none;"><i class="icon-eye" style="color: white;">
                                                            <fa-icon [icon]="faEye " size="1x"></fa-icon>
                                                        </i></li>                                                         
                                                        <p class="timeLineDescriptionText likeDislikeCountText">
                                                            {{item.totalAddViews}}</p>
                                                    </div>
                                                    <div class="timelineO-item" style="margin-top:4%; padding-top:5px">
                                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <span class="time listTimeText"><i class="fa fa-clock"
                                                                style="padding-right: 5px;"></i>{{item.timePosted}}
                                                            </span>
                                                        </div>
                                                        
                                                        <div class="headerMainDiv ">
                                                            <div class="headerUserImage">                                                             
                                                                    <img *ngIf="item.userImagebase64 != null" [src]="item.userImagebase64" alt="{{item.userName}}" class="imageRadiasHalf">
                                                                    <img *ngIf="item.userImagebase64 == null" src="../../assets/images/ads/dummyimage.png" alt="{{item.userName}}" class="imageRadiasHalf">                                                             
                                                            </div>
                                                            <div class="headerName">                                                                                                                                                                                                                                            
                                                                    <span style="color:#363b4d;font-size: 14px; font-weight: 600;">{{item.userName}}</span>                                                              
                                                            </div>                                                           
                                                        </div>
                                                        <div class=" headerBreadCrum">                                                                                                                                                                                                                                         
                                                                <span style="font-size: 14px;"> Sent an item to : {{item.cat}} > {{item.subCat}}</span>                                                               
                                                        </div>                                                                                                               
                                                        <h3 class="timelineO-header">                                                                                                                                                                             
                                                        </h3>
                                                        <div class="timelineO-body">
                                                            <div class="col-lg-12"
                                                                style="padding-left:0px!important;padding-right:0px!important">
                                                                <div class="col-lg-3"
                                                                    style="padding-left:0px!important;padding-right:0px!important">
                                                                    <div class="pnlImageDiv">
                                                                        <figure>
                                                                            <span
                                                                                class="tg-themetag tg-featuretag">featured</span>
                                                                            <a href="javascript:void(0);">
                                                                                <img *ngIf="item.imagePath == '' || item.imagePath == null"  src="../../assets/images/ads/no_image.jpg" alt="{{item.title}}" class="featuredAdsImageClass" />
                                                                                <img *ngIf="item.imagePath != '' && item.imagePath != null && item.imagePath != 0" src="{{item.imagePath}}" alt="{{item.title}}" class="featuredAdsImageClass" />
                                                                            </a>
                                                                            <!-- <a href="javascript:void(0);"><img src="../assets/images/AdsImages/Autos/4C741829-C8BC-4AE4-B274-532D22774D33/main.jpeg" alt="{{item.title}}" class="featuredAdsImageClass"></a> -->
                                                                            <span class="tg-photocount">See more
                                                                                Photos</span>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-9"
                                                                    style="padding-left:0px!important;padding-right:0px!important;">
                                                                    <div class="tg-adtitle"
                                                                        style="padding-right: 10px!important;">
                                                                        <div style="float: left;">
                                                                            <!-- <a [routerLink]="['addDetails']">Details</a> -->
                                                                            <!-- <a [routerLink]="['../addDetails',item.add_CatID]">{{item.title}}</a> -->
                                                                            <h3><a style="cursor:pointer"
                                                                                    (click)="ongetDetails(item)">{{item.title}}</a>
                                                                            </h3>
                                                                        </div>
                                                                        <div style="float: right;">
                                                                            <span class="verifiedAddTitle">
                                                                                <i class="fa fa-check-circle"
                                                                                    style="color: #00cc67;"></i>Verified
                                                                                Ad</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="timeLineDescriptionDiv" style="    overflow-y: clip;background: mintcream;">
                                                                        <span class="timeLineDescription" [innerHtml]="item.description"></span>
                                                                    </div>                                                                    
                                                                    <div class="tg-adprice">
                                                                        <div style="float: left;">
                                                                            <h4 class="timeLineDescriptionText"
                                                                                style="color: orange;">{{item.currency}}
                                                                                : {{item.price}}</h4>
                                                                        </div>

                                                                        <div class="tg-phonelike" style="margin-top: -5px; float: right;margin-right: 10px;    min-width: 150px;
                                                                        text-align: left;">
                                                                            <a class="tg-btnphone"
                                                                                href="javascript:void(0);"
                                                                                style="background-color: #00cc67;color: white;">
                                                                                <i class="fa fa-phone"></i>
                                                                                <span><em>{{item.add_Phone}}</em></span>
                                                                            </a>
                                                                            <span class="tg-like"
                                                                                style="background-color: #f91942;"><i
                                                                                    class="fa fa-clock"
                                                                                    style="color: white;"></i></span>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div style="float:left; width:100%;">
                                                                        <h4 class="timeLineDescriptionText" style="color: orange;">{{item.currency}} : {{item.price}}</h4>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- <div class="timelineO-footer">
                                                            <h3><a href="javascript:void(0);">{{item.title}}</a></h3>
                                                        </div> -->
                                                        <div class="timeLineOLocationFooter" style="width:100%">
                                                            <div style=" float:left; text-align:left;"
                                                                class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                <address><i class="fas fa-location-arrow"
                                                                        style="margin-right: 5px;"></i>{{item.country}}
                                                                    - {{item.city}}</address>
                                                            </div>
                                                            <div id="divClassPostActions1" runat="server"
                                                                style=" float:left;text-align:left;" class="col-lg-6">
                                                            </div>
                                                        </div>
                                                        <div class="timeLineOLocationFooterButtons" style="width:100%">
                                                            <div style=" float:left; text-align:center;"
                                                                class="col-xs-4 col-sm-4 col-md-4 col-lg-4" 
                                                                (click)="submitPostLikeDislike(1,i,addResults[i])">
                                                                <i class="fas fa-thumbs-up" style="color:#555;"></i>
                                                                <p style="margin-left: 35%; margin-top: -35%; color:#555;" class="timeLineDescriptionText likeDislikeCountText">
                                                                    {{item.totalLikeCount}}</p>
                                                            </div>
                                                            <div style=" float:left; text-align:center; color:#555;"
                                                            class="col-xs-4 col-sm-4 col-md-4 col-lg-4" 
                                                                (click)="submitPostLikeDislike(2,i,addResults[i])">
                                                                <i class="fas fa-thumbs-down" style="color:#555;"></i>
                                                                <p style="margin-left: 35%; margin-top: -35%; color:#555;" class="timeLineDescriptionText likeDislikeCountText">
                                                                    {{item.totalDisLikeCount}}</p>
                                                            </div>
                                                            <div style=" float:left; text-align:center;"
                                                            class="col-xs-4 col-sm-4 col-md-4 col-lg-4">                                                            
                                                                    <li style="list-style: none;margin-top: -7%;"><i class="icon-eye" style="color: #555;">
                                                                        <fa-icon [icon]="faEye " size="1x"></fa-icon>
                                                                    </i></li>                                                         
                                                                    <p style="margin-left: 40%; margin-top: -38%; color:#555;" class="timeLineDescriptionText likeDislikeCountText">
                                                                        {{item.totalAddViews}}</p>
                                                            </div>

                                                          
                                                        <!-- <div class="time-label dislikeButton"
                                                            (click)="submitPostLikeDislike(2,i,addResults[i])">
                                                            <i class="fas fa-thumbs-down" style="color: white;"></i>
                                                            <p class="timeLineDescriptionText likeDislikeCountText">
                                                                {{item.totalDisLikeCount}}</p>
                                                        </div>
                                                        <div class="time-label viewsButton">
                                                            <li style="list-style: none;"><i class="icon-eye" style="color: white;">
                                                                <fa-icon [icon]="faEye " size="1x"></fa-icon>
                                                            </i></li>                                                         
                                                            <p class="timeLineDescriptionText likeDislikeCountText">
                                                                {{item.totalAddViews}}</p>
                                                        </div> -->

                                                        </div>
                                                        <div id="timeLineComments" class="timeLineOComments">

                                                            <div *ngIf="item.adComments.length > 0"
                                                                class="tg-dashboardholder tg-offersmessages"
                                                                style="padding:0px">
                                                                <div _ngcontent-qjt-c102="" class="tg-dashboardboxtitle"
                                                                    style="background-color: #f9f9f9; " >
                                                                    <h2 _ngcontent-qjt-c102="" style="color: #555;">
                                                                        Comments:</h2>
                                                                        <!-- <div style="float: left;margin-left: 1%; margin-top: 5px;" ">
                                                                            <p>view</p>
                                                                        </div>-->
                                                                        <p style="float: right;margin-right: 1%;" (click)="showSubItem(i)">view Total:{{item.adComments.length}}</p>
                                                                </div>
                                                                <ul>
                                                                    <li
                                                                        style="list-style: none;text-decoration: none; ">
                                                                        <div class="tg-chatarea " *ngIf="visibleIndex === i">
                                                                            <div class="tg-dashboardscrollbar "
                                                                                malihu-scrollbar
                                                                                [scrollbarOptions]="scrollbarOptions">
                                                                                <div *ngFor="let comm of item.adComments"
                                                                                    class="tg-offerermessage ">
                                                                                    <figure>
                                                                                        <a href="javascript:void(0);">
                                                                                        <!-- <img *ngIf="comm.userImage == '' || comm.userImage == null" src="../../assets/images/ads/dummyimage.png" alt="image description "  style="width:35px;height:35px" />
                                                                                        <img *ngIf="comm.userImage != ''" src="{{comm.userImage}} " alt="image description "  style="width:35px;height:35px" /> -->
                                                                                        <img  *ngIf="comm.imageBase64 != null" [src]="comm.imageBase64" alt="image description" style="width:35px;height:35px">
                                                                                        <img *ngIf="comm.imageBase64 == null || comm.imageBase64 == 0" src="../../assets/images/ads/dummyimage.png" alt="image description" style="width:35px;height:35px">
                                                                                        </a>
                                                                                    </figure>
                                                                                    <div class="tg-description ">
                                                                                        <div
                                                                                            style="float:left;margin-top: 11px; ">
                                                                                            <h6 class="ng-binding ">
                                                                                                {{comm.addedByName}}
                                                                                            </h6>
                                                                                        </div>
                                                                                        <div style="float:left ">
                                                                                            <p class="ng-binding ">
                                                                                                {{comm.description}}</p>
                                                                                        </div>
                                                                                        <!-- <span ng-bind-html="{{item.description}} "></span>-->
                                                                                        <div class="clearfix "></div>
                                                                                        <time
                                                                                            datetime="2017-08-08 ">{{comm.timeCommented}}</time>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="form-group "
                                                                style="margin-bottom: 0px!important">
                                                                <div class="timeLineknockMessage">
                                                                    <input type="text " name="adtitle "
                                                                        class="typeAndEnter"
                                                                        [(ngModel)]="commentDescription"
                                                                        class="form-control "
                                                                        placeholder="Type and press Enter ! "
                                                                        ng-keyup="$event.keyCode==13 && vm.submitComment(item,vm.commentDescription) "
                                                                        (keyup.enter)="submitComment(i,addResults[i],commentDescription)">
                                                                </div>
                                                                <!--<div style="float: left;width: 19% ">
                                                                     <button [disabled]="(authState | async).athenticated === false" class="tg-btn " type="button " name="reply" ng-keyup="$event.keyCode == 13 && submitComment()" (keyup.enter)="submitComment(i,addResults[i],commentDescription)" placeholder="Type Here &amp; Press Enter">Knock</button>
                                                                </div>-->
                                                            </div>

                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav class="tg-pagination " *ngIf="addResults && addResults.length > 0">
                            <ul>
                                <li class="tg-prevpage" style="width:49%"><a (click)="pageChanged(2)"><i
                                            class="fa fa-angle-left "></i></a></li>
                                <!-- <li><a href="# ">1</a></li>
                                <li><a href="# ">2</a></li>
                                <li><a href="# ">3</a></li>
                                <li><a href="# ">4</a></li>
                                <li class="tg-active "><a href="# ">5</a></li>
                                <li>...</li>
                                <li><a href="# ">10</a></li> -->
                                <li class="tg-nextpage" style="width:49%"><a (click)="pageChanged(1)"><i
                                            class="fa fa-angle-right "></i></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--************************************
            About Us End
        *************************************-->
</main>