import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { error } from 'protractor';
import { adds } from '../../models/Adds/adds.model';
import { AdComments } from '../../models/comments/comments.model';
import { Comments } from '../../models/comments/comment.model';
import { likeDislikeDetails } from '../../models/Adds/likeDislikeDetails.model';
import { allSearchFilters } from '../../models/requestsModel/search/searchInput.model';
import { searchResulteDTO } from '../../models/search/openSearch.model';
import { categories } from '../../models/category/category.model';
import { CategoryDto } from '../../models/category/categoryDTO.model';
import { Store } from '@ngrx/store';
import * as fromSearch from '../../../store/app.state';
import * as searchActions from '../../../store/actions/search.action';
import { searchState } from '../../models/search/searchState.model';

@Injectable()
export class openSearchService{
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     
private result:number;
serchData:object={};

connectionCheck = new BehaviorSubject<boolean>(false);

private subjectBehav = new Subject<number>();
currentSubject =this.subjectBehav.asObservable();

private datanewString = new BehaviorSubject<string>("0");
currentSearchFiltersBool =this.datanewString.asObservable();

sidebarState = new BehaviorSubject(false);
//booleanBehaviour =this.sidebarState.asObservable();

private serchDataNew =new BehaviorSubject<object>(this.serchData);
public  currentSearchFiltersNew = this.serchDataNew.asObservable();

private data = new BehaviorSubject<allSearchFilters>({} as any);
currentSearchFilters = this.data.asObservable();

private dataNew = new BehaviorSubject<allSearchFilters>({} as any);
currentNewSearchFilters = this.data.asObservable();

private interfaceSettings = new BehaviorSubject<string>({} as any);
currentInterface = this.interfaceSettings.asObservable();

private userInnerSideBarSettings = new BehaviorSubject<string>({} as any);
currentInnerSideBarInterface = this.userInnerSideBarSettings.asObservable();

constructor(private httpclient:HttpClient, private store: Store<fromSearch.AppState>){} 
setData(data:allSearchFilters) {
  debugger;
  this.data.next(data);
 // this.dataNew.next(data);
 // this.sidebarState.next(true);
}
setStoreData(data:allSearchFilters) {
  debugger;
  const searchData=new searchState;
  searchData.category = data.CatID;
  searchData.firstChild = data.firstChild;
  searchData.levelOfSearch = data.levelOfSearch;
  searchData.secondChild = data.secondChild;
  searchData.subCategory = data.subCatID;
  searchData.thirdChild = data.thirdChild;
  //this.data.next(data); 
 this.store.dispatch(new searchActions.trySearch(searchData));
}

setDataNew(data:allSearchFilters) {
  debugger;
 // this.dataNew.next(data);
//  this.data.next(data);
//   this.datanewString.next("1");
//   this.serchDataNew.next(data);
//   this.subjectBehav.next(1);
//   this.subjectBehav.next(2);
//   this.subjectBehav.next(3);
// this.connectionCheck.next(true);
 

}

public getString() :Observable<string> {
  debugger;
  return new Observable(fn => {
    this.currentSearchFiltersBool.subscribe(fn);
  });
}

setInterface(data:string) {
  this.interfaceSettings.next(data);
}

setInnerSideBar(data:string) {
  this.userInnerSideBarSettings.next(data);
}

  submitAddComment(commentDetails:AdComments):Observable<any>{     
  ////debugger;   
  return this.httpclient.post<number>(this.baseUrl+'services/AdComments/addPostComment',JSON.stringify(commentDetails),this.httpOptions)
  .pipe();  
   
    // return this.httpclient.post<number>(this.baseUrl+'services/AdComments/addPostComment',JSON.stringify(commentDetails),this.httpOptions).subscribe(data => {           
    //  //this.result = data;
    // }),
    // catchError(error =>{
    //  // return throwError('seomething went wrong');
    // })  
    // //debugger;
    //return this.result;             
  }
 
  getSearchResult(allSearchFilter : allSearchFilters): Observable<searchResulteDTO[]>{    

   return this.httpclient.post<searchResulteDTO[]>(this.baseUrl+'services/Search/openSearch',JSON.stringify(allSearchFilter),this.httpOptions)
    .pipe(map((response : searchResulteDTO[])=> {
     // //debugger;
      return response;
    }),
    catchError(error =>{
      return throwError('seomething went wrong');
    })
    )     
  }
  getSearchResultbyCompany(allSearchFilter : allSearchFilters): Observable<searchResulteDTO[]>{    

    return this.httpclient.post<searchResulteDTO[]>(this.baseUrl+'services/Search/openSearchbyCompany',JSON.stringify(allSearchFilter),this.httpOptions)
     .pipe(map((response : searchResulteDTO[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
  getSubCategories(cateID : string): Observable<CategoryDto[]>{    

    return this.httpclient.get<CategoryDto[]>(this.baseUrl+'services/Cat/byParentId?parentId='+cateID,this.httpOptions)
     .pipe(map((response : CategoryDto[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }   
}