import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
//import Swal from 'sweetalert2/dist/sweetalert2.js';
//import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AdcompanyService } from 'src/app/shared/services/compnies/CompanyServices';
import { adDealService } from 'src/app/shared/services/deal/deal-services.service';
import { DealRequest } from 'src/app/shared/models/deal/deal';
import { NgOption } from '@ng-select/ng-select';
import { CategoryDto } from 'src/app/shared/models/category/categoryDTO.model';
import { DealProductRequest } from 'src/app/shared/models/deal/dealProductRequest';
import { Product } from 'src/app/product/show-product/Product';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Quill from 'quill';
import 'quill-mention';
import 'quill-emoji';


@Component({
  selector: 'app-add-edit-deal',
  templateUrl: './add-edit-deal.component.html',
  styleUrls: ['./add-edit-deal.component.scss'],
})
export class AddEditDealComponent implements OnInit {
  dealDetails: Product;
  ActualPriceAlert: boolean;
  StartDateAlert: boolean;
  EndDateAlert: boolean;
  imageurls = [];
  videoUrls = [];
  imageurlsNew = [];
  imageDeleteFrom: any;
  DealID: number;
  proID: any;
  proAddedBy: any;

  constructor(private dealService: adDealService,private ngxLoader: NgxUiLoaderService, private adcompanyService: AdcompanyService, private userInterface: openSearchService, private adproductService: adProductService, private formBuilder: FormBuilder,
    private mScrollbarService: MalihuScrollbarService, private notifyService: NotificationService, private route: ActivatedRoute, private router: Router) { }

  selectedCategory = '0';
  selectedSubCategory = '0';
  selectedSubCatFirstChild = '0';
  selectedSubCatSecondChild = '0';
  selectedSearchWord = '';
  subCategories: CategoryDto[];
  subCatFirstChildList: CategoryDto[];
  subCatSecondChildList: CategoryDto[];

  categoryTitle = "";
  subCategoryTitle = "";
  subToFirstTitle = "";
  subToSecondTitle = "";
  finalSelection = "";
  allselected: number = 0;
  levelOfSearch: number = 0;
  levelToShow: number = 0;

  faArrowRight = faArrowRight;

  Name = "";
  BrandName = "";
  //AddedBy = 1225;
  AddedBy;
  AddedDate;
  EditedBy;
  EditedDateTime;
  UserID;
  ComID;
  EndCatID = "";
  Price;
  TermsArea = "";
  EndDate;
  StartDate;
  ActualPrice;
  descriptionArea = "";
  ProNameAlert = false;
  ProPriceAlert = false;
  addCategoryAlert = false;
  addSubCatAlert = false;

  CurrienciesList: NgOption[] = [
    { id: 0, title: "Select Curriencies" },
    { id: 1, title: "PKR" },
    { id: 2, title: "INR" },
    { id: 3, title: "DOLLER" },
    { id: 4, title: "OR" },
    { id: 5, title: "AED" },
    { id: 6, title: "SR" },
    { id: 7, title: "Other" }
  ]

  selectedCurrencyID: number;

  public quill: Quill;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        [
          { header: 1 },
          { header: 2 },
          { header: 3 },
          { header: 4 },
          { header: 5 },
        ], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction  
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
        [{ 'font': [] }],
        [{ 'align': [] }],  
        ['clean'], // remove formatting button  
        //['link'],
        ['link', 'image', 'video'],
        [{ 'color': [] }],
      ]
    }
  };

  public editorCreated(event: Quill,description): void {
    debugger;
    this.quill = event;
    this.quill.setHtml(description);
    let toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('table', () => {
      //this.addNewTable();
    });
  }

  public editor2Created(event: Quill,description): void {
    debugger;
    this.quill = event;
    this.quill.setHtml(description);
    let toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('table', () => {
      //this.addNewTable();
    });
  }


  ngOnInit(): void {
    this.LoadCompanyData();
    this.LoadProductData();
    this.LoadDealList();
  }

  LoadProductData() {
    //console.log(this.route.snapshot.params.dealID);
    this.ngxLoader.start();
    this.dealService.GetDealbyID(this.route.snapshot.params.dealID).subscribe((result) => {
      //console.log(result);
      this.dealDetails = result;
      this.Name = this.dealDetails[0].name;
      this.Price = this.dealDetails[0].price;
      this.ActualPrice = this.dealDetails[0].priceActual;
      this.selectedCurrencyID = this.dealDetails[0].priceUnit;
      this.descriptionArea = this.dealDetails[0].description;
      this.StartDate = this.dealDetails[0].startDate;
      this.EndDate = this.dealDetails[0].endDate;
      this.TermsArea = this.dealDetails[0].terms;
      /*For categories*/
      this.categoryTitle = this.dealDetails[0].cat;
      this.subCategoryTitle = this.dealDetails[0].subCat;
      this.subToFirstTitle = this.dealDetails[0].endCatID;

      this.selectedItemsList = this.dealDetails[0].dealProductData;

      //debugger;
      if (this.dealDetails[0].dealImagesData != null) {
        for (let ingrediant of this.dealDetails[0].dealImagesData) {
          this.imageurls.push(ingrediant.imageBase64);
        }
      }
      setTimeout(() => {
        this.ngxLoader.stop();
  
      }, 1000);
    });
  }

  updatedetails() {
    ////debugger; 
    //first do the validation
    var result = this.validateForm();
    if (result === true) {

      const DealDetails = new DealRequest();
      DealDetails.DealID = this.dealDetails[0].dealID;
      DealDetails.LI_Name = this.Name;
      DealDetails.LI_Description = this.descriptionArea;
      DealDetails.LI_Terms = this.TermsArea;
      DealDetails.LI_AddedBy = this.AddedBy;
      // DealDetails.LI_AddedDate = this.AddedDate; 
      DealDetails.LI_EditedBy = this.EditedBy;
      // DealDetails.LI_EditedDateTime= this.EditedDateTime;
      DealDetails.LI_StartDate = this.StartDate;
      DealDetails.LI_EndDate = this.EndDate;
      DealDetails.LI_UserID = this.UserID;
      DealDetails.LI_ComID = this.ComID;
      // DealDetails.LI_CatID = this.selectedSubCategory;
      // DealDetails.LI_SubCatID= this.selectedSubCatFirstChild;
      //DealDetails.LI_EndCatID= this.selectedSubCatSecondChild; 
      DealDetails.LI_Price = parseInt(this.Price);
      DealDetails.LI_PriceActual = parseInt(this.ActualPrice);
      DealDetails.LI_PriceUnit = this.selectedCurrencyID;
      DealDetails.LI_IsActive = false;
      DealDetails.LI_IsDeleted = false;

    //  //debugger;
      var ProductDeal = [];
      var counter = 1;
      if (this.selectedItemsList != null) {
        if (this.selectedItemsList.length > 0) {
          for (let ProID of this.selectedItemsList) {
            this.proID = ProID.proID;
            this.proAddedBy = ProID.proAddedBy;
            var deal = {};
            deal["DealID"] = counter,
              deal["ProductID"] = this.proID,
              deal["AddedBy"] = this.proAddedBy,
              deal["AddedDate"] = "04/14/2022",
              deal["isActive"] = false,
              deal["isDeleted"] = false
            ProductDeal.push(deal);
            counter++;
          }
        }
      }

      DealDetails.postProduct = ProductDeal;
      //debugger;
     // //console.log(DealDetails);
     if(this.selectedItemsList.length != 0){
      if (DealDetails.LI_Price < DealDetails.LI_PriceActual) {
        if (DealDetails.LI_StartDate < DealDetails.LI_EndDate) {
          this.dealService.updateDealDetails(DealDetails);
          this.successNotification();
        } else {
          this.notifyService.showError('StartDate is smaller then EndDate !!', 'Worng Data');
        }
      } else {
        this.notifyService.showError('Provide Actual price is greater then price !!', 'Worng Data');
      }
    }else {
      this.notifyService.showError('Please Select Products !!', 'Worng Action');
    }
    }
    else {
      this.notifyService.showError('Provide the missing data !', 'Missing Data');
    }
  }
  // reloadComponent() {
  //   let currentUrl = this.router.url;
  //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //   this.router.onSameUrlNavigation = 'reload';
  //   this.router.navigate([currentUrl]);
  // }
  onImagesUpdate() {
    const dealDetails = new DealProductRequest();
    dealDetails.DealID = this.dealDetails[0].dealID;
    dealDetails.AddedBy = this.dealDetails[0].userID;
    var photos = [];

    var counter = 1;
    if (this.imageurls.length > 0) {
      this.imageurls.forEach(element => {
        var photo = {}; // here's your object
        photo["dealID"] = counter,
          photo["dealPhotoPath"] = element,
          photo["dealPhotoIsActive"] = true,
          photo["dealPHotoIsDeleted"] = false,
          photo["dealPhotoAddedBy"] = 0,
          photo["dealPhotoAddedDate"] = "04//2022",
          photo["dealPhotoEditedBy"] = 0,
          photo["dealPhotoEditedDate"] = "",
          photo["dealIsThumbnail"] = false
        photos.push(photo);
        counter++;
      });
    }
    dealDetails.postPhotos = photos;
    //debugger;
    //console.log(dealDetails);
    this.dealService.updateDealImages(dealDetails);
    if (dealDetails != null) {
      this.notifyService.showSuccess('Bravo!', 'Image Updated successfully !!');
    }
    else {
      //this.notifyService.showError('Provide the missing data !', 'Missing Data');
      this.notifyService.showError('Image not Update !!', 'Somthing Wrong');
    }
    //console.log(photos);
  }

  successNotification() {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 2000
    })
  }


  validateForm() {
    //debugger;
    var result = true;
    // if (typeof this.selectedCategory == 'undefined' || !this.selectedCategory || this.selectedCategory == "0") {
    //   this.addCategoryAlert = true;
    //   result = false;
    // }
    // else if (typeof this.selectedSubCategory == 'undefined' || !this.selectedSubCategory || this.selectedSubCategory == "0") {
    //   this.addCategoryAlert = false;
    //   this.addSubCatAlert = true;
    //   result = false;
    // }
    if (typeof this.Name == 'undefined' || !this.Name) {
      this.addCategoryAlert = false;
      this.addSubCatAlert = false;
      this.ProNameAlert = true;
      result = false;
    }
    else if (typeof this.Price == 'undefined' || !this.Price) {
      this.ProNameAlert = false;
      this.ProPriceAlert = true;
      result = false;
    }
    else if (typeof this.ActualPrice == 'undefined' || !this.ActualPrice) {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ActualPriceAlert = true;
      result = false;
    }
    else if (typeof this.StartDate == 'undefined' || !this.StartDate) {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ActualPriceAlert = false;
      this.StartDateAlert = true;
      result = false;
    }
    else if (typeof this.EndDate == 'undefined' || !this.EndDate) {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ActualPriceAlert = false;
      this.StartDateAlert = false;
      this.EndDateAlert = true;
      result = false;
    }
    else {
      this.ProNameAlert = false;
      this.ProPriceAlert = false;
      this.ActualPriceAlert = false;
      this.StartDateAlert = false;
      this.EndDateAlert = false;
    }
    return result;
  }


  getSubCategories(selectedLevel: number, event: any) {
    //debugger;
    let catToSearch: string = "";
    if (selectedLevel == 0) {
      catToSearch = this.selectedCategory;
      this.categoryTitle = event.target.options[event.target.options.selectedIndex].text;
      //var e = document.getElementById("ddpCat");
      //var text=e.options[e.selectedIndex].text;
      ////console.log(this.selectedCategory +"-"+this.categoryTitle);
      this.selectedSubCategory = '0';
      this.selectedSubCatFirstChild = '0';
      this.selectedSubCatSecondChild = '0';
    }
    else if (selectedLevel == 1) {
      catToSearch = this.selectedSubCategory;
      this.subCategoryTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToFirstTitle = "";
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 2) {
      catToSearch = this.selectedSubCatFirstChild;
      this.subToFirstTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 3) {
      catToSearch = this.selectedSubCatSecondChild;
      this.subToSecondTitle = event.target.options[event.target.options.selectedIndex].text;
    }

    this.userInterface.getSubCategories(catToSearch)
      .subscribe((data: CategoryDto[]) => {
        //debugger;
        if (selectedLevel == 0) {
          this.subCategories = data;
          this.levelOfSearch = 1;
          this.levelToShow = 1;
        }
        else if (selectedLevel == 1) {
          this.subCatFirstChildList = data;
          this.levelOfSearch = 2;
          if (this.subCatFirstChildList.length > 0) {
            this.levelToShow = 2;
          }
          else {
            this.levelToShow = 1;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 2) {
          this.subCatSecondChildList = data;
          this.levelOfSearch = 3;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 3;
          }
          else {
            this.levelToShow = 2;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 3) {
          this.levelOfSearch = 4;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 4;
          }
          else {
            this.levelToShow = 3;
            this.allselected = 1;
          }
        }
        ////console.log(data);
      });

    // if(this.subCategoryIDFromSearch)
    // {
    //   this.selectedSubCategory = this.subCategoryIDFromSearch;
    // }
  }

  companyList: any;
  LoadCompanyData() {
    //debugger;
    this.adcompanyService.getCompanyDetails().subscribe(data => {
      this.companyList = data;
    });
  }

  removeImageEdit(i, imagepath) {
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.ImagePath = imagepath;
  }

  removeImage(i) {
    this.imageurls.splice(i, 1);
    // this.videoUrls.splice(i, 1);
    this.imageurlsNew.splice(i, 1);
  }

  onSelectFile(event) {
    //debugger;
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      var totalFiles = this.imageurls.length + filesAmount;

      if (totalFiles > 5) {
        alert("file limit execede!");
      }
      else {
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageurls.push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }

  DealList: any = [];
  LoadDealList() {
    this.ngxLoader.start();
    this.adproductService.getProductDetails().subscribe((data: any[]) => {
      this.DealList = data;
     // //console.log(this.DealList);
     setTimeout(() => {
      this.ngxLoader.stop();

    }, 1000);
    });
  }

  selectedItemsList = [];
  onClickADD() {
    //debugger;
    this.selectedItemsList = this.DealList.filter((value, index) => {
      return value.proIsActive
    });
    ////console.log(this.selectedItemsList);
  }

}
