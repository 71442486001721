import { Component, OnInit } from '@angular/core';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { Router, ActivatedRoute } from "@angular/router";
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { FormGroup, NgForm } from '@angular/forms';
//import Swal from 'sweetalert2/dist/sweetalert2.js';
//import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { NotificationService } from '../shared/services/notification.service';
import { AdcompanyService } from '../shared/services/compnies/CompanyServices';
import { companyDetails } from '../shared/models/Company/companyDetails';
import { companyRequest } from '../shared/models/Company/companyRequest';
import { addPhotos } from '../shared/models/addPhotos.model';
import { dataLibraries } from '../shared/models/category/dataLibraries/dataLibraries.model';
import { NgOption } from '@ng-select/ng-select';
import { dataLibraryService } from '../shared/services/search/DataLibraries.service';
import { LocalStorageService } from 'angular-web-storage';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Quill from 'quill';
import 'quill-mention';
import 'quill-emoji';


@Component({
  selector: 'app-user-new-company',
  templateUrl: './user-new-company.component.html',
  styleUrls: ['./user-new-company.component.css']
})
export class UserNewCompanyComponent implements OnInit {


  public scrollbarOptions = { axis: 'y', theme: 'light' };
  selectedCountry = '1';
  selectedCity = '0';

  public quill: Quill;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        [
          { header: 1 },
          { header: 2 },
          { header: 3 },
          { header: 4 },
          { header: 5 },
        ], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction  
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
        [{ 'font': [] }],
        [{ 'align': [] }],  
        ['clean'], // remove formatting button  
        //['link'],
        ['link', 'image', 'video'],
        [{ 'color': [] }],
      ]
    },

    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
    keyboard: {
      bindings: {        
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter');
            return true;
          },
        },
      },
    },       
  };

  public editorCreated(event: Quill,descriptionArea): void {
    this.quill = event;
    this.quill.setHtml(descriptionArea);
    let toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('table', () => {
      //this.addNewTable();
    });
  }

  constructor(private adcompanyService: AdcompanyService,private dlService : dataLibraryService,public localStor: LocalStorageService,
    private userInterface: openSearchService,private ngxLoader: NgxUiLoaderService
    , private router: Router, private route: ActivatedRoute,private notifyService: NotificationService
    , private mScrollbarService: MalihuScrollbarService) {
      if(this.localStor.get('currentCountry'))
      {
        this.selectedCountry =this.localStor.get('currentCountry');
      }
      if(this.localStor.get('currentCity'))
      {
        this.selectedCity =this.localStor.get('currentCity');
      }
     }

  comNameAlert = false;
  comLicenceAlert = false;
  comNumberAlert = false;
  comEmailAlert = false;
  comIndustryAlert = false;
  comSizeAlert = false;
  comName = "";
  comIndustry = "";
  comSize;
  comCountry:number;
  comEmail = "";
  comNumber = "";
  comLicence = "";
  comWebsite = "";
  comAddress = "";
  comDescription = "";

  IndustryList: dataLibraries[]


  fillDropDowns() {
    this.IndustryList = [
      { id: "0", title: "select Industry" },
      { id: "2", title: "Industry 1" },
      { id: "1", title: "Industry 2" },
      { id: "3", title: "Industry 3" },
      { id: "4", title: "Industry 4" },
      { id: "5", title: "Industry 5+" },
    ]
  }
  companyList: any;

  LoadCompanyData() {
    //debugger;
    this.adcompanyService.getCompanyDetails().subscribe(data => {
      this.companyList = data;
    });
  }


  ngOnInit(): void {
    this.fillDropDowns();
    this.LoadCompanyData();
  }

  CountryList: NgOption[] = [
    { id: 0, title: "Select Country" },
    { id: 1, title: "United Arab" },
    { id: 2, title: "Pakistan" },
    { id: 3, title: "Saudi Arabia" },
    { id: 4, title: "KUWAIT" },
    { id: 5, title: "Oman" },
    { id: 7, title: "india" },
    { id: 8, title: "United Kingdom" },
    { id: 9, title: "United States" },
    { id: 10, title: "China" },
    { id: 11, title: "Japan" },
    { id: 12, title: "Canada" }
  ]   
  
  reloadCurrentPage() {
    window.location.reload();
   };
   selectedCurrency: string;
  onSubmit(form: NgForm) {
    ////debugger; 
    var result = this.validateCompanyForm();
    if (result == true) {
      const companyDetails = new companyRequest();
      companyDetails.mode = "add";
      companyDetails.Com_Name = this.comName;
      companyDetails.Com_Industry = this.comIndustry;
      companyDetails.Com_Size = parseInt(this.comSize);
      companyDetails.Com_Email = this.comEmail;
      companyDetails.Com_Number = this.comNumber;
      companyDetails.Com_Licence = this.comLicence;
      companyDetails.Com_Website = this.comWebsite
      companyDetails.Com_Address = this.comAddress;
      companyDetails.Com_Description = this.comDescription;
      companyDetails.Com_CountryID = this.comCountry;
      //companyDetails.Com_CountryID = parseInt(this.selectedCountry);
      companyDetails.ImageName = "";
      companyDetails.isActive = false;
      companyDetails.isDeleted = false;

      var photos = [];
      //debugger
      var counter = 1;
      if (this.imageurls.length > 0) {
        this.imageurls.forEach(element => {
          companyDetails.ImageName = element;
        })
      }

      //companyDetails.ImageName = photos;
      //debugger;
      //console.log(companyDetails);
      this.ngxLoader.start();  
      this.adcompanyService.submitCompanyDetails(companyDetails).subscribe(data => {
        if (data != 0) {
          setTimeout(() => {
            this.ngxLoader.stop();
           }, 0); 
          //this.reloadComponent();
          this.successNotification();      
        this.GotoCompanydetails();
        } 
        else {
          this.errorNotification();
        }
      });  
    }
    else {
      this.notifyService.showError('Provide the missing data !', 'Missing Data');
      //this.errorNotification();
    }

  }

  GotoCompanydetails() {
   // this.userInterface.setInterface("0");
   // this.router.navigate(['/usercompany'], { relativeTo: this.route });
    this.router.navigate(['/usercompany']);
  }

  successNotification() {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 2000
    })
  }

  errorNotification() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Provide the missing data ‼️‼️</a>'
    })
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  validateCompanyForm() {
    //debugger;
    var result = true;
    if (typeof this.comName == 'undefined' || !this.comName || this.comName == "0") {
      this.comNameAlert = true;
      result = false;
    }
    else if (typeof this.comIndustry == 'undefined' || !this.comIndustry || this.comIndustry == "0") {
      this.comNameAlert = false;
      this.comIndustryAlert = true;
      result = false;
    }
    else if (typeof this.comSize == 'undefined' || !this.comSize || this.comSize == "0") {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comSizeAlert = true;
      result = false;
    }
    else if (typeof this.comEmail == 'undefined' || !this.comEmail || this.comEmail == "0") {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comSizeAlert = false;
      this.comEmailAlert = true;
      result = false;
    }
    else if (typeof this.comNumber == 'undefined' || !this.comNumber || this.comNumber == "0") {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comNumberAlert = false;
      this.comEmailAlert = false;
      this.comNumberAlert = true;
      result = false;
    }
    else if (typeof this.comLicence == 'undefined' || !this.comLicence || this.comLicence == "0") {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comNumberAlert = false;
      this.comEmailAlert = false;
      this.comNumberAlert = false;
      this.comLicenceAlert = true;
      result = false;
    }
    else {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comNumberAlert = false;
      this.comEmailAlert = false;
      this.comNumberAlert = false;
      this.comLicenceAlert = false;
    }
    return result;
  }


  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

  imageDeleteFrom: FormGroup;
  postImages: addPhotos[];
  imageurls = [];
  imageurlsNew = [];
  base64String: string;
  name: string;
  imagePath: string;

  removeImageEdit(i, imagepath) {
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.ImagePath = imagepath;
  }

  removeImage(i) {
    this.imageurls.splice(i, 1);
    this.imageurlsNew.splice(i, 1);
  }

  // onSelectFile(event) {
  //   if (event.target.files && event.target.files[0]) {
  //     var filesAmount = event.target.files.length;
  //     //var proPhotos: addPhotos[];
  //     if (filesAmount > 5) {
  //       alert("file limit execede!");
  //     }
  //     else {
  //       for (let i = 0; i < filesAmount; i++) {
  //         var reader = new FileReader();
  //         reader.onload = (event: any) => {
  //           this.imageurls.push({ base64String: event.target.result, });
  //           this.imageurlsNew.push(event.target.result);
  //         }
  //         reader.readAsDataURL(event.target.files[i]);
  //       }
  //     }
  //   }
  // }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      var totalFiles =  this.imageurls.length + filesAmount ;

      if(totalFiles > 1)
      {
        alert("file limit execede!");
      }            
      else {
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
           // this.imageurls.push({ base64String: event.target.result});
            this.imageurls.push(event.target.result);
            //this.imageurlsNew.push({base64String: event.target.result});
            //this.imageurlsNew.push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }


}
