import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { adds } from 'src/app/shared/models/Adds/adds.model';
import { HttpClient } from '@angular/common/http';
import { addsService } from 'src/app/shared/services/addServices';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import { addDetailsService } from 'src/app/shared/services/addDetails.service';
import { searchResulteDTO } from 'src/app/shared/models/search/openSearch.model';
import { Router } from '@angular/router';
//import { Router } from 'express';
import { allSearchFilters } from 'src/app/shared/models/requestsModel/search/searchInput.model';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { LocalStorageService } from 'angular-web-storage';
import { dataLibrary } from 'src/app/shared/models/category/dataLibraries/dataLibrary.model';
import { dataLibraryService } from 'src/app/shared/services/search/DataLibraries.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Product } from 'src/app/product/show-product/Product';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {Meta , Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/auth/auth.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.state';

//declare function CountryAlert():any;
declare const CountryAlert:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  //main:HTMLScriptElement;
 //@ViewChild('modalselectcurrency', {static : true}) modalselectcurrency:ElementRef;
  topFifteenAdds: any[];
  topDeals:adds[];
  faCoffee = faCoffee;
  faHeart = faHeart;
  faBookmark=faBookmark;
  selectedCountry = '0';
  selectedCity = '0';
  selectedCityName='';
  citySelection : dataLibrary[]=[];
  ProductList: any=[];
  ProductListFiltered: any=[];
  TotalLength:any;
  page:number=1;
  PostList: any[];
  PropertyList: any[];
  JobList: any[];
  PropertySaleList: any[];
  JobHiringList: any[];
  showPhone: boolean = false;
  title = 'World’s Largest Marketplace - MyKnocks';
  @Input() nameSelected :string;
  userName: string;
 slideIndex = 1;
 cityNameToShow: string;

  constructor(private adServices: addDetailsService,private dlService : dataLibraryService, private ngxLoader: NgxUiLoaderService,
    public localStor: LocalStorageService,private operSearch: openSearchService,private router: Router,
    private adproductService: adProductService,private http: HttpClient,private addService: addsService,
    private titleService: Title, private metaService: Meta, private store: Store<fromApp.AppState>) {
    if(this.localStor.get('currentCountry'))
    {
      this.selectedCountry =this.localStor.get('currentCountry');
    }
    if(this.localStor.get('currentCity'))
    {
      this.selectedCity =this.localStor.get('currentCity');
    }
    //this.getCities();
    if(this.selectedCountry == null || this.selectedCountry == '0')
    {
      CountryAlert();
    }
   }

  ngOnInit(): void {    
   // this.modalselectcurrency.nativeElement.click();
   //debugger;
   
   
    
    this.getTopAdds();
    this.getTopDeals();
    this.LoadAutosData();
    this.LoadPropertyforRentData();
    this.LoadPropertyforSaleData();
    this.LoadJobData();
    this.LoadJobforHiring();
    this.LoadProductData();
    this.loadCountryName();
    this.LoadProductDataFiltered();
  }

  addToCart(product: Product) {
    debugger;
    product.proQuantity = 1;
    this.adproductService.addToCart(product);
  }
  productFavourite(product: Product) {
    this.adproductService.FavouriteProduct(product);
  }
  addFavourite(ads: adds) {
    this.adproductService.FavouriteAds(ads);
  }
  DealFavourite(ads: adds) {
    this.adproductService.FavouriteDeals(ads);
  }
  
  loadCountryName()
  {
    this.getCities();

    // console.log("inside get city name  :"+this.selectedCity);
    // console.log(this.citySelection[0].name);
    // if(this.citySelection.length>0)
    // {
    //   const selectedIds = this.citySelection.find(i => i.id === this.selectedCity);
    // }
  }

  getCities(){  
    
    // this.dataService.getUsers().pipe(
    //   map(data => data.items) // this is now an Observable<User[]>
    // );

    this.dlService.getCitiesByCountry(this.selectedCountry)
        .subscribe((data:dataLibrary[]) => {
          this.citySelection = data;
          this.TotalLength = data.length;
          console.log("inside get city name  :"+this.selectedCity);
          this.cityNameToShow = this.citySelection.find(i => i.id === this.selectedCity).name;
           //= selectedIds.name;         
        });               
  }
  LoadProductData() {
    ////debugger;
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopProductaDetails(con).subscribe((data:any[]) => {
      this.ProductList = data;
      this.TotalLength = data.length;
      //console.log(this.ProductList);
    });
  }
  LoadProductDataFiltered() {
    ////debugger;
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopProductaDetailsFiltered(con).subscribe((data:any[]) => {
      this.ProductListFiltered = data;
      this.TotalLength = data.length;
      //console.log(this.ProductList);
    });
  }
  
  stringJson: any;
  stringObject: any;
  LoadAutosData() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopAutosAdsDetails(con).subscribe((data: any[]) => {
      this.PostList = data;
      //console.log(this.PostList)
    });
  }
  LoadPropertyforRentData() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopPropertyRentDetails(con).subscribe((data:any[]) => {
      this.PropertyList = data;
      this.TotalLength = data.length;
      ////console.log(this.PropertyList);
    });
  }
  LoadPropertyforSaleData() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopPropertySaleDetails(con).subscribe((data:any[]) => {
      this.PropertySaleList = data;
      this.TotalLength = data.length;
    //  //console.log(this.PropertySaleList);
    });
  }
  LoadJobData() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopJobforWanted(con).subscribe((data:any[]) => {
      this.JobList = data;
      this.TotalLength = data.length;
     // //console.log(this.JobList);
    });
  }
  LoadJobforHiring() {
    var con  = parseInt(this.selectedCountry);
    this.adServices.gettopJobforHiring(con).subscribe((data:any[]) => {
      this.JobHiringList = data;
      this.TotalLength = data.length;
     // //console.log(this.JobHiringList);
    });
  }
  itemList = [];
  selectedItems = [];
  settings = {};

  SearchSendNew(catNameReceiver: string , catIdReceiver:string , subCat:string, subCatNameReceiver:string){
    debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;     

    catName = catNameReceiver;// "Classified"; 
    catID = catIdReceiver; // "d7b411fb-1c21-4f0a-88f7-34d6b789f67d";
    subCatName =subCatNameReceiver;
    subCatID= subCat;

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-');    
    requestData.searchSender = "1";
    //this.operSearch.setStoreData(requestData); 

    this.localStor.set('searchPattern', JSON.stringify(requestData));  

    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_self')

  }

  ClassifiedsearchSend(){
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;     

    catName = "Classified"; 
    catID = "d7b411fb-1c21-4f0a-88f7-34d6b789f67d";
    subCatName ="";
    subCatID= "0";

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-');
    this.operSearch.setData(requestData); 
   // this.router.navigate(['/search',newCatName,newSubCatName]);

    let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
    window.open(url.toString(), '_self')
  }
  AutosearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;     

    catName = "Autos"; 
    catID = "5bf243b1-2be4-4dda-93a0-cf9e06209e0f";
    subCatName ="";
    subCatID= "0";

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-');
    this.operSearch.setData(requestData); 
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  PropertyRentsearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Property-For-Rent"; 
    catID = "0372dc00-f9cb-4cb5-8b95-901ea8a02efa";
    subCatName ="";
    subCatID= "0";
    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  PropertySalesearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Property-For-Sale"; 
    catID = "27bbb528-5666-4429-b153-7d6441c0efa1";
    subCatName ="";
    subCatID= "0";
    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  JobWantsearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Jobs"; 
    catID = "3c064386-a300-4f90-8e18-f6da9f83aa1c";
    subCatName ="I-want-Job";
    subCatID= "A07D5BE2-BC59-4086-82F5-1E1DF97EC87A";     

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  JobHiresearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Jobs"; 
    catID = "3c064386-a300-4f90-8e18-f6da9f83aa1c";
    subCatName ="I-am-Hiring";
    subCatID= "967CEED7-54C0-488E-8AA8-69E6045E6C05";        

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "2";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  ProductsearchSend(){
    debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
      catName = "Products"; 
      catID = "C4E1F606-8105-4666-A8BC-C6657730A0DF"; 
      subCatName = "";
      subCatID= "0";   
      
    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    requestData.typeIdentity ="2";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 

    if(this.router.url.includes('search'))
     {
      requestData.searchSender = "1";
      this.localStor.set('searchPattern', JSON.stringify(requestData)); 
      //this.operSearch.setDataNew(requestData);
      let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
      window.open(url.toString(), '_self')
     }
     else
     {
      requestData.searchSender = "1";
      this.localStor.set('searchPattern', JSON.stringify(requestData)); 
      let url = this.router.createUrlTree(['/search',newCatName,newSubCatName])
      window.open(url.toString(), '_self')      
     }


   // this.operSearch.setData(requestData);
    //this.router.navigate(['/search',newCatName,newSubCatName]);
  }
  DealsearchSend(){
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;
    catName = "Items";  
    catID = "c4e1f606-8105-4666-a8bc-c6657730a0df"; 
    subCatName = "?q=sad&_keyori=ss&from=input&spm=a2a0e.pdp.search.go";
    subCatID= "0"; 

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    requestData.typeIdentity ="1";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-'); 
    this.operSearch.setData(requestData);
    this.router.navigate(['/search',newCatName,newSubCatName]);
  }

  ClassSearchSend()
  {
    //debugger;
    const requestData = new allSearchFilters();
    
    let catName : string;
    let subCatName : string ;
    let catID: string ;
    let subCatID: string ;         

    catName = "Classified"; 
    catID = "d7b411fb-1c21-4f0a-88f7-34d6b789f67d";
    subCatName ="";
    subCatID= "0";

    requestData.CatID =catID;
    requestData.subCatID =subCatID;
    requestData.levelOfSearch = "1";
    requestData.searchWord = "";
    var newCatName = catName.replace(/ /g,'-');
    var newSubCatName = subCatName.replace(/ /g,'-');
    this.operSearch.setData(requestData); 
    this.router.navigate(['/search','Classified',newSubCatName]);
  }

  getTopAdds() {
   // this.ngxLoader.start();
    var con  = parseInt(this.selectedCountry);
    this.addService.getTopAdds(con)
    .subscribe((data:adds[]) => {
      ////console.log(data);
      this.topFifteenAdds = data;
      //console.log(this.topFifteenAdds + "Classified");
      // setTimeout(() => {
      //   this.ngxLoader.stop();

      // }, 1000);    
    });
  }
  getTopDeals() {
    //debugger;
    var con  = parseInt(this.selectedCountry);
    this.addService.getTopDeals(con)
    .subscribe((data:adds[]) => {
      ////console.log(data);
      this.topDeals = data;
      //console.log(this.topDeals);     
    });
  }
 
  toggleStyle: boolean = false;

  toggle(){
    //console.log(this.toggleStyle);
    this.toggleStyle = !this.toggleStyle;
  }
  ongetDetails(itemDetails :searchResulteDTO)
  {
    //debugger;
    let url = this.router.createUrlTree(['/details',itemDetails.cat,itemDetails.add_ID])
    window.open(url.toString(), '_self')
  }
  
  showPhones:any
  showPhoneNumber(items) {
    //debugger;
    if(items){
      if (this.showPhone == false) {
        this.showPhones = items;
      }
      else {
        this.showPhone = true;
      }
  }
  }

  mySlideImages = [{url:'../assets/images/catImages/Beats.jpg',categoryName:"Classified",category:'Accessories',catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"D1FAF65C-41D6-46DF-A27E-D610447F3E1B"},
  {url:'../assets/images/catImages/toy.jpg',category:'Baby Items',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"D0242819-FB2B-47C5-B76A-F8B6AE1A0ABE"},
  {url: '../assets/images/catImages/Books.jpg',category:'Books',categoryName:"Classified", catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"3B3A5EC7-4CB1-4FFB-85C8-13E849427C90"},
  {url:'../assets/images/catImages/CameraClass.jpg',category:'Camera',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"E07C2000-B995-440B-86B0-077F01B6089E"},
  {url:'../assets/images/catImages/Clothings.jpg',category:'Clothing',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"D0242819-FB2B-47C5-B76A-F8B6AE1A0ABE"},
  {url:'../assets/images/catImages/Laptops.jpg',category:'Computers',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"5F29FF53-457B-4BA3-9CD0-A0A02BFBB5CD"},
  {url:'../assets/images/catImages/HomeAppliances.jpg',category:'Electronics',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"DB70A015-7A8C-4ADB-87FD-5DE91852CD6C"},
  {url:'../assets/images/catImages/Furnitures.jpg',category:'Furniture',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"CBE61317-40BB-483B-BF40-F2A781BE701D"},
  {url: '../assets/images/catImages/GamesNew.jpg',category:'Gaming',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"E4E3813A-E469-4FA7-AA8D-C45F1733691D"},
  {url:'../assets/images/catImages/Sports.jpg',category:'Sports',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"D8613F11-C947-4E1A-A9FB-2E1CB17C6039"},
  {url:'../assets/images/catImages/Fashion.jpg',category:'Jewellery',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"D1FAF65C-41D6-46DF-A27E-D610447F3E1B"},
  {url: '../assets/images/catImages/PetsNew.jpg',category:'Pets',categoryName:"Classified",catid:"d7b411fb-1c21-4f0a-88f7-34d6b789f67d", subCatId:"1D36E7EB-89AC-4AB6-9A4C-94C24CDDDAB7"},
 ];
  
//   SlideOptions = { items: 1, dots: true, nav: false };  
//   CarouselOptions = { items: 3, dots: true, nav: true };

  //mySlideImages = [1,2,3].map((i)=> `https://picsum.photos/640/480?image=${i}`);
  //myCarouselImages =[1,2,3,4,5,6].map((i)=>`https://picsum.photos/640/480?image=${i}`);
 // mySlideOptions={items: 1, dots: true, nav: false};

    limit: number = 10; // <==== Edit this number to limit API results
    customOptions: OwlOptions = {
      loop: true,
      autoplay: true,
      center: false,
      dots: false,
      autoHeight: true,
      autoWidth: true,      
      responsive: {
        0: {
            items: 1,
            nav: true
        },
        700: {
            items: 7,
            nav: true
        },
        1000: {
            items: 9,
            nav: true,
            loop: true
        }
      }
    }



  mySlideOptions= {
          items: 1,
         nav: true,
         dots:false,
          loop: true,
            margin: 0,
            rtl: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                700: {
                    items: 4,
                    nav: true
                },
                1000: {
                    items: 6,
                    nav: true,
                    loop: true
                }
  }
};

  //myCarouselOptions={items: 3, dots: true, nav: true};

  

}
