<!-- Table -->
<div class="container-xxl center" style="width: 90%;">
    <table class="table table-striped table-hover">
        <thead>
            <tr style="background: #00cc67;color: white;">
                <th></th>
                <th>Title</th>
                <th>Category</th>
                <th>Price</th>
                <th>Date</th>
                <th>Actions 🎬</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let items of PostList | paginate: { itemsPerPage: 10, currentPage: page,totalItems:TotalLength }">
                <td><img class="img-fluid circular_image" *ngIf="items.addImageBase != null" [src]="items.addImageBase" /><img class="img-fluid circular_image" src="../../assets/images/ads/no_image.jpg" *ngIf="items.addImageBase == null" /></td>
                <!-- src="../../assets/images/angular.png" -->
                <td>{{items.title}}</td>
                <td>{{items.cat}} ⇛ {{items.subCat}}</td>
                <td>{{items.price}} ⇛ {{items.currencyName}}</td>
                <td>{{items.datePosted}}</td>
                <td>
                    <!-- routerLink="/update/{{items.proID}}"       (click)="editClick(items)" -->
                    <a routerLink="/useredit/{{items.cat}}/{{items.addID}}">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                    </a>
                </td>
            </tr>
            <tr *ngIf="!PostList || PostList.length==0">
                <td colspan="6">No data found</td>
            </tr>
        </tbody>
    </table>
    <pagination-controls class="my-pagination text-center" (pageChange)="page = $event">
    </pagination-controls>
</div>