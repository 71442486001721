<p>list of companies!</p>

<div class="row">
    <div class="col-xs-12">
        <ul class="list-group">
            <li><a class="list-group-item" style="cursor:pointer" *ngFor="let company of topCompanies; let i = index">
                {{company.licenseNumber}} / {{company.nameEnglish}} / {{company.nameArabic}} 
                }</a>
            </li>
        </ul>
    </div>
</div>