import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProComments } from '../../models/comments/Pro-comments.model';
import { ProlikeDislikeDetails } from '../../models/Company/likeDislikeDetails.model';
import { DealViewCount } from '../../models/likesDislikes/ViewCount-Deal';
import { ProViewCount } from '../../models/likesDislikes/ViewCount-pro';

@Injectable({
  providedIn: 'root'
})
export class ActionserviceServices {

  private baseUrl = environment.baseUrl;  
  private httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'         
      })
    };     
private result:number;
private resultLike:string;
constructor(private httpclient:HttpClient){}

    submitLikeDislike(likeDislike:ProlikeDislikeDetails){  
      // //debugger;
      return  this.httpclient.post<string>(this.baseUrl+'services/DealLikeDislike/likeDislikePost',JSON.stringify(likeDislike),this.httpOptions)
      .pipe();                                   
    }

    submitview(viewDetail:DealViewCount){     
      //debugger;
      this.httpclient.post<number>(this.baseUrl+'services/DealViewCount/AddViewbyID',JSON.stringify(viewDetail),this.httpOptions).subscribe(data => {
        //debugger; 
        //console.log(data);           
    return this.result = data;
      })               
    }
    getAddLikeDislikeCount(likeDislike: ProlikeDislikeDetails): Observable<any>{    

      return this.httpclient.post<number>(this.baseUrl+'services/DealLikeDislike/getlikeDislikeCountByPost',JSON.stringify(likeDislike),this.httpOptions)
       .pipe(map((response : number)=> {
       //  //debugger;
         return response;
       })
       )     
     }

    getAddComments(DealID : number):Observable<any[]>{
      //debugger;
      return this.httpclient.get<any>(this.baseUrl+'services/DealComments/byID?DealID='+DealID,this.httpOptions);
    }
    
    submitAddComment(commentDetails:ProComments):Observable<number>{     
    ////debugger;   
    return this.httpclient.post<number>(this.baseUrl+'services/DealComments/addPostComment',JSON.stringify(commentDetails),this.httpOptions)
    .pipe(
      map((response : number)=> {
      return response;
    })
    );
    
  }
    
}
