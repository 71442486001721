import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OrderService } from '../shared/services/compnies/OrderServices';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { openSearchService } from '../shared/services/search/openSearch.service';
import { NgForm } from '@angular/forms';
import { OrderRequest } from '../shared/models/Company/Orders/OrderRequest';

@Component({
  selector: 'app-user-orders-card-details',
  templateUrl: './user-orders-card-details.component.html',
  styleUrls: ['./user-orders-card-details.component.css']
})
export class UserOrdersCardDetailsComponent implements OnInit {

  constructor(private userInterface: openSearchService,private ngxLoader: NgxUiLoaderService,private orderService: OrderService,private router: Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.calculateOrderAmmount();
    this.LoadUserOrders();
  }
  OrderList: any=[];
  TotalLength:any;
  page:number=1;
  cardCvvAlert: boolean = false;
  cardNumberAlert: boolean = false;
  cardNumberDigitAlert: boolean = false;
  ExpiryMonthAlert: boolean = false;
  cardNumber:number=null;
  ExpiryMonth:number=0;
  ExpiryYear:number=0;
  cardCvv:number=0;
  totalValue = 0;
  orderDetails: OrderRequest;
  

  LoadUserOrders() {
    // //debugger;
    // this.ngxLoader.start(); 
    // this.orderService.getOrdersList().subscribe((data:any[]) => {
    //   this.OrderList = data;
    //   console.log(this.OrderList[0])
    //   setTimeout(() => {
    //     this.ngxLoader.stop();
    //    }, 0); 
    //   this.TotalLength = data.length;
    // });
  }
  calculateOrderAmmount(){  
    //debugger;
    this.orderDetails = this.orderService.getLocalOrders();
    if(this.orderDetails.ProductOrder != null)
    {
      this.totalValue =this.orderDetails.ProductOrder.OrderTotal;
    }  
    else
    {

    }  
  }
  goToOrderDetails(orderId) {  
    //debugger;  
    this.router.navigate(['../user/orders/'+orderId]);
  }
  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home']);
  }

  onUAECardSubmit(form : NgForm)
  {

  }

}
