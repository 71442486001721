import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-contect-us',
  templateUrl: './contect-us.component.html',
  styleUrls: ['./contect-us.component.css']
})
export class ContectUsComponent implements OnInit {

  constructor(private notifyService: NotificationService) { }

  ngOnInit(): void {
  }
  shownotify(){
    this.notifyService.showSuccess('Successfully Register !!', 'Thanks');
  }
}
