export class AdComments{
    public commentID: number;
    public commentDesc : string ;  
    public addedBy: number;
    public addedDate : string ;  
    public editedBy : number ;  
    public editedDate : string ;  
    public  postID: string ;  
    public  icludeCV: boolean ;  

    public CatName: string ; 
}