import { jobFacilities } from "./jobFacilities.model";

export class jobsWanted{        
    public jobID: number;
    public jobAddID : string ;  
    public jobExpID : number ;   
    public jobEduID : number ;   
    public jobCommitmentID : number ;   
    public jobCatID : string ;   
    public jobSubCatID : string ;   
    public jobCompensation: number;
    public jobShiftID: number;
    public jobFacilities: jobFacilities[];
}