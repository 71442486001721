export class searchState{    
    public levelOfSearch : string ;      
    //common filters  
    public category: string;
    public subCategory : string ;  
    public firstChild: string;
    public secondChild : string ;  
    public thirdChild: string;
}    



