import { Component, OnInit } from '@angular/core';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { Router, ActivatedRoute } from "@angular/router";
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { AdcompanyService } from 'src/app/shared/services/compnies/CompanyServices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { adProductService } from 'src/app/shared/services/compnies/ProductServices';
import { addDetailsService } from 'src/app/shared/services/addDetails.service';
import { adDealService } from 'src/app/shared/services/deal/deal-services.service';
import { searchResulteDTO } from 'src/app/shared/models/search/openSearch.model';
import { RegisterserviceService } from 'src/app/shared/registerservice.service';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})
export class UserHomeComponent implements OnInit {

  public scrollbarOptions = { axis: 'y', theme: 'light' };
  List: any;
  PostList: any=[];
  ProductList: any=[];
  DealList: any=[];
  TotalLength:any;
  page:number=1;
  showVerifyControl :number=0;
  userWhatsAppCode;
  whatsAppNumber;
numberValidated:number=0;

  constructor( private dealService: adDealService,private adService: addDetailsService,private adcompanyService: AdcompanyService,private userInterface: openSearchService,private router: Router, private route:ActivatedRoute
    ,private mScrollbarService: MalihuScrollbarService,private ngxLoader: NgxUiLoaderService,public adproductService: adProductService,
    private regUserService: RegisterserviceService, private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.LoadTotalCount();
    this.LoadPostData();
    this.LoadProductData();
    this.LoadDealList();
    //this.whatsAppNumber='Enter WhatsApp # along country code';
  }

backtoMain()
{
  this.userInterface.setInterface("0");
  this.router.navigate(['../home'],{relativeTo : this.route });
}

LoadTotalCount() {
  //debugger;
  this.ngxLoader.start();
  this.adcompanyService.getTotalByUser().subscribe(data => {
    this.List = data;
    this.numberValidated = this.List[0].whatsAppVerified;
    setTimeout(() => {
      this.ngxLoader.stop();
     }, 1000); 
  });
}

LoadPostData() {
  //debugger;
 // this.ngxLoader.start(); 
  this.adService.getadsDetails().subscribe((data:any[]) => {
    this.PostList = data;
    // setTimeout(() => {
    //   this.ngxLoader.stop();
    //  }, 0); 
    this.TotalLength = data.length;
  });
}
LoadProductData() {
  this.adproductService.getProductDetails().subscribe((data:any[]) => {
    this.ProductList = data;
  });
}
LoadDealList() {
  this.dealService.getDealList().subscribe((data:any[]) => {
    this.DealList = data;
  });
}

onWhtsAppCorfirm()
{
  //debugger;
  if(this.whatsAppNumber != '' && this.whatsAppNumber != undefined)
  {
    //debugger;
    this.regUserService.verifyWhtsApp(this.whatsAppNumber).subscribe((data:number) => {
      var response = data;
      console.log(response);
      if(response == 1)
      {
        this.showVerifyControl =1;
        this.successNotification("6 Digit Verfication code sent to your mobile Number, Please check.");
      }
      else
      {
        this.errorNotification("Something went wrong.Please provide correct WhatsApp #.");       
        this.showVerifyControl =0;
      }      
    });
  }
  else
  {
    this.errorNotification("Something went wrong.Please provide correct WhatsApp #.");   
    this.showVerifyControl =0;
  }
  
}

onWhtsAppVerify()
{
  if(this.userWhatsAppCode != ''  && this.userWhatsAppCode != undefined )
  {
    this.regUserService.onWhtsAppVerify(this.userWhatsAppCode).subscribe((data:number) => {
      var response = data;
      console.log(response);
      if(response == 1)
      {
        this.successNotification("Thanks alot, Your whatsApp # verified successfully.");
        this.numberValidated = 1;
        this.showVerifyControl == 0;
        this.userWhatsAppCode ='';
        this.whatsAppNumber ='';
      }
      else
      {
        this.showVerifyControl == 1;
        this.numberValidated =0;
        this.errorNotification("Something went wrong.Please provide Correct OTP.");
      }      
    });
  }
  else
  {
    this.errorNotification("Something went wrong.Please provide Correct 6 Digit OTP.");
    this.numberValidated =0;
  }
  
}

ongetDetails(itemCat :string, itemId: string)
  {
    let url = this.router.createUrlTree(['/details',itemCat,itemId])
    window.open(url.toString(), '_self')
  }

  ongetProductDetails(itemCat :string, itemId: string)
  {
    let url = this.router.createUrlTree(['/details',itemCat,itemId])
    window.open(url.toString(), '_self')
  }
 successNotification(message:string) {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'Sucess',
      text: message,
      showConfirmButton: false,
      timer: 2500
    })
  }
  errorNotification(message:string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
      footer: 'for example 00971556565656 ',
      //footer: '<a href="">Provide the missing data ‼️‼️</a>',
      timer: 2500,
      showConfirmButton: false,
    })
  }
}
