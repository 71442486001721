<main id="tg-main" class="tg-main tg-haslayout">
    <!--************************************
					Contact Us Start
			*************************************-->
			<div class="container">
				<div class="row">
					<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<div id="tg-content" class="tg-content">
							<div class="tg-sectionhead tg-sectionheadvtwo">
								<div class="tg-title">
									<h2>PRIVACY POLICY</h2>
								</div>
								<div class="tg-description">
									<p>Last updated January 31, 2024</p>
								</div>
							</div>
							
							<div class="tg-faqarea" style="height: auto!important;">
								<div class="tg-verticalscrollbar tg-dashboardscrollbar" style="max-height: none!important;">
									<div id="tg-themecollapse" class="tg-themecollapse tg-themecollapsefaq" >
										<div class="tg-collaptabpane">
											<h3 class="open" style="padding: 20px;"><b>Feel Free to contact us at : enquries@myknocks.com</b></h3><br />
											<div class="tg-collapsecontent">
												<div class="tg-description"><p>													
													We know that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly. 
                                                                                                        This Privacy Notice describes how MyKnocks.com For E-Commerce and its affiliates collect and process your personal information through MyKnocks websites,
                                                                                                         devices, products, services, online stores, and applications that reference this Privacy Notice (together "MyKnocks Services"). By using Myknocks Services,
                                                                                                         you are consenting to the practices described in this Privacy Notice.
												</p></div>
											</div>
                                                                                        <div class="tg-collapsecontent">
                                                                                        <h3 class="open" style="padding: 20px;"><b>What Personal Information About Customers Does MyKnocks Collect?</b></h3><br />
                                                                                        <div class="tg-description"><p>
                                                                                                We collect your personal information in order to provide and continually improve our products and services. Here are the types of personal information we collect:

                                                                                                <br><b>Information You Give Us:</b><br>
                                                                                                 We receive and store any information you provide in relation to MyKnocks Services. 
                                                                                                Click here to see examples of what we collect. 
                                                                                                You can choose not to provide certain information, but then you might not be able to take advantage of many of our Myknocks Services.
                                                                                                <br><b>Automatic Information: </b><br>
                                                                                                We automatically collect and store certain types of information about your use of Myknocks Services, including information about your interaction with content and services available through Myknocks Services. Like many websites, we use " cookies " and other unique identifiers, and we obtain certain types of information when your web browser or device accesses Myknocks Services and other content served by or on behalf of Myknocks on other websites. Click here to see examples of what we collect.
                                                                                                Information from Other Sources: We might receive information about you from other sources such as updated delivery and address information from our carriers, which we use to correct our records and deliver your next purchase more easily. Click here to see additional examples of the information we receive.   
                                                                                                <br><b>Location Information: </b><br>
                                                                                                "MyKnocks app collects location data to enable location feature only when the application is open and user is about to submit an add”
                                                                                                “User location data is also used to provide ads/support advertising/support ads.”
                                                                                                MyKnocks will detect the user location information which later on will be displayed on add details page along with map for the customers to understand the add location.
                                                                                                Myknocks will not get your location information in any other case and will not store it.
                                                                                        </p></div>

                                                                                        </div>

                                                                                        <div class="tg-collapsecontent">
                                                                                                <h3 class="open" style="padding: 20px;"><b>For What Purposes Does MyKnocks Use Your Personal Information?</b></h3><br />
                                                                                                <div class="tg-description"><p>
                                                                                                        We use your personal information to operate, provide, develop, and improve the products and services that we offer our customers. These purposes include:
        
                                                                                                        <br><b>Information You Give Us:</b><br>
                                                                                                        <br><b>Purchase and delivery of products and services.</b><br> We use your personal information to take and handle orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers.
                                                                                                        <br><b>Provide, troubleshoot, and improve Services.</b><br> We use your personal information to provide functionality, analyze performance, fix errors, and improve the usability and effectiveness of the Myknocks Services.
                                                                                                        <br><b>Recommendations and personalization.</b><br> We use your personal information to recommend features, products, and services that might be of interest to you, identify your preferences, and personalize your experience with Myknocks Services.
                                                                                                        <br><b>Provide voice, image and camera services.</b><br> When you use our voice, image and camera services, we use your voice input, images, videos, and other personal information to respond to your requests, provide the requested service to you, and improve our services. 
                                                                                                        <br><b>Comply with legal obligations.</b><br> In certain cases, we collect and use your personal information to comply with laws. For instance, we collect from sellers information regarding place of establishment.
                                                                                                        <br><b>Communicate with you.</b><br> We use your personal information to communicate with you in relation to Myknocks Services via different channels (e.g., by phone, e-mail, chat).
                                                                                                        <br><b>Advertising.</b><br> We use your personal information to display interest-based ads for features, products, and services that might be of interest to you. We do not use information that personally identifies you to display interest-based ads.
                                                                                                        <br><b>Fraud Prevention and Credit Risks.</b><br> We use personal information to prevent and detect fraud and abuse in order to protect the security of our customers
                                                                                                        
                                                                                                </p></div>
                                                                                                
                                                                                                </div>

                                                                                                <div class="tg-collapsecontent">
                                                                                                        <h3 class="open" style="padding: 20px;"><b>Does Myknocks Share Your Personal Information?</b></h3><br />
                                                                                                        <div class="tg-description"><p>
                                                                                                                Information about our customers is an important part of our business, and we are not in the business of selling our customers' personal information to others. Myknocks does not shares customers' personal information to anyone.                
                                                                                                                </div>                
                                                                                                        </div>

                                                                                                        <div class="tg-collapsecontent">
                                                                                                                <h3 class="open" style="padding: 20px;"><b>How Secure Is Information About Me?</b></h3><br />
                                                                                                                <div class="tg-description"><p>
                                                                                                                        We design our systems with your security and privacy in mind.<br>
                                                                                                                •	We work to protect the security of your personal information during transmission by using encryption protocols and software.<br>
                                                                                                                •	We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling credit card data.<br>
                                                                                                                •	We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of personal customer information. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you.<br>
                                                                                                                •	Our devices offer security features to protect them against unauthorized access and loss of data. <br>
                                                                                                                •	It is important for you to protect against unauthorized access to your password and to your computers, devices, and applications. Be sure to sign off when finished using a shared computer.<br>

                                                                                                                        </div>                
                                                                                                                </div>

                                                                                                                <div class="tg-collapsecontent">
                                                                                                                        <h3 class="open" style="padding: 20px;"><b>Examples of Information Collected</b></h3><br />
                                                                                                                        <div class="tg-description"><p>
                                                                                                                        •	Provide your location details while posting any Add or Product <br>
                                                                                                                        •	We need your location details to exactly find your location to set it on google maps so this functionality will enable you to select your location automatically while posting any add<br>
                                                                                                                        •       Information You Give Us When You Use MyKnocks Services<br>
                                                                                                                        •	You provide information to us when you:<br>
                                                                                                                        •	search or shop for products or services in our stores<br>
                                                                                                                        •	add or remove an item from your cart, or place an order through or use MyKnocks Services<br>
                                                                                                                        •	provide information in Your Account (and you might have more than one if you have used more than one e-mail address or mobile number when shopping with us) 
                                                                                                                        •	upload your contacts<br>
                                                                                                                        •	configure your settings on, provide data access permissions for, or interact with an Myknocks service<br>
                                                                                                                        •	provide information in your Company account<br>
                                                                                                                        •	offer your products or services on Myknocks Services<br>
                                                                                                                        •	communicate with us by phone, e-mail, or otherwise<br>
                                                                                                                        •	complete a questionnaire, a support ticket, or a contest entry form<br>
                                                                                                                       
                                                                                                                                </div>                
                                                                                                                        </div>


										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			<!--************************************
					Contact Us End
			*************************************-->
</main>


