import { Action } from '@ngrx/store';
import { searchState } from 'src/app/shared/models/search/searchState.model';
import { searchResulteDTO } from 'src/app/shared/models/search/openSearch.model';
 
export enum SearchActionTypes {
  SETSEARCH= 'SETSEARCH',
  GETSEARCH= 'GETSEARCH'
}

export class trySearch implements Action {
  readonly type = SearchActionTypes.SETSEARCH;
  constructor(public payload: searchState) {
    debugger;
    console.log('testingSearch');
  }
}
export class getSearch implements Action {
  readonly type = SearchActionTypes.GETSEARCH;
  constructor(public payload: searchResulteDTO[]) {
    //debugger;     
  }
}
 
export type SearchActions =
 trySearch
 | getSearch ;