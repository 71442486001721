import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../auth.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.state';
import * as authActions from '../../store/actions/auth.actions';
//import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';
//import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { LocalStorageService } from 'angular-web-storage';
import { AdcompanyService } from 'src/app/shared/services/compnies/CompanyServices';
import { RregisterDTO } from './Register';
import { RegisterserviceService } from 'src/app/shared/registerservice.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { NgOption } from '@ng-select/ng-select';
import { from } from 'rxjs';

;
//import { fas } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  token: string;
  sendemail:string;
  faFacebook = faFacebook;
  faGoogle = faGoogle;
  faLinkedin = faLinkedin;
  faTwitter = faTwitter;
  faEnvelope = faEnvelope;
  faLock = faLock;
  logInMessage: string;
  UserImage: any;
  userName: "";
  email: "";
  Country;
  phoneNumber: "";
  userPassword: "";
  retypePassword: "";
  NameAlert=false;
  EmailAlert=false;
  PhoneAlert=false;
  PasswordAlert=false;
  RePasswordAlert=false;




  constructor(private adcompanyService: AdcompanyService, private authSer: AuthenticationService, public localStor: LocalStorageService,
    private router: Router, private userInterface: openSearchService, private route: ActivatedRoute, private notifyService: NotificationService, private registerservice: RegisterserviceService
    , private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    //this.logInMessage = "";
    this.store.select('auth').subscribe(data =>
      this.logInMessage = data.errorMessage
    )
  }
  onSignIn(form: NgForm) {
    //debugger;
    const email = form.value.email;
    const pass = form.value.pass;
    this.store.dispatch(new authActions.trySignIn({ username: email, password: pass }));

    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
    
   // window.location.href = 'home';
    //this.router.navigate(['../header'], { relativeTo: this.route });
  //   debugger
  //   this.router.navigateByUrl('/header', { skipLocationChange: true }).then(() => {
  //     this.router.navigate(['/login']);
  // }); 

  }

  onSubmit(form: NgForm) {
    //debugger;
    var result = this.validateForm();
    //if (result === true) {
      if (this.userName != undefined && this.email != undefined && this.phoneNumber != undefined && this.userPassword != undefined && this.retypePassword != undefined && this.Country != undefined) {
        if (this.userPassword == this.retypePassword) {
          const reg = new RregisterDTO();
          reg.userName = this.userName;
          reg.PrimaryEmail = this.email;
          reg.Country = this.Country;
          reg.MobileNumber = this.phoneNumber.toString();
          reg.UserPassword = this.userPassword;
          reg.ConfirmPassword = this.retypePassword;
          this.registerservice.submitRegistration(reg).subscribe(data => {
            if (data > 0) {
              //this.notifyService.showSuccess('Successfully Register !!', 'Wellcome');
              this.successNotification('User has been Registered Successfully!');
              this.resetForm();
              form.reset();
            }
            else if (data = -5)
            {
              this.errorNotification('Email already exists please use another email!');
            }
            else if (data = -10)
            {
              this.errorNotification('UserName already exists please use another UserName!');
            }
            else {
              this.notifyService.showError('Please Provide new Email', 'Somting Wrong');
            }
          });    
          this.RePasswordAlert =false;  
        }
        else {
          this.notifyService.showError('Provide Same Password !', 'Missing Data');
          this.errorNotification('Provide Same Password !');
          this.RePasswordAlert =true;
        }
      }
      else {
        this.notifyService.showError('Provide Complete Information!', 'Missing Data');
      }    
  }

  onEmailSubmit() {
    this.registerservice.submitEmail(this.sendemail).subscribe((result) => {
      if (result != 0 && result != null) {
        this.sendemail = '';
        this.successNotification('Password has been sent on your registered Email !!');
        //this.notifyService.showSuccess('Password send on your Email !!', 'Successfully');
      } else { 
        //this.notifyService.showError('Please provide valid Email', 'Email is Worng'); }
        this.errorNotification('Please provide valid email Address');
      }
    });
    ////console.log(this.sendemail)
  }

  validateForm() {
    //debugger;
    var result = true;
    if (typeof this.userName == 'undefined' || !this.userName || this.userName == "0") {
      this.NameAlert = true;
      result = false;
    }
    else
    {
      this.NameAlert = false;
    }
    if(typeof this.email == 'undefined' || !this.email || this.email == "0") {
    this.EmailAlert = true;
    result = false;
    }
    else
    {
      this.EmailAlert = false;
    }
    if(typeof this.phoneNumber == 'undefined' || !this.phoneNumber || this.phoneNumber == "0") {
      this.PhoneAlert = true;
      result = false;
    }
    else
    {
      this.PhoneAlert = false;
    }
    if(typeof this.userPassword == 'undefined' || !this.userPassword || this.userPassword == "0") {
      this.PasswordAlert = true;
      result = false;
    }
    else
    {
      this.PasswordAlert = false;
    }
    if(typeof this.retypePassword == 'undefined' || !this.retypePassword || this.retypePassword == "0") {
      this.RePasswordAlert = true;
      result = false;
    }
    else
    {
      this.RePasswordAlert = false;
    }
    
    const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    
    if (!emailRegex.test(this.email)) {
      this.EmailAlert = true;
      result = false;
    } else {      
      this.EmailAlert = false;
    }

    
    return result;
  }
  onChange($event) {
    debugger;
    const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    if (!emailRegex.test(this.email)) {
      this.EmailAlert = true;     
    } else { 
      this.EmailAlert = false;     
    }

    //
  }

  onPasswordChange($event) {
    debugger;
    //const passRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8}$/);
    const passReg = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&*]).{8,20}$/);
    //const emailRegex = new RegExp(/^[a-zA-Z0-9!@#$&()-`.+,/]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    if (!passReg.test(this.userPassword)) {
      this.PasswordAlert = true;     
    } else { 
      this.PasswordAlert = false;     
    }
  }
  onResPasswordChange($event) {
    debugger;    
    if (this.retypePassword != this.userPassword) {
      this.RePasswordAlert = true;     
    } else { 
      this.RePasswordAlert = false;     
    }
  }

  onPhoneChange($event) {
    debugger;    
    if (this.phoneNumber == '') {
      this.PhoneAlert = true;     
    } else { 
      this.PhoneAlert = false;     
    }
  }

  onNameChange($event) {
    debugger;    
    if (this.userName =='') {
      this.NameAlert = true;     
    } else { 
      this.NameAlert = false;     
    }
  }

  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['/home'], { relativeTo: this.route });
  }

resetForm(){
  this.userName= "";
  this.email= "";
  this.Country ="";
  this.phoneNumber= "";
  this.userPassword= "";
  this.retypePassword= "";
  this.NameAlert=false;
  this.EmailAlert=false;
  this.PhoneAlert=false;
  this.PasswordAlert=false;
  this.RePasswordAlert=false;
}

  successNotification(message:string) {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'Congratulation Check your email',
      text:message,
      showConfirmButton: true,
      timer: 2500
    })
  }

  errorNotification(message:string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
      footer: 'Provide the missing data ‼️‼️'
    })
  }

  CountryList: NgOption[] = [
    { id: 0, title: "Select Country" },
    { id: 1, title: "United Arab Emirates" },
    { id: 2, title: "Pakistan" },
    { id: 3, title: "Saudi Arabia" },
    { id: 4, title: "KUWAIT" },
    { id: 5, title: "Oman" },
    { id: 7, title: "India" },
    { id: 8, title: "United Kingdom" },
    { id: 9, title: "United States" },
    { id: 10, title: "China" },
    { id: 11, title: "Japan" },
    { id: 12, title: "Canada" }
  ]
}
