import { DealProduct } from "./addPhotos.model";
import { DealsPhotos } from "./dealPhotos.model";
import { DealProductRequest } from "./dealProductRequest";

export class DealRequest{
    public DealID : number ; 
    public LI_Name : string ; 
    public LI_Terms : string ; 
    public LI_CountryID: number; 
    public LI_Description : string ; 
    public LI_AddedBy: number ; 
    public LI_AddedDate : string ; 
    public LI_StartDate : string ; 
    public LI_EndDate : string ; 
    public LI_EditedBy: number ; 
    public LI_EditedDateTime: string ; 
    public LI_UserID: number ; 
    public LI_ComID: number ; 
    public LI_CatID: string ; 
    public LI_SubCatID: string ; 
    public LI_EndCatID: string ; 
    public LI_EndSubCatID: string ; 
    public LI_Price: number ; 
    public LI_PriceUnit: number ; 
    public LI_PriceActual: number ; 
    public LI_IsActive: boolean ;
    public LI_IsDeleted: boolean; 


    //public postProduct : DealProductRequest;
    public postProduct : DealProduct[];
    public postPhotos : DealsPhotos[];
}