<p>list of Top Books !</p>

<div class="row">
    <div class="col-xs-12">
        <ul class="list-group">
            <li><a class="list-group-item" style="cursor:pointer" *ngFor="let book of topBooks; let i = index">
                {{book.title}} / {{book.author}} 
                </a>
            </li>
        </ul>
    </div>
</div>