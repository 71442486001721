import { User } from '../../core/models/user.model';
import { searchState } from '../../shared/models/search/searchState.model';
import { searchResulteDTO } from 'src/app/shared/models/search/openSearch.model';
import { SearchResultActionTypes, SearchResultActions } from '../actions/searchResult.action';

export interface State {     
  selectedResult : searchResulteDTO[];
} 
export const initialState : State = {
  selectedResult :null      
}; 
export function searchResultReducer(state = initialState, action: SearchResultActions){
  switch (action.type) {      
    case SearchResultActionTypes.GETSEARCH: {      
      return{
        ...state,        
        selectedResult: action.payload        
      }
    }
    default: {
      return state;
    }
  }
}