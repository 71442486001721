import { Component, OnInit } from '@angular/core';
import { Product } from '../product/show-product/Product';
import { adProductService } from '../shared/services/compnies/ProductServices';
import { openSearchService } from '../shared/services/search/openSearch.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderRequest } from '../shared/models/Company/Orders/OrderRequest';
import { Orders } from '../shared/models/Company/Orders/Orders';
import { OrderDetails } from '../shared/models/Company/Orders/OrderDetails';
import * as jwt_decode from "jwt-decode";
import { LocalStorageService } from 'angular-web-storage';
import { OrderService } from '../shared/services/compnies/OrderServices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from '../shared/services/notification.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.css']
})
export class BillingDetailsComponent implements OnInit {

  cartProducts: Product[];
  constructor(public adproductService: adProductService,private userInterface: openSearchService,private router: Router,private route:ActivatedRoute,
    private storage: LocalStorageService, private orService: OrderService ,
    private ngxLoader: NgxUiLoaderService,private notifyService: NotificationService,public localStor: LocalStorageService) { }

  totalValue = 0;
  Total=0;
  name:number;
  grandTotal=0;
  proQuantity=1;
  discountTotal=0;
  TotalLength:any;
  page:number=1;
  logInUserName:string;
  AddressID:any;
  confirmOrder:boolean;
  orderDetailsNew = [];
  selectedCountry = '0'; 
  shippingCountry = '0';

  ngOnInit(): void {
    //debugger
   // this.getCartProduct();

   if(this.localStor.get('currentCountry'))
   {
     this.selectedCountry =this.localStor.get('currentCountry');
   }
   if(this.localStor.get('shippingAdd'))
   {
     this.shippingCountry =this.localStor.get('shippingAdd');
   }
   
    this.cartProducts =  JSON.parse(localStorage.getItem("active_item"))
    this.TotalLength = this.cartProducts.length;
    this.calculateCartProduct();


    let token = this.storage.get('currentUser');
    let tokenInfo = this.getDecodedAccessToken(token); // decode token 
   
    if (tokenInfo != null) {
      this.logInUserName = tokenInfo.nameid;
    }

    this.AddressID = localStorage.getItem('shippingAdd');
    
    console.log(this.logInUserName );
    this.confirmOrder = true;
  }
  

changeQuantity(selectedProduct: Product, quantity: number): void {  
  //debugger
  console.log(selectedProduct)

if(quantity > 0)
{

this.cartProducts = this.adproductService.getLocalCartProducts();

let updateItem = this.cartProducts.find(f=>f.proID == selectedProduct.proID);

  let index = this.cartProducts.indexOf(updateItem);

selectedProduct.proQuantity = quantity;

  this.cartProducts[index].proQuantity =  quantity;

  //this.cartProducts[index].proTotal += parseInt(selectedProduct.proPrice);
  //console.log(this.cartProducts[index].proTotal)
  //this.totalValue += parseInt(selectedProduct.proPrice);
}
else	
{	
  this.cartProducts = this.adproductService.getLocalCartProducts();
  
  let updateItem = this.cartProducts.find(f=>f.proID == selectedProduct.proID);

  let index = this.cartProducts.indexOf(updateItem);

  this.cartProducts.splice(index, 1);
}

  this.adproductService.setLocalCartProducts(this.cartProducts);
  this.calculateCartProduct();
}  

calculateCartProduct(): void {  
  //debugger;
  this.totalValue =0;
  this.grandTotal=0;
  let productTotal =0;
  this.discountTotal =0;
  this.cartProducts = this.adproductService.getLocalCartProducts();
  this.cartProducts.forEach((product) => { 
    this.totalValue  += parseInt(product.proPrice) * product.proQuantity ;
    this.discountTotal +=  parseInt(product.proPrice) - product.proDiscount;
    
  });
  if(this.totalValue >0)
  {
    this.grandTotal =  this.totalValue - 0;
  }
  else 
  {
    
  }

}
  removeCartProduct(product: Product) {
    this.adproductService.removeLocalCartProduct(product);
    this.cartProducts =  JSON.parse(localStorage.getItem("active_item"))
    this.calculateCartProduct();
  }

  addFavourite(product: Product) {
    this.adproductService.FavouriteProduct(product);
  }
  
getCurrentOrderNumber()
{
  const now = new Date();
  const hoursAndMinutes = now.getDay() +''+ now.getHours() + '' + now.getMinutes();
  const OrderNumber = 'KC-'+ this.logInUserName + '-'+ hoursAndMinutes;
 
  return OrderNumber;

}

getDecodedAccessToken(token: string): any {
  try {
    return jwt_decode(token);
  }
  catch (Error) {
    return null;
  }
}

  backtoMain()
  {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'],{relativeTo : this.route });
  }

  onOrderSubmit() {
     debugger; 

     //var orderDetailsNew = [];
     const postOrderRequest = this.getOrderDetails();
     if(postOrderRequest!=null)
     {
      this.ngxLoader.start();  
      this.orService.submitThePost(postOrderRequest).subscribe(data => {
        if (data != 0) {
         this.confirmOrder = false;
        //  this.ResetForm();
          setTimeout(() => {
            this.ngxLoader.stop();
           }, 0);
          this.successNotification();
          localStorage.removeItem("active_item");             
          localStorage.removeItem('shippingAdd');
          this.orderDetailsNew = null;
          this.userInterface.setInterface("1");
         this.router.navigate(['../user/orderlist']);         
          // this.reloadComponent();             
        }
        else {
          this.errorNotification();
        }       
      });
     }
        
         //this.ResetForm();    
         ////debugger;       
         //this.router.navigate(['../home'],{relativeTo : this.route });
     //  }
     //  else {
     //    this.notifyService.showError('Provide the missing data !', 'Missing Data');
     //  }
    // }else{ this.notifyService.showError('Please Enable the Location !!', 'Somthing Wrong');}      
  }

  getOrderDetails()
  {
    debugger; 
    const postOrderRequest = new OrderRequest();
        
    const orderRequest = new Orders();
    const orderDetails = new OrderDetails();     
    //var orderDetailsNew = [];

    orderRequest.CompanyID =0
    orderRequest.CustomerID = 0;
    orderRequest.OrderAddedBy = 0;
    orderRequest.OrderAddedDate ='';
    orderRequest.OrderCoupenAppliedId =0;
    orderRequest.OrderCurrency =0;
    orderRequest.OrderDiscountApplied =0;
    orderRequest.OrderEditDate = '';
    orderRequest.OrderEditedBy =0;
    orderRequest.OrderId =0;
    orderRequest.OrderNumber=this.getCurrentOrderNumber();
    orderRequest.OrderTotal=this.grandTotal;
    orderRequest.OrderStatus=0;
    orderRequest.PaymentDetailId =0;


    let shippingAddID = localStorage.getItem('shippingAdd');
    if(this.shippingCountry != "0")
    {
      orderRequest.ShippingAddressId=parseInt(this.shippingCountry);
    } 
    else
    {
      orderRequest.ShippingAddressId=0;
    }

    orderRequest.ShippingAddressId=0;//this.AddressID;
    orderRequest.ShippingMethodId =1;
    
    postOrderRequest.ProductOrder = orderRequest;     
    postOrderRequest.OrderPayment = null;

   this.cartProducts = this.adproductService.getLocalCartProducts();

    this.cartProducts.forEach((product) => { 
      debugger;
      var orderDetailsToNew = {};
      orderDetailsToNew["OrderId"]=0;
      orderDetailsToNew["ProductColledtedAmmount"]= parseInt(product.proPrice);
      orderDetailsToNew["ProductDiscountApplied"] =0;
      orderDetailsToNew["ProductId"] = product.proID;
      orderDetailsToNew["ProductQuantity"] = product.proQuantity;
      orderDetailsToNew["ProductTotalPrice"] = parseInt(product.proPrice);

      this.orderDetailsNew.push(orderDetailsToNew);      
  });
  postOrderRequest.ProductOrderDetails = this.orderDetailsNew ; 
  
  return postOrderRequest;
}

  successNotification() {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1000
    })
  }
  errorNotification() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Provide the missing data ‼️‼️</a>'
    })
  }

  onOrderCheckOut(){

    const postOrderRequest = this.getOrderDetails();
    if(postOrderRequest!=null)
    {
      this.orService.setLocalOrders(postOrderRequest);

      this.router.navigate(['../checkouts/userPayment']); 
    }
  }

}
