import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-mainloader',
  templateUrl: './mainloader.component.html',
  styleUrls: ['./mainloader.component.css']
})
export class MainloaderComponent implements OnInit {
  loading: boolean;
 
  constructor(private loaderService: LoaderService) {
 
    this.loaderService.isLoading.subscribe((v) => {
      //console.log(v);
      this.loading = v;
    });
 
  }
  ngOnInit() {
  }
 
}