import { Component, OnInit } from '@angular/core';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { Router, ActivatedRoute } from "@angular/router";
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { FormGroup, NgForm } from '@angular/forms';
//import Swal from 'sweetalert2/dist/sweetalert2.js';
//import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { NotificationService } from '../shared/services/notification.service';
import { AdcompanyService } from '../shared/services/compnies/CompanyServices';
import { companyDetails } from '../shared/models/Company/companyDetails';
import { companyRequest } from '../shared/models/Company/companyRequest';
import { addPhotos } from '../shared/models/addPhotos.model';
import { dataLibraries } from '../shared/models/category/dataLibraries/dataLibraries.model';
import { companyRequestImage } from '../shared/models/Company/companyRequestImage';
import { NgOption } from '@ng-select/ng-select';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Quill from 'quill';
import 'quill-mention';
import 'quill-emoji';

@Component({
  selector: 'app-user-company',
  templateUrl: './user-company.component.html',
  styleUrls: ['./user-company.component.css']
})
export class UserCompanyComponent implements OnInit {

  public scrollbarOptions = { axis: 'y', theme: 'light' };

  public quill: Quill;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        [
          { header: 1 },
          { header: 2 },
          { header: 3 },
          { header: 4 },
          { header: 5 },
        ], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction  
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
        [{ 'font': [] }],
        [{ 'align': [] }],  
        ['clean'], // remove formatting button  
        //['link'],
        ['link', 'image', 'video'],
        [{ 'color': [] }],
      ]
    },
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter');
            return true;
          },
        },
      },
    },
  };

  public editorCreated(event: Quill,descriptionArea): void {
    this.quill = event;
    this.quill.setHtml(descriptionArea);
    let toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('table', () => {
    });
  }


  constructor(private adcompanyService: AdcompanyService,private ngxLoader: NgxUiLoaderService,
    private userInterface: openSearchService
    , private router: Router, private route: ActivatedRoute
    , private mScrollbarService: MalihuScrollbarService) { }
  private notifyService: NotificationService;


  selectedCompany = "";
  selectedWebsite = "";
  selectedIndustry = "";
  selectedSize = "";
  selectedLicence = "";
  selectedPhone = "";
  selectedEmail = "";
  selectedAddress = "";
  selectedDescription = "";
  comNameAlert = false;
  comLicenceAlert = false;
  comNumberAlert = false;
  comEmailAlert = false;
  comIndustryAlert = false;
  comSizeAlert = false;
  comName = "";
  comIndustry = "";
  comSize = 0;
  comCountry="";
  comEmail = "";
  comNumber = "";
  comLicence = "";
  comWebsite = "";
  comAddress = "";
  comDescription = "";

  imageDeleteFrom: FormGroup;
  postImages: addPhotos[];
  imageurls = [];
  url=[];
  imageurlsNew = [];
  base64String: string;
  name: string;
  imagePath: string;

  IndustryList: dataLibraries[]


  fillDropDowns() {
    this.IndustryList = [
      { id: "0", title: "select Industry" },
      { id: "2", title: "Industry 1" },
      { id: "1", title: "Industry 2" },
      { id: "3", title: "Industry 3" },
      { id: "4", title: "Industry 4" },
      { id: "5", title: "Industry 5+" },
    ]
  }
  CountryList: NgOption[] = [
    { id: 0, title: "Select Country" },
    { id: 1, title: "United Arab" },
    { id: 2, title: "Pakistan" },
    { id: 3, title: "Saudi Arabia" },
    { id: 4, title: "KUWAIT" },
    { id: 5, title: "Oman" },
    { id: 7, title: "india" },
    { id: 8, title: "United Kingdom" },
    { id: 9, title: "United States" },
    { id: 10, title: "China" },
    { id: 11, title: "Japan" },
    { id: 12, title: "Canada" }
  ]      
  companyList: any;

  LoadCompanyData() {
    //debugger;
    this.adcompanyService.getCompanyDetails().subscribe(data => {
      this.ngxLoader.start(); 
      this.companyList = data;
      //console.log(this.companyList);
      if (this.companyList.comImageName != null) {
        //debugger;
        this.imageurls.push(this.companyList.imageBase64);
        setTimeout(() => {
          this.ngxLoader.stop();
         }, 0); 
        //let ingrediant;
        //this.imageurls.push(ingrediant.imageBase64 );
        //this.imageurlsNew.push({ base64String: this.companyList.imageBase64 });
      }
    });
  }


  ngOnInit(): void {
    this.LoadCompanyData();
    this.fillDropDowns();

  }

  selectedCurrency: string;
  onSubmit(form: NgForm) {
    //debugger; 
    var result = this.validateCompanyForm();
    if (result === true)
      {
      const companyDetails = new companyRequest();
      companyDetails.mode = "update";
      companyDetails.Com_Name = this.companyList.comName;
      companyDetails.Com_Industry = this.companyList.comIndustry;
      companyDetails.Com_Size = this.companyList.comSize;
      companyDetails.Com_Email = this.companyList.comEmail;
      companyDetails.Com_Number = this.companyList.comNumber;
      companyDetails.Com_Licence = this.companyList.comLicence;
      companyDetails.Com_Website = this.companyList.comWebsite
      companyDetails.Com_Address = this.companyList.comAddress;
      companyDetails.Com_Description = this.companyList.comDescription;
      companyDetails.Com_CountryID = parseInt(this.companyList.comCountry);
      //companyDetails.ImageName = "";
      companyDetails.isActive = false;
      companyDetails.isDeleted = false;

      //debugger;
      //console.log(companyDetails);
      this.ngxLoader.start();  
      this.adcompanyService.submitCompanyDetails(companyDetails).subscribe(data => {
        if (data != 0) {
          setTimeout(() => {
            this.ngxLoader.stop();
           }, 0); 
          //this.reloadComponent();
          this.successNotification();
        } 
        else {
          this.errorNotification();
        }
      });  
    }
    else{
      this.errorNotification();
      //this.notifyService.showError('Provide the missing data !', 'Missing Data');
    }
  }

  onImagesUpdate(){
    //debugger;
    const companyDetails = new companyRequestImage();  
    //console.log(this.companyList); 
    companyDetails.Com_ID = this.companyList.companyID;
    ////console.log(this.companyID);
    var photos = [];
      //debugger
      var counter = 1;
      if(this.imageurls.length > 0)
      {
      this.imageurls.forEach(element =>{
      companyDetails.ImageName = element;
      })
      }
      //debugger;
     // //console.log(companyDetails);
      this.adcompanyService.updateCompanyImage(companyDetails).subscribe(data => {
        if(data != 0){
          this.successNotification();}//else{this.errorNotification();}
      });
      //this.notifyService.showSuccess('Bravo!', 'Image Updated successfully !!');
  }
  

  successNotification() {
    Swal.fire({
     // position: 'top-center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1000
    })
  }

  errorNotification() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Provide the missing data ‼️‼️</a>'
    })
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  validateCompanyForm() {
    //debugger;
    var result = true;
    if (typeof this.companyList.comName == 'undefined' || !this.companyList.comName || this.companyList.comName == "0") {
      this.comNameAlert = true;
      result = false;
    }
    else if (typeof this.companyList.comIndustry == 'undefined' || !this.companyList.comIndustry || this.companyList.comIndustry == "0") {
      this.comNameAlert = false;
      this.comIndustryAlert = true;
      result = false;
    }
    else if (typeof this.companyList.comSize == 'undefined' || !this.companyList.comSize || this.companyList.comSize == 0) {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comSizeAlert = true;
      result = false;
    }
    else if (typeof this.companyList.comEmail == 'undefined' || !this.companyList.comEmail || this.companyList.comEmail == "0") {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comSizeAlert = false;
      this.comEmailAlert = true;
      result = false;
    }
    else if (typeof this.companyList.comNumber == 'undefined' || !this.companyList.comNumber || this.companyList.comNumber == "0") {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comNumberAlert = false;
      this.comEmailAlert = false;
      this.comNumberAlert = true;
      result = false;
    }
    else if (typeof this.companyList.comLicence == 'undefined' || !this.companyList.comLicence || this.companyList.comLicence == "0") {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comNumberAlert = false;
      this.comEmailAlert = false;
      this.comNumberAlert = false;
      this.comLicenceAlert = true;
      result = false;
    }
    else {
      this.comNameAlert = false;
      this.comIndustryAlert = false;
      this.comNumberAlert = false;
      this.comEmailAlert = false;
      this.comNumberAlert = false;
      this.comLicenceAlert = false;
    }
    return result;
  }



  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

  removeImageEdit(i, imagepath) {
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.ImagePath = imagepath;
  }

  removeImage(i) {
    this.imageurls.splice(i, 1);
    this.imageurlsNew.splice(i, 1);
  }
  
  onSelectFile(event) {
    //debugger;
    for (let i = 0; i < this.imageurls.length; i++) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageurls[0] = event.target.result;
      }
      reader.readAsDataURL(event.target.files[i]);
    }
  }
  
  
}

