import { userproject_detail } from "./userproject_details";

export class userproject{
    public mode :string;
    public ProjectID: number;
    public UserID: number;
    public ProjectTitle :string;
    public ProjectDescription :string;
    public ProjectCompany :string;

    public projectdetail: userproject_detail[];
}