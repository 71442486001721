import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { error } from 'protractor';
import { adds } from '../models/Adds/adds.model';
import { addDetailsDTO } from '../models/Adds/addDetails.model';
import { addDetailsParams } from '../models/requestsModel/addDetailsParams.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AdDetails } from 'src/app/ads/add-edit-post/AdDetails';
import { classified } from '../models/classified.model';
import { adPosting } from '../models/adPosting.model';
import { AdsImagesRequest } from '../models/Adds/AdsImagesRequest';
import { dataLibrary } from '../models/category/dataLibraries/dataLibrary.model';

@Injectable()
export class addDetailsService{
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };
private details : addDetailsDTO;     
  result: any;
constructor(private httpclient:HttpClient){}

GetAddDetailsByID(requestData:addDetailsParams){  
   // //debugger;
    return  this.httpclient.post<addDetailsDTO>(this.baseUrl+'services/Ads/adDetails',JSON.stringify(requestData),this.httpOptions)
    .pipe(map((response : addDetailsDTO)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
  );                        
}


  GetTopAddsByUser(addID:string){
   //debugger;
   return this.httpclient.get<adds[]>(this.baseUrl+'services/Ads/byUserID?addID='+addID,this.httpOptions)
    .pipe(map((response : adds[])=> {
      return response;
    }),
    catchError(error =>{
      return throwError('seomething went wrong');
    })
    )     
  }
  getTopAdsByCategory(addID:string){
    //debugger;
    return this.httpclient.get<adds[]>(this.baseUrl+'services/Ads/topAdsByCategory?addID='+addID,this.httpOptions)
     .pipe(map((response : adds[])=> {
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }

  getadsDetails():Observable<any[]>{
    //debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/Ads/adAllDetails');
  }

  gettopAutosAdsDetails(country : number): Observable<any[]>{    
    return this.httpclient.get<any[]>(this.baseUrl+'services/Ads/AutosNewAds?country='+country,this.httpOptions)
     .pipe(map((response : any[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
   gettopProductaDetails(country : number): Observable<any[]>{    
    return this.httpclient.get<any[]>(this.baseUrl+'services/Ads/ProductNewAds?country='+country,this.httpOptions)
     .pipe(map((response : any[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
   gettopProductaDetailsFiltered(country : number): Observable<any[]>{    
    return this.httpclient.get<any[]>(this.baseUrl+'services/Ads/ProductNewAdsFiltered?country='+country,this.httpOptions)
     .pipe(map((response : any[])=> {      
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
  gettopPropertyRentDetails(country : number): Observable<any[]>{    
    return this.httpclient.get<any[]>(this.baseUrl+'services/Ads/PropertyforRent?country='+country,this.httpOptions)
     .pipe(map((response : any[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
   gettopPropertySaleDetails(country : number): Observable<any[]>{    
    return this.httpclient.get<any[]>(this.baseUrl+'services/Ads/PropertyforSale?country='+country,this.httpOptions)
     .pipe(map((response : any[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
   gettopJobforWanted(country : number): Observable<any[]>{    
    return this.httpclient.get<any[]>(this.baseUrl+'services/Ads/Jobforwant?country='+country,this.httpOptions)
     .pipe(map((response : any[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
   gettopJobforHiring(country : number): Observable<any[]>{    
    return this.httpclient.get<any[]>(this.baseUrl+'services/Ads/JobforHiring?country='+country,this.httpOptions)
     .pipe(map((response : any[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
  GetAdsByIDNew(requestData:addDetailsParams){  
     //debugger;
     //return  this.httpclient.get<addDetailsDTO>(this.baseUrl+'services/Ads/adDetailsByID?addID=',this.httpOptions)
     return  this.httpclient.post<addDetailsDTO>(this.baseUrl+'services/Ads/adDetailsByID?addID=',JSON.stringify(requestData),this.httpOptions)
     .pipe(map((response : addDetailsDTO)=> {
       return response;
       }),
       catchError(error =>{
         return throwError('seomething went wrong');
       })
   );                       
   }

 UpdateAdsDetails(postingData:adPosting){  
  //debugger;
  return this.httpclient.post<any>(this.baseUrl+'services/Ads/Details/updatDetails',JSON.stringify(postingData),this.httpOptions)               
}

updateAdsImages(adImagesData:AdsImagesRequest){  
  //debugger;
  this.httpclient.post<number>(this.baseUrl+'services/Ads/UpdateImage',JSON.stringify(adImagesData),this.httpOptions).subscribe(data => {
    //debugger; 
    //console.log(data);           
return this.result = data; 
  })               
}

updateproductVideo(postingData:adPosting){  
  //debugger;
  this.httpclient.post<number>(this.baseUrl+'services/Ads/UpdateVideo',JSON.stringify(postingData),this.httpOptions).subscribe(data => {
    //debugger; 
    //console.log(data);           
return this.result = data; 
  })               
}

    // getTopAdds(){
    //   //debugger;
    //   const requestData = new addDetailsParams();
    //   requestData.cat="Classified";
    //   requestData.addID ="B7A1A6D3-D488-4281-8801-D8E890D99787";
            
    //  return this.httpclient.get<addDetailsDTO>(this.baseUrl+'services/Ads/adDetails',this.httpOptions)
    //   .pipe(map((response : addDetailsDTO)=> {
    //     return response;
    //   }),
    //   catchError(error =>{
    //     return throwError('seomething went wrong');
    //   })
    //   )     
    // }
 
}