import { Component, Input, OnInit } from '@angular/core';
import { addDetailsService } from 'src/app/shared/services/addDetails.service';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgOption } from '@ng-select/ng-select';
import { AdDetails } from './AdDetails';
//import Swal from 'sweetalert2/dist/sweetalert2.js';
// 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { addDetailsDTO } from 'src/app/shared/models/Adds/addDetails.model';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CategoryDto } from 'src/app/shared/models/category/categoryDTO.model';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { addDetailsParams } from 'src/app/shared/models/requestsModel/addDetailsParams.model';
import { dataLibraries } from 'src/app/shared/models/category/dataLibraries/dataLibraries.model';
import { Options } from 'ng5-slider';
import { classified } from 'src/app/shared/models/classified.model';
import { adPosting } from 'src/app/shared/models/adPosting.model';
import { dropdownLibrary } from 'src/app/shared/models/category/dataLibraries/FillDropDown.model';
import { postAutos } from 'src/app/shared/models/postAutos.model';
import { postProperty } from 'src/app/shared/models/postProperty.model';
import { AdsImagesRequest } from 'src/app/shared/models/Adds/AdsImagesRequest';
import { jobsWanted } from 'src/app/shared/models/jobsWanted.model';
import { jobsAvailable } from 'src/app/shared/models/jobsAvailable.model';
import Quill from 'quill';
import 'quill-mention';
import 'quill-emoji';
@Component({
  selector: 'app-add-edit-post',
  templateUrl: './add-edit-post.component.html',
  styleUrls: ['./add-edit-post.component.css']
})
export class AddEditPostComponent implements OnInit {

  @Input() ads: any;
  //GetData: FormGroup;
  addDetails: addDetailsDTO; 
  //tempDetails: addDetailsDTO;
  constructor(private userInterface: openSearchService, private adService: addDetailsService,private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder,
    private notifyService: NotificationService,) { 
    this.currentDate = new Date().getTime();}

 currentDate : any;
 
  title: string;
  price: number;
  phone: number;
  currencyID: number;
  description: string;
  catID: string;
  subCatID: string;
  selectedAge: number;
  selectedUsage: number;
  selectedCondition: number;
  firstchildID: string;
  selectedExperience: number;
  kilometer: number;

  furnished: number;
  size: number;
  readyDate: string;
  toAge: number;
  fromAge: number;
  fromSalary: number;
  toSalary: number;
  positions: number;
  shift: number;
  travelRequired: string;

  faArrowRight = faArrowRight;
  selectedCategory = '0';
  selectedSubCategory = '0';
  selectedSubCatFirstChild = '0';
  selectedSubCatSecondChild = '0';
  selectedSearchWord = '';
  subCategories: CategoryDto[];
  subCatFirstChildList: CategoryDto[];
  subCatSecondChildList: CategoryDto[];
  categoryTitle = "";
  subCategoryTitle = "";
  subToFirstTitle = "";
  subToSecondTitle = "";
  finalSelection = "";

  productMainID: number = 0;
  allselected: number = 0;
  levelOfSearch: number = 0;
  levelToShow: number = 0;

  imageDeleteFrom: FormGroup;
  videoDeleteFrom: FormGroup;
  imageurls = [];
  videoUrls = [];
  imageurlsNew = [];
  base64String: string;
  name: string;
  imagePath: string;

  public quill: Quill;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        [
          { header: 1 },
          { header: 2 },
          { header: 3 },
          { header: 4 },
          { header: 5 },
        ], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction  
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
        [{ 'font': [] }],
        [{ 'align': [] }],  
        ['clean'], // remove formatting button  
        //['link'],
        ['link', 'image', 'video'],
        [{ 'color': [] }],
      ]
    }
  };

  public editorCreated(event: Quill,description): void {
    debugger;
    this.quill = event;
    this.quill.setHtml(description);
    let toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('table', () => {
      //this.addNewTable();
    });
  }

  ngOnInit(): void {
    this.fillDropDowns();
    this.LoadProductData();
    //this.quill.setText(this.description);
    //this.quill.setHtml(this.description);
    //this.GetAddDetailsByID();
  }

  LoadProductData() {
    ////console.log(this.router.snapshot.params.addID);
    const requestData = new addDetailsParams();
    requestData.addID = this.route.snapshot.params.addID;
    requestData.cat = this.route.snapshot.params.cat;

    this.adService.GetAdsByIDNew(requestData).subscribe((result) => {
      //console.log(result);
      //debugger;
      this.addDetails = result;
      this.title = this.addDetails[0].title;
      this.price = this.addDetails[0].price;
      this.currencyID = this.addDetails[0].currencyID;
      this.description = this.addDetails[0].description;
      //this.quill.setText(this.description);
      //this.quill.setHtml(this.description);
      /* ---Classified--- */
      this.selectedAge = this.addDetails[0].ageID;
      this.selectedUsage = this.addDetails[0].usageID;
      this.selectedCondition = this.addDetails[0].conditionID;
      /* ---Autos--- */
      // this.selectedAutoAge = this.addDetails[0].ageID;
      // this.selectedAutoUsage = this.addDetails[0].usageID;
      this.selectedEngineBike = this.addDetails[0].engineID;
      this.selectedBodyCondition = this.addDetails[0].bodyConID;
      this.selectedBodyType = this.addDetails[0].bodyTypeID;
      this.selectedColor = this.addDetails[0].colorID;
      this.selectedDoor = this.addDetails[0].doorsID;
      this.selectedTrim = this.addDetails[0].trimID;
      this.selectedCylender = this.addDetails[0].cylenderID;
      this.selectedMechenicalCondition = this.addDetails[0].macConID;
      this.selectedTransmission = this.addDetails[0].transmissionID;
      this.kilometer = this.addDetails[0].kilometer;
      this.selectedWarranty = this.addDetails[0].warID;
      this.selectedYear = this.addDetails[0].yearID;
      this.selectedFuelType = this.addDetails[0].ftID;
      this.selectedHPList = this.addDetails[0].hpID;
      /* ---JOB--- */
      this.selectedRCommitment = this.addDetails[0].commitmentID;
      this.selectedREducation = this.addDetails[0].educationID;
      this.selectedRWE = this.addDetails[0].experienceID;
      this.selectedDegree = this.addDetails[0].degreeTitle;
      this.selectedPosition = this.addDetails[0].positionsID;
      this.selectedRGender = this.addDetails[0].genderID;
      this.selectedRShift = this.addDetails[0].shiftID;
      /* ---PROPERTY--- */
      this.selectedBaths = this.addDetails[0].baths;
      this.selectedBeds = this.addDetails[0].beds;
      this.selectedDuration = this.addDetails[0].duration;
      this.selectedPaid = this.addDetails[0].payment;
      this.selectedMonth = this.addDetails[0].readyDate;
      this.FacilityBuilding = this.addDetails[0].building;
      this.FacilitySize = this.addDetails[0].size;
      this.toAge = this.addDetails[0].toAge;
      this.fromAge = this.addDetails[0].fromAge;
      this.toSalary = this.addDetails[0].toSalary;
      this.fromSalary = this.addDetails[0].fromSalary;
      this.furnished = this.addDetails[0].furnished;
      this.travelType = this.addDetails[0].travelRequired;

      /* ---CATEGORIES--- */
      this.categoryTitle = this.addDetails[0].cat;
      this.catID = this.addDetails[0].catID;
      this.subCategoryTitle = this.addDetails[0].subCat;
      this.subCatID = this.addDetails[0].subCatID;
      this.subToFirstTitle = this.addDetails[0].firstchild;
      this.firstchildID = this.addDetails[0].firstchildID;
      /* ---IMAGES--- */
      if (this.addDetails[0].adImagesData != null) {
        for (let ingrediant of this.addDetails[0].adImagesData) {
          this.imageurls.push(ingrediant.imageBase64);
        }
      }
      /* ---VIDEO--- */
      //debugger;
      if (this.addDetails[0].videO_PATH != null && this.addDetails[0].videO_PATH != "0"  && this.addDetails[0].videO_PATH != "" ) {
        //debugger;
        this.videoUrls.push(this.addDetails[0].videoBase64);
      }
      ////console.log(this.videoUrls);
    });
  }


  updateAds() {
    //debugger;
    const postingData = new adPosting();
    postingData.addID = this.addDetails[0].addID;
    postingData.catID = this.addDetails[0].catID;
    postingData.subCatID = this.addDetails[0].subCatID;
    postingData.title = this.title;
    postingData.price = this.price;
    postingData.description = this.addDetails[0].description;
    postingData.currencyID = this.currencyID;

    //debugger;
    if (this.catID.trim().toLowerCase() == 'd7b411fb-1c21-4f0a-88f7-34d6b789f67d') {
      if (this.validateClassified()) {
        const classifiedData = new classified();
        classifiedData.ageID = this.selectedAge;
        classifiedData.conditionID = this.selectedCondition;
        classifiedData.usageID = this.selectedUsage;

        postingData.postClassfied = classifiedData;
      }
      else {
        this.notifyService.showError('Provide the missing data !', 'Missing Data in Classified');
        return;
      }
    }
    else if (this.catID.trim().toLowerCase() == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f')// autos
    {
      if (this.validateAutosfied()) {
        const autosData = new postAutos;
        autosData.age = this.selectedAge;
        autosData.usage = this.selectedUsage;
        autosData.color = this.selectedColor;
        autosData.years = this.selectedYear;
        autosData.bodyCon = this.selectedBodyCondition;
        autosData.macCon = this.selectedMechenicalCondition;
        autosData.trimID = this.selectedTrim;
        autosData.bodyType = this.selectedBodyType;
        autosData.doors = this.selectedDoor;
        autosData.cylender = this.selectedCylender;
        autosData.transmission = this.selectedTransmission;
        autosData.hp = this.selectedHPList;
        autosData.ft = this.selectedFuelType;
        autosData.kilometer= this.kilometer;
        autosData.motercycleEngine = this.selectedEngineBike;
        autosData.warranty = this.selectedWarranty;

        postingData.postAutos = autosData;
      }
      else {
        this.notifyService.showError('Provide the missing data !', 'Missing Data in Autos');
        return;
      }
    }   
    else if (this.catID.trim().toLowerCase() == '3c064386-a300-4f90-8e18-f6da9f83aa1c')// Jobs
    {
      if (this.validateJobfied()) {
        //jobHiring settings
        if (this.subCatID.trim().toUpperCase() == '967CEED7-54C0-488E-8AA8-69E6045E6C05') {
          const jobHiring = new jobsAvailable;      
          jobHiring.jobEduID = this.selectedREducation;
          jobHiring.jobExpID = this.selectedRWE;
          jobHiring.jobCommitmentID = this.selectedRCommitment;
          jobHiring.jobsFromAge = this.fromAge;
          jobHiring.jobsToAge = this.toAge;
          jobHiring.jobsFromSalary = this.fromSalary;
          jobHiring.jobsToSalary = this.toSalary;
          jobHiring.jobsGenderID = this.selectedRGender;
          jobHiring.jobsNoOfPositions = this.selectedPosition;
          jobHiring.jobsShiftID = this.selectedRShift;
          jobHiring.jobsjDegreeTitle = this.selectedDegree;

          postingData.postJobs = jobHiring;
        }//job wanted sttings
        else if (this.subCatID.trim().toUpperCase() == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A') {
          const jobWan = new jobsWanted;
          jobWan.jobExpID = this.selectedRWE;
          jobWan.jobEduID = this.selectedREducation;
          jobWan.jobCommitmentID = this.selectedRCommitment;
          //jobWan.jobCompensation = this.selectedCompensation;

          postingData.postJobsWanted = jobWan;
        }
      }
      else {
        this.notifyService.showError('Provide the missing data !', 'Missing Data in Job');
        return;
      }
    }
    else if (this.catID.trim().toLowerCase() == '0372dc00-f9cb-4cb5-8b95-901ea8a02efa'||  this.catID.trim().toLowerCase() == '27bbb528-5666-4429-b153-7d6441c0efa1') // Property for rent & sale
        {
          //for rent
          if(this.validatePropertyFields()){
            const property = new postProperty();
            property.beds = this.selectedBeds;
            property.baths = this.selectedBaths;
            property.building = this.FacilityBuilding;
            property.furnished = 1;
            //property.size = this.FacilitySize;
            property.readyDate = this.selectedMonth;
            property.duration = this.selectedDuration;
            property.PM = this.selectedPaid;
            property.proLandlordName = "";
            property.proRegistrationNumber = "";
            property.proBrokerID = "";
            property.proBrokerName = "";
            property.proAffentNumber = "";

            postingData.postProperty = property;
          }
          else {
            this.notifyService.showError('Provide the missing data !', 'Missing Data in Autos');
            return;
          }
        }
    else {
      this.notifyService.showError('Provide the missing data !', 'Something Wrong');
      return;
    }

    this.adService.UpdateAdsDetails(postingData).subscribe(data => {
      if (data != 0) {
        this.successNotification();
      }
      else {
        this.errorNotification();
      }
    });    
  }
  
  successNotification() {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1500
    })
  }

  errorNotification() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Provide the missing data ‼️‼️</a>'
    })
  }

  onImagesUpdate() {
    //debugger;
    const adImagesData = new AdsImagesRequest();
    adImagesData.addID = this.addDetails[0].addID;
    adImagesData.catID = this.addDetails[0].catID;
    adImagesData.addedBy = this.addDetails[0].addedBy;
    var photos = [];

    var counter = 1;
    if (this.imageurls.length > 0) {
      this.imageurls.forEach(element => {
        var photo = {}; // here's your object
        photo["addID"] = counter,
          photo["addPhotoPath"] = element,
          photo["addPhotoIsActive"] = true,
          photo["addPHotoIsDeleted"] = false,
          photo["addPhotoAddedBy"] = 0,
          photo["addPhotoAddedDate"] = "07/02/2022",
          photo["addPhotoEditedBy"] = 0,
          photo["addPhotoEditedDate"] = "",
          photo["addIsThumbnail"] = false
        photos.push(photo);
        counter++;
      });
    }

    adImagesData.postPhotos = photos;
    //debugger;
    this.adService.updateAdsImages(adImagesData);
    if (adImagesData != null) {
      this.notifyService.showSuccess('Bravo!', 'Images Updated successfully !!');
    }
    else {
      //this.notifyService.showError('Provide the missing data !', 'Missing Data');
      this.notifyService.showError('Images not Update !!', 'Somthing Wrong');
    }
  }

  onVideoUpdate() {
    const postingData = new adPosting();
    postingData.addID = this.addDetails[0].addID;
    var Video = [];
    //debugger
    var counter = 1;
    if (this.videoUrls.length > 0) {
      this.videoUrls.forEach(element => {
        postingData.VIDEO_PATH = element;
      })
    }
    //debugger;
    //console.log(postingData);
    this.adService.updateproductVideo(postingData);
   // this.notifyService.showSuccess('Bravo!', 'Video Updated successfully !!');
    //this.successNotification();
    if(postingData != null){
      this.notifyService.showSuccess('Bravo!', 'Video Updated successfully !!');
    }
    else{
      this.notifyService.showError('Error!', 'Video NOT Updated !!');
    }
  }

  getSubCategories(selectedLevel: number, event: any) {
    //debugger;
    let catToSearch: string = "";
    if (selectedLevel == 0) {
      catToSearch = this.selectedCategory;
      this.categoryTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subCatID = '0';
      this.firstchildID = '0';
      this.selectedSubCatSecondChild = '0';
    }
    else if (selectedLevel == 1) {
      catToSearch = this.subCatID;
      this.subCategoryTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToFirstTitle = "";
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 2) {
      catToSearch = this.firstchildID;
      this.subToFirstTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 3) {
      catToSearch = this.selectedSubCatSecondChild;
      this.subToSecondTitle = event.target.options[event.target.options.selectedIndex].text;
    }

    this.userInterface.getSubCategories(catToSearch)
      .subscribe((data: CategoryDto[]) => {
        //debugger;
        if (selectedLevel == 0) {
          this.subCategories = data;
          this.levelOfSearch = 1;
          this.levelToShow = 1;
        }
        else if (selectedLevel == 1) {
          this.subCatFirstChildList = data;
          this.levelOfSearch = 2;
          if (this.subCatFirstChildList.length > 0) {
            this.levelToShow = 2;
          }
          else {
            this.levelToShow = 1;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 2) {
          this.subCatSecondChildList = data;
          this.levelOfSearch = 3;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 3;
          }
          else {
            this.levelToShow = 2;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 3) {
          this.levelOfSearch = 4;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 4;
          }
          else {
            this.levelToShow = 3;
            this.allselected = 1;
          }
        }
        ////console.log(data);
      });

    // if(this.subCategoryIDFromSearch)
    // {
    //   this.subCatID = this.subCategoryIDFromSearch;
    // }
  }

  removeImageEdit(i, imagepath) {
    this.imageDeleteFrom.value.id = i
    this.imageDeleteFrom.value.ImagePath = imagepath;
  }

  removeImage(i) {
    this.imageurls.splice(i, 1);
    this.imageurlsNew.splice(i, 1);
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      var totalFiles = this.imageurls.length + filesAmount;

      if (totalFiles > 5) {
        alert("file limit execede!");
      }
      else {
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            //this.imageurls.push({ base64String: event.target.result, });
            this.imageurls.push(event.target.result);
            //this.imageurlsNew.push({base64String: event.target.result});
            //this.imageurlsNew.push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }

  removeVideoEdit(videopath) {
    this.videoDeleteFrom.value.id = 0;
    this.videoDeleteFrom.value.Videopath = videopath;
  }
  removeVideo() {
    this.addDetails[0].videoBase64.splice(0);
    this.addDetails[0].videoBase64.splice(1);
  }

  // onSelectVideo(event) {
  //   if (event.target.files && event.target.files[0]) {
  //     var filesAmount = event.target.files.length;

  //     for (let i = 0; i < filesAmount; i++) {
  //       var reader = new FileReader();
  //       reader.onload = (event: any) => {
  //         this.videoUrls[0] = event.target.result;
  //       }
  //       reader.readAsDataURL(event.target.files[i]);
  //     }
  //   }
  // }
  onSelectVideo(event) {
    //debugger;
    let files = event.target.files;
    if (!this.validateFile(files[0].name)) {
     // this.errorNotification();
      this.notifyService.showError('Please Select Video .mp4 !!', 'Somthing Wrong');
      return false;
    }
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;     
    
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.videoUrls[0] = event.target.result;
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'mp4') {
        return true;
    }
    else {
        return false;
    }
  }

  DoorsList: dropdownLibrary[];
  selectedDoor: number;
  selectedDoorAlert = false;
  YearsList: dropdownLibrary[];
  selectedYear: number;
  selectedYearAlert = false;
  ColorsList: dropdownLibrary[];
  selectedColor: number;
  selectedColorAlert = false;

  BodyConditionList: dropdownLibrary[];
  selectedBodyCondition: number;
  selectedBcAlert = false;
  MechenicalConditionList: dropdownLibrary[];
  selectedMechenicalCondition: number;
  selectedMcAlert = false;
  TrimList: dropdownLibrary[];
  selectedTrim: number;
  selectedTrimAlert = false;
  BodyTypeList: dropdownLibrary[];
  selectedBodyType: number;
  selectedBtAlert = false;
  CylenderList: dropdownLibrary[];
  selectedCylender: number;
  selectedClAlert = false;
  TransmissionList: dropdownLibrary[];
  selectedTransmission: number;
  selectedTransmissionAlert = false;
  HPList: dropdownLibrary[];
  selectedHPList: number;
  selectedHpAlert = false;
  WarrantyList: dropdownLibrary[];
  selectedWarranty: number;
  selectedWlAlert = false;
  EngineList: dropdownLibrary[];
  selectedEngine: number;
  selectedElAlert = false;
  EngineBikeList: dropdownLibrary[];
  selectedEngineBike: number;
  selectedEbAlert = false;

  CountriesList: dropdownLibrary[];
  selectedCountries: number;

  FuelTypeList: dropdownLibrary[];
  selectedFuelType: number;
  selectedFTAlert = false;
  AutoAgeList: dropdownLibrary[];
  selectedAutoAge: number;
  selectedAutoUsage: number;
  AutoUsageList: dropdownLibrary[];
  selectedAutoAgeAlert = false;
  //selecteAutodAge
  selectedAutoCondition: number;
  selectedAutoUsageAlert = false;
  selectedExperienceAlert = false;
  selectedEducationAlert = false;
  selectedCommitementAlert = false;
  selectedShiftAlert = false;


  CurrienciesList: dropdownLibrary[];
  AgeList: dropdownLibrary[];
  selectedAgeClassAlert = false;
  UsageList: dropdownLibrary[];
  selectedUsageAlert = false;
  PositionList: dropdownLibrary[];
  selectedPosition: number;
  ConditionList: dropdownLibrary[];
  selectedConditionAlert = false;
  selectedDegree = '';
  selectedCompensation = '';
  selectedCompensationAlert = false;
  RWEList: dropdownLibrary[];
  selectedRWE: number;
  REducationList: dropdownLibrary[];
  selectedREducation: number;
  RGenderList: dropdownLibrary[];
  selectedRGender: number;
  RCommitmentList: dropdownLibrary[];
  selectedRCommitment: number;
  RShiftList: dropdownLibrary[];
  selectedRShift: number;

  autoFacilitiesNew = [];
  public scrollbarOptions = { axis: 'y', theme: 'light' };
  minSalValue: number = 0;
  maxSalValue: number = 15000;
  minkmValue: number = 0;
  maxkmValue: number = 400;
  optionsKM: Options = {
    floor: 0,
    ceil: 1000
  };
  optionsSal: Options = {
    floor: 0,
    ceil: 100000
  };

  minAgeValue: number = 25;
  maxAgeValue: number = 35;
  optionsAge: Options = {
    floor: 15,
    ceil: 75
  };

  selectedMonth = '';
  selectedMonthAlert = false;
  selectedMonthShow = false;
  FacilityReadyDate = '';
  FacilityBuilding = '';
  FacilityBuildingAlert = false;
  showBuildingOption = false;
  FacilitySize = '';
  showSizeOption = false;
  facilitySizeALert = false;
  //furnished = false;
  furnishedShow = false;
  furnishedIsChecked = false;
  LanLord: any;
  showSalary: any;
  travelType: any;
  showLanLordOption = false;
  Agent = "";
  Parking = false;
  Balcony = false;
  BalconyIsChecked = false;
  ParkingIsChecked = false;
  showBalconyOption = false;
  BedsList: dropdownLibrary[];
  selectedBeds: number;
  selectedBedsAlert = false;
  showBedOptions = false;
  BathsList: dropdownLibrary[];
  selectedBaths: number;
  selectedBathsAlert = false;
  showBathOptions = false;

  DurationList: dropdownLibrary[];
  selectedDuration: number;
  selectedDurationAlert = false;
  showDurationOPtion = false;

  PaidList: dropdownLibrary[];
  selectedPaid: number;
  selectedPaidAlert = false;
  showRentPaidOption = false;
  selectedCurrency: number;

  selectedPositionAlert: boolean;
  selectedGenderAlert: boolean;


  validateClassified() {
    var result = true;
    if (typeof this.selectedAge == 'undefined' || !this.selectedAge || this.selectedAge == 0) {
      this.selectedAgeClassAlert = true;
      result = false;
    }
    else if (typeof this.selectedUsage == 'undefined' || !this.selectedUsage || this.selectedUsage == 0) {
      this.selectedAgeClassAlert = false;
      this.selectedUsageAlert = true;
      result = false;
    }
    else if (typeof this.selectedCondition == 'undefined' || !this.selectedCondition || this.selectedCondition == 0) {
      this.selectedAgeClassAlert = false;
      this.selectedUsageAlert = false;
      this.selectedConditionAlert = true;
      result = false;
    }
    else {
      this.selectedAgeClassAlert = false;
      this.selectedUsageAlert = false;
      this.selectedConditionAlert = false;
    }
    return result;
  }
  validateAutosfied() {
    var result = true;
    if (this.subCatID.trim().toUpperCase() == '042F8B36-2F6E-4A05-8DF0-2BC49710D67C') {
      if (typeof this.selectedAge == 'undefined' || !this.selectedAge || this.selectedAge == 0) {
        this.selectedAutoAgeAlert = true;
        result = false;
      }
      else if (typeof this.selectedUsage == 'undefined' || !this.selectedUsage || this.selectedUsage == 0) {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = true;
        result = false;
      }
      else if (typeof this.selectedWarranty == 'undefined' || !this.selectedWarranty || this.selectedWarranty == 0) {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedWlAlert = true;
        result = false;
      }
      else {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedWlAlert = false;
      }
    }
    else if (this.subCatID.trim().toUpperCase() == '79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.subCatID.trim().toUpperCase() == 'FE1C13DE-9342-4EFB-824B-806DA0B8E3FE') {
      if (typeof this.selectedYear == 'undefined' || !this.selectedYear || this.selectedYear == 0) {
        this.selectedYearAlert = true;
        result = false;
      }
      else if (typeof this.selectedDoor == 'undefined' || !this.selectedDoor || this.selectedDoor == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = true;
        result = false;
      }
      else if (typeof this.selectedColor == 'undefined' || !this.selectedColor || this.selectedColor == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = true;
        result = false;
      }
      else if (typeof this.selectedBodyCondition == 'undefined' || !this.selectedBodyCondition || this.selectedBodyCondition == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = true;
        result = false;
      }
      else if (typeof this.selectedMechenicalCondition == 'undefined' || !this.selectedMechenicalCondition || this.selectedMechenicalCondition == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = true;
        result = false;
      }
      else if (typeof this.selectedTrim == 'undefined' || !this.selectedTrim || this.selectedTrim == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedTrimAlert = true;
        result = false;
      }
      else if (typeof this.selectedBodyType == 'undefined' || !this.selectedBodyType || this.selectedBodyType == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedTrimAlert = false;
        this.selectedBtAlert = true;
        result = false;
      }
      else if (typeof this.selectedCylender == 'undefined' || !this.selectedCylender || this.selectedCylender == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedTrimAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = true;
        result = false;
      }
      else if (typeof this.selectedTransmission == 'undefined' || !this.selectedTransmission || this.selectedCylender == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedTrimAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTransmissionAlert = true;
        result = false;
      }
      else if (typeof this.selectedFuelType == 'undefined' || !this.selectedFuelType || this.selectedFuelType == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTrimAlert = false;
        this.selectedTransmissionAlert = false;
        this.selectedFTAlert = true;
        result = false;
      }
      else if (typeof this.selectedHPList == 'undefined' || !this.selectedHPList || this.selectedHPList == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTransmissionAlert = false;
        this.selectedFTAlert = false;
        this.selectedTrimAlert = false;
        this.selectedHpAlert = true;
        result = false;
      }
      else if (typeof this.selectedWarranty == 'undefined' || !this.selectedWarranty || this.selectedWarranty == 0) {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTrimAlert = false;
        this.selectedTransmissionAlert = false;
        this.selectedFTAlert = false;
        this.selectedHpAlert = false;
        this.selectedWlAlert = true;
        result = false;
      }
      else {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTransmissionAlert = false;
        this.selectedFTAlert = false;
        this.selectedTrimAlert = false;
        this.selectedWlAlert = false;
        this.selectedElAlert = false;
        this.selectedHpAlert = false;
        this.selectedEbAlert = false;
        this.selectedWlAlert = false;
      }
    }
    else if (this.subCatID.trim().toUpperCase() == '6240A3B2-15B4-49AC-BF96-B30311BAEC60') {
      if (typeof this.selectedYear == 'undefined' || !this.selectedYear || this.selectedYear == 0) {
        this.selectedYearAlert = true;
        result = false;
      }
      else if (typeof this.selectedColor == 'undefined' || !this.selectedColor || this.selectedColor == 0) {
        this.selectedYearAlert = false;
        this.selectedColorAlert = true;
        result = false;
      }
      else if (typeof this.selectedBodyCondition == 'undefined' || !this.selectedBodyCondition || this.selectedBodyCondition == 0) {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = true;
        result = false;
      }
      else if (typeof this.selectedMechenicalCondition == 'undefined' || !this.selectedMechenicalCondition || this.selectedMechenicalCondition == 0) {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = true;
        result = false;
      }
      else if (typeof this.selectedWarranty == 'undefined' || !this.selectedWarranty || this.selectedWarranty == 0) {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedWlAlert = true;
        result = false;
      }
      else if (typeof this.selectedEngineBike == 'undefined' || !this.selectedEngineBike || this.selectedEngineBike == 0) {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedWlAlert = false;
        this.selectedEbAlert = true;
        result = false;
      }
      else {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedWlAlert = false;
      }
    }
    else if (this.subCatID.trim().toUpperCase() == '9C89C78F-B925-4129-A95B-D4D750954AA3') {
      if (typeof this.selectedAutoAge == 'undefined' || !this.selectedAutoAge || this.selectedAutoAge == 0) {
        this.selectedAutoAgeAlert = true;
        result = false;
      }
      else if (typeof this.selectedAutoUsage == 'undefined' || !this.selectedAutoUsage || this.selectedAutoUsage == 0) {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = true;
        result = false;
      }
      else {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
      }
    }
    else {
      this.selectedYearAlert = false;
      this.selectedDoorAlert = false;
      this.selectedColorAlert = false;
      this.selectedBcAlert = false;
      this.selectedMcAlert = false;
      this.selectedAutoAgeAlert = false;
      this.selectedAutoUsageAlert = false;
      this.selectedBtAlert = false;
      this.selectedClAlert = false;
      this.selectedTransmissionAlert = false;
      this.selectedFTAlert = false;
      this.selectedTrimAlert = false;
      this.selectedWlAlert = false;
      this.selectedElAlert = false;
      this.selectedHpAlert = false;
      this.selectedEbAlert = false;
      this.selectedWlAlert = false;
      this.selectedAutoAgeAlert = false;
      this.selectedAutoUsageAlert = false;
      this.selectedWlAlert = false;
      result = true;
    }
    return result;
  }
  validateJobfied() {
    var result = true;
    if (this.subCatID.trim().toUpperCase() == '967CEED7-54C0-488E-8AA8-69E6045E6C05') {
      if (typeof this.selectedPosition == 'undefined' || !this.selectedPosition || this.selectedPosition == 0) {
        this.selectedPositionAlert = true;
        result = false;
      }
      else if (typeof this.selectedRGender == 'undefined' || !this.selectedRGender || this.selectedRGender == 0) {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = true;
        result = false;
      }
      else if (typeof this.selectedRWE == 'undefined' || !this.selectedRWE || this.selectedRWE == 0) {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = true;
        result = false;
      }
      else if (typeof this.selectedREducation == 'undefined' || !this.selectedREducation || this.selectedREducation == 0) {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = true;
        result = false;
      }
      else if (typeof this.selectedRCommitment == 'undefined' || !this.selectedRCommitment || this.selectedRCommitment == 0) {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = true;
        result = false;
      }
      else if (typeof this.selectedRShift == 'undefined' || !this.selectedRShift || this.selectedRShift == 0) {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = false;
        this.selectedShiftAlert = true;
        result = false;
      }
      else {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = false;
        this.selectedShiftAlert = false;
        result = true;
      }
    }
    else if (this.subCatID.trim().toUpperCase() == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A') {
      if (typeof this.selectedCompensation == 'undefined' || !this.selectedCompensation || this.selectedCompensation == "") {
        this.selectedCompensationAlert = true;
        result = false;
      }
      else if (typeof this.selectedRWE == 'undefined' || !this.selectedRWE || this.selectedRWE == 0) {
        this.selectedCompensationAlert = false;
        this.selectedExperienceAlert = true;
        result = false;
      }
      else if (typeof this.selectedREducation == 'undefined' || !this.selectedREducation || this.selectedREducation == 0) {
        this.selectedCompensationAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = true;
        result = false;
      }
      else if (typeof this.selectedRCommitment == 'undefined' || !this.selectedRCommitment || this.selectedRCommitment == 0) {
        this.selectedCompensationAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = true;
        result = false;
      }
      else {
        this.selectedCompensationAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = false;
        result = true;
      }
    }
    return result;
  }
  validatePropertyFields()
  {
    var result = true;
    if (this.firstchildID.trim().toUpperCase() == '220EC902-8ED1-4128-BD35-F3C4318EF5AC' || this.firstchildID.trim().toUpperCase() == '48E2CB62-A9E4-4103-8751-248646CB1D0A' || this.firstchildID.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' || this.firstchildID.trim().toUpperCase() == '59C7CD4B-B627-4C88-8303-91FA48EA72AD' || this.firstchildID.trim().toUpperCase() == 'BF6DC65D-8B63-4C26-B4F6-5FE9E8530245' || this.firstchildID.trim().toUpperCase() == '65134CA9-E6E2-4FD9-93FF-83BC56C09A86' || this.firstchildID.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' || this.firstchildID.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' || this.firstchildID.trim().toUpperCase() == 'A884609A-AEAF-4FB3-9FFA-879992B88A2E' || this.firstchildID.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' || this.firstchildID.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E' || this.firstchildID.trim().toUpperCase() == 'E47081E1-2622-4255-A89D-34FF3ED38AC4' || this.firstchildID.trim().toUpperCase() == '9D477485-41B4-4E98-A3C4-3903D84C71DE') {
      if (typeof this.selectedMonth == 'undefined' || !this.selectedMonth || this.selectedMonth == "0") {
        this.selectedMonthAlert = true;
        result = false;
      }
      else if (typeof this.selectedDuration == 'undefined' || !this.selectedDuration || this.selectedDuration == 0) {
        this.selectedMonthAlert = false;
        this.selectedDurationAlert = true;
        result = false;
      }
      else if (typeof this.selectedPaid == 'undefined' || !this.selectedPaid || this.selectedPaid == 0) {
        this.selectedMonthAlert = false;
        this.selectedDurationAlert = false;
        this.selectedPaidAlert = true;
        result = false;
      }
      else if (this.firstchildID.trim().toUpperCase() == '220EC902-8ED1-4128-BD35-F3C4318EF5AC' || this.firstchildID.trim().toUpperCase() == '0D71E6F8-7F0D-47C0-9284-AC5B52971641' || this.firstchildID.trim().toUpperCase() == '48E2CB62-A9E4-4103-8751-248646CB1D0A' || this.firstchildID.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' || this.firstchildID.trim().toUpperCase() == '59C7CD4B-B627-4C88-8303-91FA48EA72AD' || this.firstchildID.trim().toUpperCase() == '65134CA9-E6E2-4FD9-93FF-83BC56C09A86' || this.firstchildID.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' || this.firstchildID.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' || this.firstchildID.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E') {
        if (typeof this.selectedMonth == 'undefined' || !this.selectedMonth || this.selectedMonth == "0") {
          this.selectedMonthAlert = true;
          result = false;
        }
        else if (typeof this.selectedBeds == 'undefined' || !this.selectedBeds || this.selectedBeds == 0) {
          this.selectedMonthAlert = false;
          this.selectedBedsAlert = true;
          result = false;
        }
        else if (typeof this.selectedBaths == 'undefined' || !this.selectedBaths || this.selectedBaths == 0) {
          this.selectedMonthAlert = false;
          this.selectedBedsAlert = false;
          this.selectedBathsAlert = true;
          result = false;
        }
        else {
          this.selectedMonthAlert = false;
          this.selectedBedsAlert = false;
          this.selectedBathsAlert = false;
          this.selectedDurationAlert = false;
          this.selectedPaidAlert = false;
          return result;
        }
      }
    }
    else if(this.firstchildID.trim().toUpperCase() == '9D7F5A44-A4AE-4446-AA59-D46C81799190' || this.subCatID.trim().toUpperCase() == '85A400C4-0F9B-4D2D-AD3D-5982DBDE8974' || this.firstchildID.trim().toUpperCase() == '7FE50536-4CE2-4A02-82F0-B27DF7047162' ){
      if (typeof this.selectedMonth == 'undefined' || !this.selectedMonth || this.selectedMonth == "0") {
        this.selectedMonthAlert = true;
        result = false;
      }
      else if (typeof this.selectedBeds == 'undefined' || !this.selectedBeds || this.selectedBeds == 0) {
        this.selectedMonthAlert = false;
        this.selectedBedsAlert = true;
        result = false;
      }
      else if (typeof this.selectedBaths == 'undefined' || !this.selectedBaths || this.selectedBaths == 0) {
        this.selectedMonthAlert = false;
        this.selectedBedsAlert = false;
        this.selectedBathsAlert = true;
        result = false;
      }
      else {
        this.selectedMonthAlert = false;
        this.selectedBedsAlert = false;
        this.selectedBathsAlert = false;
        this.selectedDurationAlert = false;
        this.selectedPaidAlert = false;
        return result;
      }
    }
    else if(this.firstchildID.trim().toUpperCase() == 'ABFEAF24-F91F-425B-830C-B044BB177DB9' || this.firstchildID.trim().toUpperCase() == '2E0E4429-081D-4CE3-9B6D-9BF1CDA0EAE1' || this.subCatID.trim().toUpperCase() == '58F1AC77-DF13-469B-8457-38DD29AF3266' || this.firstchildID.trim().toUpperCase() == '23D83481-6D73-46CB-B584-1468D0A6C057' || this.firstchildID.trim().toUpperCase() == 'B5BE8248-7BFB-4A82-BFC1-E8243312B0F8' || this.firstchildID.trim().toUpperCase() == 'EC1C7024-1344-435C-ACB7-D6F262658E41' || this.firstchildID.trim().toUpperCase() == '466E8421-D720-4217-843E-00A0C923BA14'){
      if (typeof this.selectedMonth == 'undefined' || !this.selectedMonth || this.selectedMonth == "0") {
        this.selectedMonthAlert = true;
        result = false;
      }
      else{
        this.selectedMonthAlert = false;
        return result;
      }
    }
    else {
      this.selectedMonthAlert = false;
      this.selectedBedsAlert = false;
      this.selectedBathsAlert = false;
      this.selectedDurationAlert = false;
      this.selectedPaidAlert = false;
      return result;
    }
    return result;

  }



  fillDropDowns() {   
    this.CurrienciesList = [
      { id: 0, title: "Select Curriencies" },
      { id: 1, title: "PKR" },
      { id: 2, title: "INR" },
      { id: 3, title: "DOLLER" },
      { id: 4, title: "OR" },
      { id: 5, title: "AED" },
      { id: 6, title: "SR" },
      { id: 7, title: "Other" }
    ] 
    this.DoorsList = [
      { id: 0, title: "Select Door" },
     // { id: 1, title: "1 Door" },
      { id: 1, title: "2 Doors" },
      { id: 2, title: "3 Doors" },
      { id: 3, title: "4 Doors" },
      { id: 4, title: "5+ Doors" },
    ]
    // this.YearsList = [
    //   { id: 0, title: "Select Year" },
    //   { id: 2001, title: "2001" },
    //   { id: 2002, title: "2002" },
    //   { id: 2003, title: "2003" },
    //   { id: 2004, title: "2004" },
    //   { id: 2005, title: "2005" },
    //   { id: 2006, title: "2006" },
    //   { id: 2007, title: "2007" },
    //   { id: 2008, title: "2008" },
    //   { id: 2009, title: "2009" },
    //   { id: 2010, title: "2010" },
    //   { id: 2011, title: "2011" },
    //   { id: 2012, title: "2012" },
    //   { id: 2013, title: "2013" },
    //   { id: 2014, title: "2014" },
    //   { id: 2015, title: "2015" },
    //   { id: 2016, title: "2016" },
    //   { id: 2017, title: "2017" },
    //   { id: 2018, title: "2018" },
    //   { id: 2019, title: "2019" },
    //   { id: 2020, title: "2020" },
    //   { id: 2021, title: "2021" },
    //   { id: 2022, title: "2022" }
    // ]
    this.YearsList = [
      { id: 0, title: "Select Year" },
      { id: 52, title: "2001" },
      { id: 53, title: "2002" },
      { id: 54, title: "2003" },
      { id: 55, title: "2004" },
      { id: 56, title: "2005" },
      { id: 57, title: "2006" },
      { id: 58, title: "2007" },
      { id: 59, title: "2008" },
      { id: 60, title: "2009" },
      { id: 61, title: "2010" },
      { id: 62, title: "2011" },
      { id: 63, title: "2012" },
      { id: 64, title: "2013" },
      { id: 65, title: "2014" },
      { id: 66, title: "2015" },
      { id: 67, title: "2016" },
      { id: 68, title: "2017" },
      { id: 69, title: "2018" },
      { id: 70, title: "2019" },
      { id: 71, title: "2020" },
      { id: 72, title: "2021" }
    ]
    this.ColorsList = [
      { id: 0, title: "Select Color" },
      { id: 1, title: "Blue" },
      { id: 2, title: "Black", },
      { id: 3, title: "Brown", },
      { id: 4, title: "Burgendy", },
      { id: 5, title: "Gold", },
      { id: 6, title: "Green" },
      { id: 7, title: "Grey" },
      { id: 8, title: "Purple" },
      { id: 9, title: "Pink" },
      { id: 10, title: "White" },
      { id: 11, title: "Silver" },
      { id: 12, title: "Other" },
      { id: 13, title: "Red" }
    ]
    this.BodyConditionList = [
      { id: 0, title: "Select Body Condition" },
      { id: 1, title: "Perfect" },
      { id: 2, title: "No Accident" },
      { id: 3, title: "Bit Wear & Tear" },
      { id: 4, title: "Normal Wear & Tear" },
      { id: 5, title: "Lots of Wear & Tear" }

    ]
    this.MechenicalConditionList = [
      { id: 0, title: "Select Mec- Condition" },
      { id: 1, title: "Perfect" },
      { id: 2, title: "Minor faults all fixed" },
      { id: 3, title: "Major faults all fixed" },
      { id: 4, title: "Major fixed Small remains" },
      { id: 5, title: "Ongoing major & minor" }

    ]
    this.TrimList = [
      { id: 0, "title": "Select Trim" },
      { id: 1, "title": "Other" }
    ]
    this.BodyTypeList = [
      { id: 0, title: "Select Body Type" },
      { id: 1, title: "Coupe" },
      { id: 2, title: "CrossOver" },
      { id: 3, title: "Hard top convertable" },
      { id: 4, title: "Hetchback" },
      { id: 5, title: "Sedan" },
      { id: 6, title: "PickUp Truck" },
      { id: 7, title: "Soft top convertable" },
      { id: 8, title: "Sports" },
      { id: 9, title: "SUV" },
      { id: 10, title: "Van" },
      { id: 11, title: "Utility Truck" },
      { id: 12, title: "Wagon" },
      { id: 13, title: "Other" }
    ]
    this.CylenderList = [
      { id: 0, title: "Select Cylender" },
      { id: 1, title: "3" },
      { id: 2, title: "5" },
      { id: 3, title: "6" },
      { id: 4, title: "8" },
      { id: 5, title: "10" },
      { id: 6, title: "12+" },
      { id: 7, title: "4" },
      { id: 8, title: "7" }
    ]
    this.TransmissionList = [
      { id: 0, title: "Select Transmission" },
      { id: 1, title: "Automatic" },
      { id: 2, title: "Manual" },
    ]
    this.HPList = [
      { id: 0, title: "Select HP" },
      { id: 1, title: "Less Then 150" },
      { id: 2, title: "150 - 200" },
      { id: 3, title: "200 - 300" },
      { id: 4, title: "300 - 400" },
      { id: 5, title: "400 - 500" },
      { id: 6, title: "500 - 600" },
      { id: 7, title: "600 - 700" },
      { id: 8, title: "700 - 800" },
      { id: 9, title: "800 - 900" },
      { id: 10, title: "900 +" },
      { id: 11, title: "unknown" }
    ]
    this.FuelTypeList = [
      { id: 0, title: "Select Engine" },
      { id: 1, title: "Gasoline" },
      { id: 2, title: "Diesel" },
      { id: 3, title: "Hybrid" },
      { id: 4, title: "Electric" }
    ]

     this.autoFacilitiesNew = [
      {id:"1"	,  title:"Air Conditioned",	enabled:"false"},
      {id:"2"	,  title:"Anti Theft",		enabled:"false"},
      {id:"3"	,  title:"AM/FM",			enabled:"false"},
      {id:"4"	,  title:"Audio",			enabled:"false"},
      {id:"5"	,  title:"Bluetooth",		enabled:"false"},
      {id:"6"	,  title:"Body Kit",		enabled:"false"},
      {id:"7"	,  title:"Brush Guard",		enabled:"false"},
      {id:"8"	,  title:"Cassete Player",	enabled:"false"},
      {id:"9"	,  title:"CD Player",		enabled:"false"},
      {id:"10",	title:"Climate Control",enabled:"false"},
      {id:"11",	title:"Cooled Seats",	enabled:"false"},
      {id:"12",	title:"DVD Player",		enabled:"false"},
      {id:"13",	title:"Fog Lights",		enabled:"false"},
      {id:"14",	title:"Heated Seats",	enabled:"false"},
      {id:"15",	title:"Keyless start",	enabled:"false"},
      {id:"16",	title:"Leather seats",	enabled:"false"},
      {id:"17",	title:"Moon roof",		enabled:"false"},
      {id:"18",	title:"Sun roof",		enabled:"false"},
      {id:"19",	title:"Power seats",	enabled:"false"},
      {id:"20",	title:"Power mirror",	enabled:"false"},
      {id:"21",	title:"Cruise control",	enabled:"false"},
      {id:"22",	title:"Power stearing",	enabled:"false"},
      {id:"23",	title:"N2O",			enabled:"false"},
      {id:"24",	title:"Front airbags",	enabled:"false"},
      {id:"25",	title:"side airbags",	enabled:"false"}
    ]

    this.WarrantyList = [
      { id: 0, title: "Select Warranty" },
      { id: 1, title: "Yes" },
      { id: 2, title: "No" },
      { id: 3, title: "Does not apply" }
    ]
    this.EngineList = [
      { id: 0, title: "Select Engine" },
      { id: 1, title: "Gasoline" },
      { id: 2, title: "Diesel" },
      { id: 3, title: "Hybrid" },
      { id: 4, title: "Electric" }
    ]
    this.EngineBikeList = [
      { id: 0, title: "Select Engine" },
      { id: 1, title: "Less then 250CC" },
      { id: 2, title: "250 - 500 CC" },
      { id: 3, title: "500 - 750 CC" },
      { id: 4, title: "750 - 1000 CC" },
      { id: 5, title: "More then 1000CC" }
    ]
    this.CountriesList = [
      { id: 0, title: "Select" },
      { id: 1, title: "United Arab Emirates" },
      { id: 2, title: "Pakistan" },
      { id: 3, title: "Saudi Arabia" },
      { id: 4, title: "KUWAIT" },
      { id: 5, title: "Oman" },
      { id: 7, title: "India" },
      { id: 8, title: "United Kingdom" },
      { id: 9, title: "United States" },
      { id: 10, title: "China" },
      { id: 11, title: "Japan" },
      { id: 12, title: "Canada" }
    ]    
    this.AgeList = [
      { id: 0, title: "Select Age" },
      { id: 1, title: "New" },
      { id: 3, title: "0-1 month" },
      { id: 4, title: "1-6 months" },
      { id: 5, title: "6-12 months" },
      { id: 6, title: "1-2 years" },
      { id: 7, title: "2-5 years" },
      { id: 8, title: "5-10 years" },
      { id: 9, title: "10+ years" }
    ]
    this.AutoAgeList = [
      { id: 0, title: "Select Age" },
      { id: 1, title: "New" },
      { id: 3, title: "0-1 month" },
      { id: 4, title: "1-6 months" },
      { id: 5, title: "6-12 months" },
      { id: 6, title: "1-2 years" },
      { id: 7, title: "2-5 years" },
      { id: 8, title: "5-10 years" },
      { id: 9, title: "10+ years" }
    ]
    this.UsageList = [
      { id: 0, title: "Select Usage" },
      { id: 1, title: "Brand New" },
      { id: 2, title: "Origional Packaging" },
      { id: 3, title: "Not Used" },
      { id: 4, title: "Used Some Time" },
      { id: 5, title: "Used Average Time" }
    ]
    
    this.ConditionList = [
      { id: 0, title: "Select Condition" },
      { id: 1, title: "Brand New " },
      { id: 2, title: "Perfect Inside & Out" },
      { id: 3, title: "A Bit wear & Tear" },
      { id: 4, title: "Normal wear & tear" },
      { id: 5, title: "Above average wear & tear" }
    ]
    this.AutoUsageList = [
      { id: 0, title: "Select Usage" },
      { id: 1, title: "Brand New" },
      { id: 2, title: "Origional Packaging" },
      { id: 3, title: "Not Used" },
      { id: 4, title: "Used Some Time" },
      { id: 5, title: "Used Average Time" }
    ]   
    this.PositionList = [
      { id: 0, title: "Select Position" },
      { id: 1, title: "1" },
      { id: 2, title: "2 - 5" },
      { id: 3, title: "5 - 10 " },
      { id: 4, title: "10 - 20 " },
      { id: 5, title: "20 - 50 " }
    ]
    this.RWEList = [
      { id: 0, title: "Select " },
      { id: 1, title: "0 -1 year" },
      { id: 2, title: "1 - 2 years" },
      { id: 3, title: "2 - 3 years" },
      { id: 4, title: "3 - 5 years" },
      { id: 5, title: "5 - 10 years" },
      { id: 6, title: "10+ years" }
    ]
    this.REducationList = [
      { id: 0, title: "Select Education" },
      { id: 1, title: "High School" },
      { id: 2, title: "Bachelors" },
      { id: 3, title: "Master" },
      { id: 4, title: "PHD" },
      { id: 5, title: "Other" },
      { id: 6, title: "10+ years" }
    ]
    this.RGenderList = [
      { id: 0, title: "Select Gender" },
      { id: 1, title: "Male" },
      { id: 2, title: "Female" },
      { id: 3, title: "No Preference" }

    ]
    this.RCommitmentList = [
      { id: 0, title: "Select Commitment" },
      { id: 1, title: "Full Time" },
      { id: 2, title: "Part Time" },
      { id: 3, title: "Contract" },
      { id: 4, title: "Temporary" },
      { id: 5, title: "Other" }

    ]
    this.RShiftList = [
      { id: 0, title: "Select Shift" },
      { id: 1, title: "Day" },
      { id: 2, title: "Afternoon" },
      { id: 3, title: "Night" },
      { id: 4, title: "Rotating" }
    ]
    this.BedsList = [
      { id: 0, title: "Select Beds" },
      { id: 1, title: "1 Bed" },
      { id: 2, title: "2 Beds" },
      { id: 3, title: "3 Beds" },
      { id: 4, title: "4 Beds" },
      { id: 5, title: "5 Beds" }
    ]
    this.BathsList = [
      { id: 0, title: "Select Bath" },
      { id: 1, title: "1 Bath" },
      { id: 2, title: "2 Baths" },
      { id: 3, title: "3 Baths" },
      { id: 4, title: "4 Baths" },
      { id: 5, title: "5 Baths" }
    ]
    this.DurationList = [
      { id: 0, title: "Select Duration" },
      { id: 1, title: "Short Term (Monthly)" },
      { id: 2, title: "Quarterly" },
      { id: 3, title: "Bi-Yearly" },
      { id: 4, title: "Yearly" }
    ]
    this.PaidList = [
      { id: 0, title: "Select Payment" },
      { id: 1, title: "Daily" },
      { id: 2, title: "Monthly" },
      { id: 3, title: "Quarterly" },
      { id: 4, title: "Bi-Yearly" },
      { id: 5, title: "Yearly" }
    ]
  }

}