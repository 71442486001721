import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { productRequest } from '../../models/Company/productRequest'
import { productDetails } from '../../models/Company/productDetail'
import { Product, UpdateProduct } from 'src/app/product/show-product/Product';
import { catchError, map } from 'rxjs/operators';
import { productImagesRequest } from '../../models/Company/productImagesRequest';
import { ProViewCount } from '../../models/likesDislikes/ViewCount-pro';
import { NotificationService } from '../notification.service';
import { ShippingAddress } from 'src/app/user-product/ShippingAddress';
import { adds } from '../../models/Adds/adds.model';


@Injectable()
export class adProductService{
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     
private result:number;
ordersChanged = new Subject<any>();
CartItemReceived = new EventEmitter<Product>();
private resultLike:string;
constructor(private httpclient:HttpClient, private notifyService: NotificationService){}

submitview(viewDetail:ProViewCount){     
  //debugger;
  this.httpclient.post<number>(this.baseUrl+'services/ProViewCount/AddViewbyID',JSON.stringify(viewDetail),this.httpOptions).subscribe(data => {
    //debugger; 
    //console.log(data);           
return this.result = data;
  })               
}

  submitProductDetails(productDetails:productRequest){  
    //debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/Product/adPost',JSON.stringify(productDetails),this.httpOptions)          
  }
  updateProductImages(productDetails:productImagesRequest){  
    //debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/Product/updateImages',JSON.stringify(productDetails),this.httpOptions)             
  }

  updateproductVideo(productDetails:productRequest){  
    //debugger;
    return this.httpclient.post<number>(this.baseUrl+'services/Product/updateVideo',JSON.stringify(productDetails),this.httpOptions)            
  }
  

  getProductDetails():Observable<any[]>{
    return this.httpclient.get<any>(this.baseUrl+'services/Product/getproductinfo');
  }

  private readonly _page = new BehaviorSubject<number>(1);
  readonly page$ = this._page.asObservable(); 
  
  get page(): number {
    return this._page.getValue();
  }

  set page(val: number) {
    this._page.next(val);
  }
  
  public pageSize: number = 3;
  public readonly _pageSizeSubject = new Subject<number>();
  public pageSizeChanges$ = this._pageSizeSubject.asObservable(); 

  // Adding New product to favourite if logged else to localStorage
  FavouriteProduct(data: Product): void {
    const a: Product[] = JSON.parse(localStorage.getItem("avf_item")) || [];
    a.push(data);
    setTimeout(() => {
      localStorage.setItem("avf_item", JSON.stringify(a));
      this.notifyService.showSuccess('Successfully !!', 'Add to Favorite');
    }, 1500);
  }
  FavouriteAds(data: adds): void {
    const a: adds[] = JSON.parse(localStorage.getItem("avf_ads")) || [];
    a.push(data);
    setTimeout(() => {
      localStorage.setItem("avf_ads", JSON.stringify(a));
      this.notifyService.showSuccess('Successfully !!', 'Add to Favorite');
    }, 1500);
  }
  FavouriteDeals(data: adds): void {
    const a: adds[] = JSON.parse(localStorage.getItem("avf_deal")) || [];
    a.push(data);
    setTimeout(() => {
      localStorage.setItem("avf_deal", JSON.stringify(a));
      this.notifyService.showSuccess('Successfully !!', 'Add to Favorite');
    }, 1500);
  }

  cartItems: Product[] = [];
  getTotalValue():number {
    //let total: number = 0;
    let sum = this.cartItems.reduce(
        (a, b) => {a = a + parseInt(b.proPrice) * b.proQuantity; return a;}, 0);
    return sum;
  }

  CartItems:Product[];
  addToCart(orders: Product): void {
    debugger;
    this.CartItems = JSON.parse(localStorage.getItem("active_item")) || [];
    let indexToUpdate = this.CartItems.findIndex(item => item.proID === orders.proID);
    if(indexToUpdate !=-1)
    {
      this.CartItems[indexToUpdate].proQuantity =  this.CartItems[indexToUpdate].proQuantity +1;
    }
    else
    {
      this.CartItems.push(orders);
    }
      
    setTimeout(() => {
        localStorage.setItem("active_item", JSON.stringify(this.CartItems));    
        this.notifyService.showSuccess('Successfully !!', 'Add to Cart'); 
    }, 500); 
    // if(this.CartItems.length == 0){
    //   this.CartItems.push(orders);
    //   setTimeout(() => {
    //     localStorage.setItem("active_item", JSON.stringify(this.CartItems));    
    //     this.notifyService.showSuccess('Successfully !!', 'Add to Cart'); 
    //   }, 500); 
    // }
    // else if(this.CartItems.length > 0){
    //   for(let i of this.CartItems){
    //     if(i.proID != orders.proID){
    //       this.CartItems.push(orders);
    //       setTimeout(() => {
    //         localStorage.setItem("active_item", JSON.stringify(this.CartItems));    
    //         this.notifyService.showSuccess('Successfully !!', 'Add to Cart'); 
    //       }, 500); 
    //     }
    //     else if(i.proID == orders.proID){
    //      // var price = i.proPrice + orders.proPrice;
    //       //orders.proPrice = price;
    //       //i.proPrice == price;
    //       //this.CartItems.push(orders);
    //       this.notifyService.showWarning('This Product is Already in Cart!!', 'Please Select Other'); 
    //     }
    //   }
    // }   
     
      // a.forEach(element => {
      //   if(element.proID != orders.proID){
      //     a.push(orders);
      //   }
      // })

    this.CartItemReceived.emit(orders);
  }

//   good:number[];
// test:Product[];
//   addToCart(orders: Product): void {
//     var a = new Product();
//     this.test = JSON.parse(localStorage.getItem("active_item")) || [];
//       for(let i of this.test){
//         //if(i.proID != orders.proID){
//           this.test.push(orders);
//         //}
//       }
//       // if(a.proID != orders.proID){
//       //   alert("success")
//       // }
//       // a.forEach(element => {
//       //   if(element.proID != orders.proID){
//       //     a.push(orders);
//       //   }
//       // })

//     //alert("Success")   
//     setTimeout(() => {
//       localStorage.setItem("active_item", JSON.stringify(a));    
//       this.notifyService.showSuccess('Successfully !!', 'Add to Cart');  
//       //this.ordersChanged.next(a)
//     }, 500);   
    
//     this.CartItemReceived.emit(orders);
//   }

  // Fetching Locat CartsProducts
 
  getLocalCartProducts(): Product[] {
    const products: Product[] =
      JSON.parse(localStorage.getItem("active_item")) || [];
    
    return products;
  }

  setLocalCartProducts(productList:Product[] ){
    localStorage.setItem("active_item", JSON.stringify(productList));     
  }
  // Fetching Locat FovoriteProducts
  getFavoriteCartProducts(): Product[] {
    const products: Product[] =
      JSON.parse(localStorage.getItem("avf_item")) || [];
    return products;
  }
  getFavoriteAds(): adds[] {
    const adds: adds[] =
      JSON.parse(localStorage.getItem("avf_ads")) || [];
    return adds;
  }
  getFavoriteDeals():adds[] {
    const adds: adds[] =
      JSON.parse(localStorage.getItem("avf_deal")) || [];
    return adds;
  }
  removeLocalProduct(product: Product) {
    const products: Product[] = JSON.parse(localStorage.getItem("avf_item"));
    this.notifyService.showSuccess('Successfully !!', 'Remove from Favorite');
    for (let i = 0; i < products.length; i++) {
      if (products[i].proID === product.proID) {
        products.splice(i, 1);
        break;
      }
    }
    // ReAdding the products after remove
    localStorage.setItem("avf_item", JSON.stringify(products));
  }
  removeLocalAds(adds: adds) {
    const ads: adds[] = JSON.parse(localStorage.getItem("avf_ads"));
    this.notifyService.showSuccess('Successfully !!', 'Remove from Favorite');
    for (let i = 0; i < ads.length; i++) {
      if (ads[i].addID === adds.addID) {
        ads.splice(i, 1);
        break;
      }
    }
    localStorage.setItem("avf_ads", JSON.stringify(ads));
  }
  removeLocalDeals(adds: adds) {
    const ads: adds[] = JSON.parse(localStorage.getItem("avf_deal"));
    this.notifyService.showSuccess('Successfully !!', 'Remove from Favorite');
    for (let i = 0; i < ads.length; i++) {
      if (ads[i].addID === adds.addID) {
        ads.splice(i, 1);
        break;
      }
    }
    localStorage.setItem("avf_deal", JSON.stringify(ads));
  }

  removeLocalCartProduct(product: Product) {
    const products: Product[] = JSON.parse(localStorage.getItem("active_item"));
    for (let i = 0; i < products.length; i++) {
      if (products[i].proID === product.proID) {
        products.splice(i, 1);
        this.notifyService.showSuccess('Successfully !!', 'Remove from Cart');
        break;
      }
    }
    // ReAdding the products after remove
    localStorage.setItem("active_item", JSON.stringify(products));
  }

  ShippingAddress(val:ShippingAddress){
    return this.httpclient.post<ShippingAddress>(this.baseUrl+'services/useraddress/shipping',val);
  }
  GetShippingAddress(val:ShippingAddress){
    return this.httpclient.post<ShippingAddress>(this.baseUrl+'services/getuseraddress/shipping',val);
  }
  updateProduct(val:UpdateProduct){
    return this.httpclient.post<UpdateProduct>(this.baseUrl+'services/Product/updatepost',val);
  }
  
  updateimage(productDetails:productRequest){  
    //debugger;
    this.httpclient.post<number>(this.baseUrl+'services/Product/updatepost',JSON.stringify(productDetails),this.httpOptions).subscribe(data => {
      //debugger; 
      //console.log(data);           
  return this.result = data;
    })               
  }

  GetProductByID(proID:any){
    return this.httpclient.get(this.baseUrl+'services/Product/getdataBYID',proID);
  }
   
   GetProductByByIDNew(proID : number){  
     //debugger;
     return  this.httpclient.get<Product>(this.baseUrl+'services/Product/getdataBYID?proID='+proID,this.httpOptions)
     .pipe(map((response : Product)=> {
       return response;
       }),
       catchError(error =>{
         return throwError('seomething went wrong');
       })
       );                        
   }
   GetProductMORENew(proID : number){  
    //debugger;
    return  this.httpclient.get<Product>(this.baseUrl+'services/Product/getdataBYselecteduserID?proID='+proID,this.httpOptions)
    .pipe(map((response : Product)=> {
      return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      );                        
  }
   

}
export class Cart {}