import { classified } from "./classified.model";
import { postAutos } from './postAutos.model';
import { postProperty } from './postProperty.model';
import { jobsAvailable } from './jobsAvailable.model';
import { jobsWanted } from './jobsWanted.model';
import { addPhotos } from './addPhotos.model';
export class adPosting{

    public addID : string;
    public title: string;
    public description : string ;
    public price: number;
    public phone : number ;
    public currencyID: number;
    public lat : string ;
    public lon: string;
    public countryID : number ;
    public cityID: number;
    public catID: string;
    public subCatID: string;
    public locationID: number;
    public link: string;
    public ImageName: string;
    public addedName: string;
    public addedImage: string;
    public VIDEO_PATH: string;
    public addSecondLevel: string;
    public addThirdLevel: string;
    public addFourthLevel: string;

    public postClassfied : classified;
    public postAutos : postAutos;
    public postProperty : postProperty;
    public postJobs : jobsAvailable;
    public postJobsWanted : jobsWanted;
    public postPhotos : addPhotos[];
}
