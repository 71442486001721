import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, pipe, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {  HttpHeaders } from '@angular/common/http';
import { map,catchError, retry , tap } from 'rxjs/operators';
import { error } from 'protractor';
import { adds } from '../models/Adds/adds.model';
import { AdComments } from '../models/comments/comments.model';
import { Comments } from '../models/comments/comment.model';
import { likeDislikeDetails } from '../models/Adds/likeDislikeDetails.model';
import { ViewCount } from '../models/likesDislikes/ViewCount';
import { ProComments } from '../models/comments/Pro-comments.model';
import { ProductComments } from '../models/comments/pro-comment.model';


@Injectable()
export class addsService{
private baseUrl = environment.baseUrl;  
    private httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'         
        })
      };     
private result:number;
private resultLike:string;
commentsChanged = new Subject<Comments[]>();
//topFifteenAdds:adds[];
constructor(private httpclient:HttpClient){}

  // getTopAdds(){
  //  // //debugger;
  //  return this.httpclient.get<adds[]>(this.baseUrl+'services/Ads/topFifteenAds',this.httpOptions)
  //   .pipe(map((response : adds[])=> {
  //     return response;
  //   }),
  //   catchError(error =>{
  //     return throwError('seomething went wrong');
  //   })
  //   )     
  // }
  getTopAdds(country : number): Observable<any[]>{    
    return this.httpclient.get<any[]>(this.baseUrl+'services/Ads/ClassifiedNew?country='+country,this.httpOptions)
     .pipe(map((response : any[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
  getTopAddsByChoice_OLD(choice: number){
  //  //debugger;
   return this.httpclient.get<adds[]>(this.baseUrl+'services/Ads/topAds?count='+choice,this.httpOptions)
    .pipe(map((response : adds[])=> {
      return response;
    }),
    catchError(error =>{
      return throwError('seomething went wrong');
    })
    )     
  }
  getTopDealsByCategory(dealID: number){
    //  //debugger;
     return this.httpclient.get<any>(this.baseUrl+'services/Deals/topdealsByCategory?dealID='+dealID,this.httpOptions)
      .pipe(map((response : any)=> {
        return response;
      }),
      catchError(error =>{
        return throwError('seomething went wrong');
      })
      )     
    }
    getTopProductsByCategory(proID: number){
      //  //debugger;
       return this.httpclient.get<any>(this.baseUrl+'services/Products/topProductsByCategory?proID='+proID,this.httpOptions)
        .pipe(map((response : any)=> {
          return response;
        }),
        catchError(error =>{
          return throwError('seomething went wrong');
        })
        )     
      }
  
  // getTopDeals(){
  //  // //debugger;
  //  return this.httpclient.get<adds[]>(this.baseUrl+'services/Ads/DealsNew',this.httpOptions)
  //   .pipe(map((response : adds[])=> {
  //     return response;
  //   }),
  //   catchError(error =>{
  //     return throwError('seomething went wrong');
  //   })
  //   )     
  // }
  getTopDeals(country : number): Observable<any[]>{    
    return this.httpclient.get<any[]>(this.baseUrl+'services/Ads/DealsNew?country='+country,this.httpOptions)
     .pipe(map((response : any[])=> {
      // //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }

   submitProductComment(Details:ProComments):Observable<number>{     
    ////debugger;   
     return this.httpclient.post<number>(this.baseUrl+'services/ProComments/addPostComment',JSON.stringify(Details),this.httpOptions)
    .pipe(
      map((response : number)=> {
      return response;
    }));
  }
  
   submitDealComment(Details:ProComments):Observable<number>{     
    ////debugger;   
     return this.httpclient.post<number>(this.baseUrl+'services/DealComments/addPostComment',JSON.stringify(Details),this.httpOptions)
    .pipe(
      map((response : number)=> {
      return response;
    }));
  }

  submitAddComment(commentDetails:AdComments):Observable<number>{     
  ////debugger;   
   return this.httpclient.post<number>(this.baseUrl+'services/AdComments/addPostComment',JSON.stringify(commentDetails),this.httpOptions)
  .pipe(
    map((response : number)=> {
    return response;
  })
  );  
  
   ////console.log(this.result);
    // return this.httpclient.post<number>(this.baseUrl+'services/AdComments/addPostComment',JSON.stringify(commentDetails),this.httpOptions).subscribe(data => {           
    //  //this.result = data;
    // }),
    // catchError(error =>{
    //  // return throwError('seomething went wrong');
    // })  
    // //debugger;
    //return this.result;             
  }
 
  getAddComments(addId : string): Observable<Comments[]>{    

   return this.httpclient.get<Comments[]>(this.baseUrl+'services/AdComments/byID?addID='+addId,this.httpOptions)
    .pipe(map((response : Comments[])=> {
     // //debugger;
      return response;
    }),
    catchError(error =>{
      return throwError('seomething went wrong');
    })
    )     
  }
  getDealComments(DealID : number):Observable<any[]>{
    //debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/DealComments/byID?DealID='+DealID,this.httpOptions);
  }
  getProductComments(ProID : number):Observable<any[]>{
    //debugger;
    return this.httpclient.get<any>(this.baseUrl+'services/ProComments/byID?ProID='+ProID,this.httpOptions);
  }

  getAddLikeDislikeCount(likeDislike:likeDislikeDetails): Observable<any>{    

    return this.httpclient.post<number>(this.baseUrl+'services/LikeDislike/getlikeDislikeCountByPost',JSON.stringify(likeDislike),this.httpOptions)
     .pipe(map((response : number)=> {
     //  //debugger;
       return response;
     }),
     catchError(error =>{
       return throwError('seomething went wrong');
     })
     )     
   }
  
  submitLikeDislike1(likeDislikeDetails:likeDislikeDetails){    
   // //debugger;     
    return this.httpclient.post<string>(this.baseUrl+'services/LikeDislike/likeDislikeAd',JSON.stringify(likeDislikeDetails),this.httpOptions)
    .pipe();                     
    }
    
    submitLikeDislike(likeDislike:likeDislikeDetails){  
     // //debugger;
      return  this.httpclient.post<string>(this.baseUrl+'services/LikeDislike/likeDislikePost',JSON.stringify(likeDislike),this.httpOptions)
      .pipe();                                   
    }

    submitview(viewDetail:ViewCount){     
      //debugger;
      this.httpclient.post<number>(this.baseUrl+'services/ViewCount/AddViewbyID',JSON.stringify(viewDetail),this.httpOptions).subscribe(data => {
        //debugger; 
        //console.log(data);           
    return this.result = data;
      })               
    }
}