import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdcompanyService } from 'src/app/shared/services/compnies/CompanyServices';
import { openSearchService } from 'src/app/shared/services/search/openSearch.service';
import { email } from './email';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {

  Subject:string;
  ToEmail:string;
  BodyHTML:string;
  constructor(private userInterface: openSearchService,private router: Router, private route: ActivatedRoute,
    private adcompanyService: AdcompanyService) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    const obj = new email();
    obj.Subject = this.Subject;
    obj.ToEmail = this.ToEmail;
    obj.BodyHTML= this.BodyHTML;

    this.adcompanyService.submitEmail(obj).subscribe(result => {
      console.log(result);
    });  
  }

  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

}
