import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
//import Swal from 'sweetalert2/dist/sweetalert2.js';
//import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { openSearchService } from '../shared/services/search/openSearch.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryDto } from '../shared/models/category/categoryDTO.model';
import { dataLibraries } from '../shared/models/category/dataLibraries/dataLibraries.model';
import { Options } from 'ng5-slider';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdPostingService } from '../add-posting/adPosting.service';
import { adPosting } from '../shared/models/adPosting.model';
import { classified } from '../shared/models/classified.model';
import { postAutos } from '../shared/models/postAutos.model';
import { autoFacilities } from '../shared/models/autoFacilities.model';
import { jobFacilities } from '../shared/models/jobFacilities.model';
import { postProperty } from '../shared/models/postProperty.model';
import { propertyFacilities } from '../shared/models/propertyFacilities.model';
import { jobsWanted } from '../shared/models/jobsWanted.model';
import { jobsAvailable } from '../shared/models/jobsAvailable.model';
import { addPhotos } from '../shared/models/addPhotos.model';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { templateJitUrl } from '@angular/compiler';
import { NgForm } from '@angular/forms';
import { title } from 'process';
import { NotificationService } from '../shared/services/notification.service';
import { prepareSyntheticListenerFunctionName } from '@angular/compiler/src/render3/util';
import { MapsAPILoader } from '@agm/core';
import { AgmCoreModule } from '@agm/core';
import { NgOption } from '@ng-select/ng-select';
import { dataLibraryService } from '../shared/services/search/DataLibraries.service';
import { LocalStorageService } from 'angular-web-storage';
import { dataLibrary } from '../shared/models/category/dataLibraries/dataLibrary.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as fromAuth from '../store/reducers/auth.reducers';
import * as fromApp from '../store/app.state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as jwt_decode from "jwt-decode";
//import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import 'quill-mention';
import 'quill-emoji';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
//import { Editor, Toolbar } from 'ngx-editor';
//import jsonDoc from './doc';
//import { Editor } from 'ngx-editor';
// import {} from 'googlemaps';
@Component({
  selector: 'app-user-new-post',
  templateUrl: './user-new-post.component.html',
  styleUrls: ['./user-new-post.component.css']
})
export class UserNewPostComponent implements OnInit {

  selectedCountry = '1';
  selectedCity = '0';
  citySelection: any;
  htmlText = '<p>Testing</p>';
  hasFocus = false;
  subject: string;
  //editor: Editor;
  //html: '';
  atValues = [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' },
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' },
  ];

  public quill: Quill;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        [
          { header: 1 },
          { header: 2 },
          { header: 3 },
          { header: 4 },
          { header: 5 },
        ], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction  
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
        [{ 'font': [] }],
        [{ 'align': [] }],  
        ['clean'], // remove formatting button  
        //['link'],
        ['link', 'image', 'video'],
        [{ 'color': [] }],
      ]
    },

    // mention: {
    //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    //   mentionDenotationChars: ['@', '#'],
    //   source: (searchTerm, renderList, mentionChar) => {
    //     let values;

    //     if (mentionChar === '@') {
    //       values = this.atValues;
    //     } else {
    //       values = this.hashValues;
    //     }

    //     if (searchTerm.length === 0) {
    //       renderList(values, searchTerm);
    //     } else {
    //       const matches = [];
    //       for (var i = 0; i < values.length; i++)
    //         if (
    //           ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
    //         )
    //           matches.push(values[i]);
    //       renderList(matches, searchTerm);
    //     }
    //   },
    // },
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter');
            return true;
          },
        },
      },
    },
       
  };

  public editorCreated(event: Quill,descriptionArea): void {
    this.quill = event;
    this.quill.setHtml(descriptionArea);
    let toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('table', () => {
      //this.addNewTable();
    });
  }

  constructor(private userInterface: openSearchService,
    private router: Router,private dlService : dataLibraryService,public localStor: LocalStorageService,
    private route: ActivatedRoute,
    private mScrollbarService: MalihuScrollbarService,private ngxLoader: NgxUiLoaderService,
    private fb: FormBuilder, private postingService: AdPostingService,
    private notifyService: NotificationService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,private store: Store<fromApp.AppState>,
    private storage: LocalStorageService,private sanitizer: DomSanitizer) {
      if(this.localStor.get('currentCountry'))
      {
        this.selectedCountry =this.localStor.get('currentCountry');
      }
      if(this.localStor.get('currentCity'))
      {
        this.selectedCity =this.localStor.get('currentCity');
        //console.log(this.selectedCity);
      }
      this.getCities();
     }

     transformhtml(htmltextwithstyle): SafeHtml {
      return this.sanitizer.bypassSecurityTrustUrl(htmltextwithstyle);
  }
  
  postDetailForm: FormGroup;

  faArrowRight = faArrowRight;

  selectedCategory = '0';
  selectedSubCategory = '0';
  selectedSubCatFirstChild = '0';
  selectedSubCatSecondChild = '0';
  selectedSearchWord = '';
  subCategories: CategoryDto[];
  subCatFirstChildList: CategoryDto[];
  subCatSecondChildList: CategoryDto[];

  phoneNumber:number;
  
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  selectedKM = '';

  categoryTitle = "";
  subCategoryTitle = "";
  subToFirstTitle = "";
  subToSecondTitle = "";
  finalSelection = "";

  descriptionArea = "";
  addTitle = "";
  addTitleAlert = false;
  addPrice;
  addPriceAlert = false;
  addCategoryAlert = false;
  addSubCatAlert = false;

  selectedKmAlert = false;
  DoorsList: dataLibraries[];
  selectedDoor: dataLibraries;
  selectedDoorAlert = false;
  YearsList: dataLibraries[];
  selectedYear: dataLibraries;
  selectedYearAlert = false;
  ColorsList: dataLibraries[];
  selectedColor: dataLibraries;
  selectedColorAlert = false;

  BodyConditionList: dataLibraries[];
  selectedBodyCondition: dataLibraries;
  selectedBcAlert = false;
  MechenicalConditionList: dataLibraries[];
  selectedMechenicalCondition: dataLibraries;
  selectedMcAlert = false;
  TrimList: dataLibraries[];
  selectedTrim: dataLibraries;
  selectedTrimAlert = false;
  BodyTypeList: dataLibraries[];
  selectedBodyType: dataLibraries;
  selectedBtAlert = false;
  CylenderList: dataLibraries[];
  selectedCylender: dataLibraries;
  selectedClAlert = false;
  TransmissionList: dataLibraries[];
  selectedTransmission: dataLibraries;
  selectedTransmissionAlert = false;
  HPList: dataLibraries[];
  selectedHPList: dataLibraries;
  selectedHpAlert = false;
  WarrantyList: dataLibraries[];
  selectedWarranty: dataLibraries;
  selectedWlAlert = false;
  EngineList: dataLibraries[];
  selectedEngine: dataLibraries;
  selectedElAlert = false;
  EngineBikeList: dataLibraries[];
  selectedEngineBike: dataLibraries;
  selectedEbAlert = false;

  CountriesList: dataLibraries[];
  selectedCountries: dataLibraries;

  FuelTypeList: dataLibraries[];
  selectedFuelType: dataLibraries;
  selectedFTAlert = false;
  AutoAgeList: dataLibraries[];
  selectedAutoAge: dataLibraries;
  AutoUsageList: dataLibraries[];
  selectedAutoAgeAlert = false;
  //selecteAutodAge
  selectedAutoUsage: dataLibraries;
  selectedAutoUsageAlert = false;
  selectedExperienceAlert = false;
  selectedEducationAlert = false;
  selectedCommitementAlert = false;
  selectedShiftAlert = false;


  AgeList: dataLibraries[];
  selectedAge: dataLibraries;
  selectedAgeClassAlert = false;
  UsageList: dataLibraries[];
  selectedUsage: dataLibraries;
  selectedUsageAlert = false;
  PositionList: dataLibraries[];
  selectedPosition: dataLibraries;
  ConditionList: dataLibraries[];
  selectedCondition: dataLibraries;
  selectedConditionAlert = false;
  selectedDegree = '';
  selectedCompensation = '';
  selectedCompensationAlert = false;
  RWEList: dataLibraries[];
  selectedRWE: dataLibraries;
  REducationList: dataLibraries[];
  selectedREducation: dataLibraries;
  RGenderList: dataLibraries[];
  selectedRGender: dataLibraries;
  RCommitmentList: dataLibraries[];
  selectedRCommitment: dataLibraries;
  RShiftList: dataLibraries[];
  selectedRShift: dataLibraries;

  autoFacilitiesNew = [];

  selectedMonth = '';
  selectedMonthAlert = false;
  selectedMonthShow = false;
  FacilityReadyDate = '';
  FacilityBuilding = '';
  FacilityBuildingAlert = false;
  showBuildingOption = false;
  FacilitySize = '';
  showSizeOption = false;
  facilitySizeALert = false;
  furnished = false;
  furnishedShow = false;
  furnishedIsChecked = false;
  LanLord: any;
  showSalary: any;
  travelType: any;
  showLanLordOption = false;
  Agent = "";
  Parking = false;
  Balcony = false;
  BalconyIsChecked = false;
  ParkingIsChecked = false;
  showBalconyOption = false;
  BedsList: dataLibraries[];
  selectedBeds: dataLibraries;
  selectedBedsAlert = false;
  showBedOptions = false;
  BathsList: dataLibraries[];
  selectedBaths: dataLibraries;
  selectedBathsAlert = false;
  showBathOptions = false;

  DurationList: dataLibraries[];
  selectedDuration: dataLibraries;
  selectedDurationAlert = false;
  showDurationOPtion = false;

  PaidList: dataLibraries[];
  selectedPaid: dataLibraries;
  selectedPaidAlert = false;
  showRentPaidOption = false;
  // CurrienciesList: dataLibraries[];
  selectedCurrency: string;

  selectedPositionAlert: boolean;
  selectedGenderAlert: boolean;

  allselected: number = 0;
  levelOfSearch: number = 0;
  levelToShow: number = 0;
  public scrollbarOptions = { axis: 'y', theme: 'light' };
  minSalValue: number = 0;
  maxSalValue: number = 250000;
  minkmValue: number = 0;
  maxkmValue: number = 500000;
  optionsKM: Options = {
    floor: 0,
    ceil: 300000
  };
  optionsSal: Options = {
    floor: 0,
    ceil: 100000
  };

  minAgeValue: number = 25;
  maxAgeValue: number = 35;
  optionsAge: Options = {
    floor: 15,
    ceil: 75
  };

  imageDeleteFrom: FormGroup;
  postImages: addPhotos[];
  imageurls = [];
  imageurlsNew = [];
  videoUrls = [];
  videoDeleteFrom: FormGroup;
  base64String: string;
  name: string;
  imagePath: string;
  logInUserType:string;
  authState: Observable<fromAuth.State>;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  removeImageEdit(i, imagepath) {
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.ImagePath = imagepath;
  }

  removeImage(i) {
    this.imageurls.splice(i, 1);
    this.imageurlsNew.splice(i, 1);
  }

  removeVideoEdit(i, videopath) {
    this.videoDeleteFrom.value.id = i;
    this.videoDeleteFrom.value.Videopath = videopath;
  }
  removeVideo(i) {
    this.videoUrls.splice(i, 1);
  }

  onSelectVideo(event) {
    //debugger;
    let files = event.target.files;
    if (!this.validateFile(files[0].name)) {
     // this.errorNotification();
      this.notifyService.showError('Please Select Video .mp4 !!', 'Somthing Wrong');
      return false;
    }
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;     
    
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.videoUrls[0] = event.target.result;
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'mp4') {
        return true;
    }
    else {
        return false;
    }
  }
  
  //postingData.title =  this.postDetailForm.get('title').value;
  onSelectFile(event) {
    // if (event.target.files && event.target.files[0]) {
    //   var filesAmount = event.target.files.length;
    //   //var proPhotos: addPhotos[];
    //   if (filesAmount > 5) {
    //     alert("file limit execede!");
    //   }
    //   else {
    //     for (let i = 0; i < filesAmount; i++) {
    //       var reader = new FileReader();
    //       reader.onload = (event: any) => {
    //         this.imageurls.push({ base64String: event.target.result, });
    //         this.imageurlsNew.push(event.target.result);
    //       }
    //       reader.readAsDataURL(event.target.files[i]);
    //     }
    //   }
    // }
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      var totalFiles =  this.imageurls.length + filesAmount ;

      if(totalFiles > 5)
      {
        alert("file limit execede!");
      }            
      else {
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.imageurls.push({ base64String: event.target.result, });
            this.imageurlsNew.push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
  }

  ngOnInit(): void {

    if(this.selectedCountry == "1")
    {
      this.latitude = 25.204849;
      this.longitude =  55.270782;
    }
    else
    {
      this.latitude = 31.582045;
      this.longitude =  74.329376 ;
    }

    this.fillDropDowns();
    this.loadForm();
    // this.selectedCurrency = { id: "5", title: "AED" };
    this.mapsAPILoader.load().then(() => {
    this.setCurrentLocation();  
    this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          if (place.geometry === undefined || place.geometry === null) {            
            return;
          }  
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);
        });
      });
    });

    this.authState = this.store.select('auth');

    let token = this.storage.get('currentUser');
    let tokenInfo = this.getDecodedAccessToken(token); // decode token 

    if (tokenInfo != null) {      
      this.logInUserType =tokenInfo.typ;
    }
    //console.log(this.logInUserType );
    //this.logInUserType =tokenInfo.Typ;
   // this.editor = new Editor();
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
  ngOnDestroy(): void {
    //this.editor.destroy();
  }
  fillDropDowns() {
    this.DoorsList = [
      { id: "0", title: "Select Door" },
     // { id: "1", title: "1" },
      { id: "1", title: "2 Doors" },
      { id: "2", title: "3 Doors" },
      { id: "3", title: "4 Doors" },
      { id: "4", title: "5+ Doors" },
    ]
    // this.YearsList = [
    //   { id: "0", title: "Select Year" },
    //   { id: "2001", title: "2001" },
    //   { id: "2002", title: "2002" },
    //   { id: "2003", title: "2003" },
    //   { id: "2004", title: "2004" },
    //   { id: "2005", title: "2005" },
    //   { id: "2006", title: "2006" },
    //   { id: "2007", title: "2007" },
    //   { id: "2008", title: "2008" },
    //   { id: "2009", title: "2009" },
    //   { id: "2010", title: "2010" },
    //   { id: "2011", title: "2011" },
    //   { id: "2012", title: "2012" },
    //   { id: "2013", title: "2013" },
    //   { id: "2014", title: "2014" },
    //   { id: "2015", title: "2015" },
    //   { id: "2016", title: "2016" },
    //   { id: "2017", title: "2017" },
    //   { id: "2018", title: "2018" },
    //   { id: "2019", title: "2019" },
    //   { id: "2019", title: "2020" },
    //   { id: "2019", title: "2021" }
    // ]
    this.YearsList = [
      { id: "0", title: "Select Year" },
      { id: "52", title: "2001" },
      { id: "53", title: "2002" },
      { id: "54", title: "2003" },
      { id: "55", title: "2004" },
      { id: "56", title: "2005" },
      { id: "57", title: "2006" },
      { id: "58", title: "2007" },
      { id: "59", title: "2008" },
      { id: "60", title: "2009" },
      { id: "61", title: "2010" },
      { id: "62", title: "2011" },
      { id: "63", title: "2012" },
      { id: "64", title: "2013" },
      { id: "65", title: "2014" },
      { id: "66", title: "2015" },
      { id: "67", title: "2016" },
      { id: "68", title: "2017" },
      { id: "69", title: "2018" },
      { id: "70", title: "2019" },
      { id: "71", title: "2020" },
      { id: "72", title: "2021" },
      { id: "73", title: "2022" },
      { id: "74", title: "2023" },
      { id: "75", title: "2024" }
    ]
    this.ColorsList = [
      { id: "0", title: "Select Color" },
      { id: "1", title: "Blue" },
      { id: "2", title: "Black"},
      { id: "3", title: "Brown"},
      { id: "4", title: "Burgendy"},
      { id: "5", title: "Gold" },
      { id: "6", title: "Green" },
      { id: "7", title: "Grey" },
      { id: "8", title: "Purple" },
      { id: "9", title: "Pink" },
      { id: "10", title: "White" },
      { id: "11", title: "Silver" },
      { id: "12", title: "Other" },
      { id: "13", title: "Red" }
    ]
    this.BodyConditionList = [
      { id: "0", title: "Select Body Condition" },
      { id: "1", title: "Perfect" },
      { id: "2", title: "No Accident" },
      { id: "3", title: "Bit Wear & Tear" },
      { id: "4", title: "Normal Wear & Tear" },
      { id: "5", title: "Lots of Wear & Tear" }

    ]
    this.MechenicalConditionList = [
      { id: "0", title: "Select Mec- Condition" },
      { id: "1", title: "Perfect" },
      { id: "2", title: "Minor faults all fixed" },
      { id: "3", title: "Major faults all fixed" },
      { id: "4", title: "Major fixed Small remains" },
      { id: "5", title: "Ongoing major & minor" }

    ]
    this.TrimList = [
      { id: "0", "title": "Select Trim" },
      { id: "1", "title": "Other" }
    ]
    this.BodyTypeList = [
      { id: "0", title: "Select Body Type" },
      { id: "1", title: "Coupe" },
      { id: "2", title: "CrossOver" },
      { id: "3", title: "Hard top convertable" },
      { id: "4", title: "Hetchback" },
      { id: "5", title: "Sedan" },
      { id: "6", title: "PickUp Truck" },
      { id: "7", title: "Soft top convertable" },
      { id: "8", title: "Sports" },
      { id: "9", title: "SUV" },
      { id: "10", title: "Van" },
      { id: "11", title: "Utility Truck" },
      { id: "12", title: "Wagon" },
      { id: "13", title: "Other" }
    ]
    this.CylenderList = [
      { id: "0", title: "Select Cylender" },
      { id: "1", title: "3" },
      { id: "2", title: "5" },
      { id: "3", title: "6" },
      { id: "4", title: "8" },
      { id: "5", title: "10" },
      { id: "6", title: "12+" },
      { id: "7", title: "4" },
      { id: "8", title: "7" }
    ]
    this.TransmissionList = [
      { id: "0", title: "Select Transmission" },
      { id: "1", title: "Automatic" },
      { id: "2", title: "Manual" },
    ]
    this.HPList = [
      { id: "0", title: "Select HP" },
      { id: "1", title: "Less Then 150" },
      { id: "2", title: "150 - 200" },
      { id: "3", title: "200 - 300" },
      { id: "4", title: "300 - 400" },
      { id: "5", title: "400 - 500" },
      { id: "6", title: "500 - 600" },
      { id: "7", title: "600 - 700" },
      { id: "8", title: "700 - 800" },
      { id: "9", title: "800 - 900" },
      { id: "10", title: "900 +" },
      { id: "1", title: "unknown" }
    ]
    this.FuelTypeList = [
      { id: "0", title: "Select Engine" },
      { id: "1", title: "Gasoline" },
      { id: "2", title: "Diesel" },
      { id: "3", title: "Hybrid" },
      { id: "4", title: "Electric" }
    ]

    autoFacilitiesNew: [
      { id: "1", title: "Air Conditioned", enabled: "false" },
      { id: "2", title: "Anti Theft", enabled: "false" },
      { id: "3", title: "AM/FM", enabled: "false" },
      { id: "4", title: "Audio", enabled: "false" },
      { id: "5", title: "Bluetooth", enabled: "false" },
      { id: "6", title: "Body Kit", enabled: "false" },
      { id: "7", title: "Brush Guard", enabled: "false" },
      { id: "8", title: "Cassete Player", enabled: "false" },
      { id: "9", title: "CD Player", enabled: "false" },
      { id: "10", title: "Climate Control", enabled: "false" },
      { id: "11", title: "Cooled Seats", enabled: "false" },
      { id: "12", title: "DVD Player", enabled: "false" },
      { id: "13", title: "Fog Lights", enabled: "false" },
      { id: "14", title: "Heated Seats", enabled: "false" },
      { id: "15", title: "Keyless start", enabled: "false" },
      { id: "16", title: "Leather seats", enabled: "false" },
      { id: "17", title: "Moon roof", enabled: "false" },
      { id: "18", title: "Sun roof", enabled: "false" },
      { id: "19", title: "Power seats", enabled: "false" },
      { id: "20", title: "Power mirror", enabled: "false" },
      { id: "21", title: "Cruise control", enabled: "false" },
      { id: "22", title: "Power stearing", enabled: "false" },
      { id: "23", title: "N2O", enabled: "false" },
      { id: "24", title: "Front airbags", enabled: "false" },
      { id: "25", title: "side airbags", enabled: "false" }
    ]

    this.WarrantyList = [
      { id: "0", title: "Select Warranty" },
      { id: "1", title: "Yes" },
      { id: "2", title: "No" },
      { id: "3", title: "Does not apply" }
    ]
    this.EngineList = [
      { id: "0", title: "Select Engine" },
      { id: "1", title: "Gasoline" },
      { id: "2", title: "Diesel" },
      { id: "3", title: "Hybrid" },
      { id: "4", title: "Electric" }
    ]
    this.EngineBikeList = [
      { id: "0", title: "Select Engine" },
      { id: "1", title: "Less then 250CC" },
      { id: "2", title: "250 - 500 CC" },
      { id: "3", title: "500 - 750 CC" },
      { id: "4", title: "750 - 1000 CC" },
      { id: "5", title: "More then 1000CC" }
    ]
    this.CountriesList = [
      { id: "0", title: "Select" },
      { id: "1", title: "United Arab Emirates" },
      { id: "2", title: "Pakistan" },
      { id: "3", title: "Saudi Arabia" },
      { id: "4", title: "KUWAIT" },
      { id: "5", title: "Oman" },
      { id: "7", title: "India" },
      { id: "8", title: "United Kingdom" },
      { id: "9", title: "United States" },
      { id: "10", title: "China" },
      { id: "11", title: "Japan" },
      { id: "12", title: "Canada" }
    ]
    this.CurrienciesList = [
      { id: "0", title: "Select Curriencies" },
      { id: "1", title: "PKR" },
      { id: "2", title: "INR" },
      { id: "3", title: "DOLLER" },
      { id: "4", title: "OR" },
      { id: "5", title: "AED" },
      { id: "6", title: "SR" },
      { id: "7", title: "Other" }
    ]
    this.AgeList = [
      { id: "0", title: "Select Age" },
      { id: "1", title: "New" },
      { id: "3", title: "0-1 month" },
      { id: "4", title: "1-6 months" },
      { id: "5", title: "6-12 months" },
      { id: "6", title: "1-2 years" },
      { id: "7", title: "2-5 years" },
      { id: "8", title: "5-10 years" },
      { id: "9", title: "10+ years" }
    ]
    this.AutoAgeList = [
      { id: "0", title: "Select Age" },
      { id: "1", title: "New" },
      { id: "3", title: "0-1 month" },
      { id: "4", title: "1-6 months" },
      { id: "5", title: "6-12 months" },
      { id: "6", title: "1-2 years" },
      { id: "7", title: "2-5 years" },
      { id: "8", title: "5-10 years" },
      { id: "9", title: "10+ years" }
    ]
    this.UsageList = [
      { id: "0", title: "Select Usage" },
      { id: "1", title: "Brand New" },
      { id: "2", title: "Origional Packaging" },
      { id: "3", title: "Not Used" },
      { id: "4", title: "Used Some Time" },
      { id: "5", title: "Used Average Time" }
    ]
    this.AutoUsageList = [
      { id: "0", title: "Select Usage" },
      { id: "1", title: "Brand New" },
      { id: "2", title: "Origional Packaging" },
      { id: "3", title: "Not Used" },
      { id: "4", title: "Used Some Time" },
      { id: "5", title: "Used Average Time" }
    ]
    this.ConditionList = [
      { id: "0", title: "Select Condition" },
      { id: "1", title: "Brand New " },
      { id: "2", title: "Perfect Inside & Out" },
      { id: "3", title: "A Bit wear & Tear" },
      { id: "4", title: "Normal wear & tear" },
      { id: "5", title: "Above average wear & tear" }
    ]
    this.PositionList = [
      { id: "0", title: "Select Position" },
      { id: "1", title: "1" },
      { id: "2", title: "2 - 5" },
      { id: "3", title: "5 - 10 " },
      { id: "4", title: "10 - 20 " },
      { id: "5", title: "20 - 50 " }
    ]
    this.RWEList = [
      { id: "0", title: "Select " },
      { id: "1", title: "0 -1" },
      { id: "2", title: "1 - 2" },
      { id: "3", title: "2 - 3 " },
      { id: "4", title: "3 - 5 " },
      { id: "5", title: "5 - 10 " },
      { id: "6", title: "10+ years" }
    ]
    this.REducationList = [
      { id: "0", title: "Select Education" },
      { id: "1", title: "High School" },
      { id: "2", title: "Bachelors" },
      { id: "3", title: "Master" },
      { id: "4", title: "PHD" },
      { id: "5", title: "Other" },
      { id: "6", title: "10+ years" }
    ]
    this.RGenderList = [
      { id: "0", title: "Select Gender" },
      { id: "1", title: "Male" },
      { id: "2", title: "Female" },
      { id: "3", title: "No Preference" }

    ]
    this.RCommitmentList = [
      { id: "0", title: "Select Commitment" },
      { id: "1", title: "Full Time" },
      { id: "2", title: "Part Time" },
      { id: "3", title: "Contract" },
      { id: "4", title: "Temporary" },
      { id: "5", title: "Other" }

    ]
    this.RShiftList = [
      { id: "0", title: "Select Shift" },
      { id: "1", title: "Day" },
      { id: "2", title: "Afternoon" },
      { id: "3", title: "Night" },
      { id: "4", title: "Rotating" }
    ]
    this.BedsList = [
      { id: "0", title: "Select Beds" },
      { id: "1", title: "1" },
      { id: "2", title: "2" },
      { id: "3", title: "3" },
      { id: "4", title: "4" },
      { id: "5", title: "5" },
      { id: "6", title: "6" },
      { id: "7", title: "7" },
      { id: "8", title: "8" },
      { id: "9", title: "9" },
      { id: "10", title: "10" }
    ]
    this.BathsList = [
      { id: "0", title: "Select Bath" },
      { id: "1", title: "1" },
      { id: "2", title: "2" },
      { id: "3", title: "3" },
      { id: "4", title: "4" },
      { id: "5", title: "5" },
      { id: "6", title: "6" },
      { id: "7", title: "7" },
      { id: "8", title: "8" },
      { id: "9", title: "9" },
      { id: "10", title: "10" }
    ]
    this.DurationList = [
      { id: "0", title: "Select Duration" },
      { id: "1", title: "Short Term (Monthly)" },
      { id: "2", title: "Quarterly" },
      { id: "3", title: "Bi-Yearly" },
      { id: "4", title: "Yearly" }
    ]
    this.PaidList = [
      { id: "0", title: "Select Payment" },
      { id: "1", title: "Daily" },
      { id: "2", title: "Monthly" },
      { id: "3", title: "Quarterly" },
      { id: "4", title: "Bi-Yearly" },
      { id: "5", title: "Yearly" }
    ]
  }

  loadForm() {
    //  this.postDetailForm= this.fb.group({
    //      title: ['', Validators.required],
    //      price: ['',Validators.required],
    //      description: [''],
    //       autos: this.fb.group({
    //         color: ['Select Color',Validators.required],
    //         years:['Select Year'],
    //         kilometer:['',Validators.required],
    //         bodyCon:['Body Condition'],
    //         macCon:['Machenical Condition'],
    //         trimID:['Trim Type'],
    //         bodyType:['Body Type'],
    //         doors:['Select Doors'],
    //         cylender:['Select Cylender'],
    //         transmission:['Select Transmission'],
    //         hp:['Select Horsepower'],
    //         war:['Select Warrenty'],
    //         ft:['Select Fuel Type'],          
    //         engine:['Select Engine'],
    //         st:['Select ST '],
    //         man:['Select Man'],
    //         usage:['Select Usage'],
    //         age:['Select Age'],
    //         warranty:['Select Warranty']
    //       })

    //  });
  }
  getCities(){
    //debugger;
    this.dlService.getCitiesByCountry(this.selectedCountry)
        .subscribe((data:any[]) => {
          this.citySelection = data;
        });
  }
  //get f() {return this.postDetailForm.controls; }

  backtoMain() {
    this.userInterface.setInterface("0");
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

  checkData() {
    //console.log(this.selectedAge.id);

  }

  // getCatByParentID(category, level) {
  //   // //debugger;

  //   ALServices.GetCatByParentID(category.cat_ID)
  //       .then(function(response) {
  //           loadCategoryDialogue(response, category, level);
  //       })
  //       .catch(function(error) {
  //           $state.go('ErrorHandlerPage', { errorCode: error.data.message });
  //       });
  // }

  getSubCategories(selectedLevel: number, event: any) {
    //debugger;
    let catToSearch: string = "";
    if (selectedLevel == 0) {
      catToSearch = this.selectedCategory;
      this.categoryTitle = event.target.options[event.target.options.selectedIndex].text;
      //var e = document.getElementById("ddpCat");
      //var text=e.options[e.selectedIndex].text;
      ////console.log(this.selectedCategory +"-"+this.categoryTitle);
      this.selectedSubCategory = '0';
      this.selectedSubCatFirstChild = '0';
      this.selectedSubCatSecondChild = '0';
    }
    else if (selectedLevel == 1) {
      catToSearch = this.selectedSubCategory;
      this.subCategoryTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToFirstTitle = "";
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 2) {
      catToSearch = this.selectedSubCatFirstChild;
      this.subToFirstTitle = event.target.options[event.target.options.selectedIndex].text;
      this.subToSecondTitle = "";
    }
    else if (selectedLevel == 3) {
      catToSearch = this.selectedSubCatSecondChild;
      this.subToSecondTitle = event.target.options[event.target.options.selectedIndex].text;
    }

    if (this.selectedCategory.trim().toLowerCase() == '0372dc00-f9cb-4cb5-8b95-901ea8a02efa' || this.selectedCategory.trim().toLowerCase() == '27bbb528-5666-4429-b153-7d6441c0efa1') {
      this.showPropertyFileds();
    }

    this.userInterface.getSubCategories(catToSearch)
      .subscribe((data: CategoryDto[]) => {
        //debugger;
        if (selectedLevel == 0) {
          this.subCategories = data;
          this.levelOfSearch = 1;
          this.levelToShow = 1;
        }
        else if (selectedLevel == 1) {
          this.subCatFirstChildList = data;
          this.levelOfSearch = 2;
          if (this.subCatFirstChildList.length > 0) {
            this.levelToShow = 2;
          }
          else {
            this.levelToShow = 1;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 2) {
          this.subCatSecondChildList = data;
          this.levelOfSearch = 3;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 3;
          }
          else {
            this.levelToShow = 2;
            this.allselected = 1;
          }

        }
        else if (selectedLevel == 3) {
          this.levelOfSearch = 4;
          if (this.subCatSecondChildList.length > 0) {
            this.levelToShow = 4;
          }
          else {
            this.levelToShow = 3;
            this.allselected = 1;
          }
        }
        ////console.log(data);
      });

    // if(this.subCategoryIDFromSearch)
    // {
    //   this.selectedSubCategory = this.subCategoryIDFromSearch;
    // }
  }
  // onSubmit(){
  //   //debugger;
  //   //console.log(this.postDetailForm.value);
  // }
  CurrienciesList: NgOption[] = [
    { id: 0, title: "Select Curriencies" },
    { id: 1, title: "PKR" },
    { id: 2, title: "INR" },
    { id: 3, title: "DOLLER" },
    { id: 4, title: "OR" },
    { id: 5, title: "AED" },
    { id: 6, title: "SR" },
    { id: 7, title: "Other" }
  ]

  onSubmit(form: NgForm) {
   // debugger; 
    //first do the validation
    var result = this.validateForm();
    if(this.latitude != null && this.longitude != null){
      if (result === true) {
        const postingData = new adPosting();
        postingData.title = this.addTitle;//  this.get('title').value;
        postingData.description = this.descriptionArea;
        postingData.countryID = parseInt(this.selectedCountry);
        postingData.cityID = parseInt(this.selectedCity);
        //postingData.cityID = 1;
        postingData.currencyID = parseInt(this.selectedCurrency);
        //for classified
        //d7b411fb-1c21-4f0a-88f7-34d6b789f67d
        postingData.catID = this.selectedCategory;
        postingData.subCatID = this.selectedSubCategory;
        //postingData.catID = "D7B411FB-1C21-4F0A-88F7-34D6B789F67D";
        //postingData.subCatID="3B3A5EC7-4CB1-4FFB-85C8-13E849427C90";                 
        //for autos
        //postingData.catID = "5BF243B1-2BE4-4DDA-93A0-CF9E06209E0F";
        //postingData.subCatID="FE1C13DE-9342-4EFB-824B-806DA0B8E3FE"; 
        //for property for rent
        //postingData.catID = "0372DC00-F9CB-4CB5-8B95-901EA8A02EFA";
        //postingData.subCatID="A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982"; 
        //for property for sale
        //postingData.catID = "27BBB528-5666-4429-B153-7D6441C0EFA1";
        //postingData.subCatID="220EC902-8ED1-4128-BD35-F3C4318EF5AC"; 
        //for job wanted
        // postingData.catID = "A07D5BE2-BC59-4086-82F5-1E1DF97EC87A";
        // postingData.subCatID="CFC3247C-A7BD-40BD-9A26-58B346D081A5"; 
        //for job hiring
        //postingData.catID = "967CEED7-54C0-488E-8AA8-69E6045E6C05";
        //postingData.subCatID="4569F2C8-D877-43DD-B6DB-DE691E2CF9DD";  
        
        postingData.lat = this.latitude.toString(); //this.latitude.toString(); //'25.400774';
        postingData.lon = this.longitude.toString();// '55.485495';
        postingData.link = ''; 
        postingData.phone = 123;
          
       // debugger;
        if(this.logInUserType == "1")
        {
          postingData.phone = this.phoneNumber;
        }     
        
        postingData.price = parseInt(this.addPrice);
        postingData.ImageName = "";
        postingData.VIDEO_PATH = "";
        postingData.addSecondLevel = this.selectedSubCategory;
        postingData.addThirdLevel = this.selectedSubCatFirstChild;
        postingData.addFourthLevel = this.selectedSubCatSecondChild;
        // postingData.addThirdLevel = this.selectedSubCatFirstChild;
        // postingData.addFourthLevel = this.selectedSubCatSecondChild;
  
        //debugger;
        //classified settings 
        const classifiedData = new classified();
        classifiedData.ageID = 0;
        classifiedData.conditionID = 0;
        classifiedData.usageID = 0;
        postingData.postClassfied = classifiedData;
  
        //autos setting 
        var autosData: postAutos;
        autosData = this.reSetAuotsFields();
  
        // property setting
        var property: postProperty;
        property = this.reSetPropertyFields();
  
        //job wanted settings
        var jobWan: jobsWanted;
        jobWan = this.reSetJobsWantedFields();
  
        //job hiring settings
        var jobHiring: jobsAvailable;
        jobHiring = this.reSetJobHiringFields();
  
        //debugger;
        if (result == true) {
          if (this.selectedCategory.trim().toLowerCase() == 'd7b411fb-1c21-4f0a-88f7-34d6b789f67d') {
            if (this.validateClassified()) {
              classifiedData.ageID = parseInt(this.selectedAge.id);
              classifiedData.conditionID = parseInt(this.selectedCondition.id);
              classifiedData.usageID = parseInt(this.selectedUsage.id);
            }
            else {
              this.notifyService.showError('Provide the missing data !', 'Missing Data in Classified');
              return;
            }
          }
          else if (this.selectedCategory.trim().toLowerCase() == '5bf243b1-2be4-4dda-93a0-cf9e06209e0f')// autos
          {
            if (this.validateAutosfied()) {
              autosData = this.setAuotsFields();
            }
            else {
              this.notifyService.showError('Provide the missing data !', 'Missing Data in Autos');
              return;
            }
          }
          else if (this.selectedCategory.trim().toLowerCase() == '0372dc00-f9cb-4cb5-8b95-901ea8a02efa' || this.selectedCategory.trim().toLowerCase() == '27bbb528-5666-4429-b153-7d6441c0efa1') // Property for rent & sale
          {
            //for rent
            if (this.validatePropertyFields()) {
              property = this.setPropertyFields();
            }
            else {
              this.notifyService.showError('Provide the missing data !', 'Missing Data in Autos');
              return;
            }
          }
          else if (this.selectedCategory.trim().toLowerCase() == '3c064386-a300-4f90-8e18-f6da9f83aa1c')// Job
          {
            if (this.validateJobfied()) {
              //jobHiring settings
              if (this.selectedSubCategory.trim().toUpperCase() == '967CEED7-54C0-488E-8AA8-69E6045E6C05') {
                jobHiring = this.setJobsHiringFields();
              }//job wanted sttings
              else if (this.selectedSubCategory.trim().toUpperCase() == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A') {
                jobWan = this.setJobsWantedFields();
              }
            }
            else {
              this.notifyService.showError('Provide the missing data !', 'Missing Data in Job');
              return;
            }
          }
        }
        else {
          this.notifyService.showError('Provide the missing data !', 'Something Wrong');
          return;
        }
  
  
        postingData.postClassfied = classifiedData;
        postingData.postAutos = autosData;
        postingData.postProperty = property;
        postingData.postJobsWanted = jobWan;
        postingData.postJobs = jobHiring;
  
        var photos = [];
  
        var counter = 1;
        if (this.imageurls.length > 0) {
          this.imageurlsNew.forEach(element => {
            var photo = {}; // here's your object
            photo["addPhotoID"] = counter,
              photo["addPhotoPath"] = element,
              photo["addPhotoIsActive"] = true,
              photo["addPHotoIsDeleted"] = false,
              photo["addPhotoAddedBy"] = 0,
              photo["addPhotoAddedDate"] = "03/03/2020",
              photo["addPhotoEditedBy"] = 0,
              photo["addPhotoEditedDate"] = "",
              photo["addIsThumbnail"] = false
            photos.push(photo);
            counter++;
          });
        }
  
        if (this.videoUrls.length > 0) {
          this.videoUrls.forEach(element => {
            postingData.VIDEO_PATH = element;
          })
        }
  
        //debugger;
        postingData.postPhotos = photos;
        //debugger;
        ////console.log(postingData);
        this.ngxLoader.start();  
        this.postingService.submitThePost(postingData).subscribe(data => {
          if (data != 0) {
            this.ResetForm();
            setTimeout(() => {
              this.ngxLoader.stop();
             }, 0);
            this.successNotification();
            // this.reloadComponent();
            //this.notifyService.showSuccess('Bravo!', 'Add posted successfully !');
          }
          else {
            this.errorNotification();
          }       
        });
        //this.ResetForm();    
        ////debugger;       
        //this.router.navigate(['../home'],{relativeTo : this.route });
      }
      else {
        this.notifyService.showError('Provide the missing data !', 'Missing Data');
      }
    }else{ this.notifyService.showError('Please Enable the Location !!', 'Somthing Wrong');}
    

  }

  successNotification() {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1000
    })
  }
  errorNotification() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href="">Provide the missing data ‼️‼️</a>'
    })
  }

  alertNotification(message:string) {
    Swal.fire({
      //position: 'top-center',
      icon: 'success',
      title: 'Please Enable Location for this site.',
      text : message,
      showConfirmButton: true,
      timer: 7000,
      footer: '<a href="https://www.myknocks.com/privacyAgrement">Please review Privacy policy from MyKnocks</a>'
    })
  }

  validateForm() {
    //debugger;
    var result = true;
    if (typeof this.selectedCategory == 'undefined' || !this.selectedCategory || this.selectedCategory == "0") {
      this.addCategoryAlert = true;
      result = false;
    }
    else if (typeof this.selectedSubCategory == 'undefined' || !this.selectedSubCategory || this.selectedSubCategory == "0") {
      this.addCategoryAlert = false;
      this.addSubCatAlert = true;
      result = false;
    }
    else if (typeof this.addTitle == 'undefined' || !this.addTitle) {
      this.addCategoryAlert = false;
      this.addSubCatAlert = false;
      this.addTitleAlert = true;
      result = false;
    }
    else if (typeof this.addPrice == 'undefined' || !this.addPrice) {
      this.addTitleAlert = false;
      this.addPriceAlert = true;
      result = false;

    }
    else {
      this.addTitleAlert = false;
      this.addPriceAlert = false;
    }
    return result;
  }

  validateClassified() {
    var result = true;
    if (typeof this.selectedAge == 'undefined' || !this.selectedAge || this.selectedAge.id == "0") {
      this.selectedAgeClassAlert = true;
      result = false;
    }
    else if (typeof this.selectedUsage == 'undefined' || !this.selectedUsage || this.selectedUsage.id == "0") {
      this.selectedAgeClassAlert = false;
      this.selectedUsageAlert = true;
      result = false;
    }
    else if (typeof this.selectedCondition == 'undefined' || !this.selectedCondition || this.selectedCondition.id == "0") {
      this.selectedAgeClassAlert = false;
      this.selectedUsageAlert = false;
      this.selectedConditionAlert = true;
      result = false;
    }
    else {
      this.selectedAgeClassAlert = false;
      this.selectedUsageAlert = false;
      this.selectedConditionAlert = false;
    }
    return result;
  }

  validateAutosfied() {
    var result = true;
    if (this.selectedSubCategory.trim().toUpperCase() == '042F8B36-2F6E-4A05-8DF0-2BC49710D67C') {
      if (typeof this.selectedAutoAge == 'undefined' || !this.selectedAutoAge || this.selectedAutoAge.id == "0") {
        this.selectedAutoAgeAlert = true;
        result = false;
      }
      else if (typeof this.selectedAutoUsage == 'undefined' || !this.selectedAutoUsage || this.selectedAutoUsage.id == "0") {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = true;
        result = false;
      }
      else if (typeof this.selectedWarranty == 'undefined' || !this.selectedWarranty || this.selectedWarranty.id == "0") {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedWlAlert = true;
        result = false;
      }
      else {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedWlAlert = false;
      }
    }
    else if (this.selectedSubCategory.trim().toUpperCase() == '79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() == 'FE1C13DE-9342-4EFB-824B-806DA0B8E3FE') {
      if (typeof this.selectedYear == 'undefined' || !this.selectedYear || this.selectedYear.id == "0") {
        this.selectedYearAlert = true;
        result = false;
      }
      else if (typeof this.selectedDoor == 'undefined' || !this.selectedDoor || this.selectedDoor.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = true;
        result = false;
      }
      else if (typeof this.selectedColor == 'undefined' || !this.selectedColor || this.selectedColor.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = true;
        result = false;
      }
      else if (typeof this.selectedBodyCondition == 'undefined' || !this.selectedBodyCondition || this.selectedBodyCondition.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = true;
        result = false;
      }
      else if (typeof this.selectedMechenicalCondition == 'undefined' || !this.selectedMechenicalCondition || this.selectedMechenicalCondition.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = true;
        result = false;
      }
      else if (typeof this.selectedTrim == 'undefined' || !this.selectedTrim || this.selectedTrim.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedTrimAlert = true;
        result = false;
      }
      else if (typeof this.selectedBodyType == 'undefined' || !this.selectedBodyType || this.selectedBodyType.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedTrimAlert = false;
        this.selectedBtAlert = true;
        result = false;
      }
      else if (typeof this.selectedCylender == 'undefined' || !this.selectedCylender || this.selectedCylender.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedTrimAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = true;
        result = false;
      }
      else if (typeof this.selectedTransmission == 'undefined' || !this.selectedTransmission || this.selectedCylender.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedTrimAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTransmissionAlert = true;
        result = false;
      }
      else if (typeof this.selectedFuelType == 'undefined' || !this.selectedFuelType || this.selectedFuelType.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTrimAlert = false;
        this.selectedTransmissionAlert = false;
        this.selectedFTAlert = true;
        result = false;
      }
      else if (typeof this.selectedHPList == 'undefined' || !this.selectedHPList || this.selectedHPList.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTransmissionAlert = false;
        this.selectedFTAlert = false;
        this.selectedTrimAlert = false;
        this.selectedHpAlert = true;
        result = false;
      }
      else if (typeof this.selectedWarranty == 'undefined' || !this.selectedWarranty || this.selectedWarranty.id == "0") {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTrimAlert = false;
        this.selectedTransmissionAlert = false;
        this.selectedFTAlert = false;
        this.selectedHpAlert = false;
        this.selectedWlAlert = true;
        result = false;
      }
      else {
        this.selectedYearAlert = false;
        this.selectedDoorAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
        this.selectedBtAlert = false;
        this.selectedClAlert = false;
        this.selectedTransmissionAlert = false;
        this.selectedFTAlert = false;
        this.selectedTrimAlert = false;
        this.selectedWlAlert = false;
        this.selectedElAlert = false;
        this.selectedHpAlert = false;
        this.selectedEbAlert = false;
        this.selectedWlAlert = false;
      }
    }
    else if (this.selectedSubCategory.trim().toUpperCase() == '6240A3B2-15B4-49AC-BF96-B30311BAEC60') {
      if (typeof this.selectedYear == 'undefined' || !this.selectedYear || this.selectedYear.id == "0") {
        this.selectedYearAlert = true;
        result = false;
      }
      else if (typeof this.selectedColor == 'undefined' || !this.selectedColor || this.selectedColor.id == "0") {
        this.selectedYearAlert = false;
        this.selectedColorAlert = true;
        result = false;
      }
      else if (typeof this.selectedBodyCondition == 'undefined' || !this.selectedBodyCondition || this.selectedBodyCondition.id == "0") {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = true;
        result = false;
      }
      else if (typeof this.selectedMechenicalCondition == 'undefined' || !this.selectedMechenicalCondition || this.selectedMechenicalCondition.id == "0") {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = true;
        result = false;
      }
      else if (typeof this.selectedWarranty == 'undefined' || !this.selectedWarranty || this.selectedWarranty.id == "0") {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedWlAlert = true;
        result = false;
      }
      else if (typeof this.selectedEngineBike == 'undefined' || !this.selectedEngineBike || this.selectedEngineBike.id == "0") {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedWlAlert = false;
        this.selectedEbAlert = true;
        result = false;
      }
      else {
        this.selectedYearAlert = false;
        this.selectedColorAlert = false;
        this.selectedBcAlert = false;
        this.selectedMcAlert = false;
        this.selectedWlAlert = false;
      }
    }
    else if (this.selectedSubCategory.trim().toUpperCase() == '9C89C78F-B925-4129-A95B-D4D750954AA3') {
      if (typeof this.selectedAutoAge == 'undefined' || !this.selectedAutoAge || this.selectedAutoAge.id == "0") {
        this.selectedAutoAgeAlert = true;
        result = false;
      }
      else if (typeof this.selectedAutoUsage == 'undefined' || !this.selectedAutoUsage || this.selectedAutoUsage.id == "0") {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = true;
        result = false;
      }
      else {
        this.selectedAutoAgeAlert = false;
        this.selectedAutoUsageAlert = false;
      }
    }
    else {
      this.selectedYearAlert = false;
      this.selectedDoorAlert = false;
      this.selectedColorAlert = false;
      this.selectedBcAlert = false;
      this.selectedMcAlert = false;
      this.selectedAutoAgeAlert = false;
      this.selectedAutoUsageAlert = false;
      this.selectedBtAlert = false;
      this.selectedClAlert = false;
      this.selectedTransmissionAlert = false;
      this.selectedFTAlert = false;
      this.selectedTrimAlert = false;
      this.selectedWlAlert = false;
      this.selectedElAlert = false;
      this.selectedHpAlert = false;
      this.selectedEbAlert = false;
      this.selectedWlAlert = false;
      this.selectedAutoAgeAlert = false;
      this.selectedAutoUsageAlert = false;
      this.selectedWlAlert = false;
      result = true;
    }
    return result;
  }

  validateRentorSalefied() {
    var result = true;
    if (this.selectedSubCatFirstChild.trim().toUpperCase() == '220EC902-8ED1-4128-BD35-F3C4318EF5AC' || this.selectedSubCatFirstChild.trim().toUpperCase() == '48E2CB62-A9E4-4103-8751-248646CB1D0A' || this.selectedSubCatFirstChild.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' || this.selectedSubCatFirstChild.trim().toUpperCase() == '59C7CD4B-B627-4C88-8303-91FA48EA72AD' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'BF6DC65D-8B63-4C26-B4F6-5FE9E8530245' || this.selectedSubCatFirstChild.trim().toUpperCase() == '65134CA9-E6E2-4FD9-93FF-83BC56C09A86' || this.selectedSubCatFirstChild.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'A884609A-AEAF-4FB3-9FFA-879992B88A2E' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'E47081E1-2622-4255-A89D-34FF3ED38AC4' || this.selectedSubCatFirstChild.trim().toUpperCase() == '9D477485-41B4-4E98-A3C4-3903D84C71DE') {
      if (typeof this.selectedMonth == 'undefined' || !this.selectedMonth || this.selectedMonth == "0") {
        this.selectedMonthAlert = true;
        result = false;
      }
      else if (typeof this.selectedDuration == 'undefined' || !this.selectedDuration || this.selectedDuration.id == "0") {
        this.selectedMonthAlert = false;
        this.selectedDurationAlert = true;
        result = false;
      }
      else if (typeof this.selectedPaid == 'undefined' || !this.selectedPaid || this.selectedPaid.id == "0") {
        this.selectedMonthAlert = false;
        this.selectedDurationAlert = false;
        this.selectedPaidAlert = true;
        result = false;
      }
      else if (this.selectedSubCatFirstChild.trim().toUpperCase() == '220EC902-8ED1-4128-BD35-F3C4318EF5AC' || this.selectedSubCatFirstChild.trim().toUpperCase() == '0D71E6F8-7F0D-47C0-9284-AC5B52971641' || this.selectedSubCatFirstChild.trim().toUpperCase() == '48E2CB62-A9E4-4103-8751-248646CB1D0A' || this.selectedSubCatFirstChild.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' || this.selectedSubCatFirstChild.trim().toUpperCase() == '59C7CD4B-B627-4C88-8303-91FA48EA72AD' || this.selectedSubCatFirstChild.trim().toUpperCase() == '65134CA9-E6E2-4FD9-93FF-83BC56C09A86' || this.selectedSubCatFirstChild.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E') {
        if (typeof this.selectedMonth == 'undefined' || !this.selectedMonth || this.selectedMonth == "0") {
          this.selectedMonthAlert = true;
          result = false;
        }
        else if (typeof this.selectedBeds == 'undefined' || !this.selectedBeds || this.selectedBeds.id == "0") {
          this.selectedMonthAlert = false;
          this.selectedBedsAlert = true;
          result = false;
        }
        else if (typeof this.selectedBaths == 'undefined' || !this.selectedBaths || this.selectedBaths.id == "0") {
          this.selectedMonthAlert = false;
          this.selectedBedsAlert = false;
          this.selectedBathsAlert = true;
          result = false;
        }
        else {
          this.selectedMonthAlert = false;
          this.selectedBedsAlert = false;
          this.selectedBathsAlert = false;
          this.selectedDurationAlert = false;
          this.selectedPaidAlert = false;
          return result;
        }
      }
    }
    else if (this.selectedSubCatFirstChild.trim().toUpperCase() == '9D7F5A44-A4AE-4446-AA59-D46C81799190' || this.selectedSubCategory.trim().toUpperCase() == '85A400C4-0F9B-4D2D-AD3D-5982DBDE8974' || this.selectedSubCatFirstChild.trim().toUpperCase() == '7FE50536-4CE2-4A02-82F0-B27DF7047162') {
      if (typeof this.selectedMonth == 'undefined' || !this.selectedMonth || this.selectedMonth == "0") {
        this.selectedMonthAlert = true;
        result = false;
      }
      else if (typeof this.selectedBeds == 'undefined' || !this.selectedBeds || this.selectedBeds.id == "0") {
        this.selectedMonthAlert = false;
        this.selectedBedsAlert = true;
        result = false;
      }
      else if (typeof this.selectedBaths == 'undefined' || !this.selectedBaths || this.selectedBaths.id == "0") {
        this.selectedMonthAlert = false;
        this.selectedBedsAlert = false;
        this.selectedBathsAlert = true;
        result = false;
      }
      else {
        this.selectedMonthAlert = false;
        this.selectedBedsAlert = false;
        this.selectedBathsAlert = false;
        this.selectedDurationAlert = false;
        this.selectedPaidAlert = false;
        return result;
      }
    }
    else if (this.selectedSubCatFirstChild.trim().toUpperCase() == 'ABFEAF24-F91F-425B-830C-B044BB177DB9' || this.selectedSubCatFirstChild.trim().toUpperCase() == '2E0E4429-081D-4CE3-9B6D-9BF1CDA0EAE1' || this.selectedSubCategory.trim().toUpperCase() == '58F1AC77-DF13-469B-8457-38DD29AF3266' || this.selectedSubCatFirstChild.trim().toUpperCase() == '23D83481-6D73-46CB-B584-1468D0A6C057' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'B5BE8248-7BFB-4A82-BFC1-E8243312B0F8' || this.selectedSubCatFirstChild.trim().toUpperCase() == 'EC1C7024-1344-435C-ACB7-D6F262658E41' || this.selectedSubCatFirstChild.trim().toUpperCase() == '466E8421-D720-4217-843E-00A0C923BA14') {
      if (typeof this.selectedMonth == 'undefined' || !this.selectedMonth || this.selectedMonth == "0") {
        this.selectedMonthAlert = true;
        result = false;
      }
      else {
        this.selectedMonthAlert = false;
        return result;
      }
    }
    else {
      this.selectedMonthAlert = false;
      this.selectedBedsAlert = false;
      this.selectedBathsAlert = false;
      this.selectedDurationAlert = false;
      this.selectedPaidAlert = false;
      return result;
    }
    return result;
  }

  validateJobfied() {
    var result = true;
    if (this.selectedSubCategory.trim().toUpperCase() == '967CEED7-54C0-488E-8AA8-69E6045E6C05') {
      if (typeof this.selectedPosition == 'undefined' || !this.selectedPosition || this.selectedPosition.id == "0") {
        this.selectedPositionAlert = true;
        result = false;
      }
      else if (typeof this.selectedRGender == 'undefined' || !this.selectedRGender || this.selectedRGender.id == "0") {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = true;
        result = false;
      }
      else if (typeof this.selectedRWE == 'undefined' || !this.selectedRWE || this.selectedRWE.id == "0") {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = true;
        result = false;
      }
      else if (typeof this.selectedREducation == 'undefined' || !this.selectedREducation || this.selectedREducation.id == "0") {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = true;
        result = false;
      }
      else if (typeof this.selectedRCommitment == 'undefined' || !this.selectedRCommitment || this.selectedRCommitment.id == "0") {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = true;
        result = false;
      }
      else if (typeof this.selectedRShift == 'undefined' || !this.selectedRShift || this.selectedRShift.id == "0") {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = false;
        this.selectedShiftAlert = true;
        result = false;
      }
      else {
        this.selectedPositionAlert = false;
        this.selectedGenderAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = false;
        this.selectedShiftAlert = false;
        result = true;
      }
    }
    else if (this.selectedSubCategory.trim().toUpperCase() == 'A07D5BE2-BC59-4086-82F5-1E1DF97EC87A') {
      if (typeof this.selectedCompensation == 'undefined' || !this.selectedCompensation || this.selectedCompensation == "") {
        this.selectedCompensationAlert = true;
        result = false;
      }
      else if (typeof this.selectedRWE == 'undefined' || !this.selectedRWE || this.selectedRWE.id == "0") {
        this.selectedCompensationAlert = false;
        this.selectedExperienceAlert = true;
        result = false;
      }
      else if (typeof this.selectedREducation == 'undefined' || !this.selectedREducation || this.selectedREducation.id == "0") {
        this.selectedCompensationAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = true;
        result = false;
      }
      else if (typeof this.selectedRCommitment == 'undefined' || !this.selectedRCommitment || this.selectedRCommitment.id == "0") {
        this.selectedCompensationAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = true;
        result = false;
      }
      else if (typeof this.selectedRShift == 'undefined' || !this.selectedRShift || this.selectedRShift.id == "0") {
        this.selectedCompensationAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = false;
        this.selectedShiftAlert = true;
        result = false;
      }
      else {
        this.selectedCompensationAlert = false;
        this.selectedExperienceAlert = false;
        this.selectedEducationAlert = false;
        this.selectedCommitementAlert = false;
        this.selectedShiftAlert = false;
        result = true;
      }
    }
    return result;
  }

  setAuotsFields() {
    const autosDate = new postAutos();
    var autoFa: autoFacilities[] = [
      { "ID": 0, "addID": "", "facilityID": 1 }
    ];
    if (this.selectedSubCategory.trim().toUpperCase() == '042F8B36-2F6E-4A05-8DF0-2BC49710D67C') {
      autosDate.age = parseInt(this.selectedAutoAge.id);
      autosDate.usage = parseInt(this.selectedAutoUsage.id);
      autosDate.war = parseInt(this.selectedWarranty.id);
      autosDate.warranty = parseInt(this.selectedWarranty.id);

      autosDate.bodyCon = 0;
      autosDate.bodyType = 0;
      autosDate.color = 0;
      autosDate.cylender = 0;
      autosDate.doors = 0;
      autosDate.ft = 0;
      autosDate.hp = 0;
      autosDate.kilometer = 0;
      autosDate.macCon = 0;
      autosDate.man = 0;
      autosDate.motercycleEngine = 0;
      autosDate.st = 0;
      autosDate.transmission = 0;
      autosDate.trimID = 0;
      autosDate.years = 0;
    }
    else if (this.selectedSubCategory.trim().toUpperCase() == '79504CCA-41F7-4936-ACF3-6757E9AA7E29' || this.selectedSubCategory.trim().toUpperCase() == 'FE1C13DE-9342-4EFB-824B-806DA0B8E3FE') {
      autosDate.age = 0;
      autosDate.years = parseInt(this.selectedYear.id);
      autosDate.doors = parseInt(this.selectedDoor.id);
      autosDate.color = parseInt(this.selectedColor.id);
      autosDate.bodyCon = parseInt(this.selectedBodyCondition.id);
      autosDate.macCon = parseInt(this.selectedMechenicalCondition.id);
      autosDate.bodyType = parseInt(this.selectedBodyType.id);
      autosDate.cylender = parseInt(this.selectedCylender.id);
      autosDate.doors = parseInt(this.selectedDoor.id);
      autosDate.ft = parseInt(this.selectedFuelType.id);
      autosDate.hp = parseInt(this.selectedHPList.id);
      autosDate.kilometer = this.maxkmValue;
      //autosDate.kilometer = this.postDetailForm.get('kilometer').value;
      autosDate.transmission = parseInt(this.selectedTransmission.id);
      autosDate.trimID = parseInt(this.selectedTrim.id);
      autosDate.war = parseInt(this.selectedWarranty.id);
      autosDate.warranty = parseInt(this.selectedWarranty.id);

      autosDate.man = 0;//parseInt(this.selected.id);;//manufacturer
      autosDate.motercycleEngine = 0;
      autosDate.st = 0;// parseInt(this.selectedse.id);;//seller type     
      autosDate.usage = 0;


    }
    else if (this.selectedSubCategory.trim().toUpperCase() == '6240A3B2-15B4-49AC-BF96-B30311BAEC60') {
      autosDate.years = parseInt(this.selectedYear.id);
      autosDate.color = parseInt(this.selectedColor.id);
      autosDate.bodyCon = parseInt(this.selectedBodyCondition.id);
      autosDate.macCon = parseInt(this.selectedMechenicalCondition.id);
      autosDate.warranty = parseInt(this.selectedWarranty.id);
      autosDate.motercycleEngine = parseInt(this.selectedEngineBike.id);

      autosDate.age = 0;
      autosDate.bodyType = 0;
      autosDate.cylender = 0;
      autosDate.doors = 0;
      autosDate.ft = 0;
      autosDate.hp = 0;
      autosDate.kilometer = this.maxkmValue;
      autosDate.man = 0;//parseInt(this.selected.id);;//manufacturer              
      autosDate.st = 0;// parseInt(this.selectedse.id);;//seller type
      autosDate.transmission = 0;
      autosDate.trimID = 0;
      autosDate.usage = 0;
      autosDate.war = 0;
    }
    else if (this.selectedSubCategory.trim().toUpperCase() == '9C89C78F-B925-4129-A95B-D4D750954AA3') {

      autosDate.age = parseInt(this.selectedAutoAge.id);
      autosDate.usage = parseInt(this.selectedAutoUsage.id);

      autosDate.bodyCon = 0;
      autosDate.bodyType = 0;
      autosDate.color = 0;
      autosDate.cylender = 0;
      autosDate.doors = 0;
      autosDate.ft = 0;
      autosDate.hp = 0;
      autosDate.kilometer = 0;
      //autosDate.kilometer = this.postDetailForm.get('kilometer').value;
      autosDate.macCon = 0;
      autosDate.man = 0;//parseInt(this.selected.id);;//manufacturer
      autosDate.motercycleEngine = 0;
      autosDate.st = 0;// parseInt(this.selectedse.id);;//seller type
      autosDate.transmission = 0;
      autosDate.trimID = 0;
      autosDate.war = 0;
      autosDate.warranty = 0;
      autosDate.years = 0;

    }
    else {
      var autoFa: autoFacilities[] = [
        { "ID": 0, "addID": "", "facilityID": 1 }
      ];
      autosDate.age = 0;
      autosDate.autoFacilities = autoFa;
      autosDate.bodyCon = 0;
      autosDate.bodyType = 0;
      autosDate.color = 0;
      autosDate.cylender = 0;
      autosDate.doors = 0;
      autosDate.ft = 0;
      autosDate.hp = 0;
      autosDate.kilometer = 0;
      //autosDate.kilometer = this.postDetailForm.get('kilometer').value;
      autosDate.macCon = 0;
      autosDate.man = 0;//manufacturer
      autosDate.motercycleEngine = 0;
      autosDate.st = 0;//seller type
      autosDate.transmission = 0;
      autosDate.trimID = 0;
      autosDate.usage = 0;
      autosDate.war = 0;
      autosDate.warranty = 0;
      autosDate.years = 0;
    }

    return autosDate;
  }

  reSetAuotsFields() {
    const autosDate = new postAutos();
    var autoFa: autoFacilities[] = [
      { "ID": 0, "addID": "", "facilityID": 1 }
    ];
    autosDate.age = 0;
    autosDate.autoFacilities = autoFa;
    autosDate.bodyCon = 0;
    autosDate.bodyType = 0;
    autosDate.color = 0;
    autosDate.cylender = 0;
    autosDate.doors = 0;
    autosDate.ft = 0;
    autosDate.hp = 0;
    autosDate.kilometer = 0;
    autosDate.macCon = 0;
    autosDate.man = 0;//manufacturer
    autosDate.motercycleEngine = 0;
    autosDate.st = 0;//seller type
    autosDate.transmission = 0;
    autosDate.trimID = 0;
    autosDate.usage = 0;
    autosDate.war = 0;
    autosDate.warranty = 0;
    autosDate.years = 0;
    return autosDate;
  }

  setJobsHiringFields() {

    const jobHiring = new jobsAvailable();

    jobHiring.jobExpID = parseInt(this.selectedRWE.id);
    jobHiring.jobEduID = parseInt(this.selectedREducation.id);
    jobHiring.jobCommitmentID = parseInt(this.selectedRCommitment.id);
    jobHiring.jobsGenderID = parseInt(this.selectedRGender.id);
    jobHiring.jobsShiftID = parseInt(this.selectedRShift.id);
    jobHiring.jobsNoOfPositions = parseInt(this.selectedPosition.id);
    jobHiring.jobsFromSalary = this.minSalValue;
    jobHiring.jobsToSalary = this.maxSalValue;
    jobHiring.jobsFromAge = this.minAgeValue;
    jobHiring.jobsToAge = this.maxAgeValue;
    jobHiring.jobsjDegreeTitle = this.selectedDegree;

    jobHiring.jobsDisplaySalary = true;
    jobHiring.jobsRequiredAge = true;
    jobHiring.jobsRequiredCity = true;
    jobHiring.jobsTravelRequired = true;
    jobHiring.jobsRequiredDegree = true;
    jobHiring.jobsRequiredExp = true;
    jobHiring.jobsRequiredGender = true;

    return jobHiring;
  }

  reSetJobHiringFields() {
    const jobHiring = new jobsAvailable()
    jobHiring.jobEduID = 0;
    jobHiring.jobExpID = 0;
    jobHiring.JobAddID = "";
    jobHiring.jobCatID = "";
    jobHiring.jobCommitmentID = 0;
    jobHiring.jobID = 0;
    jobHiring.jobSubCatID = "";
    jobHiring.jobsDisplaySalary = false;
    jobHiring.jobsFromAge = 0;
    jobHiring.jobsFromSalary = 0;
    jobHiring.jobsGenderID = 0;
    jobHiring.jobsNoOfPositions = 1;
    jobHiring.jobsRequiredAge = false;
    jobHiring.jobsRequiredCity = false;
    jobHiring.jobsTravelRequired = false;
    jobHiring.jobsRequiredDegree = false;
    jobHiring.jobsRequiredExp = false;
    jobHiring.jobsRequiredGender = false;
    jobHiring.jobsShiftID = 0;
    jobHiring.jobsToAge = 0;
    jobHiring.jobsToSalary = 0;
    jobHiring.jobsjDegreeTitle = "";

    return jobHiring;
  }

  setJobsWantedFields() {

    const jobWant = new jobsWanted();

    jobWant.jobExpID = parseInt(this.selectedRWE.id);
    jobWant.jobEduID = parseInt(this.selectedREducation.id);
    jobWant.jobCommitmentID = parseInt(this.selectedRCommitment.id);
    jobWant.jobShiftID = parseInt(this.selectedRShift.id);
    jobWant.jobCompensation = parseInt(this.selectedCompensation);

    return jobWant;
  }

  reSetJobsWantedFields() {

    var JobFa: jobFacilities[] = [
      { "ID": 0, "JobaddID": "", "facilityID": 1 }
    ];

    const jobWanted = new jobsWanted();
    jobWanted.jobFacilities = JobFa;
    jobWanted.jobCompensation = 0;
    jobWanted.jobCommitmentID = 0;
    jobWanted.jobEduID = 0;
    jobWanted.jobExpID = 0;
    jobWanted.jobID = 0;
    jobWanted.jobSubCatID = "";

    return jobWanted;
  }

  reSetPropertyFields() {
    const property = new postProperty();
    var proFa: propertyFacilities[] = [
      { "ID": 0, "FacilityID": 1 }
    ];

    property.PM = 0;
    property.baths = 0;
    property.building = "0";
    property.duration = 0;
    property.furnished = 0;
    property.proAffentNumber = "";
    property.proBrokerID = "";
    property.proBrokerName = "";
    property.proCommunityFee = 0;
    property.proDeveloper = "";
    property.proDuration = 0;
    property.proFurnished = false;
    property.proID = 0;
    property.proLandlordName = "";
    property.proReferenceID = 0;
    property.proRegistrationNumber = "";
    property.proSender = false;
    property.propaymentMethod = 0;
    property.propertyFacilities = proFa;
    property.readyDate = "";
    property.size = 0;

    return property
  }

  setPropertyFields() {
    const property = new postProperty();
    var proFa: propertyFacilities[] = null;

    let proFacilities = [];
    let facilityData = {} as propertyFacilities;
    //commentData.id = 3;
    //commentData.name = 'something';
    //myArray.push(commentData);

    // var proFa: propertyFacilities[] ; = [
    //   { "ID": 0, "FacilityID": 1 }
    // ];

    property.PM = 0;
    property.baths = 0;

    property.readyDate = "";
    if (this.selectedMonthShow == true) {
      property.readyDate = this.FacilityReadyDate;
    }

    property.building = "";
    if (this.showBuildingOption == true) {
      property.building = this.FacilityBuilding;
    }
    property.duration = 0;

    if (this.showDurationOPtion == true) {
      property.duration = parseInt(this.selectedDuration.id);
    }
    property.proFurnished = false;
    if (this.furnishedShow == true) {
      property.proFurnished = this.furnishedIsChecked;
    }

    if (this.showBalconyOption == true) {
      if (this.BalconyIsChecked == true) {
        facilityData.ID = 1;
        facilityData.FacilityID = 1;

        proFacilities.push(facilityData);
      }
      if (this.ParkingIsChecked == true) {
        facilityData = {} as propertyFacilities;
        if (proFacilities.length > 0) {
          facilityData.ID = 2;
        }
        else {
          facilityData.ID = 1;
        }
        facilityData.FacilityID = 2
        proFacilities.push(facilityData);
      }
    }

    if (proFacilities.length > 0) {
      property.propertyFacilities = proFacilities;
    }
    else {
      property.propertyFacilities = null;
    }

    property.proAffentNumber = "";
    property.proBrokerID = "";
    property.proBrokerName = "";
    property.proCommunityFee = 0;
    property.proDeveloper = "";
    property.proID = 0;
    property.proLandlordName = "";
    property.proReferenceID = 0;
    property.proRegistrationNumber = "";
    property.propaymentMethod = 0;
    property.readyDate = "";

    property.proSender = false;

    if (this.showLanLordOption == true) {
      if (this.LanLord != "" && this.LanLord == "LanLord") {
        property.proSender = true
      }

    }

    property.size = 0;
    if (this.showSizeOption == true) {
      property.size = parseInt(this.FacilitySize);
    }

    property.beds = 0;
    if (this.showBedOptions == true) {
      property.beds = parseInt(this.selectedBeds.id);
    }

    if (this.showBathOptions == true) {
      property.baths = parseInt(this.selectedBaths.id);
    }

    property.PM = 0;
    if (this.showRentPaidOption == true) {
      property.PM = parseInt(this.selectedPaid.id);
    }

    // public proID: number;
    // public proAddID : string ;  
    // public beds : number ;
    // public baths: number;
    // public building : string ;  
    // public furnished : number ;
    // public size: number;
    // public readyDate : string ;  
    // public duration : number ;
    // public PM: number;
    // public proReferenceID : number ;  
    // public proFurnished : boolean ;
    // public proDuration: number;
    // public propaymentMethod : number ;  
    // public proDeveloper : string ;
    // public proCommunityFee : number ;
    // public proSender: boolean;
    // public proLandlordName : string ;  
    // public proRegistrationNumber : string ;
    // public proBrokerID : string ;  
    // public proBrokerName : string ;
    // public proAffentNumber : string ;             
    // public propertyFacilities : propertyFacilities[];

    return property
  }

  validatePropertyFields() {
    var result = true;
    if (this.selectedMonthShow == true && (typeof this.selectedMonth == 'undefined' || !this.selectedMonth || this.selectedMonth == "0")) {
      this.selectedMonthAlert = true;
      result = false;
    }
    else if (this.showBuildingOption == true && (typeof this.FacilityBuilding == 'undefined' || !this.FacilityBuilding || this.FacilityBuilding == "")) {
      this.selectedMonthAlert = false;
      this.FacilityBuildingAlert = true;
      result = false;
    }
    else if (this.showSizeOption == true && (typeof this.FacilitySize == 'undefined' || !this.FacilitySize || this.FacilitySize == "")) {
      this.selectedMonthAlert = false;
      this.FacilityBuildingAlert = false;
      this.facilitySizeALert = true;
      result = false;
    }
    else if (this.showBedOptions == true && (typeof this.selectedBeds == 'undefined' || !this.selectedBeds || this.selectedBeds.id == "0")) {
      this.selectedMonthAlert = false;
      this.FacilityBuildingAlert = false;
      this.facilitySizeALert = false;
      this.selectedBedsAlert = true;
      result = false;
    }
    else if (this.showBathOptions == true && (typeof this.selectedBaths == 'undefined' || !this.selectedBaths || this.selectedBaths.id == "0")) {
      this.selectedMonthAlert = false;
      this.FacilityBuildingAlert = false;
      this.facilitySizeALert = false;
      this.selectedBedsAlert = false;
      this.selectedBathsAlert = true;
      result = false;
    }
    else if (this.showDurationOPtion == true && (typeof this.selectedDuration == 'undefined' || !this.selectedDuration || this.selectedDuration.id == "0")) {
      this.selectedMonthAlert = false;
      this.FacilityBuildingAlert = false;
      this.facilitySizeALert = false;
      this.selectedBedsAlert = false;
      this.selectedBathsAlert = false;
      this.selectedDurationAlert = true;
      result = false;
    }
    else if (this.showRentPaidOption == true && (typeof this.selectedPaid == 'undefined' || !this.selectedPaid || this.selectedPaid.id == "0")) {
      this.selectedMonthAlert = false;
      this.FacilityBuildingAlert = false;
      this.facilitySizeALert = false;
      this.selectedBedsAlert = false;
      this.selectedBathsAlert = false;
      this.selectedDurationAlert = false;
      this.selectedPaidAlert = true;
      result = false;
    }
    else {
      this.selectedMonthAlert = false;
      this.FacilityBuildingAlert = false;
      this.facilitySizeALert = false;
      this.selectedBedsAlert = false;
      this.selectedBathsAlert = false;
      this.selectedDurationAlert = false;
      this.selectedPaidAlert = false;
      result = true;
    }

    return result;

  }

  showPropertyFileds() {
    this.showMonthFileds();
    this.showFurnishedButton();
    this.showLanLord();
    this.showBalCony();
    this.showBuilding();
    this.showSize();
    this.showBedsBaths();
    this.showDuration();
    this.showRentPaid();

  }

  showMonthFileds() {
    if (this.selectedSubCatFirstChild.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '48E2CB62-A9E4-4103-8751-248646CB1D0A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '65134CA9-E6E2-4FD9-93FF-83BC56C09A86' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '59C7CD4B-B627-4C88-8303-91FA48EA72AD' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' ||
      this.selectedSubCategory.trim().toUpperCase() == 'BF6DC65D-8B63-4C26-B4F6-5FE9E8530245' ||
      this.selectedSubCategory.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'E47081E1-2622-4255-A89D-34FF3ED38AC4' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '9D477485-41B4-4E98-A3C4-3903D84C71DE' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'CB140F96-5F91-43BF-96FF-2DD56357E2B2' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7FE50536-4CE2-4A02-82F0-B27DF7047162' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '51358E57-C0DE-462C-8216-2CE99C008556' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'ABFEAF24-F91F-425B-830C-B044BB177DB9' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'FA91D5BB-D606-42BD-8A42-1F81286C1923' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '2E0E4429-081D-4CE3-9B6D-9BF1CDA0EAE1' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '23D83481-6D73-46CB-B584-1468D0A6C057' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A884609A-AEAF-4FB3-9FFA-879992B88A2E'// || 
      //this.selectedSubCatFirstChild.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' || 
      //this.selectedSubCatFirstChild.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E' || 
      //this.selectedSubCatFirstChild.trim().toUpperCase() == 'E47081E1-2622-4255-A89D-34FF3ED38AC4' || 
      //this.selectedSubCatFirstChild.trim().toUpperCase() == '9D477485-41B4-4E98-A3C4-3903D84C71DE'
    ) {
      this.selectedMonthShow = true;
    }
    else {
      this.selectedMonthShow = false;
    }
  }

  showFurnishedButton() {
    if (this.selectedSubCatFirstChild.trim().toUpperCase() == 'B5BE8248-7BFB-4A82-BFC1-E8243312B0F8' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '9D7F5A44-A4AE-4446-AA59-D46C81799190' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '466E8421-D720-4217-843E-00A0C923BA14' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '220EC902-8ED1-4128-BD35-F3C4318EF5AC' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '0D71E6F8-7F0D-47C0-9284-AC5B52971641' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7FE50536-4CE2-4A02-82F0-B27DF7047162' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'ABFEAF24-F91F-425B-830C-B044BB177DB9' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '2E0E4429-081D-4CE3-9B6D-9BF1CDA0EAE1' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '23D83481-6D73-46CB-B584-1468D0A6C057' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '48E2CB62-A9E4-4103-8751-248646CB1D0A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '59C7CD4B-B627-4C88-8303-91FA48EA72AD' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'BF6DC65D-8B63-4C26-B4F6-5FE9E8530245' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '65134CA9-E6E2-4FD9-93FF-83BC56C09A86' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A884609A-AEAF-4FB3-9FFA-879992B88A2E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'E47081E1-2622-4255-A89D-34FF3ED38AC4') {
      this.furnishedShow = true;
    }
    else {
      this.furnishedShow = false;
    }

  }

  showLanLord() {
    if (this.selectedSubCatFirstChild.trim().toUpperCase() == 'B5BE8248-7BFB-4A82-BFC1-E8243312B0F8' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'EC1C7024-1344-435C-ACB7-D6F262658E41' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '9D7F5A44-A4AE-4446-AA59-D46C81799190' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '466E8421-D720-4217-843E-00A0C923BA14' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '220EC902-8ED1-4128-BD35-F3C4318EF5AC' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '0D71E6F8-7F0D-47C0-9284-AC5B52971641' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7FE50536-4CE2-4A02-82F0-B27DF7047162' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'ABFEAF24-F91F-425B-830C-B044BB177DB9' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '2E0E4429-081D-4CE3-9B6D-9BF1CDA0EAE1' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '23D83481-6D73-46CB-B584-1468D0A6C057') {
      this.showLanLordOption = true;
    }
    else {
      this.showLanLordOption = false;
    }
  }

  showBalCony() {
    if (this.selectedSubCatFirstChild.trim().toUpperCase() == 'B5BE8248-7BFB-4A82-BFC1-E8243312B0F8') {
      this.showBalconyOption = true;
    }
    else {
      this.showBalconyOption = false;
    }
  }

  showBuilding() {
    if (this.selectedSubCatFirstChild.trim().toUpperCase() == 'B5BE8248-7BFB-4A82-BFC1-E8243312B0F8' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '9D7F5A44-A4AE-4446-AA59-D46C81799190' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '466E8421-D720-4217-843E-00A0C923BA14' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '0D71E6F8-7F0D-47C0-9284-AC5B52971641' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7FE50536-4CE2-4A02-82F0-B27DF7047162' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'ABFEAF24-F91F-425B-830C-B044BB177DB9' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'BF6DC65D-8B63-4C26-B4F6-5FE9E8530245' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A884609A-AEAF-4FB3-9FFA-879992B88A2E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'E47081E1-2622-4255-A89D-34FF3ED38AC4') {
      this.showBuildingOption = true;
    }
    else {
      this.showBuildingOption = false;
    }
  }

  showSize() {
    if (this.selectedSubCatFirstChild.trim().toUpperCase() == 'B5BE8248-7BFB-4A82-BFC1-E8243312B0F8' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'EC1C7024-1344-435C-ACB7-D6F262658E41' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '466E8421-D720-4217-843E-00A0C923BA14' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'ABFEAF24-F91F-425B-830C-B044BB177DB9' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '2E0E4429-081D-4CE3-9B6D-9BF1CDA0EAE1' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '23D83481-6D73-46CB-B584-1468D0A6C057' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'BF6DC65D-8B63-4C26-B4F6-5FE9E8530245' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A884609A-AEAF-4FB3-9FFA-879992B88A2E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'E47081E1-2622-4255-A89D-34FF3ED38AC4' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '9D477485-41B4-4E98-A3C4-3903D84C71DE') {
      this.showSizeOption = true;
    }
    else {
      this.showSizeOption = false;
    }

  }

  showBedsBaths() {

    if (this.selectedSubCatFirstChild.trim().toUpperCase() == '9D7F5A44-A4AE-4446-AA59-D46C81799190' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '220EC902-8ED1-4128-BD35-F3C4318EF5AC' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '0D71E6F8-7F0D-47C0-9284-AC5B52971641' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7FE50536-4CE2-4A02-82F0-B27DF7047162' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '48E2CB62-A9E4-4103-8751-248646CB1D0A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '59C7CD4B-B627-4C88-8303-91FA48EA72AD' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '65134CA9-E6E2-4FD9-93FF-83BC56C09A86' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'BF6DC65D-8B63-4C26-B4F6-5FE9E8530245' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'CB140F96-5F91-43BF-96FF-2DD56357E2B2') {
      this.showBedOptions = true;
      this.showBathOptions = true;
      this.showBalconyOption = true;
    }
    else {
      this.showBedOptions = false;
      this.showBathOptions = false;
      this.showBalconyOption = false;
    }

  }

  showDuration() {

    if (this.selectedSubCatFirstChild.trim().toUpperCase() == '220EC902-8ED1-4128-BD35-F3C4318EF5AC' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '48E2CB62-A9E4-4103-8751-248646CB1D0A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '59C7CD4B-B627-4C88-8303-91FA48EA72AD' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'BF6DC65D-8B63-4C26-B4F6-5FE9E8530245' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '65134CA9-E6E2-4FD9-93FF-83BC56C09A86' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A884609A-AEAF-4FB3-9FFA-879992B88A2E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'E47081E1-2622-4255-A89D-34FF3ED38AC4' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '9D477485-41B4-4E98-A3C4-3903D84C71DE') {
      this.showDurationOPtion = true;
    }
    else {
      this.showDurationOPtion = false;
    }

  }

  showRentPaid() {

    if (this.selectedSubCatFirstChild.trim().toUpperCase() == '220EC902-8ED1-4128-BD35-F3C4318EF5AC' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '48E2CB62-A9E4-4103-8751-248646CB1D0A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '7F98C45F-343C-459D-B09E-A5898CE51C9A' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '59C7CD4B-B627-4C88-8303-91FA48EA72AD' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'BF6DC65D-8B63-4C26-B4F6-5FE9E8530245' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '65134CA9-E6E2-4FD9-93FF-83BC56C09A86' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '50C4EB6E-9746-49A2-80CF-F5D247A4EFD7' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'FC1C67D6-8286-403E-9E8D-84A230C56B61' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A884609A-AEAF-4FB3-9FFA-879992B88A2E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'A5E98EED-EDE7-43BE-A4F8-DD0A0DC4F982' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'CA195461-3B54-48AD-AC2C-D6BC23ABD97E' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == 'E47081E1-2622-4255-A89D-34FF3ED38AC4' ||
      this.selectedSubCatFirstChild.trim().toUpperCase() == '9D477485-41B4-4E98-A3C4-3903D84C71DE') {
      this.showRentPaidOption = true;
    }
    else {
      this.showRentPaidOption = false;
    }

  }

  private setCurrentLocation() {
    //debugger;
    if (navigator.geolocation) {
    if ('geolocation' in navigator) {
      this.showMap();
     // this.showMapStatic();
    //   if(navigator.permissions && navigator.permissions.query)
    //   {
      // navigator.permissions.query({ name: "geolocation" }).then((result) => {
      //   if (result.state === "granted") {
      //     debugger;
      //     this.showMap();
      //   } else if (result.state === "prompt") { 
      //     debugger;         
      //     this.showMapStatic();
      //     this.alertNotification("Plese Enable Location from Settings, 'MyKnocks app collects location data to enable location feature only when the application is open and user is about to submit an add' !");
      //   }
      //   else if (result.state === "denied") {          
      //     debugger;
      //     this.alertNotification("Plese Enable Location from Settings, 'MyKnocks app collects location data to enable location feature only when the application is open and user is about to submit an add' !");
      //     this.showMapStatic();
      //   }        
      //   else {
      //     //debugger;
      //     this.alertNotification("Plese Enable Location from Settings, 'MyKnocks app collects location data to enable location feature only when the application is open and user is about to submit an add' !");
      //     this.showMapStatic();
      //   }
      //   // Don't do anything if the permission was denied.
      // })      
    // }    
  }  
  }    
  }

  private showMapStatic() {
    //debugger;    
    this.zoom = 8;
    this.getAddress(this.latitude, this.longitude);
  }
 private showMap() {
  //debugger;
  if (navigator.geolocation) {
       
  navigator.geolocation.getCurrentPosition(position => {    
    this.latitude = position.coords.latitude;
    this.longitude = position.coords.longitude;
    this.zoom = 8;
    this.getAddress(this.latitude, this.longitude);
    },
    (error) => {
      //debugger;
      if(error.code == 1 )
      {
        //debugger;        
        this.showMapStatic();
        this.alertNotification("Plese Enable Location from Settings, 'MyKnocks app collects location data to enable location feature only when the application is open and user is about to submit an add' !");
          
      }
      else if (error.code ==2)
      {
        //debugger;
        this.showMapStatic();
        //this.alertNotification('hello');
        this.alertNotification("Plese Enable Location from Settings, MyKnocks app collects location data to enable location feature only when the application is open and user is about to submit an add");          
      }
      else if (error.code ==3)
      {
        //debugger;
        this.showMapStatic();
          this.alertNotification("Plese Enable Location from Settings, 'MyKnocks app collects location data to enable location feature only when the application is open and user is about to submit an add' !");         
      }
      else 
      {
        //debugger;
        this.showMapStatic();
        this.alertNotification("Plese Enable Location from Settings, 'MyKnocks app collects location data to enable location feature only when the application is open and user is about to submit an add' !");        
      }
      
    },
    {
        timeout:15000 
    }
  );
  }
  else
  {
    this.alertNotification("Plese Enable Location from Settings, 'MyKnocks app collects location data to enable location feature only when the application is open and user is about to submit an add' !");
    this.showMapStatic();
  }
 }  
 onSelectionChanged = (event) => {
  if (event.oldRange == null) {
    //this.onFocus();
  }
  if (event.range == null) {
    //this.onBlur();
  }
};
onFocus = () => {
  //console.log('On Focus');
};
onBlur = () => {
  //console.log('Blurred');
};

onContentChanged = (event) => {
  //console.log(event.html);
};

reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  ResetForm() {
    this.selectedCategory = '0';
    this.selectedSubCategory = '0';
    this.selectedSubCatFirstChild = '0'; 
    this.selectedSubCatSecondChild = '0';
    this.categoryTitle = ''
    this.subCategoryTitle = '';
    this.subToFirstTitle = '';
    this.subToSecondTitle= '';
    this.addTitle = "";
    this.descriptionArea = "";
    this.addPrice = "";
    this.selectedCurrency = null;
    this.selectedAge = null;
    this.selectedUsage = null;
    this.selectedCondition = null;
    this.selectedRWE = null;
    this.selectedRCommitment = null;
    this.selectedRShift = null;
    this.selectedREducation = null;
    this.selectedPosition = null;
    this.selectedRGender = null;
    this.selectedDegree = null;
    this.selectedCompensation = null;
    this.selectedMonth = null;
    this.selectedBeds = null;
    this.selectedBaths = null;
    this.selectedBaths = null;
    this.selectedDuration = null;
    this.selectedPaid = null;
    this.selectedYear = null;
    this.selectedDoor = null;
    this.selectedColor = null;
    this.selectedAutoUsage = null;
    this.selectedKM = null;
    this.selectedBodyCondition = null;
    this.selectedMechenicalCondition = null;
    this.selectedTransmission = null;
    this.selectedBodyType = null;
    this.selectedCylender = null;
    this.selectedFuelType = null;
    this.selectedHPList = null;
    this.selectedWarranty = null;
    this.selectedTrim = null;
    this.selectedAutoAge = null;
    this.selectedAutoUsage = null;
    this.imageurls.splice(0);
    this.imageurls.splice(1);
    this.imageurls.splice(2);
    this.imageurls.splice(3);
    this.imageurls.splice(4);
    this.imageurls.splice(5);
    this.videoUrls.splice(0);
    this.imageurls = null;
    this.imageurlsNew = null;

  }



  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  markerDragEnd($event: any) {
    //console.log($event);
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }


  // coordinates = new google.maps.LatLng(this.latitude, this.longitude);

  //   mapOptions: google.maps.MapOptions = {
  //    center: this.coordinates,
  //    zoom: 8
  //   };

  //   marker = new google.maps.Marker({
  //     position: this.coordinates,
  //     map: this.map,
  //   });

  //   ngAfterViewInit() {
  //     this.mapInitializer();
  //   }

  //   mapInitializer() {
  //     this.map = new google.maps.Map(this.gmap.nativeElement, 
  //     this.mapOptions);
  //     this.marker.setMap(this.map);
  //   }
  //  }



  // function loadCategoryDialogue(response, category, level) {
  //   var listToChkFirst = document.getElementById('listSelect');
  //   var listToChkSecond = document.getElementById('listSubSelect');
  //   var listToChkThird = document.getElementById('listSubToSecondSelect');
  //   var listToChkfourth = document.getElementById('listSubToThirdSelect');
  //   var finalSelectionAlert = document.getElementById('finalSelectionAlert');

  //   //debugger;
  //   if (level == 1) {
  //       vm.categoryTitle = category.Cat;
  //       vm.subCategoryTitle = "";
  //       vm.subToFirstTitle = "";
  //       vm.subToSecondTitle = "";

  //       vm.finalSelectionMessage = "";
  //       vm.subCatList = response.data;
  //       listToChkFirst.style.display = "block";

  //       vm.subToSubCatList = null;
  //       vm.subToSecondList = null;
  //       vm.subToThirdList = null;

  //       listToChkSecond.style.display = "none";
  //       listToChkThird.style.display = "none";
  //       listToChkfourth.style.display = "none";

  //       // listToChkFirst.style.width = "100%!important";
  //       // listToChkSecond.style.width = "0%!important";
  //       // listToChkThird.style.width = "0%!important";

  //       //document.getElementById('listSubSelect');
  //   } else if (level == 2) {
  //       vm.subCategoryTitle = category.cat_Name;
  //       vm.subToFirstTitle = "";
  //       vm.subToSecondTitle = "";

  //       if (response.data.length > 0) {
  //           vm.subToSubCatList = response.data;
  //           listToChkFirst.style.display = "block";
  //           listToChkSecond.style.display = "block";
  //           vm.finalSelectionMessage = "";
  //           finalSelectionAlert.style.display = "none";

  //           vm.finalSelection = "";
  //       } else {
  //           vm.finalSelectionMessage = "Your selection is done Kindly close the Box !";
  //           vm.subToSubCatList = null;

  //           listToChkSecond.style.display = "none";
  //           finalSelectionAlert.style.display = "block";
  //           vm.finalSelection = category.cat_ID;
  //       }

  //       vm.subToSecondList = null;
  //       vm.subToThirdList = null;

  //       listToChkThird.style.display = "none";
  //       listToChkfourth.style.display = "none";

  //   } else if (level == 3) {
  //       vm.subToFirstTitle = category.cat_Name;
  //       vm.subToSecondTitle = "";

  //       if (response.data.length > 0) {
  //           vm.subToSecondList = response.data;
  //           listToChkFirst.style.display = "block";
  //           listToChkSecond.style.display = "block";
  //           listToChkThird.style.display = "block";
  //           vm.finalSelectionMessage = "";
  //           finalSelectionAlert.style.display = "none";

  //           vm.finalSelection = "";

  //       } else {
  //           vm.finalSelectionMessage = "Your selection is done Kindly close the Box !";
  //           listToChkThird.style.display = "none";
  //           vm.subToSecondList = null;
  //           finalSelectionAlert.style.display = "block";

  //           vm.finalSelection = category.cat_ID;
  //       }

  //       vm.subToThirdList = null;

  //       listToChkfourth.style.display = "none";
  //   } else if (level == 4) {
  //       vm.subToSecondTitle = category.cat_Name;
  //       if (response.data.length > 0) {

  //           vm.subToThirdList = response.data;

  //           listToChkFirst.style.display = "block";
  //           listToChkSecond.style.display = "block";
  //           listToChkThird.style.display = "block";
  //           listToChkfourth.style.display = "block";

  //           vm.finalSelectionMessage = "";
  //           finalSelectionAlert.style.display = "none";
  //           vm.finalSelection = "";

  //       } else {
  //           vm.finalSelectionMessage = "Your selection is done Kindly close the Box !";
  //           listToChkfourth.style.display = "none";
  //           finalSelectionAlert.style.display = "block";
  //           vm.finalSelection = category.cat_ID;
  //       }
  //   }
  //   // loadCategoryPostTypeForm();
  // }

  // 042F8B36-2F6E-4A05-8DF0-2BC49710D67C   -- > boats sub caregory
  // 79504CCA-41F7-4936-ACF3-6757E9AA7E29   -- > Car sub caregory
  // FE1C13DE-9342-4EFB-824B-806DA0B8E3FE   -- > Heavy Vahical sub category
  // 6240A3B2-15B4-49AC-BF96-B30311BAEC60   -- > Motorcycles sub category
  // 9C89C78F-B925-4129-A95B-D4D750954AA3   -- > Accessories & Parts
}
