export class jobsAvailable{        
    public jobID: number;
    public JobAddID : string ;  
    public jobExpID : number ;      
    public jobEduID : number ;  
    public jobCommitmentID : number ;  
    public jobCatID : string ;  
    public jobSubCatID : string ;  
    public jobsFromSalary : number ;  
    public jobsToSalary : number ;  
    public jobsShiftID : number ;  
    public jobsNoOfPositions : number ;  
    public jobsDisplaySalary : boolean ;  
    public jobsjDegreeTitle : string ;  
    public jobsTravelRequired : boolean ;  
    public jobsGenderID : number ;  
    public jobsFromAge : number ;  
    public jobsToAge : number ;  
    public jobsRequiredGender : boolean ;  
    public jobsRequiredExp : boolean ;  
    public jobsRequiredDegree : boolean ;  
    public jobsRequiredAge : boolean ;  
    public jobsRequiredCity : boolean ;     

}