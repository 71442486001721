export class Orders{ 
    public OrderId: number;
    public OrderNumber: string;
    public CustomerID : number ;  
    public CompanyID: number;    
    public ShippingAddressId: number;
    public PaymentDetailId : number ;  
    public OrderStatus : number ; 
    public OrderTotal : number ;  
    public OrderCurrency: number;
    public OrderDiscountApplied : number ;  
    public OrderCoupenAppliedId : number ;  
    public ShippingMethodId : number ;  
    public OrderAddedBy : number ; 
    public OrderAddedDate: string;
    public OrderEditedBy : number ;  
    public OrderEditDate : string;      
}