import { Component, OnInit } from '@angular/core';
import { companyService } from './company-list.service';
import { TopCompanies } from './company.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscribable, Subscription } from 'rxjs';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {

  topCompanies : TopCompanies[];

  constructor(private http: HttpClient,private comService: companyService) { }

  ngOnInit(): void {
   // //debugger;
    this.getComList();
  }

  getComList() {
    this.comService.getRecipe()
    .subscribe((data:TopCompanies[]) => {
      //console.log(data);
      this.topCompanies = data;
      //console.log(this.topCompanies);
      this.topCompanies.forEach(element => {
        //console.log(element);
      });
    });
  }

}
