<h3 class="text-center">Checking Out Products - Billing Details</h3>

<div class="col-xs-12 col-sm-12 col-md-12 board-inner" id="status-buttons">
  <ul class="nav nav-tabs" id="myTab">
    <div class="liner"></div>

    <!-- circular user icon -->
    <li id="productsTab" style="margin: auto;">
      <a [routerLink]="['/usercart']" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" data-toggle="tab" title="Product Summary">
        <span class="round-tabs one">
          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
        </span>
      </a>
    </li>

    <!-- circular tasks icon -->
    <li id="shippingTab" style="margin: auto;">
        <a [routerLink]="['/checkouts/usershopping']" routerLinkActive="active" data-toggle="tab" title="Shipping Details">
        <span class="round-tabs two">
          <i class="fa fa-truck" aria-hidden="true"></i>
        </span>
      </a>
    </li>

    <!-- circular home icon -->
    <li id="billingTab" style="margin: auto;">
      <a [routerLink]="[
          '/checkouts',
          { outlets: { checkOutlet: ['billing-details'] } }
        ]" routerLinkActive="active" data-toggle="tab" title="Confirmation">
        <span class="round-tabs three">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </span>
      </a>
    </li>

    <!-- circular ok icon -->
    <li id="resultTab" style="margin: auto;">
      <a [routerLink]="['/checkouts', { outlets: { checkOutlet: ['result'] } }]" routerLinkActive="active"
        data-toggle="tab" title="Payment">
        <span class="round-tabs four">
          <i class="fa fa-credit-card" aria-hidden="true"></i>
        </span>
      </a>
    </li>
  </ul>
</div>
<div class="row col-xs-12 col-sm-12 col-md-12 col-lg-10 col-sm-offset-1 col-md-offset-1" style="margin-top: 25px;">
    <!-- <div class="tg-dashboardbanner">
        <h1>My Orders </h1> &nbsp;        
        <ol class="tg-breadcrumb">
            <li><a (click)="backtoMain()" style="cursor: pointer;text-decoration:none">Go to Home</a></li>           
            <li class="tg-active">Order Details</li>
        </ol>
    </div> -->
        <section class="tg-dbsectionspace tg-haslayout">
            <div class="row">
                <fieldset>
                    <div class="tg-postanad">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="tg-dashboardbox">
                                <div class="tg-dashboardboxtitle">
                                    <h2>payment Details</h2>
                                </div>
                                <div class="col-xs-10 col-sm-10 col-md-10 col-md-offset-1" style="border: 2px solid rgba(0, 0, 0, .125);
                                border-radius: 10px; padding-top: 15px; padding-bottom: 15px; margin-top: 25px; margin-bottom: 25px;">                                                              
                                    <h4 class="mb-3">Order Ammount </h4>
                                    <div class="col-xs-10 col-sm-10 col-md-10">
                                        <div class="row">
                                            <div class="col-xs-2 col-sm-2 col-md-2">
                                                <dt>Total price:</dt>
                                            </div>
                                            <div class="col-xs-2 col-sm-2 col-md-2">
                                                <dd class="text-left ml-3" >{{ totalValue }}</dd>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="col-xs-10 col-sm-10 col-md-10 col-md-offset-1" style="border: 2px solid rgba(0, 0, 0, .125);
                                                    border-radius: 10px; padding-top: 15px; padding-bottom: 15px; margin-top: 25px; margin-bottom: 25px;">                                                              
                                                        <h4 class="mb-3">Card Details</h4>
                                                        <form class="needs-validation" #f="ngForm" (ngSubmit)="onUAECardSubmit(f)">
                                                          <div class="row">
                                                            <div class="col-md-6 mb-3">
                                                              <label for="firstName">Card Number</label>
                                                              <input type="number" class="form-control" placeholder="14 digit card number " maxlength="14" id="cardNumber" [(ngModel)]="cardNumber" name="cardNumber" />
                                                              <div class="invalid-feedback" *ngIf="cardNumberAlert==true">
                                                                Valid card number is required.
                                                              </div>
                                                              <div class="invalid-feedback" *ngIf="cardNumberDigitAlert==true">
                                                                Valid card number 12 Digit is required.
                                                              </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                              <label for="ExpiryMonth">Expiry Month</label>
                                                              <input type="number" class="form-control" maxlength="2" id="ExpiryMonth" [(ngModel)]="ExpiryMonth" name="ExpiryMonth" />
                                                              <div class="invalid-feedback" *ngIf="ExpiryMonthAlert==true">
                                                                Enter valid Expiry Month
                                                              </div>
                                                            </div>
                                                          </div>
                                                    
                                                          <div class="col-md-6 mb-3">
                                                            <label for="ExpiryMonth">Expiry Year</label>
                                                            <input type="number" class="form-control" maxlength="2" id="ExpiryYear" [(ngModel)]="ExpiryYear" name="ExpiryYear" />
                                                            <div class="invalid-feedback" *ngIf="ExpiryMonthAlert==true">
                                                              Enter valid Expiry Year
                                                            </div>
                                                          </div>
                                                    
                                                          <div class="col-md-6 mb-3">
                                                              <label for="address">CVV</label>
                                                              <input type="number" class="form-control" maxlength="3" id="cardCvv" placeholder="123" [(ngModel)]="cardCvv"
                                                                name="cardCvv" />
                                                              <div class="invalid-feedback" *ngIf="cardCvvAlert == true">
                                                                Enter valid Three Digit code only
                                                              </div>
                                                          </div>             
                                                          <hr class="col-md-6 mb-3" />
                                                          <button class="btn btn-primary btn-lg btn-block" type="submit">
                                                            Continue to checkout
                                                          </button>
                                                        </form>
                                </div>

                                <!-- <pagination-controls class="my-pagination text-center" (pageChange)="page = $event">
                                </pagination-controls> -->
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </section>    
</div>

<!-- 
<div class="col-xs-12 col-sm-12 col-md-12 col-sm-offset-1 col-md-offset-1 ">
    
   
    
    <div class="row" style="margin-top: 150px;">
        <aside class="col-lg-6" style="    border: 2px solid rgba(0, 0, 0, .125);
        border-radius: 10px; padding-top: 15px; padding-bottom: 15px; margin-right: 10px;">
            <div class="container-xl center">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr style="background: #00cc67;color: white;">
                            <th scope="col">Product</th>
                            <th scope="col" width="120">Quantity </th>
                            <th scope="col" width="120">Unit Price</th>
                            <th scope="col" width="120">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of cartProducts | paginate: { itemsPerPage: 10, currentPage: page,totalItems:TotalLength }">
                            <td>
                                <figure class="itemside align-items-center">
                                    <div class="aside">
                                        <img *ngIf="product.proImageBase != null" [src]="product.proImageBase" class="img-sm" />
                                        <img *ngIf="product.proImageBase == null" src="../../assets/images/ads/no_image.jpg" class="img-sm" />
                                    </div>
                                    <figcaption class="info"> <a href="javascript:void(0)" class="title text-dark"
                                            data-abc="true">{{product.proName}}</a>
                                        <p class="text-muted small">Brand: {{product.proBrandName}}</p>
                                    </figcaption>
                                </figure>
                            </td>
                            <td> 
                               <input type="number" disabled class="form-control" (change)="changeQuantity(product,product.proQuantity)" [(ngModel)]="product.proQuantity" name="quantity">                                
                            </td>
                            <td>
                                <div class="price-wrap"> <var class="price">{{product.proPrice}}</var> <small
                                        class="text-muted">&nbsp;{{product.proPriceUnitName}}</small> </div>
                            </td>
                            <td>
                                <div class="price-wrap"> <var class="price">{{product.proPrice * product.proQuantity}}</var> <small
                                        class="text-muted">&nbsp;{{product.proPriceUnitName}}</small> </div>
                            </td>
                           
                        </tr>
                        <tr *ngIf="!cartProducts || cartProducts.length==0">
                            <td colspan="6">No data found</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls class="my-pagination text-center" (pageChange)="page = $event">
                </pagination-controls>
            </div>
        </aside>
        <aside class="col-lg-4" style="    border: 2px solid rgba(0, 0, 0, .125);
        border-radius: 10px; padding-top: 15px; padding-bottom: 15px;">
            <div class="card">
                <div class="card-body">
                    <h4 class="d-flex justify-content-between align-items-center mb-3">
                        <span class="text-muted">Your cart</span>
                        <span class="badge badge-primary badge-pill" style="float: right;background: #00cc67;">{{ cartProducts.length }}</span>
                    </h4>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <h5 style="text-align: center;">Order Summary</h5>
                        <table class="table table-striped table-hover">
                            <thead>
                                <th style="text-align: center;">Name</th>
                                <th style="text-align: center;">Quantity</th>
                                <th style="text-align: center;">Price</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of cartProducts">
                                    <td>{{product.proName}}</td>
                                    <td>{{product.proQuantity}}</td>
                                    <td>{{product.proPrice * product.proQuantity}} {{product.proPriceUnitName}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <hr />
                    <dl class="dlist-align" style="margin-bottom: 0px!important;">
                        <dt>Total price:</dt>
                        <dd class="text-right ml-3" >{{ totalValue }}</dd>
                    </dl>
                    <dl class="dlist-align" style="margin-bottom: 0px!important;">
                        <dt>Delivery Fee:</dt>
                        <dd class="text-right text-danger ml-3">+  0.00</dd>
                    </dl>
                    <dl class="dlist-align" style="margin-bottom: 0px!important;">
                        <dt>Total:</dt>
                        <dd class="text-right text-dark b ml-3"><strong>{{ grandTotal }}</strong></dd>
                    </dl>
                    <div class="row" style="margin-bottom: 10%;">
                        <div *ngIf="shippingCountry != '1' || shippingCountry != '2'" class="col-lg-4" style="float: left;">
                            <a href="javascript:void(0)" style="margin-left: 5px;margin-right: 5px;" (click)="onOrderSubmit()"  class="btn btn-out btn-success btn-square btn-main" > Confirm Order</a>
                        </div>
                        <div *ngIf="shippingCountry == '1' || shippingCountry == '2'" class="col-lg-4" style="float: left;">
                          <a href="javascript:void(0)" style="margin-left: 5px;margin-right: 5px;" (click)="onOrderCheckOut()"  class="btn btn-out btn-success btn-square btn-main" > Checkout</a>
                      </div>
                        <div class="col-lg-4" style="float: left;">
                            <a href="javascript:void(0)" style="margin-left: 5px;margin-right: 5px;"  class="btn btn-out btn-success btn-square btn-main" data-abc="true">Go Back Shopping</a>
                        </div>
                    </div>
                </div>
            </div>
        </aside>         
    </div>
  </div> -->



